export const display = (value) => {
  if (value) {
    return value;
  } else {
    return " - ";
  }
};

export const eitherDisplayOrRemove = (value) => {
  // IF VALUE IS TRUTHY  DISPLAY VALUE
  if (value) {
    return value;
    // IF VALUE IS FALSY ( NULL OR UNDEFINED OR EMPTY STRING OR 0 OR FALSE)  DISPLAY EMPTY STRING
  } else {
    return " ";
  }
};

export const displayFormatted = (oldText, newText) => {
  if (oldText) {
    return newText;
  } else {
    return "";
  }
};

export const format = {
  replace: (oldText, newText) => newText,
  autoFormat: (oldtext) => {
    const words = oldtext.replaceAll("_", " ");
    const cap = words[0].toUpperCase() + words.slice(1);
    return cap;
  },
};

export const formatCurrency = (amount, currency = "USD") => {
  const options = {
    style: "currency",
    currency,
    minimumFractionDigits: amount % 1 === 0 ? 0 : 2, // Show decimals only if the amount has them
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat("en-US", options).format(amount);
};

export const truncateText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const generateUniqueKey = () => {
  return Math.random().toString(36).substring(2, 15);
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const toggleClassName = (
  currentClassName,
  toggleClassName,
  condition
) => {
  return condition
    ? `${currentClassName} ${toggleClassName}`
    : currentClassName;
};

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const filterArrayByProperty = (array, propertyName, filterValue) => {
  return array.filter((item) => item[propertyName] === filterValue);
};

export const customisePartName = (name) => {
  if (name == "oem") return "OEM";
  if (name == "after_market") return "After Market";
  if (name == "used") return "Used";
  if (name == "oes") return "OES";
};
