import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../App";
import "../../components/Models/VehicleModel.css";
import { configDetails } from "../../redux/Account/action";
import {
  addVehicle,
  decodeLicence,
  decodeVIN,
  importedVehicleCreated,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../redux/Customer/action";
import { fetchProvinces } from "../../redux/Vehicles/action";
import useDebounce from "../../utility/useDebounce";
import { checkValidVIN } from "../../utility/utilityFunctions";
import SuccessMessageModal from "../Common/SuccessMessage";

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  outline: "none",
};

const MissingVehicleInfoModal = ({
  open,
  handleClose,
  id,
  vehicalDetailFunction,
  setAddVehicleInfoButton,
  setVehicleCreatedId,
  // vehicles,
  // setVehicles,
  // editVehicle,
  // setEditVehicle,
  vehicleSource,
}) => {
  const {
    handleSubmit,
    control,
    setValue: setValueinForm,
    formState: { errors },
    reset,
    getValues,
    setError,
    clearErrors,
    watch,
    setFocus,
  } = useForm();
  const [step, setStep] = useState(vehicleSource ? 2 : 1);
  const [decode, setDecode] = useState({
    vin: false,
    licence: false,
  });
  const dispatch = useDispatch();
  const [vinError, setVINError] = useState("");

  const [vinLoading, setVINLoading] = useState(false);
  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [trimEngineValue, setTrimEngineValue] = useState("");
  const [yearPage, setYearPage] = useState(1);
  const [provinceValue, setProvinceValue] = useState("");

  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [provinces, setProvince] = useState([]);

  const [makeLoader, setMakeLoader] = useState(false);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [provinceLoader, setProvinceLoader] = useState(false);

  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);

  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  let vehicleMakeParams = {
    page: makeValuePage,
    limit: 500,
    year: yearValue,
  };
  const watchedLicenceValue = watch("licenseNumber", "");

  // Use debounce for the watched value
  const debouncedLicenceValue = useDebounce(watchedLicenceValue, 1500);

  const watchedVINValue = watch("vin", "");

  // Use debounce for the watched value
  const debouncedVINValue = useDebounce(watchedVINValue, 1500);

  const fetchConfig = async () => {
    await dispatch(configDetails());
  };
  const config = useSelector((state) => state.account.configDetailData);

  useEffect(() => {
    fetchVehicleYear();
    fetchProvincesList();
    // fetchVehicleMake(vehicleMakeParams);
  }, []);
  useEffect(() => {
    if (open) {
      fetchConfig();
    }
  }, [open]);
  useEffect(() => {
    if (vehicleSource) {
      setStep(2);
      setValueinForm("vin", vehicleSource?.vin);
      setValueinForm("licenseNumber", vehicleSource?.license);

      if (vehicleSource?.vehicleYear) {
        setValueinForm("year", vehicleSource?.vehicleYear);
        setYearValue(vehicleSource?.vehicleYear);
      }
      fetchVehicleMake({
        page: makeValuePage,
        limit: 500,
        year: vehicleSource?.vehicleYear,
      });

      if (vehicleSource?.vehicleMake?.name) {
        setValueinForm(
          "vehicleMakeId",

          vehicleSource?.vehicleMake?._id
        );
        setMakeValue(vehicleSource?.vehicleMake?._id);

        fetchVehicleModel({
          page: modelValuePage,
          limit: 1000,
          vehicleMakeId: vehicleSource?.vehicleMake?._id,
          year: vehicleSource?.vehicleYear,
        });
      }
      if (vehicleSource?.vehicleModel?.name) {
        setValueinForm("vehicleModelId", vehicleSource?.vehicleModel?._id);
        setModelValue(vehicleSource?.vehicleModel?._id);

        fetchVehicleTrim({
          page: trimValuePage,
          limit: 1000,
          vehicleMakeId: vehicleSource?.vehicleMake?._id,
          vehicleModelId: vehicleSource?.vehicleModel?._id,
          year: vehicleSource?.vehicleYear,
        });
      }

      if (vehicleSource?.vehicleModelTrim?.name) {
        setValueinForm(
          "vehicleModelTrimId",
          vehicleSource?.vehicleModelTrim?._id
        );
        setTrimValue(vehicleSource?.vehicleModelTrim?._id);
      }
    }
  }, [vehicleSource]);

  const fetchProvincesList = async () => {
    await dispatch(fetchProvinces());
  };

  const provincesList = useSelector((state) => state.vehicle.provinces)?.sort(
    (a, b) => a.province.localeCompare(b.province)
  );

  useEffect(() => {
    setProvince(provincesList);
  }, [provincesList]);

  useEffect(() => {
    const defaultProvince = provinces?.find((d) => d.isDefault === true)?._id;
    setValueinForm("province", defaultProvince);
    setProvinceValue(defaultProvince);
  }, [provinces, open, provinceValue]);

  const fetchVehicleYear = async () => {
    await dispatch(vehicleYearMaster({ page: yearPage, limit: 500 }));
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const makes = useSelector((state) => state.customer?.vehicleMake);

  useEffect(() => {
    setMake(makes);
  }, [makes]);

  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };

  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);

    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);

  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");
      setShowLoader(true);
      setVINLoading(true);

      const resp = await dispatch(decodeVIN(getValues()?.vin));
      console.log("🚀 ~ handleDecodeVIN ~ resp:", resp);

      if (resp?.status) {
        // setModelSelectLoader(true);
        setMakeValue(resp?.data?.make?._id);
        setModelValue("");
        setTrimValue("");
        await dispatch(
          vehicleMakeMaster({
            page: 1,
            limit: 1000,
            year: resp?.data?.year,
          })
        );
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          year: resp?.data?.year,
        };
        let maekResult = await dispatch(vehicleModelMaster(para));
        if (maekResult) {
          let para = {
            page: 1,
            limit: 1000,
            vehicleMakeId: resp?.data?.make?._id,
            vehicleModelId: resp?.data?.model?._id,
            year: resp?.data?.year,
          };
          await dispatch(vehicleTrimMaster(para));
        }

        setValueinForm("year", resp?.data?.year + "");
        setValueinForm("vehicleMakeId", resp?.data?.make?._id);
        setValueinForm("vehicleModelId", resp?.data?.model?._id);
        setValueinForm("vehicleModelTrimId", resp?.data?.trim?._id);
        setYearValue(resp?.data?.year);
        setMakeValue(resp?.data?.make?._id);
        setModelValue(resp?.data?.model?._id);
        setTrimValue(resp?.data?.trim?._id);

        setVINLoading(false);
        if (step == 1) setStep(2);
      }
      setShowLoader(false);
    } else if (getValues()?.vin && getValues()?.vin.length == 0) {
      setVINLoading(false);
      setShowLoader(false);
      clearErrors("vin");
      // setVINError("Please Enter Valid VIN");
    } else {
      setVINLoading(false);
      setShowLoader(false);

      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");

      // message.error("Please enter VIN number");
    }
  };
  const handleDecodeLicence = async (value) => {
    if (!provinceValue) {
      setError("province", {
        message: "Please select province",
      });
      return toast.info("Please select province");
    }
    if (provinceValue && debouncedLicenceValue) {
      setShowLoader(true);
      const resp = await dispatch(
        decodeLicence({
          licenceNumber: debouncedLicenceValue,
          provinceCode: provinces?.find((d) => d._id == provinceValue)
            ?.provinceCode,
        })
      );
      // setModelSelectLoader(true);
      console.log("resprespresp", resp);
      if (resp?.status) {
        setValueinForm("vin", resp?.data?.vin);
        handleDecodeVIN();
      } else {
        setShowLoader(false);
      }
    }
  };
  useEffect(() => {
    if (config?.isLicensePlateDecodeEnabled && debouncedLicenceValue) {
      // Replace with your fetch function
      if (decode?.licence) handleDecodeLicence(debouncedLicenceValue);
    }
  }, [debouncedLicenceValue]);
  useEffect(() => {
    if (debouncedVINValue) {
      // Replace with your fetch function
      if (decode?.vin) handleDecodeVIN();
    }
  }, [debouncedVINValue]);
  const onSubmit = async (values) => {
    setShowLoader(true);

    if (vehicleSource?.source == "shop_imported") {
      let payload = {
        year: values?.year + "",
        vehicleMakeId: values?.vehicleMakeId,
        vehicleModelId: values?.vehicleModelId,
        customerId: id,
      };
      if (values?.vin) {
        payload.vin = values?.vin;
      }
      if (values?.province) {
        payload.provinceId = values?.province;
      }
      if (values?.licenseNumber) {
        payload.licenseNumber = values?.licenseNumber;
      }
      if (values.vehicleModelTrimId) {
        payload.vehicleModelTrimId = values?.vehicleModelTrimId;
      }

      const resp = await dispatch(
        importedVehicleCreated(payload, vehicleSource?._id)
      );

      if (resp.status) {
        setAddVehicleInfoButton(true);
        vehicalDetailFunction(id);
        handelCloseModel();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        setOpenSuccessModal(true);
        setSuccessMessage("New Vehicle Added");
        setVehicleCreatedId(resp?.data?._id);
      }
    }
    if (!vehicleSource) {
      let payload = {
        year: values?.year + "",
        vehicleMakeId: values?.vehicleMakeId,
        vehicleModelId: values?.vehicleModelId,
        vin: values?.vin,
        // licenseNumber: values.licenseNumber,
        customerId: id,
        provinceId: values?.province,
      };
      if (values?.province) {
        payload.provinceId = values?.province;
      }
      if (values?.licenseNumber) {
        payload.licenseNumber = values?.licenseNumber;
      }
      if (values.vehicleModelTrimId) {
        payload.vehicleModelTrimId = values?.vehicleModelTrimId;
      }

      const resp = await dispatch(addVehicle(payload));
      console.log("🚀 ~ onSubmit ~ resp:", resp);

      if (resp?.status) {
        // setShowLoader(false);
        setAddVehicleInfoButton(true);
        vehicalDetailFunction(id);
        handelCloseModel();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        setOpenSuccessModal(true);
        setSuccessMessage("New Vehicle Added");
        setVehicleCreatedId(resp?.data?._id);
      }
    }
    setShowLoader(false);
  };
  const handelCloseModel = () => {
    handleClose();
    reset();
    setTrimEngineValue(null);
    setYearValue(null);
    setMakeValue(null);
    setModelValue(null);
    setTrimValue(null);
    setDecode({ vin: false, licence: false });
    setProvinceValue("");
    setStep(1);
  };
  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];

    if (firstErrorField) {
      // Select the error element using the name attribute
      const errorElement = document.querySelector(
        `[name="${firstErrorField}"]`
      );
      if (errorElement) {
        // Scroll to the error element smoothly
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => {
          const offset = 140; // Adjust this value based on your modal's header/footer
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - offset; // Absolute position adjustment

          // Ensure scrolling within the modal
          const modalContainer = document.querySelector(".MuiDialog-container"); // Adjust selector based on your modal structure
          if (modalContainer) {
            modalContainer.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
          // Set focus to the error field
          setFocus(firstErrorField);
        }, 100); // Delay to ensure scroll completes
      }
    }
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={handelCloseModel}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            // width: "41vw",
            // width: "100%",
            // maxWidth: "50vw",
            // maxHeight: "90vh",
            // overflow: "hidden",
            // overflowY: "scroll",
          }}
          className="modelSize"
        >
          <AppBar color="secondary" variant="elevation" handelCloseModel>
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                {vehicleSource ? "Confirm Vehicle Information" : "Add Vehicle"}
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handelCloseModel}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              marginTop: "64px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit, onInvalid)} noValidate>
              <Grid
                container
                spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflow: "auto",
                  marginBottom: "24px",
                  maxHeight: "76vh",
                  padding: "24px",
                  paddingBottom: 0,
                }}
              >
                {(!getValues().year ||
                  !getValues().vehicleMakeId ||
                  !getValues().vehicleModelId ||
                  (config?.isLicensePlateRequired &&
                    !getValues().licenseNumber)) && (
                  <Grid item xs={12}>
                    <Typography style={{ color: REQUIRED_RED }}>
                      Please fill out the missing fields for this vehicle.
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    VIN{" "}
                    {/* {step == 1 && <span style={{ color: "#D32F2F" }}>*</span>} */}
                  </Typography>
                  <Controller
                    name="vin"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        // required
                        inputProps={{
                          maxLength: 17, // Limits input length to 17 characters
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.vin}
                        helperText={errors.vin ? errors.vin.message : ""}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setDecode({ ...decode, vin: true });

                          setValueinForm("vin", e.target.value?.toUpperCase());
                          if (e.target.value !== "") {
                            const regex = checkValidVIN(e.target.value);

                            if (regex) {
                              clearErrors("vin");
                              handleDecodeVIN();
                            } else {
                              setError("vin", {
                                message: "Please enter valid VIN",
                              });
                            }
                          } else {
                            clearErrors("vin");
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    paddingLeft: "24px",
                    marginTop: "24px",
                  }}
                >
                  or
                </Divider>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Year <span style={{ color: "#D32F2F" }}> *</span>
                    </Typography>
                    <Controller
                      defaultValue=""
                      name="year"
                      control={control}
                      rules={{
                        required: "Year is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          fullWidth
                          value={yearValue}
                          onChange={(event) => {
                            field.onChange(event);

                            setYearValue(event.target.value);
                            setMakeValue(null);
                            setModelValue(null);
                            setTrimValue(null);
                            setMake([]);
                            setModel([]);
                            setTrim([]);
                            fetchVehicleMake({
                              page: makeValuePage,
                              limit: 500,
                              year: event.target.value,
                            });
                            setValueinForm("vehicleMakeId", "");
                            setValueinForm("vehicleModelId", "");
                            setValueinForm("vehicleModelTrimId", "");
                          }}
                          error={!!errors.year}
                          helperText={errors.year ? errors.year.message : ""}
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                        >
                          {Array.isArray(year) &&
                            year?.map((y) => {
                              return (
                                <MenuItem value={y} key={y}>
                                  {y}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                    {errors.year && (
                      <FormHelperText error>
                        {errors.year.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Make <span style={{ color: "#D32F2F" }}> *</span>
                    </Typography>
                    {makeLoader && makeValue != null ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        sx={{ fontSize: "3.5rem" }}
                        className="skelton"
                      ></Skeleton>
                    ) : (
                      <Controller
                        name="vehicleMakeId"
                        defaultValue={""}
                        control={control}
                        rules={{
                          required: "Make is required",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            value={makeValue ? makeValue : ""}
                            disabled={yearValue !== "" ? false : true}
                            onChange={(event) => {
                              field.onChange(event);

                              setMakeValue(event.target.value);
                              setModelValue(null);
                              setModel([]);
                              setTrim([]);
                              setTrimValue(null);
                              setValueinForm("vehicleModelId", "");
                              setValueinForm("vehicleModelTrimId", "");
                              fetchVehicleModel({
                                page: modelValuePage,
                                limit: 1000,
                                vehicleMakeId: event.target.value,
                                year: yearValue,
                              });
                            }}
                            error={!!errors.vehicleMakeId}
                            helperText={
                              errors.vehicleMakeId
                                ? errors.vehicleMakeId.message
                                : ""
                            }
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                          >
                            {Array.isArray(make) &&
                              make?.map((m) => {
                                return (
                                  <MenuItem value={m?._id} key={m?._id}>
                                    {m?.name}
                                  </MenuItem>
                                );
                              })}
                            {makeLoader && (
                              <MenuItem disabled>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        )}
                      />
                    )}
                    {errors.vehicleMakeId && (
                      <FormHelperText error>
                        {errors.vehicleMakeId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Model <span style={{ color: "#D32F2F" }}> *</span>
                    </Typography>
                    {modelLoader && modelValue != null ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        sx={{ fontSize: "3.5rem" }}
                        className="skelton"
                      ></Skeleton>
                    ) : (
                      <Controller
                        name="vehicleModelId"
                        control={control}
                        rules={{
                          required: "Model is required",
                        }}
                        render={({ field, fieldState }) => (
                          <Select
                            {...field}
                            fullWidth
                            value={modelValue}
                            disabled={
                              yearValue !== "" && makeValue !== ""
                                ? false
                                : true
                            }
                            onChange={(event) => {
                              field.onChange(event);
                              setModelValue(event.target.value);
                              setTrimValue(null);
                              setValueinForm("vehicleModelTrimId", null);
                              setTrim([]);

                              fetchVehicleTrim({
                                page: trimValuePage,
                                limit: 1000,
                                vehicleMakeId: makeValue,
                                vehicleModelId: event.target.value,
                                year: yearValue,
                              });
                            }}
                            error={!!errors.vehicleModelId}
                            helperText={
                              errors.vehicleModelId
                                ? errors.vehicleModelId.message
                                : ""
                            }
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                          >
                            {Array.isArray(model) &&
                              model?.map((m) => {
                                return (
                                  <MenuItem value={m?._id} key={m?._id}>
                                    {m?.name}
                                  </MenuItem>
                                );
                              })}

                            {modelLoader && (
                              <MenuItem disabled>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        )}
                      />
                    )}
                    {errors.vehicleModelId && (
                      <FormHelperText error>
                        {errors.vehicleModelId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Trim
                      {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                    </Typography>
                    {trimLoader && trimValue != null ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        sx={{ fontSize: "3.5rem" }}
                        className="skelton"
                      ></Skeleton>
                    ) : (
                      <Controller
                        name="vehicleModelTrimId"
                        control={control}
                        // rules={{
                        //   required: "Make is required",
                        // }}
                        render={({ field, fieldState }) => (
                          <Select
                            fullWidth
                            disabled={
                              yearValue && makeValue && modelValue
                                ? false
                                : true
                            }
                            value={trimValue}
                            onChange={(event) => {
                              field.onChange(event);

                              setTrimValue(event.target.value);
                              setTrimEngineValue(
                                trim.find((d) => d?._id == event.target.value)
                              );
                            }}
                            error={!!errors.vehicleModelTrimId}
                            helperText={
                              errors.vehicleModelTrimId
                                ? errors.vehicleModelTrimId.message
                                : ""
                            }
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                            // {...field}
                          >
                            {Array.isArray(trim) &&
                              trim?.map((m) => {
                                return (
                                  <MenuItem value={m?._id} key={m?._id}>
                                    <div>
                                      <div style={{ fontWeight: 500 }}>
                                        {m?.name}
                                      </div>
                                      <div>{m?.description}</div>
                                    </div>
                                  </MenuItem>
                                );
                              })}
                            {trimLoader && (
                              <MenuItem disabled>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        )}
                      />
                    )}
                  </FormControl>
                </Grid>

                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    paddingLeft: "24px",
                    marginTop: "24px",
                  }}
                >
                  and
                </Divider>
                <Grid item xs={12}>
                  <Stack gap={1}>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Licence Plate Number
                      {config?.isLicensePlateRequired && (
                        <span style={{ color: "#D32F2F" }}> *</span>
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <div>
                      <FormControl style={{ width: "100px" }}>
                        {provinceLoader && provinceValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="province"
                            control={control}
                            rules={{
                              required: config?.isLicensePlateRequired
                                ? "Province is required"
                                : "",
                            }}
                            render={({ field }) => (
                              <Select
                                fullWidth
                                value={provinceValue ? provinceValue : ""}
                                renderValue={(selectedId) => {
                                  // Find and display the province code based on the selected ID
                                  const selectedProvince = provinces?.find(
                                    (d) => d._id === selectedId
                                  );
                                  return (
                                    selectedProvince?.provinceCode ||
                                    "Select Province Code"
                                  );
                                }}
                                error={!!errors.province}
                                helperText={
                                  errors.province ? errors.province.message : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                                {...field}
                                onChange={(event) => {
                                  console.log("🚀 ~ event:", event);
                                  setProvinceValue(event.target.value);
                                  field.onChange(event);
                                  clearErrors("province");
                                }}
                              >
                                {Array.isArray(provinces) &&
                                  provinces?.map((m) => {
                                    return (
                                      <MenuItem value={m?._id} key={m?._id}>
                                        {m?.province}
                                      </MenuItem>
                                    );
                                  })}
                                {provinceLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                        {errors.province && (
                          <FormHelperText error>
                            {errors.province.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <Controller
                      name="licenseNumber"
                      control={control}
                      rules={{
                        required: config?.isLicensePlateRequired
                          ? "Licence Plate Number is required"
                          : "",
                      }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          error={!!errors.licenseNumber}
                          helperText={
                            errors.licenseNumber
                              ? errors.licenseNumber.message
                              : ""
                          }
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setValueinForm(
                              "licenseNumber",
                              e.target.value?.toUpperCase()
                            );
                            setDecode({ ...decode, licence: true });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {config?.isLicensePlateDecodeEnabled &&
                                  getValues("licenseNumber") &&
                                  getValues("licenseNumber") !== "" && (
                                    <Button onClick={handleDecodeLicence}>
                                      Decode
                                    </Button>
                                  )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>

                {console.log("trimEngineValue", trimEngineValue)}
                {trimValue &&
                  (trimEngineValue?.bodyType ||
                    trimEngineValue?.engineDriveType ||
                    trimEngineValue?.engineFuelType ||
                    trimEngineValue?.engineTransmission ||
                    trimEngineValue?.engineType) && (
                    <>
                      <Divider
                        textAlign="center"
                        style={{
                          width: "100%",
                          paddingLeft: "24px",
                          marginTop: "16px",
                        }}
                      ></Divider>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        xl={12}
                        spacing={3}
                        style={{
                          // width: "100%",
                          marginLeft: "24px",
                          marginTop: "24px",
                          padding: 0,
                          background: "#F5F5F7",
                        }}
                        borderRadius={2}
                      >
                        {trimEngineValue?.engineType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineFuelType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Fuel Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineFuelType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineDriveType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Drive Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineDriveType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineTransmission && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Transmission
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineTransmission}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.bodyType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 16px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Body Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.bodyType}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                padding={"24px"}
                paddingTop={"0px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row"></Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handelCloseModel}
                      size="large"
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handelCloseModel}
                      size="large"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MissingVehicleInfoModal;
