import { Add, Delete, Edit, KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";

import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../../App";
import DiscountComponent from "../../../components/DiscountComponent/DiscountComponent";
import FormatNumber, {
  FormatNumberForKm,
} from "../../../components/FormatNumber/FormatNumber";
import { SHOP_IMPORTED } from "../../../config/dataService/constants";
import { discountProgramApi } from "../../../redux/Account/Pricing/action";
import { updateUserMembershipDetail } from "../../../redux/Account/action";
import {
  customerDetail as customerDetailApi,
  importedCustomerDetail,
} from "../../../redux/Customer/action";
import { termsAndWarrantyData } from "../../../redux/Estimate/action";
import {
  CustomersListBasedOnType,
  createNewInvoice,
  createNewInvoiceDraft,
  invoiceDetails,
} from "../../../redux/Invoice/action";
import { vehicleDetails } from "../../../redux/Vehicles/action";
import { appointmentDetails } from "../../../redux/appointment/action";
import { erMsgValidation } from "../../../utility/ValidationMessages";
import DropdownLoader from "../../Common/DropdownLoader";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AddCustomerProgramModal from "../../modal/AddCustomerProgramModal";
import AddShopSupplyModal from "../../modal/AddShopSupplyModal";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import MissingVehicleInfoModal from "../../modal/MissingVehicleInfoModal";
import ServiceModal from "../../modal/ServiceModal";
import "../Invoice.css";

const styleChip = {
  color: "#002394",
  // marginLeft: "200px",
  width: "125px",
  height: "21px",
  padding: "4px",
  alignItems: "right",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
};

const NewInvoice = () => {
  const { id, appoinmentId, customerId, type } = useParams();
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const serviceRef = useRef([]);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [storeValues, setStoreValues] = useState();
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [openProgramModal, setOpenProgramModal] = useState(false);
  const [allService, setAllServices] = useState([]);
  // const [showLoader, setShowLoader] = useState(false);
  const [termsData, setTermsData] = React.useState("");

  const [getTermsData, setGetTermsData] = React.useState("");
  const [demoDescription, setDemoDescription] = React.useState("");

  const [customersListData, setCustomersListData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [checkChangeLoader, setCheckChangeLoader] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [shopSupplyData, setShopSupplyData] = useState([]);
  const [editShopSupply, setEditShopSupply] = useState({
    edit: false,
    index: "",
  });
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const handleOpenCustomerInfo = () => setOpenCustomerInfo(true);

  const handleCloseCustomerInfo = async (resp) => {
    console.log("🚀 ~ handleCloseCustomerInfo ~ resp:", resp);
    if (resp?.data?._id) {
      setTimeout(() => {
        console.log("handleCloseCustomerInfo apidata", resp?.data);

        setValue("customerId", resp?.data?._id);
        setOpenCustomerInfo(false);
      }, 200);
    } else {
      setOpenCustomerInfo(false);
    }
  };

  const [commingFromModalData, setCommingFromModalData] = React.useState([]);
  const [commingPartsFromModalData, setCommingPartsFromModalData] =
    React.useState([]);
  const [editServiceData, setEditServiceData] = useState({
    edit: false,
    index: "",
  });
  const [IsSaveDraft, setIsSaveDraft] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    reset,
    setFocus,
    clearErrors,
  } = useForm();

  console.log(
    "🚀 ~ handleCloseCustomerInfo ~ getValues:",
    getValues().customerId
  );

  const handleOpenServiceModal = () => setOpenServiceModal(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = React.useState(false);
  const [reqDate, setreqDate] = useState(new Date());
  const [customerData, setCustomerData] = React.useState("");
  const [customerMembershipId, setCustomermemberShipId] = useState(null);
  const [isRemoveDiscount, setIsRemoveDiscount] = useState(false);
  const [vehicleData, setVehicleData] = React.useState("");
  const [selectDate, setSelectDate] = React.useState(null);
  const [completionDate, setCompletionDate] = React.useState(null);
  const [customValidationErr, setCustomValidationErr] = React.useState(null);
  const [odoValidationErr, setOdoValidationErr] = React.useState(null);
  const [customerDetail, setCustomerDetail] = React.useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [customerListLoader, setCustomerListLoader] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });

  const [data, setData] = React.useState("");
  const [dropOffDate, setDropOffDate] = useState(null);
  const [discountList, setDiscountList] = useState([]);
  const [discountId, setDiscountId] = useState(null);
  const limit = 50;
  const [totals, setTotals] = useState({
    subTotal: 0,
    discount: 0,
    taxPercentage: 13,
    tax: 0,
    estimatedTotal: 0,
    discountPercentage: 0,
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const [customerDropdownLoading, setCustomerDropdownLoading] = useState(false);
  const [vehicleDropdownLoading, setVehicleDropdownLoading] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState({});
  const [customerTypeData, setCustomerTypeData] = React.useState([]);
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [addVehicleInfoButton, setAddVehicleInfoButton] = React.useState(false);
  const [customerCretedFrom, setCustomerCretedFrom] = React.useState("");
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const [newVehicleCreatedId, setVehicleCreatedId] = useState("");
  const [vehicleSourceData, setVehicleSourceData] = React.useState("");
  const [messageType, setMessageType] = useState("");
  const [discountError, setDiscountError] = useState(false);

  const handleVehicleInfoOpen = () => {
    setVehicleInfoOpen(true);
    handleClose();
  };
  const handleVehicleInfoClose = () => {
    setVehicleInfoOpen(false);
    handleClose();
    if (vehicleSourceData) {
      setVehicleSourceData("");
      setVehicleData("");
    }
  };

  const [state, setState] = React.useState({
    shop_created: true,
    shop_imported: true,
    apex_auto_app: true,
  });
  useEffect(() => {
    reset({
      invoicePreparedBy: "",
      odoDroppedOff: "",
      odoReturned: "",
    });

    setCompletionDate(null);
    setSelectDate(null);
    setTotals({
      subTotal: 0,
      discount: 0,
      taxPercentage: 13,
      tax: 0,
      estimatedTotal: 0,
      discountPercentage: 0,
    });
    setAllServices([]);
    setCustomerData(null);
    setVehicleData(null);
    setData([]);

    setTermsData("");
    setDemoDescription("");
    setShopSupplyData([]);
  }, [window.location.pathname]);

  const fetchDiscountProgram = async () => {
    let resp = await dispatch(discountProgramApi());
    if (resp.length > 0) {
      setDiscountList(resp);
    }
  };
  const fetchCustomerMembership = async (ID, flag) => {
    let discount = await dispatch(discountProgramApi());
    if (discount.length > 0) {
      setDiscountList(discount);
    }

    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetailApi(ID));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(ID));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = ID;
    resp.refIdImport = ID;
    if (resp?.membershipProgram?._id && resp?.membershipProgram?._id !== "") {
      setCustomermemberShipId(resp.membershipProgram._id);
      if (flag) {
        setDiscountId(resp.membershipProgram._id);
        const selectedDiscount = discount.find(
          (item) => item._id === resp.membershipProgram._id
        );
        setSelectedDiscount(selectedDiscount);
      }
    } else {
      setDiscountId("none");
    }
  };

  useEffect(() => {
    if (!id && !customerId) {
      fetchDiscountProgram();
    }
  }, []);

  useEffect(() => {
    if (customerData?.refIdUsers && customerData.refIdUsers !== "") {
      fetchCustomerMembership(customerData.refIdUsers, !id);
    }
    setSelectDate(dayjs(dayjs().format("YYYY-MM-DD")));
    setValue("dropOffDate", dayjs(dayjs().format("YYYY-MM-DD")));
    setCompletionDate(dayjs(dayjs().format("YYYY-MM-DD")));
    setValue("completionDate", dayjs(dayjs().format("YYYY-MM-DD")));
  }, [customerData]);

  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      fetchVehicles();
    }
    if (customerData?.createdFrom == "shop_imported") {
      console.log("🚀 ~ useEffect ~ customerData:", customerData);
      setCustomerCretedFrom("shop_imported");
      setAddCustomerInfoButton(false);
      handleOpenCustomerInfo();
    }
  }, [customerData]);

  //validate function

  const validateInvoice = (values) => {
    console.log("🚀 ~ validateInvoice ~ values:", values);
    const dropOdo = values?.odoDroppedOff?.toString()?.replace(/,/g, "");
    const pickUpOdo = values?.odoReturned?.toString()?.replace(/,/g, "");

    // Discount validation
    if (discountError) {
      toast.error("Discount cannot be more than subtotal amount.");
      return false;
    }

    // Odometer validation
    if (+dropOdo > +pickUpOdo) {
      setOdoValidationErr(
        "Odometer Returned should be greater than Odometer Dropped Off"
      );
      return false;
    }
    setOdoValidationErr(null);

    // Date validation
    if (
      selectDate &&
      completionDate &&
      new Date(selectDate) > new Date(completionDate)
    ) {
      setCustomValidationErr(
        "Work Completion date should be later than Dropped off date."
      );
      return false;
    }
    setCustomValidationErr(null);

    if (!IsSaveDraft && allService.length === 0) {
      setMessageType("error");
      setOpenSuccessModal(true);
      setSuccessMessage("Atleast one service is required.");

      return false;
    }

    if (
      customerMembershipId &&
      customerMembershipId !== "" &&
      discountId === "none"
    ) {
      setIsRemoveDiscount(true);
    }
    const discountClone =
      discountId && discountId !== "" && discountId !== "none"
        ? discountId
        : null;

    if (discountClone && discountClone !== customerMembershipId) {
      setOpenProgramModal(true);
      setStoreValues(values);
      return false;
    } else if (customerMembershipId === discountClone) {
    } else if (customerMembershipId && customerMembershipId !== "") {
      setOpenProgramModal(true);
      setStoreValues(values);
      return false;
    }

    onSubmitInvoice(values);
    // return true;
  };

  // Helper function to convert dates to UTC ISO string
  const toISOStringUTC = (date) => {
    const inputDate = new Date(date);
    return new Date(
      Date.UTC(
        inputDate.getFullYear(),
        inputDate.getMonth(),
        inputDate.getDate(),
        0,
        0,
        0,
        0
      )
    ).toISOString();
  };

  const onSubmitInvoice = async (values) => {
    clearErrors();
    const dropOdo = values?.odoDroppedOff?.toString()?.replace(/,/g, "");
    const pickUpOdo = values?.odoReturned?.toString()?.replace(/,/g, "");

    // Prepare date strings in UTC format
    const completionDateisoString = completionDate
      ? toISOStringUTC(completionDate)
      : "";
    const selectDateDateisoString = selectDate
      ? toISOStringUTC(selectDate)
      : "";

    // Prepare payload
    console.log("handleCloseCustomerInfo", customerData?.refIdUsers);
    let payload = {
      customerId: customerData?.refIdUsers,
      invoicePreparedBy: values?.invoicePreparedBy,
      odoDroppedOff: dropOdo,
      odoReturned: pickUpOdo,
      vehicleId: vehicleData,
      appointmentCompletionDate: completionDateisoString,
      appointmentDroppedOffDate: selectDateDateisoString,
      shopSupplies: [...shopSupplyData],
      discount: totals?.discount,
      tax: totals?.tax,
      subTotal: totals?.subTotal,
      Total: totals?.estimatedTotal,
    };

    if (appoinmentId) payload.appointmentId = appoinmentId;
    if (termsData && demoDescription) {
      payload.termsAndWarranty = {
        title: termsData?.title,
        description: demoDescription,
      };
    }
    if (id) payload.draftId = id;

    // Process services
    let allServices = [];
    let priceNotAvailable = false;

    allService?.forEach((d) => {
      let data = { serviceId: d?.service?._id, serviceName: d?.service?.name };

      if (d?.pricingType === "fixed") {
        data.pricingType = "fixed";
        data.fixedPrice = d?.fixedPrice || "";
        if (!+d?.fixedPrice) {
          priceNotAvailable = true;
          toast.error("Please add a price for all services.");
          return;
        }
      } else {
        data.pricingType = "labor_&_parts";
        data.labor = { qty: d?.laborQuantity, ratePerHour: d?.laborRate };
        data.parts = d?.parts?.map((part) => ({
          partName: part?.partName,
          partNumber: part?.partNumber,
          description: part?.description,
          qty: part?.qty,
          cost: part?.cost,
          totalAmount: part?.totalAmount,
          partsProvider: part?.partsProvider,
        }));
      }

      allServices.push(data);
    });

    if (priceNotAvailable) return;

    payload.services = allServices;

    // Remove empty fields in draft save mode
    if (IsSaveDraft) {
      [
        "invoicePreparedBy",
        "odoDroppedOff",
        "odoReturned",
        "appointmentCompletionDate",
        "appointmentDroppedOffDate",
        "shopSupplies",
        "services",
        "termsAndWarranty",
      ].forEach((field) => {
        if (
          !payload[field] ||
          (Array.isArray(payload[field]) && payload[field].length === 0)
        ) {
          delete payload[field];
        }
      });
    }

    // Add discount if applicable
    if (discountId && discountId !== "" && discountId !== "none") {
      payload.membershipProgram = discountId;
    } else if (customerMembershipId && customerMembershipId !== "") {
      // payload.membershipProgram = ""; If membership program is empty, then don't pass membershipProgram key in payload
    }

    setShowLoader(true);

    // Dispatch appropriate action based on Save or Submit
    const dispatchAction = IsSaveDraft
      ? createNewInvoiceDraft
      : createNewInvoice;
    let result = await dispatch(
      dispatchAction({ sendEmail: isSendEmail }, payload)
    );

    if (result) {
      setOpenSuccessModal(true);
      // setIsRemoveDiscount(false);
      setStoreValues(null);
      setMessageType("success");
      setSuccessMessage(
        IsSaveDraft
          ? "Invoice draft was successfully created."
          : "Invoice was successfully created."
      );
      setTimeout(() => {
        navigate(
          IsSaveDraft ? "/invoice/drafts" : `/invoice/sent/${result?._id}`
        );
      }, 1000);
    }

    setShowLoader(false);
  };

  useEffect(() => {
    let subTotal = 0;

    // Calculate shop supply subtotal
    subTotal += shopSupplyData?.reduce(
      (acc, suppu) => acc + +suppu.totalAmount,
      0
    );

    // Calculate service subtotal
    subTotal += allService?.reduce((acc, service) => {
      if (service?.pricingType === "fixed") {
        return acc + +service.fixedPrice;
      } else {
        // Add labor costs
        let serviceTotal = +(service.laborQuantity * service.laborRate);

        // Add parts costs
        serviceTotal += service?.parts?.reduce(
          (partAcc, part) => partAcc + +part.totalAmount,
          0
        );

        return acc + serviceTotal;
      }
    }, 0);

    // If discountId is selected, use its discount
    let discountValue = 0;
    let discountPercentage = 0;

    if (selectedDiscount?._id) {
      discountPercentage = selectedDiscount?.discount || 0; // Use selected discount percentage
      discountValue = parseFloat((subTotal * discountPercentage) / 100).toFixed(
        2
      );
    } else {
      // Use manually entered discount if no selectedDiscount is selected
      discountValue = +totals?.discount || 0;
      discountPercentage =
        subTotal === 0 ? 0 : (discountValue / subTotal) * 100;
    }

    // Reset discount if subtotal is zero
    if (subTotal === 0) {
      discountValue = 0;
    }

    // Calculate tax and estimated total
    const taxPercentage = +totals?.taxPercentage || 0;
    const tax =
      taxPercentage > 0
        ? ((subTotal - discountValue) * taxPercentage) / 100
        : 0;
    const estimatedTotal = subTotal - discountValue + tax;

    setTotals({
      subTotal,
      taxPercentage,
      discount: discountValue,
      tax,
      estimatedTotal,
      discountPercentage: discountPercentage
        ? discountPercentage.toFixed(2)
        : 0,
    });

    // Validate discount
    const isValidDiscount = subTotal === 0 || discountValue <= subTotal;
    setDiscountError(!isValidDiscount);
  }, [allService, shopSupplyData, customerMembershipId]);

  const termsAndWarranty = async () => {
    const data = await dispatch(termsAndWarrantyData());
    const filteredData = data.map(({ _id, ...rest }) => rest);
    setGetTermsData(filteredData);
  };
  const removeServicesClicked = (idx) => {
    const newdata = [...allService];
    let newArray = [...newdata.slice(0, idx), ...newdata.slice(idx + 1)];
    setAllServices(newArray);
  };
  const EditServiceHandler = (idx) => {
    handleOpenServiceModal();
    setEditServiceData({ edit: true, index: idx });
  };

  const getDescriptionForTitle = (titleToFind, data) => {
    for (const item of data) {
      if (item.title === titleToFind) {
        return item.description;
      }
    }
    return "";
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmitInvoice)();
    }
  };

  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      fetchVehicles();
    }
  }, [customerData]);

  const fetchVehicles = async () => {
    // setShowLoader(true);
    setVehicleDropdownLoading(true);
    let data;
    if (appoinmentId) {
      data = await dispatch(vehicleDetails(10, 1, customerData?._id));
    } else {
      data = await dispatch(vehicleDetails(10, 1, customerData?.refIdUsers));
    }
    if (data) {
      setData(data);
      if (data.length == 0) {
        handleVehicleInfoOpen();
      } else if (data.length == 1) {
        setVehicleData(data[0]?._id);
        setValue("vehicleId", data[0]?._id);
        clearErrors("vehicleId");
        if (data[0].source && data[0].source == "shop_imported") {
          setVehicleSourceData(data[0]);
          handleVehicleInfoOpen();
        }
      } else {
        handleCloseCustomerInfo();
      }
    }
    setVehicleDropdownLoading(false);

    // setShowLoader(false);
  };
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && newVehicleCreatedId) {
      setVehicleData(newVehicleCreatedId);
      setVehicleCreatedId("");
    }
  }, [data]);

  const customersLists = async (page, limit, createId) => {
    // setCustomerDetail([]);
    if (customerId) {
      setShowLoader(true);
    }
    try {
      setCustomerListLoader(true);
      let data;
      data = await dispatch(
        CustomersListBasedOnType(customerSearch, customerTypeData, page, limit)
      );
      if (data.data) {
        // setCustomerDetail(data.data);
        setCustomerDetail(data.data);
        setServicePageData({
          totalpages: data?.totalPages,
          currentPage: data?.currentPage,
        });
        if (createId) {
          setCustomerData(data.data.find((d) => d.refIdUsers === createId));
        } else if (id) {
          if (getValues().customerId) {
            let selectuser = data.data.find(
              (d) => d.refIdUsers == getValues().customerId
            );
            setCustomerData(selectuser);
          }
          // setCustomerData(data.data.find((d) => d._id == customerData?._id));
        } else if (appoinmentId) {
          setCustomerData(data.data.find((d) => d._id == customerData?._id));
        } else if (customerId) {
          setCustomerData(data.data.find((d) => d._id == customerId));
        } else {
          setCustomerData("");
        }
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
    setCheckChangeLoader(false);
  };
  useEffect(() => {
    if (customerTypeData.length != 0) {
      if (!appoinmentId && !customerId && !type) {
        customersLists(page, limit);
      }
    } else {
      setCheckChangeLoader(false);
    }
  }, [customerTypeData]);

  useEffect(() => {
    if (!id && !appoinmentId && !customerId && !type) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
    }
    termsAndWarranty();
    if (appoinmentId) {
      fetchAppointmentDetails(appoinmentId);
    }
    if (id) {
      fetchInvoiceDetails(id);
    }
    if (customerId) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setValue("customerId", customerId);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
      customerDataDetail();
    }
  }, [window.location.pathname]);

  const customerDataDetail = async () => {
    setShowLoader(true);

    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetailApi(customerId));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(customerId));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = customerId;
    resp.refIdImport = customerId;

    setCustomerData(resp);
    setCustomerDetail([resp]);
    setShowLoader(false);
  };

  const fetchAppointmentDetails = async (appoinmentId) => {
    setShowLoader(true);
    const resp = await dispatch(appointmentDetails(appoinmentId));
    if (resp) {
      setAppoinmentData(resp);
      const { _id, firstName, lastName } = resp?.customer;
      let fullname = firstName;
      if (lastName) fullname = fullname + " " + lastName;
      resp.customer = {
        ...resp?.customer,
        refIdUsers: _id,
        fullName: fullname,
      };

      setCustomerData(resp?.customer);
      setVehicleData(resp?.vehicle?._id);
      // setValue("odoDroppedOff", resp?.dropOffODO);
      // setValue("odoReturned", resp?.completionODO);
      setValue("customerId", resp?.customer?._id);
      setValue("vehicleId", resp?.vehicle?._id);
      setValue("odoDroppedOff", FormatNumberForKm(resp?.dropOffODO));
      setValue("odoReturned", FormatNumberForKm(resp?.completionODO ?? "0"));

      setCustomerTypeData(["shop_created", "apex_auto_app"]);
      if (
        resp?.customer?.createdFrom == "shop_created" ||
        resp?.customer?.createdFrom == "apex_auto_app"
      ) {
        setState({ shop_created: true, apex_auto_app: true });
      } else if (resp?.customer?.createdFrom == "shop_imported") {
        setState({ shop_imported: true });
      }

      const providedDate = resp?.dropOffDateTime
        ? dayjs(resp?.dropOffDateTime)
        : null;

      setSelectDate(dayjs(dayjs(providedDate).format("YYYY-MM-DD")));
      setValue("dropOffDate", resp?.dropOffDateTime);

      const providedDateComplete = resp?.shopClosingTime
        ? dayjs(resp?.shopClosingTime)
        : null;

      setCompletionDate(
        dayjs(dayjs(providedDateComplete).format("YYYY-MM-DD"))
      );
      setValue("completionDate", resp?.shopClosingTime);

      // setTermsData(
      //   resp?.termsAndWarranty.description ? resp?.termsAndWarranty : ""
      // );
      // setDemoDescription(resp?.termsAndWarranty?.description);

      setTotals({
        discount: +resp?.discount ? +resp?.discount : 0,
        tax: +resp?.tax ? +resp?.tax : 0,

        estimatedTotal: +resp?.Total ? +resp?.Total : 0,

        taxPercentage: +(
          parseInt(parseInt(resp?.tax == null ? 0 : resp?.tax) * 100) /
          +(
            parseInt(resp?.subTotal == null ? 0 : resp?.subTotal) -
            parseInt(resp?.discount == null ? 0 : resp?.discount)
          )
        )
          ? +(
              parseInt(parseInt(resp?.tax == null ? 0 : resp?.tax) * 100) /
              +(
                parseInt(resp?.subTotal == null ? 0 : resp?.subTotal) -
                parseInt(resp?.discount == null ? 0 : resp?.discount)
              )
            )
          : 13,
        subTotal: +resp?.subTotal ? +resp?.subTotal : 0,
        discountPercentage: 0,
      });

      let services = [];
      let notNewAddedData;
      let newAddedData;

      resp?.services?.map((data) => {
        notNewAddedData = resp?.services?.filter(
          (item) => item.newAdded == false
        );
        newAddedData = resp?.services?.filter((item) => item.newAdded);

        // console.log("foo newAddedData", newAddedData);

        let approvedItem = [];
        if (newAddedData.length > 0) {
          approvedItem = newAddedData.filter(
            (item) => item.approvalStatus === "approved"
          );

          // console.log("foo approvedItem", approvedItem);

          if (approvedItem) {
            approvedItem?.map((item) => {
              notNewAddedData.push(item);
            });
          }
        }
      });

      // console.log("foo notNewAddedData", notNewAddedData);

      notNewAddedData?.map((item) => {
        // console.log("foo item", item);
        // let service = {};

        // if (item?.pricingType === "fixed") {
        //   service._id = item.serviceId;
        //   service.name = item.serviceName;
        //   service.pricingType = "fixed";
        //   service.fixedPrice = item.fixedPrice ? item.fixedPrice : "";
        // }

        // if (item?.pricingType == "labor_&_parts") {
        //   service._id = item.serviceId;
        //   service.name = item.serviceName;
        //   service.pricingType = "labor_&_parts";
        //   service.labor = {
        //     qty: item?.labor?.qty,
        //     ratePerHour: item?.labor?.ratePerHour,
        //     totalAmount: item?.labor?.totalAmount,
        //   };
        //   service.parts = [];
        //   item?.parts?.map((part) => {
        //     service.parts.push({
        //       partName: part?.partName,
        //       partNumber: part?.partNumber,
        //       description: part?.description,
        //       qty: part?.qty,
        //       cost: part?.cost,
        //       totalAmount: part?.totalAmount,
        //       partsProvider: part?.partsProvider,
        //     });
        //   });
        // }

        let service = {
          ...item,
          // laborQuantity: item?.labor?.qty ? item?.labor?.qty : undefined,
          // laborRate: item?.labor?.ratePerHour
          //   ? item?.labor?.ratePerHour
          //   : undefined,
          service: { _id: item.serviceId, name: item.serviceName },
        };
        if (item?.pricingType == "no_price") {
          service.fixedPrice = "0";
          service.pricingType = "fixed";
        }
        if (item?.pricingType == "range") {
          service.fixedPrice = item.priceMin;
          service.pricingType = "fixed";
        }

        if (item?.labor?.qty) {
          service.laborQuantity = item?.labor?.qty;
          service.laborRate = item?.labor?.ratePerHour;
        } else {
          delete service.laborQuantity;
          delete service.laborRate;
        }

        if (item?.parts?.length > 0) {
          service.parts = [];
          item?.parts?.map((part) => {
            service.parts.push({
              partName: part?.partName,
              partNumber: part?.partNumber,
              description: part?.description,
              qty: part?.qty,
              cost: part?.cost,
              totalAmount: part?.totalAmount,
              partsProvider: part?.partsProvider,
            });
          });
        } else {
          delete service.parts;
        }

        delete service.labor;
        delete service.serviceName;
        delete service.serviceId;

        // console.log("foo service", service);
        services.push(service);

        // console.log("foo services", services);

        // return services;
      });

      setAllServices(services);
    }
    setShowLoader(false);
  };

  const fetchInvoiceDetails = async (id) => {
    setShowLoader(true);
    let membershipResp = await dispatch(discountProgramApi());
    if (membershipResp.length > 0) {
      setDiscountList(membershipResp);
    }
    const resp = await dispatch(invoiceDetails(id));

    if (resp) {
      setValue("invoicePreparedBy", resp?.invoicePreparedBy);
      setValue("customerId", resp.customer?._id);
      setValue("vehicleId", resp.vehicle?._id);

      setCustomerTypeData(["shop_created", "apex_auto_app"]);
      if (
        resp?.customer?.createdFrom == "shop_created" ||
        resp?.customer?.createdFrom == "apex_auto_app"
      ) {
        setState({
          shop_created: true,
          apex_auto_app: true,
          shop_imported: false,
        });
      } else if (resp?.customer?.createdFrom == "shop_imported") {
        setState({ shop_imported: true });
      }
      setVehicleData(resp?.vehicle?._id);
      // setValue("odoDroppedOff", resp?.odoDroppedOff);
      // setValue("odoReturned", resp?.odoReturned);
      setValue("odoDroppedOff", FormatNumberForKm(resp?.odoDroppedOff));
      setValue("odoReturned", FormatNumberForKm(resp?.odoReturned));

      const providedDate = dayjs(resp?.appointmentDroppedOffDate);

      setSelectDate(
        resp?.appointmentDroppedOffDate
          ? dayjs(dayjs(providedDate).format("YYYY-MM-DD"))
          : null
      );
      setValue(
        "dropOffDate",
        resp?.appointmentDroppedOffDate
          ? dayjs(dayjs(providedDate).format("YYYY-MM-DD"))
          : null
      );
      const providedDateComplete = dayjs(resp?.appointmentCompletionDate);

      setCompletionDate(
        resp?.appointmentCompletionDate
          ? dayjs(dayjs(providedDateComplete).format("YYYY-MM-DD"))
          : null
      );
      setValue(
        "completionDate",
        resp?.appointmentCompletionDate
          ? dayjs(dayjs(providedDateComplete).format("YYYY-MM-DD"))
          : null
      );

      setTermsData(
        resp?.termsAndWarranty?.description ? resp?.termsAndWarranty : ""
      );
      setDemoDescription(resp?.termsAndWarranty?.description);

      let supplyData = [];
      resp?.shopSupplies?.map((data) => {
        let finalData = {
          itemName: data?.itemName,
          cost: data?.cost + " ",
          qty: data?.qty + " ",
          totalAmount: data?.totalAmount + " ",
        };
        supplyData.push(finalData);
      });
      setShopSupplyData(supplyData);

      let services = [];

      resp?.services?.map((data) => {
        let service = {
          ...data,
          laborQuantity: data?.labor?.qty,
          laborRate: data?.labor?.ratePerHour,

          service: { _id: data.serviceId?._id, name: data?.serviceId?.name },
          serviceCategory: {
            _id: data?.serviceId?.serviceCategory?._id,
            name: data?.serviceId?.serviceCategory?.name,
          },
        };

        delete data?.labor?.qty;
        delete data?.labor?.ratePerHour;

        delete service["serviceId"];
        delete service["serviceName"];
        services.push(service);
      });
      // if (resp && resp?.customer && resp?.customer?._id) {
      //   resp.customer = {
      //     ...resp?.customer,
      //     refIdUsers: resp?.customer?._id,
      //   };
      //   setCustomerData(resp?.customer);
      // }
      if (resp && resp?.vehicle && resp?.vehicle?._id) {
        setVehicleData(resp?.vehicle?._id);
      }
      setAllServices(services);

      if (resp?.membershipProgram?._id && resp?.membershipProgram?._id !== "") {
        setDiscountId(resp.membershipProgram._id);
        if (membershipResp.length > 0) {
          const sd = membershipResp.find(
            (item) => item._id === resp.membershipProgram._id
          );
          setSelectedDiscount(sd);
        }
      } else {
        setDiscountId("none");
      }
      const discount = +resp?.discount || 0;
      const subTotal = +resp?.subTotal || 0;
      const tax = +resp?.tax || 0;

      // Calculate discount percentage if membership discount is available
      const discountPercentage =
        resp?.membershipProgram?.discount && subTotal
          ? (discount / subTotal) * 100
          : 0;

      const taxPercentage =
        subTotal - discount > 0 ? (tax * 100) / (subTotal - discount) : 0;

      const estimatedTotal = subTotal - discount + tax;

      setTotals({
        discount: discount,
        tax: tax,
        estimatedTotal: estimatedTotal,
        taxPercentage: taxPercentage,
        subTotal: subTotal,
        discountPercentage: discountPercentage, // set discount percentage if applicable
      });
    }
    setShowLoader(false);
  };
  // const invoiceData = useSelector((state) => state.invoice.invoiceDetails);

  const [open3, setOpen3] = React.useState(false);

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    setOpen3(false);
    setEditShopSupply({ edit: false, index: "" });
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };

  const deleteshopSupplies = (idx) => {
    const filteredShopSupplyData = shopSupplyData.filter(
      (item, index) => index !== idx
    );
    setShopSupplyData(filteredShopSupplyData);
  };
  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const offset = 100; // Adjust the offset value as needed
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;
          window.scrollBy({ top: offsetPosition, behavior: "smooth" });
          setFocus(firstErrorField);
        }, 0);
      }
    }
  };

  const cancelInvoice = () => {
    if (id) {
      customersLists();
      termsAndWarranty();

      fetchInvoiceDetails(id);
    }
    navigate(-1);
    if (appoinmentId) {
      customersLists();
      termsAndWarranty();
      fetchAppointmentDetails(appoinmentId);
      setValue("invoicePreparedBy", "");
      setTotals({
        subTotal: 0,
        discount: 0,
        taxPercentage: 0,
        tax: 0,
        estimatedTotal: 0,
        discountPercentage: 0,
      });
      setTermsData("");
      setDemoDescription("");
      setShopSupplyData([]);
      setAllServices([]);
    } else {
      setValue("invoicePreparedBy", "");
      setValue("odoDroppedOff", "");
      setValue("odoReturned", "");
      setTotals({
        subTotal: 0,
        discount: 0,
        taxPercentage: 0,
        tax: 0,
        estimatedTotal: 0,
        discountPercentage: 0,
      });
      setCustomerData("");
      setVehicleData("");
      setSelectDate(null);
      setCompletionDate(null);
      setTermsData("");
      setDemoDescription("");
      setShopSupplyData([]);
      setAllServices([]);
    }
  };

  async function handleInputChange(newInputValue) {
    setCustomerSearch(newInputValue);
    setCustomerDetail([]);
    try {
      setCustomerListLoader(true);
      let data;
      data = await dispatch(
        CustomersListBasedOnType(newInputValue, customerTypeData, 1, limit)
      );
      if (data) {
        setCustomerDetail(data.data);

        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const handleInputDebounced = (event, newInputValue) => {
    debouncedHandleInputChange(newInputValue);
  };

  const handleCheckChange = (event) => {
    setCheckChangeLoader(true);

    setCustomerDetail([]);
    setCustomerSearch("");
    setPage(1);
    if (event.target.name && event.target.checked == true) {
      setCustomerTypeData((prevArray) => [...prevArray, event.target.name]);
    } else {
      const filterData = customerTypeData.filter(
        (value) => value !== event.target.name
      );
      setCustomerTypeData(filterData);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleBlur = () => {
    if (customerSearch != "") {
      setCustomerSearch("");
      handleInputChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };
  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      customersLists(page + 1, limit);
    }
  };

  return (
    <>
      <Box
        padding={"32px 24px"}
        sx={{
          width: "fit-content",
          minWidth: "100%",
        }}
      >
        {" "}
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
          alertType={messageType}
        />
        <form onSubmit={handleSubmit(validateInvoice, onInvalid)}>
          <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to="/invoice/all"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Invoices
                  </Link>
                  <Link
                    // to="/invoice/new"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {id ? "Drafts " : "New Invoice "}
                    {/* Invoice */}
                  </Link>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                {id ? "Edit " : "New "}
                Invoice
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={"24px"} margin={"32px 0"} boxShadow={2}>
            <Grid container item justifyItems={"space-between"}>
              <Grid
                container
                item
                justifyItems={"space-between"}
                rowGap={3}
                padding={"10px 0px"}
              >
                <Grid item container columns={16}>
                  <Grid item md={5.2}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Invoice Prepared By{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="invoicePreparedBy"
                      control={control}
                      rules={{
                        required: erMsgValidation.invoicePreparedBy,
                      }}
                      render={({ field }) => (
                        <TextField
                          // label="Invoice Prepared By"
                          variant="outlined"
                          fullWidth
                          error={!!errors.invoicePreparedBy}
                          helperText={
                            errors.invoicePreparedBy
                              ? errors.invoicePreparedBy.message
                              : ""
                          }
                          {...field}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                          // required={!IsSaveDraft}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <FormControl
                        fullWidth
                        required
                        error={!!errors.customerId}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1" className="label-500">
                            Customer{" "}
                            <span style={{ color: REQUIRED_RED }}>*</span>
                          </Typography>
                        </div>
                        <Controller
                          name="customerId" // Form field name
                          control={control}
                          rules={{ required: "Customer is required" }} // Validation rule
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Autocomplete
                              ref={ref}
                              name={name}
                              disablePortal
                              id="combo-box-demo"
                              options={customerDetail ? customerDetail : []}
                              getOptionLabel={(option) =>
                                // option.firstName + " " + option.lastName
                                option.fullName
                              }
                              value={customerData ? customerData : null}
                              onChange={(event, newValue) => {
                                // Handle the change here if needed
                                setValue("vehicleId", null);
                                setVehicleData("");
                                setCustomerData(newValue);
                                onChange(newValue);
                                setCustomerCretedFrom(newValue?.createdFrom);
                                if (customerData != null) {
                                  setData("");
                                }
                              }}
                              loading={customerListLoader}
                              loadingText={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <CircularProgress />
                                </div>
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label=""
                                  onChange={(e) =>
                                    handleInputDebounced(e, e.target.value)
                                  }
                                  onBlur={handleBlur}
                                  // style={{cursor: "not-allowed"}}
                                />
                              )}
                              ListboxProps={{
                                style: { maxHeight: "300px" },
                                onScroll: (event) => {
                                  handleScroll(event);
                                },
                              }}
                              disabled={
                                customerId ||
                                appoinmentId ||
                                customerTypeData.length == 0
                              }
                              renderOption={(props, option) => (
                                <>
                                  <Box component="li" {...props}>
                                    <li
                                      {...props}
                                      style={{
                                        width: "100%",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        if (
                                          option.createdFrom == "shop_imported"
                                        ) {
                                          handleOpenCustomerInfo();
                                        }
                                      }}
                                    >
                                      <div>
                                        {/* {option.firstName + " " + option.lastName} */}
                                        {option.fullName}
                                      </div>
                                      <div>
                                        <Chip
                                          style={{
                                            ...styleChip,
                                            display: "flex",
                                            background:
                                              option.createdFrom ==
                                              "shop_created"
                                                ? "#75DBFF80"
                                                : option.createdFrom ==
                                                  "shop_imported"
                                                ? "#E1D0FD"
                                                : "#EFEFEF",
                                            color:
                                              option.createdFrom ==
                                              "shop_created"
                                                ? "#000000"
                                                : option.createdFrom ==
                                                  "shop_imported"
                                                ? "#3E1C76"
                                                : "#002394",
                                          }}
                                          className="chip-text-style"
                                          label={
                                            option.createdFrom == "shop_created"
                                              ? "Shop Created"
                                              : option.createdFrom ==
                                                "shop_imported"
                                              ? "Shop Imported"
                                              : "Apex Auto"
                                          }
                                        />
                                      </div>
                                    </li>

                                    {/* <li>
                                <CircularProgress />
                              </li> */}
                                  </Box>
                                  <Box component="li" {...props}>
                                    {props["data-option-index"] + 1 ==
                                      customerDetail?.length &&
                                      customerListLoader && (
                                        <li
                                          style={{
                                            width: "100%",
                                            justifyContent: "center",

                                            display: "flex",
                                          }}
                                        >
                                          <CircularProgress />
                                        </li>
                                      )}
                                  </Box>
                                </>
                              )}
                            />
                          )}
                        />
                        {errors.customerId && (
                          <FormHelperText error>
                            {errors.customerId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                  </Grid>
                  {!addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleOpenCustomerInfo();
                          }}
                        >
                          {" "}
                          + Add Customer Info
                        </Button>
                      </Grid>
                    )}
                </Grid>
                <Grid item container columnGap={2} rowGap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_created}
                        onChange={handleCheckChange}
                        name="shop_created"
                        disabled={customerId || appoinmentId}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Created"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_imported}
                        onChange={handleCheckChange}
                        name="shop_imported"
                        disabled={customerId || appoinmentId}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Imported"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.apex_auto_app}
                        onChange={handleCheckChange}
                        name="apex_auto_app"
                        disabled={customerId || appoinmentId}
                      />
                    }
                    labelPlacement="end"
                    label="Apex Auto"
                  />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <FormControl
                      fullWidth
                      error={!!errors.vehicleId} // Show error if validation fails
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="edit-invoice-form-title style-15-500-24-px">
                          Vehicle <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>
                      </div>
                      <Controller
                        name="vehicleId" // Form field name
                        control={control}
                        rules={{ required: "Vehicle is required" }} // Validation rule
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            name={name}
                            ref={ref}
                            // value={vehicleData}
                            value={
                              Array.isArray(data) && customerData !== null
                                ? vehicleData
                                : ""
                            }
                            // label="customer"
                            // disabled={appoinmentId && appoinmentData?.vehicle?._id}
                            onChange={(e) => {
                              setVehicleData(e.target.value);
                              onChange(e.target.value);
                            }}
                            className="customer-dropdown"
                          >
                            {(data ? data : [])?.map((data, idx) => {
                              if (data)
                                return (
                                  <MenuItem
                                    value={data._id}
                                    onClick={() => {
                                      if (
                                        data.source &&
                                        data.source == "shop_imported"
                                      ) {
                                        setVehicleSourceData(data);
                                        handleVehicleInfoOpen();
                                      }
                                    }}
                                  >
                                    {data?.vehicleYear
                                      ? data?.vehicleYear
                                      : " "}
                                    {data?.year && data?.year}{" "}
                                    {data?.vehicleMake?.name &&
                                      data?.vehicleMake?.name}{" "}
                                    {data?.vehicleModel?.name &&
                                      data?.vehicleModel?.name}{" "}
                                    {data?.vehicleModelTrim?.name &&
                                      data?.vehicleModelTrim?.name}
                                  </MenuItem>
                                );
                            })}
                            {vehicleDropdownLoading && <DropdownLoader />}
                          </Select>
                        )}
                      />
                      {errors.vehicleId && (
                        <FormHelperText error>
                          {errors.vehicleId.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* {addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && ( */}
                  {customerData &&
                    customerData?.createdFrom != "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleVehicleInfoOpen();
                            setVehicleSourceData("");
                          }}
                        >
                          {" "}
                          + Add New Vehicle
                        </Button>
                      </Grid>
                    )}

                  {/* )} */}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                  rowSpacing={2}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Odometer Dropped Off{" "}
                      {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                    </Typography>

                    {/* <FormatNumberForKm inputValue={"10000"} /> */}
                    <Controller
                      name="odoDroppedOff"
                      control={control}
                      // rules={{
                      //   required: !IsSaveDraft
                      //     ? "Dropped off odo is required"
                      //     : undefined,
                      // }}
                      render={({ field }) => (
                        <TextField
                          // label="Odometer Dropped Off"
                          // disabled={appoinmentId && appoinmentData?.dropOffODO}
                          variant="outlined"
                          fullWidth
                          // type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">km</InputAdornment>
                            ),
                          }}
                          error={!!errors.odoDroppedOff}
                          helperText={
                            errors.odoDroppedOff
                              ? errors.odoDroppedOff.message
                              : ""
                          }
                          {...field}
                          onChange={(event) => {
                            setValue(
                              "odoDroppedOff",
                              FormatNumberForKm(event.target.value)
                            );
                          }}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                          // required={!IsSaveDraft}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Odometer Returned{" "}
                      {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                    </Typography>
                    <Controller
                      name="odoReturned"
                      control={control}
                      // rules={{
                      //   required: !IsSaveDraft
                      //     ? erMsgValidation.odoReturned
                      //     : undefined,
                      // }}
                      render={({ field }) => (
                        <TextField
                          // label="Odometer Returned"
                          // type="number"
                          variant="outlined"
                          // disabled={appoinmentId && appoinmentData?.completionODO}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">km</InputAdornment>
                            ),
                          }}
                          error={!!errors.odoReturned}
                          helperText={
                            errors.odoReturned ? errors.odoReturned.message : ""
                          }
                          {...field}
                          onChange={(event) => {
                            setValue(
                              "odoReturned",
                              FormatNumberForKm(event.target.value)
                            );
                          }}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                          // required={!IsSaveDraft}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#D32F2F",
                        marginTop: "3px",
                      }}
                    >
                      {odoValidationErr}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                  rowSpacing={2}
                >
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="width-100-pers"
                  >
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Dropped Off
                      {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                    </Typography>

                    <Controller
                      name="dropOffDate" // Field name
                      control={control}
                      // rules={{
                      //   required: !IsSaveDraft
                      //     ? "Drop off date is required."
                      //     : undefined, // Validation rule with message
                      // }}
                      render={({
                        field: { onChange, value, ref, onBlur, name },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name={name}
                            ref={ref}
                            onBlur={onBlur}
                            value={selectDate || null}
                            onChange={(date) => {
                              if (
                                completionDate &&
                                dayjs(dayjs(date).format("YYYY-MM-DD")) >
                                  new Date(completionDate)
                              ) {
                                setCustomValidationErr(
                                  "Work Completion date will be always higher than Dropped off."
                                );
                              } else {
                                setCustomValidationErr(null);
                              }

                              setSelectDate(
                                dayjs(dayjs(date).format("YYYY-MM-DD"))
                              );
                              onChange(date); // Update the Controller's value on change
                            }}
                            error={!!errors.dropOffDate}
                            helperText={
                              errors.dropOffDate
                                ? errors.dropOffDate.message
                                : ""
                            }
                            style={{ width: "100%" }}
                            components={{
                              OpenPickerIcon: KeyboardArrowDown,
                            }}
                            slotProps={{
                              textField: {
                                error: !!error, // Set error state for TextField
                                helperText: error ? error.message : undefined, // Set helper text
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!error}
                                helperText={
                                  error ? error.dropOffDate.message : ""
                                }
                                required={!IsSaveDraft}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="width-100-pers"
                  >
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Work Completion Date{" "}
                      {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                    </Typography>

                    <Controller
                      name="completionDate" // Field name
                      control={control}
                      // rules={{
                      //   required: !IsSaveDraft
                      //     ? "Completion date is required."
                      //     : "", // Validation rule with message
                      // }}
                      render={({
                        field: { onChange, value, ref, onBlur, name },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name={name}
                            ref={ref}
                            onBlur={onBlur}
                            // label="Select date"
                            value={completionDate || null}
                            // disabled={appoinmentId && appoinmentData?.shopClosingTime}
                            // disablePast={true}
                            onChange={(date) => {
                              if (
                                new Date(selectDate) >
                                dayjs(dayjs(date).format("YYYY-MM-DD"))
                              ) {
                                setCustomValidationErr(
                                  "Work Completion date will be always higher than Dropped off."
                                );
                              } else {
                                setCustomValidationErr(null);
                              }

                              setCompletionDate(
                                dayjs(dayjs(date).format("YYYY-MM-DD"))
                              );
                              onChange(date);
                              // field.onChange(date);
                              // const formattedDate = new Date(date);
                              // setCompletionDate(formattedDate);
                            }}
                            style={{ width: "100%" }}
                            components={{
                              OpenPickerIcon: KeyboardArrowDown,
                            }}
                            slotProps={{
                              textField: {
                                error: !!error, // Set error state for TextField
                                helperText: error ? error.message : undefined, // Set helper text
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!error.completionDate}
                                helperText={
                                  error.completionDate
                                    ? error.completionDate.message
                                    : ""
                                }
                                required={!IsSaveDraft}
                              />
                            )}
                          />
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#D32F2F",
                              marginTop: "3px",
                            }}
                          >
                            {customValidationErr}
                          </Typography>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>

                <Divider
                  style={{
                    width: "100%",
                    borderBottomStyle: "dotted",
                    borderBottomWidth: "4px",
                  }}
                />
              </Grid>
              <Grid item container justifyContent={"end"} padding={"12px 0px"}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpenServiceModal}
                    className="btn-text-size-manage"
                  >
                    ADD SERVICE
                  </Button>
                </Grid>
              </Grid>
              {allService?.map((data, index) => {
                return (
                  <>
                    {(data?.pricingType == "fixed" ||
                      data?.pricingType == "no_price") && (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                      width={"120px"}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={1}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.service?.name}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>{" "}
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {data?.pricingType == "labor_&_parts" && (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          {/* {console.log("foo data", data)} */}
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={0}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    width={"120px"}
                                  >
                                    {1}
                                  </TableCell>
                                  <TableCell align="left">Labor</TableCell>

                                  <TableCell align="left" width={"120px"}>
                                    {data?.laborQuantity}
                                  </TableCell>
                                  <TableCell align="left" width={"120px"}>
                                    {data?.laborRate ? (
                                      <FormatNumber
                                        inputValue={data?.laborRate}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell align="left" width={"120px"}>
                                    {data?.laborQuantity && data?.laborRate ? (
                                      <FormatNumber
                                        inputValue={
                                          data?.laborQuantity * data?.laborRate
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                                {data?.parts?.map((row, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      width={"120px"}
                                    >
                                      {idx + 2}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography
                                        variant="body2"
                                        fontWeight={400}
                                      >
                                        {row?.partName}
                                      </Typography>

                                      <Typography
                                        fontWeight={400}
                                        fontSize={12}
                                      >
                                        Part Provider:{" "}
                                        {row?.partsProvider == "oem" && "OEM"}{" "}
                                        {row?.partsProvider == "after_market" &&
                                          "After Market"}{" "}
                                        {row?.partsProvider == "used" && "Used"}
                                      </Typography>
                                      {row?.partNumber && (
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          fontSize={12}
                                        >
                                          Part Number: {row?.partNumber}
                                        </Typography>
                                      )}
                                      {row?.description && (
                                        <Typography
                                          variant="body2"
                                          fontSize={12}
                                          // color={"#848484"}
                                        >
                                          Description: {row?.description}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="left" width={"120px"}>
                                      {row.qty}
                                    </TableCell>
                                    <TableCell align="left" width={"120px"}>
                                      <FormatNumber
                                        inputValue={row.cost ? row.cost : "-"}
                                      />
                                    </TableCell>
                                    <TableCell align="left" width={"120px"}>
                                      {row.totalAmount ? (
                                        <FormatNumber
                                          inputValue={row.totalAmount}
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            {/* <Grid
                            container
                            item
                            justifyContent={"end"}
                            padding={"24px"}
                          >
                            <Grid item>
                              <Stack direction="row" spacing={2}>
                                <Button
                                  variant="contained"
                                  startIcon={<Edit />}
                                  onClick={handleOpen2}
                                  className="btn-text-size-manage"
                                >
                                  EDIT SERVICE
                                </Button>
                                <EditServiceModal
                                  open2={open2}
                                  handleClose2={handleClose2}
                                  handleOpen1={handleOpen1}
                                  handleOpen2={handleOpen2}
                                />
                                <Button
                                  variant="contained"
                                  color="error"
                                  startIcon={<Delete />}
                                  className="btn-text-size-manage"
                                >
                                  REMOVE SERVICE
                                </Button>
                              </Stack>
                            </Grid>{" "}
                          </Grid> */}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      container
                      item
                      justifyContent={"end"}
                      padding={"24px"}
                      ref={serviceRef.current[index]}
                    >
                      <Grid item>
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            startIcon={<Edit />}
                            onClick={() => {
                              EditServiceHandler(index);
                            }}
                            className="btn-text-size-manage"
                          >
                            EDIT SERVICE
                          </Button>

                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<Delete />}
                            className="btn-text-size-manage"
                            onClick={() => {
                              removeServicesClicked(index);
                            }}
                          >
                            REMOVE SERVICE
                          </Button>
                        </Stack>
                      </Grid>{" "}
                    </Grid>
                  </>
                );
              })}
              <Divider
                style={{
                  width: "100%",
                  borderBottomStyle: "dotted",
                  borderBottomWidth: "4px",
                }}
              />
              <Grid item container justifyContent={"end"} padding={"20px 0px "}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpen3}
                    className="btn-text-size-manage"
                  >
                    ADD SHOP SUPPLIES
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{
                  backgroundColor: "#c5d0da",
                  color: "black",
                  marginTop: "5px",
                }}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    padding={"16px"}
                    fontSize={"20px"}
                  >
                    Shop Supplies
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                  <Table
                    aria-label="caption table"
                    padding="16px"
                    style={{ boxShadow: 2 }}
                  >
                    <TableHead
                      style={{ backgroundColor: "#f4f6f8", fontWeight: 500 }}
                    >
                      <TableRow>
                        <TableCell align="left" width={"9.6%"}>
                          No.
                        </TableCell>
                        <TableCell> Description</TableCell>
                        <TableCell align="left" width={"9.6%"}>
                          {" "}
                          Quantity
                        </TableCell>
                        <TableCell align="left" width={"9.6%"}>
                          {" "}
                          Cost
                        </TableCell>
                        <TableCell align="left" width={"9.6%"}>
                          {" "}
                          Amount
                        </TableCell>
                        <TableCell width={"280px"}> Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shopSupplyData.map((row, index) => (
                        <TableRow key={row.no}>
                          <TableCell component="th" scope="row" width={"9.6%"}>
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">{row.itemName}</TableCell>
                          <TableCell align="left" width={"9.6%"}>
                            {row?.qty}
                          </TableCell>
                          <TableCell align="left" width={"9.6%"}>
                            {" "}
                            <FormatNumber inputValue={row.cost} />
                          </TableCell>
                          <TableCell align="left" width={"9.6%"}>
                            {" "}
                            <FormatNumber inputValue={row.totalAmount} />
                          </TableCell>
                          <TableCell align="left" className="gap-of-btns">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Edit />}
                              // onClick={handleOpen3}
                              onClick={() => {
                                setOpen3(true);
                                setEditShopSupply({ edit: true, index: index });
                              }}
                              className="btn-text-size-manage"
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              startIcon={<Delete />}
                              onClick={() => {
                                deleteshopSupplies(index);
                              }}
                            >
                              REMOVE
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* <Grid container item justifyContent={"end"} padding={"24px"}>
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        <Stack direction="column">
                          <TextField
                            variant="outlined"
                            size="medium"
                            label="Discount($)"
                            color={discountError ? "error" : "primary"}
                            value={totals?.discount}
                            disabled={totals?.subTotal == 0 ? true : false}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setTotals({
                                  discount: +e.target.value,
                                  tax:
                                    totals?.taxPercentage > 0
                                      ? ((totals?.subTotal - +e.target.value) *
                                          totals?.taxPercentage) /
                                        100
                                      : 0,

                                  estimatedTotal:
                                    totals?.subTotal -
                                    e.target.value +
                                    (totals?.taxPercentage > 0
                                      ? ((totals?.subTotal - +e.target.value) *
                                          totals?.taxPercentage) /
                                        100
                                      : 0),
                                  taxPercentage:
                                    totals?.taxPercentage > 0
                                      ? totals?.taxPercentage
                                      : 0,
                                  subTotal: totals?.subTotal,
                                });
                                if (e.target.value > totals?.subTotal) {
                                  setDiscountError(true);
                                } else {
                                  setDiscountError(false);
                                }
                              } else {
                                toast.error("Please Enter Number value");
                              }
                            }}
                          />
                          {discountError && (
                            <p
                              style={{
                                color: "#d32f2f",
                                fontSize: "0.75rem",
                                letterSpacing: "0.03333em",
                                lineHeight: "1.66",
                                marginTop: "3px",
                              }}
                            >
                              Discount can not be more than subtotal amount
                            </p>
                          )}
                        </Stack>
                        <TextField
                          variant="outlined"
                          size="medium"
                          value={totals?.taxPercentage}
                          label="Taxes (%)"
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setTotals({
                                taxPercentage: +e.target.value,
                                tax:
                                  ((totals?.subTotal - totals?.discount) *
                                    e.target.value) /
                                  100,
                                estimatedTotal:
                                  totals?.subTotal -
                                  totals?.discount +
                                  ((totals?.subTotal - totals?.discount) *
                                    +e.target.value) /
                                    100,
                                discount: totals?.discount,
                                subTotal: totals?.subTotal,
                              });
                            } else {
                              toast.error("Please Enter Number value");
                            }
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
              <DiscountComponent
                discountId={discountId}
                setDiscountId={setDiscountId}
                setSelectedDiscount={setSelectedDiscount}
                totals={totals}
                setTotals={setTotals}
                discountList={discountList.length > 0 ? discountList : []}
              />

              {/* <Grid
                container
                item
                justifyContent={"end"}
                padding={"0px 23px 24px 24px"}
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>Sub Total</Typography>

                      <Typography mb={1}>Discount</Typography>

                      <Typography mb={1}>Tax</Typography>
                      <Typography fontWeight={500}> Total</Typography>
                    </Box>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.subTotal} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.discount} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.tax} />
                      </Typography>
                      <Typography>
                        <FormatTotalNumbers
                          inputValue={totals?.estimatedTotal}
                        />
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid> */}
              <Grid
                container
                item
                justifyContent={"start"}
                marginTop={"24px"}
                columnGap={4}
              >
                <Grid item lg={2.7} xl={2.7} md={2.7} sm={2.7} xs={2.7}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={getTermsData ? getTermsData : []}
                    onChange={(e, value) => {
                      setTermsData(value);
                      setDemoDescription(
                        getDescriptionForTitle(value?.title, getTermsData)
                      );
                    }}
                    value={termsData == "" ? null : termsData}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.title}
                      </Box>
                    )}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Terms and Warranty"
                        // required={!IsSaveDraft}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  lg={8.5}
                  xl={8.9}
                  md={8.9}
                  sm={8.9}
                  xs={8.9}
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "10px",
                    width: "72%",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      maxWidth={"100%"}
                      className="desc-style"
                    >
                      {demoDescription}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"end"} spacing={2}>
            <Grid item xs={6} sm={"auto"}>
              <Button
                fullWidth
                color="error"
                variant="contained"
                className="btn-text-size-manage"
                onClick={cancelInvoice}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6} sm={"auto"}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                className="btn-text-size-manage"
                onClick={() => {
                  setIsSaveDraft(true);
                  setIsSendEmail(false);
                }}
              >
                SAVE AS DRAFT
              </Button>
            </Grid>
            {/* <Grid item xs={6} sm={"auto"}>
              <Button
                fullWidth
                variant="contained"
                className="btn-text-size-manage"
                type="submit"
                onClick={() => {
                  setIsSaveDraft(false);
                  setIsSendEmail(true);
                }}
              >
                SAVE AND SEND
              </Button>
            </Grid> */}
            <Grid item xs={6} sm={"auto"}>
              <Button
                fullWidth
                variant="contained"
                className="btn-text-size-manage"
                type="submit"
                onClick={() => {
                  setIsSaveDraft(false);
                  setIsSendEmail(false);
                }}
              >
                SAVE
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={checkChangeLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <AddShopSupplyModal
        open={open3}
        handleClose={handleClose3}
        setShopSupplyData={setShopSupplyData}
        shopSupplyData={shopSupplyData}
        editShopSupply={editShopSupply}
        setEditShopSupply={setEditShopSupply}
      />
      <ConfirmCustomersInfoModel
        open={openCustomerInfo}
        handleClose={handleCloseCustomerInfo}
        customerData={customerData}
        id={customerData?.refIdImport}
        vehicalDetailFunction={fetchVehicles}
        setAddCustomerInfoButton={setAddCustomerInfoButton}
        setCustomerData={setCustomerData}
        setCustomerTypeData={setCustomerTypeData}
        state={state}
        setState={setState}
        setAddVehicleInfoButton={setAddVehicleInfoButton}
        madeFromCustomerProfile={false}
      />
      <MissingVehicleInfoModal
        open={vehicleInfoOpen}
        handleClose={handleVehicleInfoClose}
        id={customerData?.refIdUsers}
        vehicalDetailFunction={fetchVehicles}
        setAddVehicleInfoButton={setAddVehicleInfoButton}
        setVehicleCreatedId={setVehicleCreatedId}
        vehicleSource={vehicleSourceData}
      />

      <ServiceModal
        open={openServiceModal}
        setOpenServiceModal={setOpenServiceModal}
        setAllServices={setAllServices}
        allServices={allService}
        editServiceData={editServiceData}
        setEditServiceData={setEditServiceData}
        // open1={open1}
        // handleClose1={handleClose1}
        // handleClose={handleClose}
        // handleOpen1={handleOpen1}

        // serviceModalDataProps={(data) => {
        //   setCommingFromModalData(data);
        // }}
        // getData={commingFromModalData}
        // editServiceData={editServiceData}
        // servicePartsModalDataProps={(data) => {
        //   setCommingPartsFromModalData(data);
        // }}
      />
      <AddCustomerProgramModal
        open={openProgramModal}
        setOpenProgramModal={setOpenProgramModal}
        isRemoveDiscount={isRemoveDiscount}
        setIsRemoveDiscount={setIsRemoveDiscount}
        onSave={async (type) => {
          if (type) {
            const resp = await dispatch(
              updateUserMembershipDetail(customerData.refIdUsers, {
                membershipProgram: discountId === "none" ? "" : discountId,
              })
            );
            if (resp) {
              onSubmitInvoice(storeValues);
            }
          } else {
            onSubmitInvoice(storeValues);
          }
        }}
        customerName={customerData?.fullName}
        programName={selectedDiscount?.programName}
      />
    </>
  );
};

export default NewInvoice;
