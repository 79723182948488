import { Add, Delete, Edit, Save, Search } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./DateTimePickerStyles.css";

import { useTheme } from "@emotion/react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Context, REQUIRED_RED } from "../../../App";
import { SHOP_IMPORTED } from "../../../config/dataService/constants";
import {
  customerDetail,
  importedCustomerDetail,
} from "../../../redux/Customer/action";
import {
  serviceCategoryData,
  serviceSubcategoryData,
} from "../../../redux/Estimate/action";
import { CustomersListBasedOnType } from "../../../redux/Invoice/action";
import { vehicleDetails } from "../../../redux/Vehicles/action";
import {
  createAppointment,
  fetchShopClosingTime,
  mechanicDetailsList,
} from "../../../redux/appointment/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AddAppointmentServiceModal from "../../modal/AddAppointmentService";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import MissingVehicleInfoModal from "../../modal/MissingVehicleInfoModal";

import axios from "axios";
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advanced format plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import { toast } from "sonner";
import { clearAllPaths, getPreviousPath } from "../../../components/history";
import {
  createServiceSubcategory,
  getAllShopServiceApi,
} from "../../../redux/Account/ServiceOffered/action";
import {
  APPOINTMENT_CONTACT_PREFRENCES,
  SHOP_SERVICE_PRICE_TYPES,
} from "../../../utility/Constant";
import { roundToNext15Minutes } from "../../../utility/helpers/commonFunction";
import { formatCurrency } from "../../../utility/helpers/displayHelper";
import DropdownLoader from "../../Common/DropdownLoader";
import AddCustomerModal from "../../Customers/Profile/AddCustomerModal";
import DescriptionModal from "./DescriptionModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const localTimezone = dayjs.tz.guess();

const styleChip = {
  color: "#002394",
  width: "125px",
  height: "21px",
  paddingTop: "10px",
  paddingBottom: "10px",
  alignItems: "right",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
};

const NewAppointment = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    setError,
    getValues,
    setFocus,
    clearErrors,
  } = useForm();
  const { id, type } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpenSideBar } = useContext(Context);
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [customerDetails, setCustomerDetails] = React.useState([]);
  const [mechanicDetail, setMechanicDetail] = React.useState("");
  const [customerData, setCustomerData] = React.useState("");
  const [customerTypeData, setCustomerTypeData] = React.useState([]);
  const [mechanicData, setMechanicData] = React.useState("");
  const [customerOnSite, setCustomerOnSite] = React.useState("");
  const [vehicleData, setVehicleData] = React.useState("");
  const [vehicleList, setVehcileList] = React.useState("");
  const [isFirstSelection, setIsFirstSelection] = useState(true);
  const [assigneeLoader, setAssigneeLoader] = useState(false);

  const [dropOffDate, setDropOffDate] = React.useState(null);
  const [pickUpDate, setPickUpDate] = React.useState(null);

  const [serviceId, setServiceId] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [addVehicleInfoButton, setAddVehicleInfoButton] = React.useState(false);

  const [showLoader, setShowLoader] = React.useState(false);
  const [checkChangeLoader, setCheckChangeLoader] = useState(false);
  const [showIdLoader, setShowIdLoader] = React.useState(true);
  const [vehicleLoader, setVehicleLoader] = React.useState(false);
  const [customerListLoader, setCustomerListLoader] = React.useState(false);
  const [customerSearch, setCustomerSearch] = useState("");
  // new apopointment hooks
  const inputDivRef = useRef([]);
  const textFieldRef = useRef([]);
  const inputFielRef = useRef([]);
  const preventCloseRef = useRef(false);

  // const [selectedDefaultService, setSelectedDefaultService] = useState(null);
  const [serviceLoader, setServiceLoader] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [serviceList, setServiceList] = useState([{ _id: "select" }]);
  console.log("🚀 ~ NewAppointment ~ serviceList:", serviceList);
  const [serviceFilteredData, setServiceFilteredData] = useState([]);
  const [anchorEls, setAnchorEls] = useState(
    Array(serviceList.length).fill(null)
  );

  // const allServiceListing = useSelector(
  //   (state) => state.account?.shopServicesListing?.data
  // );
  const cachedServices = JSON.parse(localStorage.getItem("allServices"));

  const [newVehicleCreatedId, setVehicleCreatedId] = useState("");

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClick(event) {
    event.preventDefault();
    // console.info("You clicked a breadcrumb.");
  }
  let limit = 5000;
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const [customerCretedFrom, setCustomerCretedFrom] = React.useState("");
  const [vehicleSourceData, setVehicleSourceData] = React.useState("");
  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });
  const cancelTokenSourceRef = useRef(null);

  const [state, setState] = React.useState({
    shop_created: true,
    shop_imported: true,
    apex_auto_app: false,
  });

  const limits = 50;

  const handleOpenCustomerInfo = () => setOpenCustomerInfo(true);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCustomerInfo = () => {
    setOpenCustomerInfo(false);
  };
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const handleVehicleInfoOpen = () => {
    setVehicleInfoOpen(true);
    handleClose();
  };
  const handleVehicleInfoClose = () => {
    setVehicleInfoOpen(false);
    handleClose();
    if (vehicleSourceData) {
      setVehicleSourceData("");
      setVehicleData("");
    }
  };

  const customerDataDetail = async () => {
    setShowLoader(true);

    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetail(id));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(id));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = id;
    resp.refIdImport = id;

    setCustomerData(resp);
    setCustomerDetails([resp]);
    setValue("customer", resp);
    setShowLoader(false);
  };

  useEffect(() => {
    console.log("patnname upatinsd sdf");
    reset({
      noteForCustomer: "",
    });
    setServiceList([{ _id: "select" }]);
    setVehicleData("");
    setDropOffDate(null);
    setPickUpDate(null);
    setCustomerOnSite(null);
    setMechanicData("");
    setServiceId([]);
    mechanicList();

    const roundedTime = dayjs();
    // roundToNext15Minutes(dayjs());
    setDropOffDate(roundedTime);
    setValue("dropOffDate", roundedTime);
    getShopClosingTime(roundedTime.format("YYYY-MM-DDTHH:mm"));

    if (id && type) {
      customerDataDetail();
    } else {
      setValue("customer", null);
      setCustomerData(null);
      setCustomerDetails(null);
    }
    setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
    setState({
      shop_created: true,
      apex_auto_app: true,
      shop_imported: true,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (customerTypeData) {
      if (!id && !type) {
        customerList(page, limits);
      }
    }
  }, [customerTypeData]);

  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      console.log("🚀 ~ useEffect ~ customerData:", customerData);
      vehicalDetail();
    }
    if (customerData?.createdFrom == "shop_imported") {
      setCustomerCretedFrom("shop_imported");
      setAddCustomerInfoButton(false);
      handleOpenCustomerInfo();
    }
  }, [customerData]);

  useEffect(() => {
    if (
      Array.isArray(vehicleList) &&
      vehicleList.length > 0 &&
      newVehicleCreatedId
    ) {
      setVehicleData(newVehicleCreatedId);
      setVehicleCreatedId("");
    }
  }, [vehicleList]);

  useEffect(() => {
    // const roundedTime = roundToNext15Minutes(dayjs());
    const roundedTime = dayjs();
    setDropOffDate(roundedTime);
    setValue("dropOffDate", roundedTime);
    getShopClosingTime(roundedTime.format("YYYY-MM-DDTHH:mm"));

    if (id && type) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
      customerDataDetail();
    }
    if (!id) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
    }
    servicesName();
    mechanicList();
    fetchAllService();
    fetchThisShopsCategory();
  }, []);

  const fetchThisShopsCategory = async () => {
    const resp = await dispatch(serviceCategoryData({ limit: 1500 }));
    // if (resp?.length > 0) {
    //   const defaultService = resp.find((item) => item.isDefault === true);
    //   setSelectedDefaultService(defaultService);
    // }
  };
  //main funciton
  const fetchAllService = async (searchTerm) => {
    // setServiceLoader(true);
    const response = await dispatch(
      getAllShopServiceApi({
        pagination: false,
        limit: 2000,
        sortBy: "name",
        sortType: "ASC",
      })
    );
    localStorage.setItem("allServices", JSON.stringify(response?.data || [])); // Store in localStorage
    // setServiceLoader(false);
    return response?.data || [];
  };
  const customerList = async (page, limits, createId) => {
    // setCustomerDetails([]);
    try {
      if (showIdLoader && id) {
        setShowLoader(true);
        setShowIdLoader(false);
      }
      setCustomerListLoader(true);
      let data;

      if (customerTypeData.length > 0) {
        data = await dispatch(
          CustomersListBasedOnType(
            customerSearch,
            customerTypeData,
            page,
            limits
          )
          // CustomersListBasedOnType(customerSearch, customerTypeData, page)
        );
        if (data.data) {
          setCustomerDetails(data.data);
          if (createId && createId !== "") {
            console.log(
              "🚀 ~ customerList ~ createId:",
              data.data.find((d) => d.refIdUsers === createId)
            );
            setCustomerData(data.data.find((d) => d.refIdUsers === createId));
          } else {
            setCustomerData(data.data.find((d) => d._id == id));
          }
          setServicePageData({
            totalpages: data?.totalPages,
            currentPage: data?.currentPage,
          });
          setCustomerListLoader(false);
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setCheckChangeLoader(false);
  };

  const mechanicList = async () => {
    setAssigneeLoader(true);
    let data = await dispatch(mechanicDetailsList());
    setMechanicDetail(data);
    setAssigneeLoader(false);
    if (data?.length == 1) {
      setMechanicData(data[0]?._id);
    } else {
      setMechanicData(data?.find((d) => d.isDefault == true)?._id);
    }
  };

  const servicesName = async () => {
    try {
      let serviceSubCategoryData = await dispatch(
        serviceSubcategoryData(page, limit)
      );
      setFilterData(serviceSubCategoryData.data);
    } catch (error) {
      console.log(error);
    }
  };

  let filteredArray;
  filteredArray = filterData?.filter((item) =>
    serviceId.includes(item.serviceSubCategory._id)
  );
  const vehicalDetail = async (newCustomerId) => {
    // setShowLoader(true);
    setVehcileList([]);
    setVehicleLoader(true);
    let data = await dispatch(
      vehicleDetails(
        10,
        1,
        newCustomerId
          ? newCustomerId
          : customerData?.refIdUsers
          ? customerData?.refIdUsers
          : customerData?._id
      )
    );
    setVehicleLoader(false);
    if (data) {
      setVehcileList(data);
      if (data.length == 0) {
        handleVehicleInfoOpen();
      } else if (data.length == 1) {
        setVehicleData(data[0]?._id);
        setValue("vehicle", data[0]?._id);
        clearErrors("vehicle");
        if (data[0].source && data[0].source == "shop_imported") {
          setVehicleSourceData(data[0]);
          handleVehicleInfoOpen();
        }
      } else {
        handleCloseCustomerInfo();
      }
    }
    // setShowLoader(false);
  };

  const getClosestAllowedMinute = (minute) => {
    let allowedMinutes = [0, 15, 30, 45];
    // Find the closest allowed minute greater than or equal to the current minute
    for (let i = 0; i < allowedMinutes.length; i++) {
      if (minute <= allowedMinutes[i]) return allowedMinutes[i];
    }
    // If no match, return the first allowed minute of the next hour
    return allowedMinutes[0];
  };

  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const offset = 100; // Adjust the offset value as needed
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;
          window.scrollBy({ top: offsetPosition, behavior: "smooth" });
          setFocus(firstErrorField);
        }, 0);
      }
    }
  };
  const onSubmit = async (value) => {
    const now = dayjs();
    let cloneData = dropOffDate;
    if (!dayjs(cloneData).isValid()) {
      setError("dropOffDate", {
        type: "custom",
        message: "Invalid date",
      });

      onInvalid(errors);
      return;
    }
    // Clinet has ask to remove this validation on feb 25
    // if (![0, 15, 30, 45].includes(dayjs(cloneData).minute())) {
    //   setError("dropOffDate", {
    //     type: "custom",
    //     message: "Please select a time ending in 00, 15, 30, or 45 minutes.",
    //   });
    //   onInvalid(errors);
    //   return;
    // }

    //Client has ask to remove this validation because he make appoitnemtn at midnight -> feb 25
    // if (dayjs(cloneData).isBefore(now)) {
    //   setError("dropOffDate", {
    //     type: "custom",
    //     message: "Please choose a future drop off time.",
    //   });
    //   onInvalid(errors);
    //   return;
    // }

    let submitData = {
      customer: customerData?.refIdUsers,
      vehicle: vehicleData,
      assignee: [mechanicData],
    };
    if (customerOnSite && customerOnSite !== "") {
      submitData.waitingOnSite = customerOnSite;
    }
    if (dropOffDate) {
      submitData.dropOffDateTime = dropOffDate.toISOString();
    }
    if (pickUpDate) {
      submitData.pickupDateTime = pickUpDate.toISOString();
    }
    if (value?.contactPrefrence) {
      submitData.contactPreferences = value?.contactPrefrence;
    }
    if (value.noteForCustomer) {
      submitData.noteForCustomer = value.noteForCustomer.trim();
    }

    if (serviceList.length > 0 && serviceList[0]._id !== "select") {
      let serviceArray = [];
      serviceList.map((item) => {
        if (item?._id !== "select") {
          serviceArray.push({
            serviceId: item?.serviceSubCategory?._id,
            description: item?.description ?? "",
          });
        }
      });
      submitData.services = serviceArray;
    } else {
      toast.error("Please add at least one service to continue.");
      return;
    }
    setShowLoader(true);
    try {
      const data = await dispatch(createAppointment(submitData));

      if (data) {
        // If the data is truthy (meaning the appointment was created successfully)
        setShowLoader(false);
        setOpenSuccessModal(true);
        setSuccessMessage("Appointment created successfully.");

        setTimeout(() => {
          if (getPreviousPath()?.split("/")?.includes("calendar")) {
            clearAllPaths();
            navigate(-1);
          } else {
            navigate("/appointment/today");
          }
        }, 1000);
      } else {
        // If the data is falsy (handling the case where the appointment creation failed)
        setShowLoader(false);
        // Optionally, you may want to handle the error state or provide user feedback.
      }
    } catch (error) {
      // Handle any unexpected errors during the appointment creation
      console.error("Error creating appointment:", error);
      // Optionally, you may want to set an error state or provide user feedback.
    }
  };

  const handleRemoveService = (id) => {
    let newArray = serviceId.filter((itemId) => itemId !== id);
    setServiceId(newArray);
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };
  async function handleInputChange(newInputValue) {
    setCustomerSearch(newInputValue);

    setCustomerDetails([]);
    try {
      setCustomerListLoader(true);
      let data;

      data = await dispatch(
        CustomersListBasedOnType(newInputValue, customerTypeData, 1, limits)
      );
      if (data) {
        setCustomerDetails(data.data);
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const handleInputDebounced = (event, newInputValue) => {
    debouncedHandleInputChange(newInputValue);
  };

  const handleCheckChange = (event) => {
    setCheckChangeLoader(true);
    // setShowLoader(true)
    setCustomerDetails([]);
    setCustomerSearch("");
    setPage(1);
    if (event.target.name && event.target.checked == true) {
      setCustomerTypeData((prevArray) => [...prevArray, event.target.name]);
    } else {
      const filterData = customerTypeData.filter(
        (value) => value !== event.target.name
      );
      setCustomerTypeData(filterData);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
    // setShowLoader(false)
  };
  const handleBlur = () => {
    if (customerSearch != "") {
      setCustomerSearch("");

      handleInputChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };

  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      customerList(page + 1, limits);
    }
  };

  const getShopClosingTime = async (time) => {
    // Cancel API call on recall
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();

    // console.log("🚀 ~ getShopClosingTime ~utc time:", dayjs().toISOString());
    let resp = await dispatch(
      fetchShopClosingTime({
        dropOffDateTime: dayjs(time).utc(),
        cancelToken: cancelTokenSourceRef.current.token,
      })
    );

    // Convert API response to local time
    const shopClosingTimeLocal = dayjs(resp.shopClosingTime).tz(localTimezone);

    // Update state and form values
    setPickUpDate(shopClosingTimeLocal);
    setValue("pickUpDate", shopClosingTimeLocal);
  };

  //New Appointment fucntions
  const handleServiceChange = (event, index) => {
    const value = event.target.value;
    setServiceList((prevList) =>
      prevList.map((service, i) =>
        i === index ? { ...service, serviceShowName: value } : service
      )
    );
    if (value === "") {
      let cloneService = [...serviceList];
      cloneService[index] = { _id: "select" };
      setServiceList(cloneService);
      setServiceFilteredData(cachedServices);
    } else {
      const filtered = cachedServices.filter((item) =>
        item.serviceSubCategory.name.toLowerCase().includes(value.toLowerCase())
      );
      const exactMatch = filtered.some(
        (item) =>
          item.serviceSubCategory.name.toLowerCase() === value.toLowerCase()
      );
      const updatedFilteredData = exactMatch
        ? filtered
        : [{ _id: "new", serviceSubCategory: { name: value } }, ...filtered];

      setServiceFilteredData(updatedFilteredData);
    }

    // Manage dropdown visibility
    setAnchorEls((prev) => {
      const newAnchors = prev.map(() => null);
      newAnchors[index] = inputDivRef.current[index];
      return newAnchors;
    });
  };

  const handleOnFocus = (event, outerIndex) => {
    const value = event?.currentTarget?.value.trim();
    setAnchorEls((prev) => {
      const newAnchors = prev.map(() => null);
      newAnchors[outerIndex] = inputDivRef.current[outerIndex];
      return newAnchors;
    });
    if (value !== "") {
      const filtered = cachedServices.filter((item) =>
        item.serviceSubCategory.name
          .toLowerCase()
          .includes(value?.toLowerCase())
      );

      const exactMatch = filtered.some(
        (item) =>
          item.serviceSubCategory.name.toLowerCase() === value?.toLowerCase()
      );

      // If no exact match, allow creating a new option
      const updatedFilteredData = exactMatch
        ? filtered
        : [{ _id: "new", serviceSubCategory: { name: value } }, ...filtered];

      setServiceFilteredData(updatedFilteredData);
    } else {
      setServiceFilteredData(cachedServices);
    }
  };

  const handleAddNewService = () => {
    let cloneService = [...serviceList];
    cloneService.push({ _id: "select" });
    setServiceList(cloneService);
  };

  const handleSaveService = async (serviceName, outerIndex) => {
    preventCloseRef.current = true;
    let payload = {
      name: serviceName,
    };

    setServiceLoader(true);
    const resp = await dispatch(createServiceSubcategory(payload));

    if (resp) {
      // Fetch updated services list
      const updatedServices = await fetchAllService();

      // Find newly created service from updated list
      const newService = updatedServices.find(
        (item) =>
          item.serviceSubCategory.name.toLowerCase() ===
          serviceName.toLowerCase()
      );

      if (newService) {
        // Update service list at the correct index
        setServiceList((prevList) => {
          const updatedList = [...prevList];
          updatedList[outerIndex] = {
            ...newService,
            serviceShowName: newService.serviceSubCategory.name,
          };
          return updatedList;
        });

        // Close the dropdown for that input field
        setAnchorEls((prev) => {
          const newAnchors = [...prev];
          newAnchors[outerIndex] = null;
          return newAnchors;
        });

        // 🔹 Refocus the input field to trigger dropdown refresh
        setTimeout(() => {
          inputFielRef.current[outerIndex]?.focus();
        }, 100);
      }
    }

    setServiceLoader(false);
    // Close the dropdown
    const newAnchorEls = [...anchorEls];
    newAnchorEls[outerIndex] = null;
    setAnchorEls(newAnchorEls);
    setTimeout(() => {
      preventCloseRef.current = false; // Reset after a short delay
    }, 300);
    setTimeout(() => {
      preventCloseRef.current = false; // Reset after a short delay
    }, 300);
  };

  const handleCreateOrSelectService = (selectedItem, outerIndex) => {
    preventCloseRef.current = true;
    const value = selectedItem.serviceSubCategory.name;

    // Check for duplicate service name with a different index
    const isDuplicate = serviceList.some(
      (service, index) =>
        service.serviceShowName === value && index !== outerIndex
    );

    if (isDuplicate) {
      toast.error(`A service with the same name already exists.`);
      preventCloseRef.current = false; // Reset to allow dropdown close
      return;
    }

    let cloneService = [...serviceList];
    let storeDescription = cloneService[outerIndex].description;
    cloneService[outerIndex] = selectedItem;
    cloneService[outerIndex].description = storeDescription;
    cloneService[outerIndex].serviceShowName = value;
    setServiceList(cloneService);

    // Close the dropdown
    const newAnchorEls = [...anchorEls];
    newAnchorEls[outerIndex] = null;
    setAnchorEls(newAnchorEls);

    setTimeout(() => {
      preventCloseRef.current = false; // Reset after a short delay
    }, 300);
  };

  const handleServiceSelection = (item, outerIndex) => {
    handleCreateOrSelectService(item, outerIndex);

    // Update filtered data with the selected service
    // const filtered = cachedServices.filter((cachedItem) =>
    //   cachedItem.serviceSubCategory.name
    //     .toLowerCase()
    //     .includes(item.serviceSubCategory.name.toLowerCase())
    // );

    // const exactMatch = filtered.some(
    //   (cachedItem) =>
    //     cachedItem.serviceSubCategory.name.toLowerCase() ===
    //     item.serviceSubCategory.name.toLowerCase()
    // );

    // const updatedFilteredData = exactMatch
    //   ? [
    //       ...filtered,
    //       {
    //         _id: "new",
    //         serviceSubCategory: {
    //           name: item.serviceSubCategory.name,
    //         },
    //       },
    //     ]
    //   : filtered;

    // setServiceFilteredData(updatedFilteredData);
  };

  return (
    <>
      <Box padding={"24px"}>
        {/* Page Header */}
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/appointment/today"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Appointments
                </Link>
                <Link
                  to="/appointment/new-appointment"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  New Appointment
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" fontWeight={500}>
              New Appointment
            </Typography>
          </Grid>
        </Grid>

        <form
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          style={{ width: "100%" }}
        >
          <Grid
            container
            padding={"24px"}
            margin={"32px 0"}
            boxShadow={
              " 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
            }
            borderRadius={"8px"}
            bgcolor={"white"}
          >
            <Grid container spacing={3}>
              {/* Customer field */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required error={!!errors.customer}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "5.5px",
                    }}
                  >
                    <Typography variant="body1" className="label-500">
                      Customer <span style={{ color: "red" }}>*</span>
                    </Typography>

                    {!addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" ? (
                      <Grid>
                        <Button
                          onClick={() => {
                            handleOpenCustomerInfo();
                          }}
                        >
                          {" "}
                          + Add Customer Info
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Typography
                          color={"primary"}
                          fontWeight={500}
                          fontSize={"15px"}
                          onClick={() => {
                            setOpenAddCustomerModal(true);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          + NEW CUSTOMER
                        </Typography>
                        <AddCustomerModal
                          openModal={openAddCustomerModal}
                          onClose={() => {
                            setOpenAddCustomerModal(false);
                          }}
                          onSubmitModal={async (resp) => {
                            if (resp?._id) {
                              await customerList(1, 5000, resp?._id);
                              setValue("customer", resp?._id);
                              // setCustomerData(resp);
                              setOpenAddCustomerModal(false);
                            } else {
                              setOpenAddCustomerModal(false);
                            }
                            // setOpenAddCustomerModal(false);
                          }}
                        />
                      </Grid>
                    )}
                  </div>

                  <Controller
                    name="customer" // Form field name
                    control={control}
                    rules={{ required: "Customer is required" }} // Validation rule
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Autocomplete
                        name={name}
                        ref={ref}
                        disablePortal
                        id="combo-box-demo"
                        options={customerDetails ? customerDetails : []}
                        getOptionLabel={(option) => option.fullName}
                        loading={customerListLoader}
                        disabled={id || customerTypeData.length == 0}
                        value={customerData ? customerData : null}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          setCustomerData(newValue);
                          setVehicleData(null);
                          setValue("vehicle", null);
                          setCustomerCretedFrom(newValue?.createdFrom);
                          if (customerData != null) {
                            setVehcileList("");
                          }
                        }}
                        loadingText={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <CircularProgress />
                          </div>
                        }
                        popupIcon={<KeyboardArrowDownIcon />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            error={!!errors.customer} // Error state for validation
                            helperText={
                              errors.customer ? errors.customer.message : ""
                            }
                            onChange={(e) =>
                              handleInputDebounced(e, e.target.value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                        ListboxProps={{
                          style: { maxHeight: "300px" },
                          onScroll: (event) => {
                            handleScroll(event);
                          },
                        }}
                        renderOption={(props, option) => (
                          <>
                            <Box component="li" {...props}>
                              <li
                                {...props}
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                key={option?._id}
                                onClick={() => {
                                  if (option.createdFrom == "shop_imported") {
                                    handleOpenCustomerInfo();
                                  }
                                }}
                              >
                                <div>{option.fullName}</div>
                                <div>
                                  <Chip
                                    style={{
                                      ...styleChip,
                                      display: "flex",
                                      background:
                                        option.createdFrom == "shop_created"
                                          ? "#75DBFF80"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#E1D0FD"
                                          : "#EFEFEF",
                                      color:
                                        option.createdFrom == "shop_created"
                                          ? "#000000"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#3E1C76"
                                          : "#002394",
                                    }}
                                    className="chip-text-style"
                                    label={
                                      option.createdFrom == "shop_created"
                                        ? "Shop Created"
                                        : option.createdFrom == "shop_imported"
                                        ? "Shop Imported"
                                        : "Apex Auto"
                                    }
                                  />
                                </div>
                              </li>
                            </Box>

                            <Box component="li" {...props}>
                              {props["data-option-index"] + 1 ==
                                customerDetails?.length &&
                                customerListLoader && (
                                  <li
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",

                                      display: "flex",
                                    }}
                                  >
                                    <CircularProgress />
                                  </li>
                                )}
                            </Box>
                          </>
                        )}
                      />
                    )}
                  />
                  {/* {errors.customer && (
                    <FormHelperText error>
                      {errors.customer.message}
                    </FormHelperText>
                  )} */}
                </FormControl>
                <Grid item container columnGap={2} rowGap={1} mt={"6px"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_created}
                        onChange={handleCheckChange}
                        name="shop_created"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Created"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_imported}
                        onChange={handleCheckChange}
                        name="shop_imported"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Imported"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.apex_auto_app}
                        onChange={handleCheckChange}
                        name="apex_auto_app"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Apex Auto"
                  />
                </Grid>
                {/* </Stack> */}
              </Grid>
              {/* vehicle field  */}
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={!!errors.vehicle} // Show error if validation fails
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "5.5px",
                      marginTop:
                        !addCustomerInfoButton &&
                        customerCretedFrom == "shop_imported"
                          ? "11px"
                          : "",
                    }}
                  >
                    <Typography variant="body1" className="label-500">
                      Vehicle <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    {customerData &&
                      customerData?.createdFrom != "shop_imported" && (
                        <Grid item>
                          <Typography
                            color={"primary"}
                            fontWeight={500}
                            fontSize={"15px"}
                            onClick={() => {
                              handleVehicleInfoOpen();
                              setVehicleSourceData("");
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {" "}
                            + ADD VEHICLE
                          </Typography>
                        </Grid>
                      )}
                  </div>
                  <Controller
                    name="vehicle" // Form field name
                    control={control}
                    rules={{ required: "Vehicle is required" }} // Validation rule
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name}
                        ref={ref}
                        IconComponent={KeyboardArrowDownIcon} // This replaces the default icon
                        value={
                          Array.isArray(vehicleList) && customerData !== null
                            ? vehicleData
                            : ""
                        }
                        onChange={(event) => {
                          onChange(event.target.value);
                          setVehicleData(event.target.value);
                        }}
                        displayEmpty
                        error={!!errors.vehicle} // Error prop for validation state
                      >
                        {vehicleList?.length > 0 &&
                          vehicleList?.map((item) => {
                            if (item)
                              return (
                                <MenuItem
                                  value={item._id}
                                  onClick={() => {
                                    console.log("dkjjgfjhsgfhjsgdsfd", item);
                                    if (
                                      item.source &&
                                      item.source == "shop_imported"
                                    ) {
                                      setVehicleSourceData(item);
                                      handleVehicleInfoOpen();
                                    }
                                  }}
                                >
                                  {item?.vehicleYear ? item?.vehicleYear : " "}
                                  {item?.year && item?.year}{" "}
                                  {item?.vehicleMake?.name &&
                                    item?.vehicleMake?.name}{" "}
                                  {item?.vehicleModel?.name &&
                                    item?.vehicleModel?.name}{" "}
                                  {item?.vehicleModelTrim?.name &&
                                    item?.vehicleModelTrim?.name}
                                </MenuItem>
                              );
                          })}
                        {vehicleLoader && <DropdownLoader />}
                      </Select>
                    )}
                  />
                  {errors.vehicle && (
                    <FormHelperText error>
                      {errors.vehicle.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* </Stack> */}
              </Grid>
              {/* Dropoff fied */}
              <Grid
                item
                xl={4}
                lg={4}
                md={isOpenSideBar ? 6 : 4}
                sm={6}
                xs={12}
                paddingTop={"0px"}
                marginTop={"0px"}
                className="width-100-per-drop-off dateTimePicker"
              >
                <FormControl fullWidth required error={!!errors?.dropOffDate}>
                  <Typography variant="body1" className="label-500-8">
                    Drop Off
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="dropOffDate" // Field name
                      control={control}
                      rules={{
                        required: "Drop-off date and time are required.",
                      }} // Validation rule
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => (
                        // <DemoContainer
                        //   components={["DateTimePicker"]}
                        //   sx={{ width: "100%" }}
                        // >
                        <DateTimePicker
                          name={name}
                          ref={ref}
                          format="DD/MM/YYYY - hh:mm A"
                          value={dropOffDate}
                          onChange={(data) => {
                            let cloneData = data;
                            if (!dayjs(cloneData).isValid()) {
                              setDropOffDate(data);
                              setError("dropOffDate", {
                                type: "custom",
                                message: "Invalid date",
                              });
                              return;
                            }
                            // if (
                            //   ![0, 15, 30, 45].includes(
                            //     dayjs(cloneData).minute()
                            //   )
                            // ) {
                            //   setDropOffDate(data);
                            //   setError("dropOffDate", {
                            //     type: "custom",
                            //     message:
                            //       "Please select a time ending in 00, 15, 30, or 45 minutes.",
                            //   });
                            //   return;
                            // }
                            const now = dayjs();
                            if (isFirstSelection) {
                              // Get current hour and closest allowed minute
                              const currentHour = dayjs().hour();
                              const currentMinute = dayjs().minute();
                              const closestMinute =
                                getClosestAllowedMinute(currentMinute);

                              // Set the selected date's time to either current time or closest allowed minute
                              const updatedDate = data
                                .set(
                                  "hour",
                                  closestMinute === 0 && currentMinute > 45
                                    ? currentHour + 1
                                    : currentHour
                                )
                                .set("minute", closestMinute);

                              // Validate against current time
                              // if (dayjs(updatedDate).isBefore(now)) {
                              //   setDropOffDate(updatedDate);
                              //   setError("dropOffDate", {
                              //     type: "custom",
                              //     message:
                              //       "Please choose a future drop off time.",
                              //   });
                              //   return;
                              // } else {
                              clearErrors("dropOffDate");
                              setDropOffDate(updatedDate);
                              setValue("pickUpDate", updatedDate);
                              getShopClosingTime(updatedDate);
                              setIsFirstSelection(false); // Mark as not the first selection
                              // }
                              onChange(updatedDate);
                            } else {
                              // Validate only, without resetting time
                              // if (dayjs(data).isBefore(now)) {
                              //   setDropOffDate(data);
                              //   setError("dropOffDate", {
                              //     type: "custom",
                              //     message:
                              //       "Please choose a future drop off time.",
                              //   });
                              //   return;
                              // } else {
                              clearErrors("dropOffDate");
                              setDropOffDate(data);
                              setValue("pickUpDate", data);
                              getShopClosingTime(data);
                              // }
                              onChange(data);
                            }

                            clearErrors("pickUpDate"); // Clear pickUpDate error if any
                          }}
                          // style={{ width: "100%" }}

                          minutesStep={15}
                          // minDate={dayjs().startOf("day")}
                          slots={{ openPickerIcon: KeyboardArrowDownIcon }} // Replace calendar icon
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error, // Set error state for TextField
                              helperText: error ? error.message : undefined, // Set helper text
                            },
                          }}
                        />
                        // </DemoContainer>
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* pickup field  */}

              <Grid
                item
                xl={4}
                lg={4}
                md={isOpenSideBar ? 6 : 4}
                sm={6}
                xs={12}
                paddingTop={"0px"}
                marginTop={"0px"}
                className="width-100-per-drop-off"
              >
                <FormControl fullWidth required error={!!errors?.pickUpDate}>
                  <Typography variant="body1" className="label-500-8">
                    Pick Up Time
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="pickUpDate" // Field name
                      control={control}
                      rules={{ required: "Pickup date and time are required." }} // Validation rule
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => (
                        // <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          name={name}
                          ref={ref}
                          format="DD/MM/YYYY - hh:mm A"
                          value={pickUpDate}
                          onChange={(data) => {
                            // if (
                            //   dropOffDate &&
                            //   dayjs(data).isBefore(dayjs(dropOffDate))
                            // ) {
                            //   setError("pickUpDate", {
                            //     type: "custom",
                            //     message:
                            //       "Pickup time must be later than drop-off time.",
                            //   });
                            // } else {
                            //   clearErrors("pickUpDate"); // Clear error if valid
                            onChange(data);
                            setPickUpDate(data);
                            // getShopClosingTime(dayjs(data));
                            // }
                          }}
                          style={{ width: "100%" }}
                          // minDate={dayjs().startOf("day")}
                          slots={{ openPickerIcon: KeyboardArrowDownIcon }} // Replace calendar icon
                          slotProps={{
                            textField: {
                              error: !!error, // Set error state for TextField
                              helperText: error ? error.message : undefined, // Set helper text
                            },
                          }}
                          shouldDisableDate={disablePrevDates(dropOffDate)}
                        />
                        // </DemoContainer>
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* assignee field  */}
              <Grid
                item
                xl={4}
                lg={4}
                md={isOpenSideBar ? 12 : 4}
                sm={12}
                xs={12}
              >
                <Stack spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    Assign To (Calendar){" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <Select
                    value={mechanicData}
                    onChange={(event) => {
                      setMechanicData(event.target.value);
                    }}
                    displayEmpty
                    required
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {mechanicDetail &&
                      mechanicDetail?.map((item) => {
                        return (
                          <MenuItem value={item?._id}>{item.name}</MenuItem>
                        );
                      })}
                    {assigneeLoader && (
                      <MenuItem
                        value={null}
                        disabled
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </MenuItem>
                    )}
                    {!assigneeLoader && mechanicDetail.length == 0 && (
                      <MenuItem value={null} disabled>
                        No assignee found
                      </MenuItem>
                    )}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                <FormControl fullWidth required error={!!errors?.isWaitonSite}>
                  <Typography variant="body1" className="label-500">
                    Is the Customer Waiting on Site?{" "}
                  </Typography>
                  <Controller
                    name="isWaitonSite" // Field name
                    control={control}
                    // rules={{ required: "Please choose one option" }} // Validation rule
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="customer-waiting-on-site"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                          setCustomerOnSite(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="wait_on_site"
                          control={<Radio />}
                          label="Wait on Site"
                        />
                        <FormControlLabel
                          value="dropping_off_car"
                          control={<Radio />}
                          label="Dropping off Car"
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.isWaitonSite && (
                    <FormHelperText>
                      {errors?.isWaitingOnSite?.message ||
                        "This field is required."}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                <FormControl fullWidth error={!!errors.contactPrefrence}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Contact Preference
                    </Typography>
                    <Controller
                      name="contactPrefrence"
                      control={control}
                      // rules={{
                      //   required: "Phone Number Type is required",
                      // }}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        >
                          {APPOINTMENT_CONTACT_PREFRENCES?.map((contact) => (
                            <FormControlLabel
                              value={contact.key}
                              control={<Radio />}
                              label={contact?.value}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </Stack>
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1" className="label-500">
                    Add Note
                  </Typography>
                  <Controller
                    name="noteForCustomer"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.noteForCustomer}
                          helperText={
                            errors.noteForCustomer
                              ? errors.noteForCustomer.message
                              : ""
                          }
                          {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Divider
              style={{
                marginTop: "24px",
                marginBottom: "24px",
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomWidth: "2px",
              }}
            />
            {serviceList &&
              serviceList[0]?._id &&
              serviceList[0]?._id !== "select" && (
                <Grid
                  item
                  container
                  justifyContent={"end"}
                  // padding={"0 0px 0 0px"}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<Add />}
                      size="large"
                      onClick={(event) => {
                        if (
                          serviceList[serviceList?.length - 1]._id !== "select"
                        ) {
                          handleAddNewService(event);
                        } else {
                          toast.error(
                            "Please select a service before adding a new one."
                          );
                        }
                      }}
                    >
                      ADD SERVICE
                    </Button>
                  </Grid>
                </Grid>
              )}
            {serviceList &&
              serviceList?.length > 0 &&
              serviceList.map((outerItem, outerIndex) => (
                <Grid container item key={outerIndex} mt={"24px"}>
                  <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                    <Grid
                      container
                      item
                      style={{
                        backgroundColor: "#fff",
                        color: "black",
                        marginTop: "8px",
                      }}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{ width: "100%", position: "relative" }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#002F9C",
                              padding: "16px",
                              borderRadius: "4px 4px 0 0",
                              height: "62px",
                            }}
                            ref={(el) => (inputDivRef.current[outerIndex] = el)}
                          >
                            <Input
                              inputRef={(el) =>
                                (inputFielRef.current[outerIndex] = el)
                              }
                              autoComplete="off"
                              id="standard-adornment-amount"
                              disableUnderline
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  if (serviceFilteredData?.length > 0) {
                                    const firstItem = serviceFilteredData[0];

                                    if (firstItem?._id === "new") {
                                      // If the first item is "new", call handleSaveService
                                      handleSaveService(
                                        firstItem.serviceSubCategory.name,
                                        outerIndex
                                      );
                                    } else {
                                      // Otherwise, select the first item
                                      handleCreateOrSelectService(
                                        firstItem,
                                        outerIndex
                                      );
                                    }
                                  }
                                }
                              }}
                              onFocus={(event) => {
                                handleOnFocus(event, outerIndex);
                              }}
                              onBlur={(event) => {
                                setTimeout(() => {
                                  if (preventCloseRef.current) {
                                    return; // Do not close if "Save" was clicked
                                  }

                                  if (
                                    serviceList[outerIndex]?._id &&
                                    serviceList[outerIndex]?._id !== "select"
                                  ) {
                                    let updatedServiceList = [...serviceList];
                                    updatedServiceList[
                                      outerIndex
                                    ].serviceShowName =
                                      serviceList[
                                        outerIndex
                                      ]?.serviceSubCategory?.name;
                                    setServiceList(updatedServiceList);
                                  } else {
                                    let updatedServiceList = [...serviceList];
                                    updatedServiceList[outerIndex] = {
                                      _id: "select",
                                    };
                                    setServiceList(updatedServiceList);
                                  }
                                  if (
                                    !inputDivRef.current[outerIndex]?.contains(
                                      document.activeElement
                                    )
                                  ) {
                                    setAnchorEls((prev) => {
                                      const newAnchors = prev.map(() => null);
                                      return newAnchors;
                                    });
                                  }
                                }, 200);
                              }}
                              onChange={(event) => {
                                if (cachedServices?.length > 0) {
                                  handleServiceChange(event, outerIndex);
                                }
                              }}
                              value={outerItem?.serviceShowName ?? ""}
                              defaultValue={outerItem?.serviceShowName ?? ""}
                              placeholder="Search or enter your own service"
                              style={{
                                height: "32px",
                                fontSize: outerItem?.serviceShowName
                                  ? "20px"
                                  : "16px",
                                fontWeight: outerItem?.serviceShowName
                                  ? "500"
                                  : "400",
                                lineHeight: outerItem?.serviceShowName
                                  ? "32px"
                                  : "24px",
                                letterSpacing: "0.15px",
                                width: "100%",
                                color: "#fff", // Text color
                                borderBottom: outerItem?.serviceShowName
                                  ? ""
                                  : "1px solid rgba(255, 255, 255, 0.42)", // White bottom border
                              }}
                              startAdornment={
                                outerItem?.serviceShowName ? null : (
                                  <InputAdornment position="start">
                                    <Search style={{ color: "#fff" }} />
                                  </InputAdornment>
                                )
                              }
                            />
                          </div>

                          {/* Dropdown with filtered results */}
                          {anchorEls[outerIndex] && (
                            <Popper
                              open={Boolean(anchorEls[outerIndex])}
                              anchorEl={anchorEls[outerIndex]}
                              placement="bottom-start" // Align popper below the input
                              style={{
                                zIndex: 1300,
                                maxWidth: "672px",
                                width: anchorEls[outerIndex]
                                  ? `${
                                      anchorEls[outerIndex].offsetWidth + 32
                                    }px`
                                  : "100%", // Match width of input
                                maxHeight: "190px",
                                overflowY: "auto",
                                backgroundColor: "#fff",
                                borderRadius: "4px",
                                marginTop: "4px",
                                boxShadow:
                                  "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                              }}
                            >
                              <Paper style={{ width: "100%" }}>
                                <MenuList
                                  style={{
                                    paddingTop: "8px",
                                    paddingBottom: "8px",
                                  }}
                                >
                                  {serviceLoader ||
                                  !cachedServices?.length > 0 ? (
                                    <Box component="li" display={"flex"}>
                                      <CircularProgress />
                                    </Box>
                                  ) : serviceFilteredData?.length > 0 ? (
                                    serviceFilteredData.map((item, index) => {
                                      if (item?._id === "new") {
                                        return (
                                          <MenuItem
                                            key={`option-${index}`}
                                            style={{
                                              height: "44px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              cursor: "default",
                                              pointerEvents: "none",
                                              paddingRight: "0",
                                            }}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                            }}
                                          >
                                            <span>
                                              {" "}
                                              {item.serviceSubCategory.name}
                                            </span>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "6px 16px",
                                                background:
                                                  "rgba(33, 150, 243, 0.15)",
                                                cursor: "pointer",
                                                pointerEvents: "all",
                                              }}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                handleSaveService(
                                                  item.serviceSubCategory.name,
                                                  outerIndex
                                                );
                                              }}
                                            >
                                              <Save
                                                sx={{
                                                  color: "#006CDD",
                                                  width: "26px",
                                                  height: "26px",
                                                }}
                                              />
                                              <span
                                                style={{
                                                  textDecoration: "underline",
                                                  color: "#006CDD",
                                                  fontSize: "13px",
                                                  textTransform: "uppercase",
                                                  fontWeight: 500,
                                                  lineHeight: "26px",
                                                  letterSpacing: "0.46px",
                                                  marginLeft: "8px",
                                                }}
                                              >
                                                Save
                                              </span>
                                            </div>
                                          </MenuItem>
                                        );
                                      } else {
                                        return (
                                          <MenuItem
                                            key={`option-${index}`}
                                            style={{
                                              height: "44px",
                                              background:
                                                item?._id === outerItem?._id
                                                  ? "#2196F30A"
                                                  : "",
                                            }}
                                            onClick={() =>
                                              handleServiceSelection(
                                                item,
                                                outerIndex
                                              )
                                            }
                                          >
                                            {item.serviceSubCategory.name}
                                          </MenuItem>
                                        );
                                      }
                                    })
                                  ) : (
                                    !serviceFilteredData?.length === 0 && (
                                      <MenuItem
                                        style={{
                                          height: "44px",
                                          textAlign: "center",
                                        }}
                                      >
                                        No results found
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </Paper>
                            </Popper>
                          )}
                        </div>
                        {outerItem?._id && outerItem?._id !== "select" && (
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <div className="tableScroll">
                              <TableContainer sx={{ maxHeight: 600 }}>
                                <Table
                                  aria-label="caption table"
                                  padding="16px"
                                >
                                  <TableHead
                                    style={{
                                      backgroundColor: "#F8F8F8",
                                      fontWeight: 500,
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          minWidth: "80px",
                                          maxWidth: "120px",
                                          width: "120px",
                                        }}
                                      >
                                        No.
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          maxWidth: "71.01%",
                                          width: "71.01%",
                                          minWidth: "500px",
                                        }}
                                      >
                                        Description
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          minWidth: "200px",
                                          maxWidth: "18.12%",
                                          width: "18.12px",
                                        }}
                                        align="left"
                                      >
                                        Cost
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        key={0}
                                        component="th"
                                        align="left"
                                        scope="row"
                                        padding="16px"
                                        style={{
                                          minWidth: "80px",
                                          maxWidth: "120px",
                                          width: "120px",
                                        }}
                                      >
                                        1
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        padding="16px"
                                        style={{
                                          maxWidth: "71.01%",
                                          width: "71.01%",
                                          minWidth: "500px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "500px",
                                            display: "flex",
                                            gap: "4px",
                                            alignItems: "center",
                                            justifyContent:
                                              !outerItem?.description
                                                ? "flex-start"
                                                : "space-between",
                                          }}
                                        >
                                          <TextField
                                            inputRef={(el) =>
                                              (textFieldRef.current[
                                                outerIndex
                                              ] = el)
                                            }
                                            multiline
                                            maxRows={5}
                                            variant="standard"
                                            value={outerItem?.description ?? ""}
                                            onChange={(event) => {
                                              setServiceList((prevList) =>
                                                prevList.map((service, i) =>
                                                  i === outerIndex
                                                    ? {
                                                        ...service,
                                                        description:
                                                          event.target.value,
                                                      }
                                                    : service
                                                )
                                              );
                                            }}
                                            fullWidth={
                                              outerItem?.description &&
                                              outerItem?.description !== ""
                                                ? true
                                                : false
                                            }
                                            placeholder="Add Description"
                                            InputProps={{
                                              disableUnderline: true,
                                              style: {
                                                width: !outerItem?.description
                                                  ? "102px"
                                                  : "100%",
                                                fontSize: "14px",
                                                color: "#646466",
                                                cursor: "text",
                                                lineHeight: "143%",
                                                letterSpacing: " 0.17px,",
                                              },
                                            }}
                                            sx={{
                                              padding: 0,
                                              border: "none",
                                              fontSize: "16px",
                                              "& .MuiInputBase-input": {
                                                padding: 0,
                                              },
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  border: "none",
                                                },
                                            }}
                                          />
                                          <Edit
                                            size={16}
                                            style={{
                                              color: "#646466",
                                              width: "20px",
                                              height: "20px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              if (
                                                textFieldRef.current[outerIndex]
                                              ) {
                                                textFieldRef.current[
                                                  outerIndex
                                                ].focus(); // Ensure focus works
                                              }
                                            }}
                                          />
                                        </div>
                                      </TableCell>

                                      <TableCell align="start" padding="16px">
                                        {outerItem?.priceType ===
                                        SHOP_SERVICE_PRICE_TYPES.RANGE
                                          ? `Range ${formatCurrency(
                                              outerItem?.priceMin
                                            )} - 
                                      ${formatCurrency(outerItem?.priceMax)}`
                                          : outerItem?.priceType ===
                                            SHOP_SERVICE_PRICE_TYPES.FIXED
                                          ? formatCurrency(
                                              outerItem?.fixedPrice
                                            )
                                          : outerItem?.priceType ===
                                            SHOP_SERVICE_PRICE_TYPES.STARTING_FROM
                                          ? `Starting From: ${formatCurrency(
                                              outerItem?.priceMin
                                            )}`
                                          : "-"}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {/* Bottom Buttons */}

                    {outerItem?._id && outerItem?._id !== "select" && (
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent={"flex-end"}
                        style={{ padding: "24px 0 " }}
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{ marginLeft: "24px" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Edit />}
                              size="large"
                              onClick={(event) => {
                                handleOnFocus(event, outerIndex);
                                if (inputFielRef.current[outerIndex]) {
                                  const inputElement =
                                    inputFielRef.current[outerIndex];
                                  inputElement.focus(); // Ensure focus works
                                  const length = inputElement.value.length;
                                  inputElement.setSelectionRange(
                                    length,
                                    length
                                  ); // Move cursor to end
                                }
                              }}
                            >
                              EDIT SERVICE
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              startIcon={<Delete />}
                              size="large"
                              onClick={() => {
                                setServiceList((prevList) => {
                                  if (prevList.length > 1) {
                                    // Remove the selected service from the list
                                    const updatedList = prevList.filter(
                                      (_, i) => i !== outerIndex
                                    );

                                    // Close the removed service's dropdown
                                    setAnchorEls((prevAnchors) => {
                                      const newAnchors = prevAnchors.map(
                                        (_, i) =>
                                          i === outerIndex
                                            ? null
                                            : prevAnchors[i]
                                      );
                                      return newAnchors;
                                    });

                                    return updatedList;
                                  } else {
                                    // If only one service is left, reset it instead of removing
                                    setAnchorEls([null]); // Close any open dropdown
                                    return [{ _id: "select" }];
                                  }
                                });
                              }}
                            >
                              REMOVE SERVICE
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}

            <Divider
              style={{
                marginTop: "24px",
                marginBottom: "24px",
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomWidth: "2px",
              }}
            />
            <Grid container justifyContent={"end"} padding={"0 0 0 0"}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
              >
                Confirm appointment
              </Button>
            </Grid>
          </Grid>
        </form>
        <AddAppointmentServiceModal
          open={open}
          handleClose={handleClose}
          filteredArray={filteredArray}
          appointentServiceModalDataProps={(dataId, page) => {
            let serviceIdArray = serviceId;
            serviceIdArray.push(dataId);
            setServiceId(serviceIdArray);
            setPage(page);
          }}
          servicesNameAPI={servicesName}
        />
        <ConfirmCustomersInfoModel
          open={openCustomerInfo}
          handleClose={handleCloseCustomerInfo}
          customerData={customerData}
          id={customerData?.refIdImport}
          vehicalDetailFunction={vehicalDetail}
          setAddCustomerInfoButton={setAddCustomerInfoButton}
          setCustomerData={setCustomerData}
          setCustomerTypeData={setCustomerTypeData}
          state={state}
          setState={setState}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
          madeFromCustomerProfile={false}
        />
        <MissingVehicleInfoModal
          open={vehicleInfoOpen}
          handleClose={handleVehicleInfoClose}
          id={customerData?.refIdUsers}
          vehicalDetailFunction={vehicalDetail}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
          setVehicleCreatedId={setVehicleCreatedId}
          vehicleSource={vehicleSourceData}
          setVehicleSourceData={setVehicleSourceData}
        />
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
        <DescriptionModal
          open={isDescriptionModalOpen}
          saveIndex={selectedIndex}
          serviceList={serviceList}
          setServiceList={setServiceList}
          onClose={() => {
            setIsDescriptionModalOpen(false);
            setSelectedIndex(0);
          }}
        />
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={checkChangeLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewAppointment;
