import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select as MuiSelect,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../../App";
import PostalCode from "../../../components/Address/PostalCode";
import { preventPhoneInputKeys } from "../../../components/FormatNumber/FormatNumber";
import { configDetails } from "../../../redux/Account/action";
import { getCountryMasterListApi } from "../../../redux/Account/peronalIfno/action";
import { discountProgramApi } from "../../../redux/Account/Pricing/action";
import {
  customerCreate,
  decodeLicence,
  decodeVIN,
  getCityList,
  getProvinceList,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../../redux/Customer/action";
import { fetchProvinces } from "../../../redux/Vehicles/action";
import { CONTACT_PREFRENCES, PHONE_TYPES } from "../../../utility/Constant";
import { filterUndefined } from "../../../utility/helpers/commonFunction";
import {
  customStyles,
  errorCustomStyles,
  phoneTypeCustomStyles,
} from "../../../utility/SelectDropdown";
import useDebounce from "../../../utility/useDebounce";
import { checkValidVIN } from "../../../utility/utilityFunctions";
import {
  inputFormatPhoneNumber,
  removeDashFromPhoneNumber,
} from "../../Common/FormatPhoneNumber";
import "../Customers.css";
import AddressSelect from "./AddressSelect";
import "./Profile.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function AddCustomerModal({ openModal, onClose, onSubmitModal }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue: setValueinForm,
    setError,
    getValues,
    setFocus,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      membershipProgram: "none",
    },
  });

  const [isLoader, setIsLoader] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const phoneTypes = PHONE_TYPES;
  const contactPrefrences = CONTACT_PREFRENCES;

  const [showSecondaryNumber, setShowSecondaryNumber] = useState(false);
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState({
    primary: "mobile",
    secondary: "mobile",
  });
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);

  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [discountProgramLoader, setDiscountProgramLoader] = useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
  });

  const [step, setStep] = useState(1);
  const [yearValue, setYearValue] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [trimEngineValue, setTrimEngineValue] = useState("");
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);
  const [makeLoader, setMakeLoader] = useState(false);
  const [provinceLoader, setProvinceLoader] = useState(false);
  const [provinces, setProvince] = useState([]);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [decode, setDecode] = useState({
    vin: false,
    licence: false,
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountrySecondary, setSelectedCountrySecondary] =
    useState(null);

  const countryMasterList = useSelector(
    (state) => state.account?.countryMasterList
  );

  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
    fetchDiscountProgram();
    fetchConfig();
    fetchCountryMasterList();
    fetchVehicleYear();
    fetchProvincesList();
  }, []);

  const fetchCountryMasterList = async () => {
    let resp = await dispatch(getCountryMasterListApi());
    if (resp.length > 0) {
      const selected = resp.find((item) => item.default === true);
      setSelectedCountry(selected);
      setSelectedCountrySecondary(selected);
    }
  };

  useEffect(() => {
    if (openModal) {
      reset();
      setIsLoader(false);
      setValueinForm("membershipProgram", "none");
      setValueinForm(
        "primaryPhoneNumberType",
        selectedPhoneTypes
          ? phoneTypes.find(
              (option) => option.key == selectedPhoneTypes?.primary
            )
          : ""
      );
      setValueinForm(
        "secondaryPhoneNumberType",
        selectedPhoneTypes
          ? phoneTypes.find(
              (option) => option.key == selectedPhoneTypes?.primary
            )
          : ""
      );
      if (provinces.length > 0) {
        const defaultProvince = provinces?.find(
          (d) => d.isDefault === true
        )?._id;
        setValueinForm("provinceId", defaultProvince);
        setProvinceValue(defaultProvince);
        setTabValue(0);
      }
    }
  }, [openModal]);

  const watchedLicenceValue = watch("licenseNumber", "");
  const debouncedLicenceValue = useDebounce(watchedLicenceValue, 1500);
  const watchedVINValue = watch("vin", "");
  const debouncedVINValue = useDebounce(watchedVINValue, 1500);

  const fetchConfig = async () => {
    await dispatch(configDetails());
  };

  const config = useSelector((state) => state.account.configDetailData);

  const fetchVehicleYear = async () => {
    await dispatch(
      vehicleYearMaster({
        page: 1,
        limit: 1000,
      })
    );
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchProvincesList = async () => {
    await dispatch(fetchProvinces());
  };
  const provincesList = useSelector((state) => state.vehicle.provinces)?.sort(
    (a, b) => a.province.localeCompare(b.province)
  );

  useEffect(() => {
    setProvince(provincesList);
  }, [provincesList]);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };

  const makes = useSelector((state) => state.customer?.vehicleMake);
  useEffect(() => {
    setMake(makes);
  }, [makes]);

  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };

  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);

  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);
    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);

  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");
      setLoader(true);

      const resp = await dispatch(decodeVIN(getValues()?.vin));
      if (resp?.status) {
        // setModelSelectLoader(true);
        setMakeValue(resp?.data?.make?._id);
        setModelValue("");
        setTrimValue("");
        await dispatch(
          vehicleMakeMaster({
            page: 1,
            limit: 1000,
            year: resp?.data?.year,
          })
        );
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          year: resp?.data?.year,
        };
        let maekResult = await dispatch(vehicleModelMaster(para));
        if (maekResult) {
          let para = {
            page: 1,
            limit: 1000,
            vehicleMakeId: resp?.data?.make?._id,
            vehicleModelId: resp?.data?.model?._id,
            year: resp?.data?.year,
          };
          await dispatch(vehicleTrimMaster(para));
        }

        setValueinForm("year", resp?.data?.year + "");
        setValueinForm("vehicleMakeId", resp?.data?.make?._id);
        setValueinForm("vehicleModelId", resp?.data?.model?._id);
        setValueinForm("vehicleModelTrimId", resp?.data?.trim?._id);
        setYearValue(resp?.data?.year);
        setMakeValue(resp?.data?.make?._id);
        setModelValue(resp?.data?.model?._id);
        setTrimValue(resp?.data?.trim?._id);

        if (step == 1) setStep(2);
      }
      setLoader(false);
    } else if (getValues()?.vin && getValues()?.vin.length == 0) {
      setLoader(false);
      clearErrors("vin");
    } else {
      setLoader(false);
      setError("vin", {
        message: "Please enter valid VIN",
      });
    }
  };
  const handleDecodeLicence = async () => {
    if (!provinceValue) {
      setError("province", {
        message: "Please select province",
      });
      return toast.info("Please select province");
    }
    if (provinceValue && debouncedLicenceValue) {
      setLoader(true);

      const resp = await dispatch(
        decodeLicence({
          licenceNumber: debouncedLicenceValue,
          provinceCode: provinces?.find((d) => d._id == provinceValue)
            ?.provinceCode,
        })
      );
      // setModelSelectLoader(true);
      if (resp?.status) {
        setValueinForm("vin", resp?.data?.vin);
        handleDecodeVIN();
      } else {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    if (config?.isLicensePlateDecodeEnabled && debouncedLicenceValue) {
      if (decode?.licence) handleDecodeLicence(debouncedLicenceValue);
    }
  }, [debouncedLicenceValue]);
  useEffect(() => {
    if (debouncedVINValue) {
      if (decode?.vin) handleDecodeVIN();
    }
  }, [debouncedVINValue]);

  const fetchDiscountProgram = async () => {
    setDiscountProgramLoader(true);
    let resp = await dispatch(discountProgramApi({ pagination: false }));
    if (resp.length > 0) {
      setDiscountList(resp);
    }
    setDiscountProgramLoader(false);
  };

  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  const handleAdd = (event) => {
    handleSubmit(async (data) => {
      const { streetAddress, city, province, postalCode } = data;
      console.log("🚀 ~ handleSubmit ~ streetAddress:", streetAddress);
      console.log("🚀 ~ handleSubmit ~ postalCode:", postalCode);
      console.log("🚀 ~ handleSubmit ~ province:", province);
      console.log("🚀 ~ handleSubmit ~ city:", city);
      const isAnyFieldFilled = [streetAddress, city, province, postalCode].some(
        (field) => field && field.trim() !== ""
      );
      if (isAnyFieldFilled) {
        if (!streetAddress)
          setError("streetAddress", {
            type: "required",
            message: "Street address is required",
          });
        if (!city)
          setError("city", { type: "required", message: "City is required" });
        if (!province)
          setError("province", {
            type: "required",
            message: "Province is required",
          });
        if (!postalCode)
          setError("postalCode", {
            type: "required",
            message: "Postal code is required",
          });

        if (!streetAddress || !city || !province || !postalCode) {
          setTabValue(2);
          return;
        }
      } else {
        if (data?.vin && data?.vin !== "") {
          if (checkValidVIN(data?.vin)) {
            clearErrors();
            clearErrors("vin");
          } else {
            setTabValue(2);
            return setError("vin", { message: "Please enter valid VIN" });
          }
        } else if (!data?.year) {
          setTabValue(2);
          return setError("vin", { message: "Please enter valid VIN" });
        } else {
          clearErrors();
        }
      }
      console.log("🚀 ~ handleSubmit ~ data:", filterUndefined(data));

      let payload = {
        notes: [],
        vehicle: [],
        countryCode: "+1",
      };

      for (const key in data) {
        if (data[key]) payload[key] = data[key];
      }
      if (data.email) {
        payload.email = data.email;
      }
      if (data.firstName) {
        payload.firstName = data.firstName;
      }
      if (data.lastName) {
        payload.lastName = data.lastName;
      }
      if (data?.streetAddress) {
        payload.streetAddress = data?.streetAddress;
      }
      if (addressValues?.city) {
        payload.city = addressValues?.cityId;
      }
      if (addressValues?.province) {
        payload.province = addressValues?.provinceId;
      }
      if (data?.postalCode) {
        payload.postalCode = data?.postalCode;
      }
      if (data?.contactPrefrence) {
        payload.contactPrefrence = data?.contactPrefrence?.key;
      } else {
        delete payload["contactPrefrence"];
      }
      if (data?.primaryPhoneNumberType) {
        payload.primaryPhoneNumberType = data?.primaryPhoneNumberType?.key;
        payload.countryCode = selectedCountry?.dial_code;
        payload.phoneNumberCountry = selectedCountry?._id;
      }

      payload.secondaryPhoneNumberType = data?.secondaryPhoneNumberType?.key;
      payload.secondaryPhoneNumber = data?.secondaryPhoneNumber;

      if (data?.secondaryPhoneNumber && data.secondaryPhoneNumber !== "") {
        payload.secondaryPhoneNumberCountryCode =
          selectedCountrySecondary?.dial_code;
        payload.secondaryPhoneNumberCountry = selectedCountrySecondary?._id;
      } else {
      }

      if (primaryEmailIndex == 0) {
        payload.email = data?.email;
        payload.secondaryEmail = data?.secondaryEmail;
      } else {
        payload.secondaryEmail = data?.email;
        payload.email = data?.secondaryEmail;
      }
      if (
        data.membershipProgram &&
        data.membershipProgram !== "" &&
        data.membershipProgram !== "none"
      ) {
        payload.membershipProgram = data.membershipProgram;
      } else {
        payload.membershipProgram = "";
      }

      let vehiclePayload = {
        vin: data?.vin,
        year: data?.year + "",
        vehicleMakeId: data?.vehicleMakeId,
        vehicleModelId: data?.vehicleModelId,
        vehicleModelTrimId: data?.vehicleModelTrimId,
        licenseNumber: data?.licenseNumber,
        provinceId: data?.provinceId,
      };

      if (!payload?.vin && data?.vin == "") {
        delete vehiclePayload.vin;
        delete payload?.vin;
      }
      if (data?.vin) {
        delete payload.vin;
      }

      if (payload?.year) {
        delete payload?.year;
      }

      if (payload?.vehicleMakeId) {
        delete payload?.vehicleMakeId;
      }

      if (payload?.vehicleModelId) {
        delete payload?.vehicleModelId;
      }

      if (
        vehiclePayload.vehicleModelTrimId === "" ||
        !vehiclePayload.vehicleModelTrimId
      ) {
        delete vehiclePayload.vehicleModelTrimId;
      }
      if (payload.vehicleModelTrimId) {
        delete payload.vehicleModelTrimId;
      }
      if (payload?.licenseNumber) {
        delete payload?.licenseNumber;
      }

      if (payload?.provinceId) {
        delete payload?.provinceId;
      }

      payload.vehicle = [filterUndefined(vehiclePayload)];
      setIsLoader(true);
      const resp = await dispatch(customerCreate(payload));
      if (resp) {
        setTimeout(() => {
          onSubmitModal(resp);
          closeModal();
          setIsLoader(false);
        }, 1000);
      } else {
        // onSubmitModal();
        // closeModal();
        setIsLoader(false);
      }
    })(event);
  };

  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    const thirdTab = ["provinceId", "licenseNumber", "year", "vin"];
    if (thirdTab.includes(firstErrorField)) {
      setTabValue(1);
    }
  };

  const closeModal = () => {
    setShowSecondaryNumber(false);
    setShowSecondaryEmail(false);
    setTrimEngineValue(null);
    setYearValue(null);
    setMakeValue(null);
    setModelValue(null);
    setTrimValue(null);
    setDecode({ vin: false, licence: false });
    setProvinceValue("");
    setTabValue(0);
    reset();
    // setStep(1);
    onClose();
  };

  const handleCountryChange = (event) => {
    const selected = countryMasterList.find(
      (item) => item._id === event.target.value
    );
    setSelectedCountry(selected);
  };

  const handleCountryChangeSecondary = (event) => {
    const selected = countryMasterList.find(
      (item) => item._id === event.target.value
    );
    setSelectedCountrySecondary(selected);
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          if (!isLoader) {
            reset();
            closeModal();
          }
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              backgroundColor: "#002394",
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                New Customer
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    if (!isLoader) {
                      reset();
                      closeModal();
                    }
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              marginTop: "66px",
            }}
          >
            <form
              onSubmit={(event) => {
                event.stopPropagation(); // Prevents the event from propagating to the outer form
                handleSubmit(handleAdd, onInvalid)(event); // Pass both handleAdd and onInvalid
              }}
            >
              <Grid
                container
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  maxHeight: "70vh",
                }}
                padding={"24px 24px 0 24px"}
              >
                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                  aria-label="basic tabs example"
                  sx={{ marginBottom: "24px" }}
                >
                  <Tab label="BASIC DETAILS" {...a11yProps(0)} />
                  <Tab label="VEHICLE DETAILS" {...a11yProps(1)} />
                  <Tab label="ADDITIONAL DETAILS" {...a11yProps(2)} />
                </Tabs>

                <div style={{ display: tabValue === 0 ? "block" : "none" }}>
                  <Grid container spacing={3}>
                    <Grid item container xs={12} sm={12} md={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {" "}
                            Primary Phone Number{" "}
                            <span style={{ color: REQUIRED_RED }}>*</span>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <Grid container columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.primaryPhoneNumberType}
                                >
                                  <Controller
                                    name="primaryPhoneNumberType"
                                    control={control}
                                    rules={{
                                      required: "Phone Number Type is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        placeholder=""
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ==
                                                  selectedPhoneTypes?.primary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option.key}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            primary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.primaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={!!errors.primaryPhoneNumberType}
                                        helperText={
                                          errors.primaryPhoneNumberType
                                            ? errors.primaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.primaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.primaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6.5}
                                xl={6.5}
                              >
                                <Controller
                                  name="phoneNumber"
                                  control={control}
                                  rules={{
                                    required: "Phone Number is required",
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      InputProps={{
                                        startAdornment: (
                                          <MuiSelect
                                            // disabled={
                                            //   type == SHOP_CREATED
                                            //     ? false
                                            //     : true
                                            // }
                                            style={{
                                              width: "92px",
                                              minWidth: "92px",
                                              height: "56px",
                                              border: "none",
                                              marginRight: "16.5px",
                                            }}
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  width: "264px",
                                                  marginLeft: "86px",
                                                  boxShadow:
                                                    "box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                                                },
                                              },
                                              MenuListProps: {
                                                style: {},
                                              },
                                            }}
                                            sx={{
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderRight: "none",
                                                },
                                              "& .MuiOutlinedInput-root": {
                                                borderTopRightRadius: "0px",
                                                borderBottomRightRadius: "0px",
                                              },
                                              borderTopRightRadius: "0px",
                                              borderBottomRightRadius: "0px",
                                              background: "#F1F1F1",
                                            }}
                                            value={selectedCountry?._id ?? ""}
                                            onChange={handleCountryChange}
                                            renderValue={(selected) => {
                                              const country =
                                                countryMasterList.find(
                                                  (item) =>
                                                    item._id === selected
                                                ) || selectedCountry;
                                              if (!country) return "Select";
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      marginRight: "8px",
                                                    }}
                                                  >
                                                    <img
                                                      src={country?.flag_svg}
                                                      alt="flag"
                                                      style={{
                                                        width: "24px",
                                                        height: "12px",
                                                      }}
                                                    />
                                                  </span>
                                                  {country.dial_code}
                                                </div>
                                              );
                                            }}
                                            IconComponent={
                                              KeyboardArrowDownIcon
                                            }
                                          >
                                            {countryMasterList?.length > 0 &&
                                              countryMasterList.map(
                                                (country) => (
                                                  <MenuItem
                                                    key={country._id}
                                                    value={country._id}
                                                    style={{ height: "44px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        marginRight: "8px",
                                                      }}
                                                    >
                                                      <img
                                                        src={country?.flag_svg}
                                                        alt="flag"
                                                        style={{
                                                          width: "24px",
                                                          height: "12px",
                                                        }}
                                                      />
                                                    </span>
                                                    {country.country} (
                                                    {country.dial_code})
                                                  </MenuItem>
                                                )
                                              )}
                                          </MuiSelect>
                                        ),
                                      }}
                                      // className={
                                      //   type != SHOP_CREATED &&
                                      //   "formFieldsDisabled"
                                      // }
                                      id="outlined-basic"
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          paddingLeft: "0",
                                        },
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      // required
                                      // disabled={
                                      //   type == SHOP_CREATED ? false : true
                                      // }
                                      error={!!errors.phoneNumber}
                                      helperText={
                                        errors.phoneNumber
                                          ? errors.phoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        if (
                                          removeDashFromPhoneNumber(
                                            e.target.value
                                          ).length <= 10
                                        ) {
                                          const input = e.target;
                                          const rawValue = e.target.value;
                                          // Store previous cursor position
                                          const prevCursorPosition =
                                            input.selectionStart;
                                          const isDeleting =
                                            e.nativeEvent.inputType ===
                                            "deleteContentBackward";
                                          // Count hyphens before formatting
                                          const hyphenCountBefore = (
                                            rawValue.match(/-/g) || []
                                          ).length;
                                          // Format the input value
                                          const formattedValue =
                                            inputFormatPhoneNumber(rawValue);

                                          // Count hyphens after formatting
                                          const hyphenCountAfter = (
                                            formattedValue.match(/-/g) || []
                                          ).length;

                                          let newCursorPosition =
                                            prevCursorPosition;

                                          if (isDeleting) {
                                            // If a hyphen was removed, move cursor back by 1
                                            if (
                                              hyphenCountAfter <
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition--;
                                            }
                                          } else {
                                            // If a hyphen was added, move cursor forward by 1
                                            if (
                                              hyphenCountAfter >
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition++;
                                            }
                                          }
                                          // Ensure cursor position doesn't go out of bounds
                                          newCursorPosition = Math.max(
                                            0,
                                            Math.min(
                                              newCursorPosition,
                                              formattedValue.length
                                            )
                                          );
                                          // Update the state
                                          setValueinForm(
                                            "phoneNumber",
                                            formattedValue
                                          );

                                          // Restore the adjusted cursor position
                                          setTimeout(() => {
                                            input.setSelectionRange(
                                              newCursorPosition,
                                              newCursorPosition
                                            );
                                          }, 0);

                                          if (
                                            removeDashFromPhoneNumber(
                                              e.target.value
                                            ).length < 10
                                          ) {
                                            setError("phoneNumber", {
                                              message:
                                                "Phone Number should be 10 digits",
                                            });
                                          } else clearErrors("phoneNumber");
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        preventPhoneInputKeys(e);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Stack>
                        {!showSecondaryNumber && (
                          <div
                            onClick={() => setShowSecondaryNumber(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD PHONE NUMBER
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {showSecondaryNumber && (
                      <Grid item container xs={12} sm={12} md={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              {" "}
                              Secondary Phone Number{" "}
                            </Typography>

                            <Grid container item columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.secondaryPhoneNumberType}
                                >
                                  <Controller
                                    name="secondaryPhoneNumberType"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        placeholder="" // defaultValue={}
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ===
                                                  selectedPhoneTypes?.secondary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            secondary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.secondaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={
                                          !!errors.secondaryPhoneNumberType
                                        }
                                        helperText={
                                          errors.secondaryPhoneNumberType
                                            ? errors.secondaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.secondaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.secondaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6.6}
                                xl={6.6}
                              >
                                <Controller
                                  name="secondaryPhoneNumber"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      InputProps={{
                                        startAdornment: (
                                          <MuiSelect
                                            style={{
                                              width: "92px",
                                              minWidth: "92px",
                                              height: "56px",
                                              border: "none",
                                              marginRight: "16.5px",
                                            }}
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  width: "264px",
                                                  marginLeft: "86px",
                                                  boxShadow:
                                                    "box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                                                },
                                              },
                                              MenuListProps: {
                                                style: {},
                                              },
                                            }}
                                            sx={{
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderRight: "none",
                                                },
                                              "& .MuiOutlinedInput-root": {
                                                borderTopRightRadius: "0px",
                                                borderBottomRightRadius: "0px",
                                              },
                                              borderTopRightRadius: "0px",
                                              borderBottomRightRadius: "0px",
                                              background: "#F1F1F1",
                                            }}
                                            value={
                                              selectedCountrySecondary?._id ??
                                              ""
                                            }
                                            onChange={
                                              handleCountryChangeSecondary
                                            }
                                            renderValue={(selected) => {
                                              const country =
                                                countryMasterList.find(
                                                  (item) =>
                                                    item._id === selected
                                                ) || selectedCountrySecondary;
                                              if (!country) return "Select";
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      marginRight: "8px",
                                                    }}
                                                  >
                                                    {country.flag}
                                                  </span>
                                                  {country.dial_code}
                                                </div>
                                              );
                                            }}
                                            IconComponent={
                                              KeyboardArrowDownIcon
                                            }
                                          >
                                            {countryMasterList?.length > 0 &&
                                              countryMasterList.map(
                                                (country) => (
                                                  <MenuItem
                                                    key={country._id}
                                                    value={country._id}
                                                    style={{ height: "44px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        marginRight: "8px",
                                                      }}
                                                    >
                                                      {country.flag}
                                                    </span>
                                                    {country.country} (
                                                    {country.dial_code})
                                                  </MenuItem>
                                                )
                                              )}
                                          </MuiSelect>
                                        ),
                                      }}
                                      id="outlined-basic"
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          paddingLeft: "0",
                                        },
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      // required
                                      error={!!errors.secondaryPhoneNumber}
                                      helperText={
                                        errors.secondaryPhoneNumber
                                          ? errors.secondaryPhoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        if (
                                          removeDashFromPhoneNumber(
                                            e.target.value
                                          ).length <= 10
                                        ) {
                                          const input = e.target;
                                          const rawValue = e.target.value;
                                          // Store previous cursor position
                                          const prevCursorPosition =
                                            input.selectionStart;
                                          const isDeleting =
                                            e.nativeEvent.inputType ===
                                            "deleteContentBackward";
                                          // Count hyphens before formatting
                                          const hyphenCountBefore = (
                                            rawValue.match(/-/g) || []
                                          ).length;
                                          // Format the input value
                                          const formattedValue =
                                            inputFormatPhoneNumber(rawValue);

                                          // Count hyphens after formatting
                                          const hyphenCountAfter = (
                                            formattedValue.match(/-/g) || []
                                          ).length;

                                          let newCursorPosition =
                                            prevCursorPosition;

                                          if (isDeleting) {
                                            // If a hyphen was removed, move cursor back by 1
                                            if (
                                              hyphenCountAfter <
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition--;
                                            }
                                          } else {
                                            // If a hyphen was added, move cursor forward by 1
                                            if (
                                              hyphenCountAfter >
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition++;
                                            }
                                          }
                                          // Ensure cursor position doesn't go out of bounds
                                          newCursorPosition = Math.max(
                                            0,
                                            Math.min(
                                              newCursorPosition,
                                              formattedValue.length
                                            )
                                          );
                                          // Update the state
                                          setValueinForm(
                                            "secondaryPhoneNumber",
                                            formattedValue
                                          );

                                          // Restore the adjusted cursor position
                                          setTimeout(() => {
                                            input.setSelectionRange(
                                              newCursorPosition,
                                              newCursorPosition
                                            );
                                          }, 0);

                                          if (
                                            removeDashFromPhoneNumber(
                                              e.target.value
                                            ).length < 10
                                          ) {
                                            setError("secondaryPhoneNumber", {
                                              message:
                                                "Phone Number should be 10 digits",
                                            });
                                          } else
                                            clearErrors("secondaryPhoneNumber");
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        preventPhoneInputKeys(e);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2} sm={2} md={1} xl={1}>
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    color="error"
                                    size="medium"
                                    onClick={() => {
                                      setShowSecondaryNumber(false);
                                      setValueinForm(
                                        "secondaryPhoneNumber",
                                        ""
                                      );
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          First Name{" "}
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: "First Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.firstName}
                              helperText={
                                errors.firstName ? errors.firstName.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Last Name
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        <Controller
                          name="lastName"
                          control={control}
                          // rules={{
                          //   required: "First Name is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.lastName}
                              helperText={
                                errors.lastName ? errors.lastName.message : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>

                    <Grid item container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            marginBottom: "8px",
                          }}
                        >
                          {" "}
                          Email
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <Grid container item columnSpacing={2} rowGap={0}>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                              <Controller
                                name="email"
                                control={control}
                                rules={{
                                  // required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-basic"
                                    /*label="Email"*/
                                    variant="outlined"
                                    fullWidth
                                    // required
                                    error={!!errors.email}
                                    helperText={
                                      errors.email ? errors.email.message : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={0 == primaryEmailIndex}
                                      onChange={(e) => {
                                        showSecondaryEmail &&
                                          setPrimaryEmailIndex(
                                            e.target.checked ? 0 : 1
                                          );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {!showSecondaryEmail && (
                          <div
                            onClick={() => setShowSecondaryEmail(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD EMAIL
                          </div>
                        )}
                        {showSecondaryEmail && (
                          <Grid
                            container
                            item
                            columnSpacing={2}
                            rowGap={2}
                            marginTop={2}
                          >
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                              <Controller
                                name="secondaryEmail"
                                control={control}
                                rules={{
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    // required
                                    error={!!errors.secondaryEmail}
                                    helperText={
                                      errors.secondaryEmail
                                        ? errors.secondaryEmail.message
                                        : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={1 == primaryEmailIndex}
                                      onChange={(e) => {
                                        setPrimaryEmailIndex(
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                                {/* <CheckBox  >Primary</CheckBox> */}
                                <IconButton
                                  color="error"
                                  size="medium"
                                  onClick={() => {
                                    setShowSecondaryEmail(false);
                                    setPrimaryEmailIndex(0);
                                    setValueinForm("secondaryEmail", "");
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>

                <div style={{ display: tabValue == 1 ? "block" : "none" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} xl={12}>
                      <Typography variant="body1" className="label-500">
                        VIN{" "}
                        {/* {step == 1 && (
                          <span style={{ color: "#D32F2F" }}>*</span>
                        )} */}
                      </Typography>
                      <Controller
                        name="vin"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            // required
                            inputProps={{
                              maxLength: 17, // Limits input length to 17 characters
                            }}
                            id="outlined-basic"
                            variant="outlined"
                            error={!!errors.vin}
                            helperText={errors.vin ? errors.vin.message : ""}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              setDecode({ ...decode, vin: true });

                              setValueinForm(
                                "vin",
                                e.target.value?.toUpperCase()
                              );
                              if (e.target.value !== "") {
                                const regex = checkValidVIN(e.target.value);
                                if (regex) {
                                  clearErrors("vin");
                                  handleDecodeVIN();
                                } else {
                                  setError("vin", {
                                    message: "Please enter valid VIN",
                                  });
                                }
                              } else {
                                clearErrors("vin");
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Divider
                      textAlign="center"
                      style={{
                        width: "100%",
                        paddingLeft: "24px",
                        marginTop: "16px",
                      }}
                    >
                      or
                    </Divider>

                    <Grid item xs={12} sm={4}>
                      <FormControl style={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Year <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          defaultValue=""
                          name="year"
                          control={control}
                          rules={{
                            required: "Year is required",
                          }}
                          render={({ field }) => (
                            <MuiSelect
                              {...field}
                              fullWidth
                              value={yearValue}
                              onChange={(event) => {
                                field.onChange(event);

                                setYearValue(event.target.value);
                                setMakeValue(null);
                                setModelValue(null);
                                setTrimValue(null);
                                setMake([]);
                                setModel([]);
                                setTrim([]);
                                fetchVehicleMake({
                                  page: 1,
                                  limit: 1000,
                                  year: event.target.value,
                                });
                                setValueinForm("vehicleMakeId", "");
                                setValueinForm("vehicleModelId", "");
                                setValueinForm("vehicleModelTrimId", "");
                              }}
                              error={!!errors.year}
                              helperText={
                                errors.year ? errors.year.message : ""
                              }
                              MenuProps={{
                                style: {
                                  maxHeight: "400px",
                                  width: "100%",
                                },
                              }}
                            >
                              {Array.isArray(year) &&
                                year?.map((y) => {
                                  return (
                                    <MenuItem value={y} key={y}>
                                      {y}
                                    </MenuItem>
                                  );
                                })}
                            </MuiSelect>
                          )}
                        />
                        {errors.year && (
                          <FormHelperText error>
                            {errors.year.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl style={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Make <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>

                        <Controller
                          name="vehicleMakeId"
                          defaultValue={""}
                          control={control}
                          rules={{
                            required: "Make is required",
                          }}
                          render={({ field }) => (
                            <MuiSelect
                              {...field}
                              fullWidth
                              value={makeValue ? makeValue : ""}
                              disabled={yearValue !== "" ? false : true}
                              onChange={(event) => {
                                field.onChange(event);

                                setMakeValue(event.target.value);
                                setModelValue(null);
                                setModel([]);
                                setTrim([]);
                                setTrimValue(null);
                                setValueinForm("vehicleModelId", "");
                                setValueinForm("vehicleModelTrimId", "");
                                fetchVehicleModel({
                                  page: 1,
                                  limit: 1000,
                                  vehicleMakeId: event.target.value,
                                  year: yearValue,
                                });
                              }}
                              error={!!errors.vehicleMakeId}
                              helperText={
                                errors.vehicleMakeId
                                  ? errors.vehicleMakeId.message
                                  : ""
                              }
                              MenuProps={{
                                style: {
                                  maxHeight: "400px",
                                  width: "100%",
                                },
                              }}
                            >
                              {Array.isArray(make) &&
                                make?.map((m) => {
                                  return (
                                    <MenuItem value={m?._id} key={m?._id}>
                                      {m?.name}
                                    </MenuItem>
                                  );
                                })}
                              {makeLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </MuiSelect>
                          )}
                        />

                        {errors.vehicleMakeId && (
                          <FormHelperText error>
                            {errors.vehicleMakeId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl style={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Model <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>

                        <Controller
                          name="vehicleModelId"
                          control={control}
                          rules={{
                            required: "Model is required",
                          }}
                          render={({ field, fieldState }) => (
                            <MuiSelect
                              {...field}
                              fullWidth
                              value={modelValue}
                              disabled={
                                yearValue !== "" && makeValue !== ""
                                  ? false
                                  : true
                              }
                              onChange={(event) => {
                                field.onChange(event);
                                setModelValue(event.target.value);
                                setTrimValue(null);
                                setValueinForm("vehicleModelTrimId", null);
                                setTrim([]);

                                fetchVehicleTrim({
                                  page: 1,
                                  limit: 1000,
                                  vehicleMakeId: makeValue,
                                  vehicleModelId: event.target.value,
                                  year: yearValue,
                                });
                              }}
                              error={!!errors.vehicleModelId}
                              helperText={
                                errors.vehicleModelId
                                  ? errors.vehicleModelId.message
                                  : ""
                              }
                              MenuProps={{
                                style: {
                                  maxHeight: "400px",
                                  width: "100%",
                                },
                              }}
                            >
                              {Array.isArray(model) &&
                                model?.map((m) => {
                                  return (
                                    <MenuItem value={m?._id} key={m?._id}>
                                      {m?.name}
                                    </MenuItem>
                                  );
                                })}

                              {modelLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </MuiSelect>
                          )}
                        />

                        {errors.vehicleModelId && (
                          <FormHelperText error>
                            {errors.vehicleModelId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Trim
                        </Typography>

                        <Controller
                          name="vehicleModelTrimId"
                          control={control}
                          render={({ field, fieldState }) => (
                            <MuiSelect
                              fullWidth
                              disabled={
                                yearValue && makeValue && modelValue
                                  ? false
                                  : true
                              }
                              value={trimValue}
                              onChange={(event) => {
                                field.onChange(event);

                                setTrimValue(event.target.value);
                                setTrimEngineValue(
                                  trim.find((d) => d?._id == event.target.value)
                                );
                              }}
                              error={!!errors.vehicleModelTrimId}
                              helperText={
                                errors.vehicleModelTrimId
                                  ? errors.vehicleModelTrimId.message
                                  : ""
                              }
                              MenuProps={{
                                style: {
                                  maxHeight: "400px",
                                  width: "100%",
                                },
                              }}
                              // {...field}
                            >
                              {Array.isArray(trim) &&
                                trim?.map((m) => {
                                  return (
                                    <MenuItem value={m?._id} key={m?._id}>
                                      <div>
                                        <div style={{ fontWeight: 500 }}>
                                          {m?.name}
                                        </div>
                                        <div>{m?.description}</div>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              {trimLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </MuiSelect>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Divider
                      textAlign="center"
                      style={{
                        width: "100%",
                        paddingLeft: "24px",
                        marginTop: "16px",
                      }}
                    >
                      and
                    </Divider>

                    <Grid item xs={12}>
                      <Stack gap={1}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Licence Plate Number
                          {config?.isLicensePlateRequired && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} gap={1}>
                        <div>
                          <FormControl style={{ width: "100px" }}>
                            <Controller
                              name="provinceId"
                              control={control}
                              rules={{
                                required: config?.isLicensePlateRequired
                                  ? "Province is required"
                                  : "",
                              }}
                              render={({ field }) => (
                                <MuiSelect
                                  fullWidth
                                  value={provinceValue || ""}
                                  renderValue={(selectedId) => {
                                    // Find and display the province code for the selected value
                                    const selectedProvince = provinces?.find(
                                      (d) => d._id === selectedId
                                    );
                                    return (
                                      selectedProvince?.provinceCode ||
                                      "Select Province Code"
                                    );
                                  }}
                                  // disabled={yearValue !== "" ? false : true}

                                  error={!!errors.province}
                                  helperText={
                                    errors.provinceId
                                      ? errors.provinceId.message
                                      : ""
                                  }
                                  MenuProps={{
                                    style: { maxHeight: "400px" },
                                  }}
                                  {...field}
                                  onChange={(event) => {
                                    console.log("🚀 ~ event:", event);
                                    setProvinceValue(event.target.value);
                                    field.onChange(event);
                                    clearErrors("provinceId");
                                  }}
                                >
                                  {Array.isArray(provinces) &&
                                    provinces?.map((m) => {
                                      return (
                                        <MenuItem value={m?._id} key={m?._id}>
                                          {m?.province}
                                        </MenuItem>
                                      );
                                    })}
                                  {provinceLoader && (
                                    <MenuItem disabled>
                                      <CircularProgress />
                                    </MenuItem>
                                  )}
                                </MuiSelect>
                              )}
                            />
                            {errors.provinceId && (
                              <FormHelperText error>
                                {errors.provinceId.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>

                        <Controller
                          name="licenseNumber"
                          control={control}
                          rules={{
                            required: config?.isLicensePlateRequired
                              ? "Licence Plate Number is required"
                              : "",
                          }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.licenseNumber}
                              helperText={
                                errors.licenseNumber
                                  ? errors.licenseNumber.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("licenseNumber");
                                setValueinForm(
                                  "licenseNumber",
                                  e.target.value?.toUpperCase()
                                );
                                setDecode({ ...decode, licence: true });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {config?.isLicensePlateDecodeEnabled &&
                                      getValues("licenseNumber") &&
                                      getValues("licenseNumber") !== "" && (
                                        <Button onClick={handleDecodeLicence}>
                                          Decode
                                        </Button>
                                      )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>

                <div style={{ display: tabValue == 2 ? "block" : "none" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Company
                        </Typography>
                        <Controller
                          name="company"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                              error={!!errors.company}
                              helperText={
                                errors.company ? errors.company.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Discount Program
                        </Typography>
                        <Controller
                          name="membershipProgram"
                          control={control}
                          render={({ field }) => (
                            <MuiSelect
                              onChange={(value) => {
                                setValueinForm(
                                  "membershipProgram",
                                  value.target.value
                                );
                              }}
                              {...field}
                            >
                              <MenuItem value={"none"}>None</MenuItem>
                              {discountList.map((item, index) => (
                                <MenuItem key={index} value={item._id}>
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%" }}
                                  >
                                    <div> {item.programName}</div>
                                    <Chip
                                      sx={{
                                        background: "#EBEBEB",
                                        color: "#000",
                                      }}
                                      size="small"
                                      label={`${item.discount}%`}
                                    />
                                  </Stack>
                                </MenuItem>
                              ))}
                            </MuiSelect>
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth error={!!errors.contactPrefrence}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Contact Preference
                          </Typography>
                          <Controller
                            name="contactPrefrence"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              >
                                {contactPrefrences?.map((contact) => (
                                  <FormControlLabel
                                    value={contact.key}
                                    control={<Radio />}
                                    label={contact?.value}
                                  />
                                ))}
                              </RadioGroup>
                            )}
                          />
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Street Address
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="streetAddress"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              error={!!errors.streetAddress}
                              helperText={
                                errors.streetAddress
                                  ? errors.streetAddress.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                setValueinForm("streetAddress", e.target.value);
                                setAddressValue({
                                  ...addressValues,
                                  streetAddress: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          City
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="city"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={cityList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"city"}
                              loader={cityListLoader}
                              setValue={setValueinForm}
                              error={!!errors.city}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.city && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.city?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Province
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="province"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={provinceList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"province"}
                              setCityList={setCityList}
                              fetchCityList={fetchCityList}
                              loader={provinceListLoader}
                              setValue={setValueinForm}
                              error={!!errors.province}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.province && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.province?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <PostalCode
                        name="postalCode"
                        control={control}
                        errors={errors}
                        setValueinForm={setValueinForm}
                        setAddressValue={setAddressValue}
                        addressValues={addressValues}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid
                item
                container
                justifyContent={"flex-end"}
                style={{ marginTop: "24px" }}
                padding={"0px 24px 24px 24px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (!isLoader) {
                          reset();
                          closeModal();
                        }
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={isLoader}
                      type="submit"
                      style={{ width: "82.3px" }}
                      onClick={() => {
                        if (Object.keys(errors)?.length != 0) {
                          setTabValue(0);
                        }
                      }}
                    >
                      {isLoader ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={openSuccessModal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="I love snacks"
        key={"top" + "center"}
        onClose={() => setOpenSuccessModal(false)}
        autoHideDuration={2000}
      >
        <Alert severity={"error"}>{successMessage}</Alert>
      </Snackbar>
    </div>
  );
}

export default AddCustomerModal;
