import { toast } from "sonner";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const kanbanList = (timeZone) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.appointment.kanbanBoardList}?timezone=${timeZone}`
      );
      if (!response.data.error) {
        dispatch({
          type: API.appointment.kanbanBoardList,
          appointmentData: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateAppointmentStatus = (id, payload) => {
  return async () => {
    try {
      const response = await DataService.put(
        `${API.appointment.updateAppointmentStatus}/${id}`,
        payload
      );

      if (response.data.status) {
        return !!response.data.status;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const appointmentList = (
  timeRange,
  page,
  limit,
  search,
  year,
  make,
  model,
  sortType,
  sortBy,
  timeZone,
  date,
  status
) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${
          API.appointment.appointmentList
        }?timeRange=${timeRange}&page=${page}&limit=${limit}&search=${search}&year=${year}&make=${make}&model=${model}&sortType=${sortType}&sortBy=${sortBy}&timezone=${timeZone}&date=${date}&status=${
          status ?? ""
        }`
      );
      if (!response.data.error) {
        dispatch({
          type: API.appointment.appointmentList,
          appointmentListData: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const appointmentDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.appointment.appointmentDetails}/${id}`
      );
      if (!response.data.error) {
        dispatch({
          type: API.appointment.appointmentDetails,
          appointmentDetailsData: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const reschuleAppointment = (payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.appointment.rescheduleAppointment,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const proposeDateForAppointment = (payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.appointment.proposeDateAndTime,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const cancleAppointment = (payload, id) => {
  return async () => {
    try {
      const response = await DataService.put(
        `${API.appointment.cancleAppointment}/${id}`,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const createAppointment = (payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.appointment.createAppointment,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const mechanicDetailsList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.appointment.mechanicDetails);
      if (response.data.status) {
        dispatch({
          type: API.appointment.mechanicDetails,
          mechanicDetailsData: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const acceptDeclineRescheduleRequest = (id, status) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        // API.appointment.rescheduleRequestAcceptDecline + id,
        `${API.appointment.rescheduleRequestAcceptDecline}/${id}`,
        { requestStatus: status }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const fetchShopClosingTime = (payload) => {
  let cancelToken = payload?.cancelToken;
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.appointment.shopClosingTime,
        {
          dropOffDateTime: payload.dropOffDateTime,
        },
        {},
        "",
        cancelToken
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
// Add service to appoitnment detail
export const addServiceToAppointmentDetailApi = (id, payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.appointment.addService + id,
        payload
      );

      if (response.data.status) {
        toast.success(response?.data?.message);
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// delete service from appoitnment detail
export const deleteServiceFromAppointmentDetailApi = (appId, serviceId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.appointment.deleteService + appId + "/" + serviceId
      );
      if (!response.data.error) {
        toast.success(response?.data?.message);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
