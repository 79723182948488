import {
  alpha,
  Box,
  Card,
  Chip,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateAvailablity } from "../../redux/Calendar/action";

// const rows = [createData("Frozen yoghurt", [159, 242], 6.0, 24), createData("Ice cream sandwich", [237, 43343], 9.0, 37, 4.3), createData("Eclair", [262], 16.0, 24, 6.0)];

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[800],
    "&:hover": {
      backgroundColor: alpha(green[800], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[800],
  },
}));

const styles = {
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    border: "1px solid #D8D8D8",
  },
  th: {
    border: "1px solid #D8D8D8",
    // borderLeft: "0px",
    borderRight: "none",
    borderBottom: "1px solid #D8D8D8",
    padding: "0px",
    textAlign: "center",
  },
  tdHeading: {
    border: "1px solid #D8D8D8",
    backgroundColor: "#ECEFF1",
    borderLeft: "0px",
    borderRight: "none",
    borderTop: "none",
    // border: "none",
    padding: "8px",
    height: "100px",

    textAlign: "center",
    alignItem: "end",
  },
  tdSlotHeading: {
    // border: "1px solid #D8D8D8",

    borderLeft: "1px solid #D8D8D8",
    borderBottom: "0px",

    // border: "none",
    padding: "8px",
    paddingRight: "0px",
    height: "100px",

    textAlign: "end",
    alignItem: "end",
    display: "grid",
    alignContent: "end",
    minWidth: "max-Content",
  },

  td: {
    border: "1px solid #D8D8D8",
    // backgroundColor: '#f2f2f2',
    borderTop: "none",
    padding: "8px",
    textAlign: "center",
    borderRight: "none",
    // height: "100px"
  },
  oneCard: {
    paddingLeft: "7px",
    marginTop: "-12px",
    // height: "85px",
  },
  slotTime: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
};
export const borderColor = (status) => {
  if (status == "waiting_for_drop_off") return "8px solid #75DBFF";
  else if (status == "vehicle_dropped_off") return "8px solid #FFC30F";
  else if (status == "in_progress") return "8px solid #FF9E31";
  else if (status == "ready_for_pick_up") return "8px solid #5CE469";
  else if (status == "customer_paid") return "8px solid #00CD44";
  else return "8px solid #F44336";
};
export const backgroundColor = (status) => {
  if (status == "waiting_for_drop_off") return "#C1EFFF";
  else if (status == "vehicle_dropped_off") return "#FFF0C2";
  else if (status == "in_progress") return "#FFE6CB";
  else if (status == "ready_for_pick_up") return "#F0F0F0";
  else if (status == "customer_paid") return "#CEFFDE";
  else return "#FDE2E0";
};
const CustomCalendar = (props) => {
  // console.log("props", props)
  const dispatch = useDispatch();
  const currentDate = new Date(props.date);
  const [dayLimit, setDayLimit] = useState(6);
  const [slotData, setSlotData] = useState([]);
  const defaultCalenderRef = useRef([]);
  // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  const currentDayOfWeek = currentDate.getDay();
  // console.log("currentDayOfWeek", currentDayOfWeek)
  // Calculate the start date of the current week
  const startDate = new Date(currentDate);
  // startDate.setDate(currentDate.getDate() - currentDayOfWeek);

  // Calculate the end date of the current week
  const endDate = new Date(currentDate);
  // endDate.setDate(currentDate.getDate() + (7 - currentDayOfWeek));

  // Create an array to store the days of the week
  const daysInWeek = [];
  const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  // Loop through the days of the week
  for (let i = 0; i < dayLimit - 1; i++) {
    const day = new Date(startDate);
    day.setDate(startDate.getDate() + i);
    daysInWeek.push({
      date: day,
      dayName: dayNames[day.getDay()],
    });
  }

  const [data, setData] = useState([]);
  useEffect(() => {
    let d = [];
    props.resources?.map((res) => {
      d.push({
        name: res?.title,
        appointment: props.events.filter((e) => e.resourceId == res._id),
      });
    });
    // console.log("props.event", props.events);
    setData(d);
  }, [props?.resources, props.events]);

  // console.log("props.event", props.events);
  // console.log("props.resources", props.resources);

  useEffect(() => {
    if (props?.view == "3day") {
      setDayLimit(4);
    } else {
      setDayLimit(6);
    }
  }, [props?.view]);

  // console.log("Start date of the current week:", startDate.toDateString());
  // console.log("End date of the current week:", endDate.toDateString());
  // console.log("Days in the current week:", daysInWeek);
  // console.log("ssds", (new Date(props.date)).)
  // console.log("props.event", props.events);
  useEffect(() => {
    const timeSlots = [];

    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute <= 30; minute += 30) {
        const time = new Date(0, 0, 0, hour, minute);
        const formattedTime = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
        timeSlots.push({
          time: formattedTime,
          key: timeSlots.length,
          appointment: [
            [
              {
                number: "",
                customerName: "",
                service: "",
                dropOffTime: "",
                height: "",
                id: "",
                appointmentStatus: "",
                width: "",
                hasMargin: false,
                margin: "",
              },
            ],
            [
              {
                number: "",
                customerName: "",
                service: "",
                dropOffTime: "",
                height: "",
                id: "",
                appointmentStatus: "",
                width: "",
                hasMargin: false,
                margin: "",
              },
            ],
            [
              {
                number: "",
                customerName: "",
                service: "",
                dropOffTime: "",
                height: "",
                id: "",
                appointmentStatus: "",
                width: "",
                hasMargin: false,
                margin: "",
              },
            ],
            [
              {
                number: "",
                customerName: "",
                service: "",
                dropOffTime: "",
                height: "",
                id: "",
                appointmentStatus: "",
                width: "",
                hasMargin: false,
                margin: "",
              },
            ],
            [
              {
                number: "",
                customerName: "",
                service: "",
                dropOffTime: "",
                height: "",
                id: "",
                appointmentStatus: "",
                width: "",
                hasMargin: false,
                margin: "",
              },
            ],
          ],
        });
      }
      console.log("timeSlots", timeSlots);
      // console.log("timeSlots", timeSlots)
      if (timeSlots.length == 48) {
        // console.log("timeSlots", timeSlots)
        let allData = [...timeSlots];
        console.log("props.events", props.events);

        props.events?.map((event) => {
          let foundColumn = daysInWeek.findIndex(
            (d) => new Date(d.date).getDate() == new Date(event.start).getDate()
          );
          // console.log("foundColumn", foundColumn)
          let date = new Date(event.start);
          let updatedDate = new Date(event.start);

          let marginRequired = false;
          let marginSize = "";
          if (
            new Date(date).getMinutes() < 30 &&
            new Date(date).getMinutes() !== 0
          ) {
            updatedDate = new Date(
              `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()} ${date.getHours()}:00 `
            );
            marginRequired = true;
            if (new Date(date).getMinutes() < 15) {
              marginSize = "-" + (15 - new Date(date).getMinutes()) * 3;
            } else if (new Date(date).getMinutes() > 15) {
              marginSize = (new Date(date).getMinutes() - 15) * 3;
            } else {
              marginSize = 0;
            }
          } else if (
            new Date(date).getMinutes() > 30 &&
            new Date(date).getMinutes() !== 0
          ) {
            updatedDate = new Date(
              `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()} ${date.getHours()}:30 `
            );
            marginRequired = true;
            if (30 - (60 - new Date(date).getMinutes()) < 15) {
              marginSize =
                "-" + (15 - (30 - (60 - new Date(date).getMinutes()))) * 3;
            } else if (30 - (60 - new Date(date).getMinutes()) > 15) {
              marginSize = (30 - (60 - new Date(date).getMinutes()) - 15) * 3;
            } else {
              marginSize = 0;
            }
          } else {
            updatedDate = updatedDate;
            marginRequired = false;
          }
          // Convert to 12-hour format
          const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          };
          const time12HourFormat = updatedDate.toLocaleTimeString(
            "en-US",
            options
          );
          const actualTime12HourFormat = date.toLocaleTimeString(
            "en-US",
            options
          );

          let findRowIndex = timeSlots.findIndex(
            (d) => d.time == time12HourFormat
          );

          const endTime = new Date(event.end);
          let findSlot = "";

          let endHour = endTime.getHours();
          const endMinute = endTime.getMinutes();
          const isPM = endHour >= 12;

          // Convert to 12-endHour format
          if (endHour > 12) {
            endHour -= 12;
          } else if (endHour === 0) {
            endHour = 12;
          }

          // console.log("endHour:", endHour, event.title);
          // console.log("endMinute:", endMinute, event.title);
          // console.log("AM/PM:", isPM ? "PM" : "AM", event.title);
          if (endMinute > 30) {
            findSlot += `${endHour}:30 ${isPM ? "PM" : "AM"}`;
          } else {
            findSlot += `${endHour}:00 ${isPM ? "PM" : "AM"}`;
          }
          let findEndRowIndex = timeSlots.findIndex((d) => d.time == findSlot);
          // console.log("findSlot", findSlot, findEndRowIndex, event.title);
          let differnce = findEndRowIndex - findRowIndex;
          let minuteDifferce =
            endMinute <= 30 ? endMinute / 30 : (endMinute - 30) / 30;
          // console.log("differnce", differnce + minuteDifferce, event.title);

          // const time12HourFormatEnd = endTime.toLocaleTimeString(
          //   "en-US",
          //   options
          // );
          // console.log(
          //   "time12HourFormatEnd",
          //   time12HourFormatEnd,
          //   event.title
          // );

          if (
            findRowIndex >= 0 &&
            foundColumn >= 0 &&
            event.resourceId == props.resources[0].id
            //  &&  findRowIndex <= 29
          ) {
            let appLength = allData[findRowIndex + 1].appointment[foundColumn];

            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].number = event.title;
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].customerName =
              event.customer?.firstName +
              " " +
              (event?.customer?.lastName ?? "");
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].service = event.service;
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].id = event.appointmentId;
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].dropOffTime = actualTime12HourFormat;
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].height =
              (differnce + minuteDifferce) * 100 +
              (marginRequired
                ? -100 + (marginSize > 0 ? marginSize : -marginSize)
                : -0);
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].appointmentStatus = event.status;

            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].hasMargin = marginRequired;
            allData[findRowIndex + 1].appointment[foundColumn][
              appLength.length - 1
            ].margin = +marginSize + "px";

            appLength.push({
              number: "",
              customerName: "",
              service: "",
              dropOffTime: "",
              height: "",
              id: "",
              appointmentStatus: "",
              width: "",
              hasMargin: false,
              margin: "",
            });
          }
        });

        setSlotData(allData);
        // scrollToRef(17);
        props.setShowLoader(false);
      }
    }
  }, [props.resources, props.events]);

  const addRef = (el, index) => {
    if (el) {
      defaultCalenderRef.current[index] = el;
    }
  };

  const getCurrentTimeIndex = () => {
    const now = new Date();
    const minutes = now.getMinutes();
    const roundedMinutes = minutes < 30 ? 0 : 30;
    const roundedTime = new Date(now);
    roundedTime.setMinutes(roundedMinutes, 0, 0); // Round to the nearest half-hour

    const currentTime = roundedTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    const currentIndex = slotData.findIndex(
      (slot) => slot.time === currentTime
    );
    return currentIndex >= 0 ? currentIndex : 0; // Default to 0 if not found
  };

  const scrollToRef = (index) => {
    if (defaultCalenderRef.current[index]) {
      defaultCalenderRef.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // console.log("slotData", slotData);
  const updateStatusAvailablity = async (payload) => {
    props.setShowLoader(true);
    const resp = await dispatch(updateAvailablity(payload));

    if (resp) {
      props.fetchAvailablityStatus();
    }
    // setShowLoader(false);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[0],
      fontSize: 11,
    },
  }));
  useEffect(() => {
    if (slotData.length > 0) {
      const currentIndex = getCurrentTimeIndex();
      scrollToRef(currentIndex);
    }
  }, [slotData, props?.view]);
  return (
    <div
      style={{
        maxHeight: "110vh",
        position: "relative",
        overflowY: "auto",
        zIndex: 0,
      }}
    >
      {/* <TableContainer sx={{ maxHeight: "100vh" }}> */}

      <Table aria-label="caption table" stickyHeader className="customTable">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                ...styles?.th,
                borderLeft:
                  props?.resources?.length == 1 && "1px solid #D8D8D8",
                borderRight: "0px solid #D8D8D8",
              }}
            >
              {" "}
            </TableCell>
            {Array.isArray(daysInWeek) &&
              daysInWeek?.map((day, index) => {
                return (
                  <TableCell
                    align="center"
                    style={styles?.th}
                    display
                    key={index}
                  >
                    <Typography
                      className="customCalendarHeaderdayName"
                      fontSize={"13px"}
                    >
                      {/* <p > */}
                      {day?.dayName}
                      {/* </p> */}
                    </Typography>
                    {/* <p> */}
                    <Typography
                      variant="h4"
                      className="customCalendarHeaderdayDate"
                    >
                      {new Date(day.date).getDate()}
                    </Typography>
                    {/* </p> */}
                    <Typography className="customCalendarHeaderdayStatus">
                      {/* <Switch defaultChecked size="small" /> */}
                      <RedSwitch
                        checked={
                          props.availablity.filter(
                            (d) =>
                              d.date == moment(day?.date).format("YYYY-MM-DD")
                          )?.length == 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          updateStatusAvailablity({
                            shopId: JSON.parse(
                              localStorage.getItem("apex-saas_details")
                            )?._id,
                            date: moment(day?.date).format("YYYY-MM-DD"),
                            isTurnOff: !e.target.checked,
                          });
                        }}
                        size="small"
                      />
                      Appointment Requests:{" "}
                      {props.availablity.filter(
                        (d) => d.date == moment(day?.date).format("YYYY-MM-DD")
                      )?.length == 0
                        ? "ON"
                        : "OFF"}
                    </Typography>
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        {props.resources.length > 1 && (
          <TableBody
            style={{ height: "100%", overflow: "auto", position: "relative" }}
          >
            {data.map((row) => (
              <TableRow key={row.name}>
                {/* {console.log("datadata",data)} */}
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    ...styles.tdHeading,
                    height: 65 / props.resources.length + "vh",
                  }}
                >
                  <Chip
                    label={row?.name}
                    // color="default"
                    size="medium"
                    variant="filled"
                    className="customCalendarChip"
                  />
                  {/* <Typography style={{ textAlign: "center", background: "red", borderRadius: "40%", padding: "5px", }}>

                                        {row.name}
                                    </Typography> */}
                </TableCell>
                {Array.from({ length: dayLimit == 6 ? 5 : 3 }, (_, index) => (
                  <TableCell style={styles.td}>
                    {row.appointment
                      .filter(
                        (a) =>
                          moment(a.start).format("YYYY-MM-DD") ==
                          moment(daysInWeek[index].date).format("YYYY-MM-DD")
                      )
                      ?.map((data) => {
                        return (
                          <Card
                            className="customeCalendarCardEvent2"
                            style={{
                              // display: row?.appointment[2].number != "" ? "" : "none",

                              // background: "#C1EFFF",
                              backgroundColor: backgroundColor(data?.status),

                              borderLeft: borderColor(data?.status),

                              // "4px solid #75DBFF",
                            }}
                            onClick={() =>
                              props.setSelectedAppointmentId(
                                data?.appointmentId
                              )
                            }
                          >
                            <Box style={styles.oneCard}>
                              {/* <p> */}

                              <h3 style={{ marginBottom: "auto" }}>
                                {data?.title + ": "}
                                {data?.customer?.firstName +
                                  " " +
                                  (data?.customer?.lastName ?? "")}
                              </h3>
                              {/* {data?.dropOffTime != "" && "Drop Off :"}
                              {data?.dropOffTime}
                              <br />
                              {data?.service} */}
                            </Box>
                          </Card>
                        );
                      })}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
        {(props.view == "3day" || props.view == "5day") &&
          (props.resources.length == 1 || props.resources.length == 0) && (
            <TableBody>
              {slotData.map((row, rowIndex) => (
                <TableRow key={row.name} ref={(el) => addRef(el, rowIndex)}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      ...styles.tdSlotHeading,
                      borderBottom:
                        rowIndex == slotData.length - 1
                          ? "1px solid #D8D8D8"
                          : "none",
                      // width: props.view == "3day" ? "" : "max-Content",
                    }}
                    key={row.time}
                  >
                    <Typography
                      style={{
                        marginBottom: "-20px",
                        display: slotData.length == rowIndex + 1 && "none",
                        // marginRight: "8px",
                      }}
                    >
                      <div style={styles.slotTime}>
                        <div>{row.time}</div>
                        <div>
                          <hr style={{ width: "12px" }} />
                        </div>
                      </div>
                    </Typography>
                  </TableCell>
                  {row?.appointment?.map((dd, mainIndex) => {
                    if (mainIndex < dayLimit - 1)
                      return (
                        <TableCell
                          style={styles.td}
                          key={row?.appointment[mainIndex].number}
                        >
                          <div
                            style={{
                              width: dayLimit == 6 ? "36%" : "60%",
                              display: "flex",
                              position: "absolute",
                            }}
                          >
                            {row?.appointment[mainIndex]?.map((d, index) => {
                              return (
                                <LightTooltip
                                  title={
                                    <Card
                                      className="customeCalendarCardEvent"
                                      style={{
                                        display:
                                          row?.appointment[mainIndex][index]
                                            .number != ""
                                            ? ""
                                            : "none",
                                        textAlign: "left",
                                        backgroundColor: backgroundColor(
                                          row?.appointment[mainIndex][index]
                                            ?.appointmentStatus
                                        ),

                                        borderLeft: borderColor(
                                          row?.appointment[mainIndex][index]
                                            ?.appointmentStatus
                                        ),

                                        height:
                                          row.appointment[mainIndex].height +
                                          "px",
                                        // width:
                                        //   props?.view == "5day"
                                        //     ? row?.appointment[mainIndex].length > mainIndex
                                        //       ? 16 / row?.appointment[mainIndex].length + "%"
                                        //       : "16%"
                                        //     : "28%",
                                        // width:
                                        //   45 /
                                        //     (row?.appointment[mainIndex]
                                        //       .length -
                                        //       1) +
                                        //   "%",
                                        position: "relative",
                                        marginTop:
                                          row.appointment[mainIndex]
                                            .hasMargin &&
                                          row.appointment[mainIndex].margin,
                                      }}
                                      onClick={() =>
                                        props.setSelectedAppointmentId(
                                          row?.appointment[mainIndex][index].id
                                        )
                                      }
                                    >
                                      <Box style={styles.oneCard}>
                                        {/* <p> */}

                                        <h3 style={{ marginBottom: "auto" }}>
                                          {row?.appointment[mainIndex][index]
                                            .number + " "}

                                          {
                                            row?.appointment[mainIndex][index]
                                              .customerName
                                          }
                                        </h3>
                                        {row?.appointment[mainIndex][index]
                                          .dropOffTime != "" && "Drop Off :"}
                                        {
                                          row?.appointment[mainIndex][index]
                                            .dropOffTime
                                        }
                                        <br />
                                        {
                                          row?.appointment[mainIndex][index]
                                            .service
                                        }
                                      </Box>
                                    </Card>
                                  }
                                  style={{ background: "none" }}
                                >
                                  <Card
                                    className="customeCalendarCardEvent"
                                    style={{
                                      display:
                                        row?.appointment[mainIndex][index]
                                          .number != ""
                                          ? ""
                                          : "none",
                                      textAlign: "left",
                                      backgroundColor: backgroundColor(
                                        row?.appointment[mainIndex][index]
                                          ?.appointmentStatus
                                      ),

                                      borderLeft: borderColor(
                                        row?.appointment[mainIndex][index]
                                          ?.appointmentStatus
                                      ),

                                      height:
                                        row.appointment[mainIndex][index]
                                          .height + "px",
                                      // width:
                                      //   props?.view == "5day"
                                      //     ? row?.appointment[mainIndex].length > mainIndex
                                      //       ? 16 / row?.appointment[mainIndex].length + "%"
                                      //       : "16%"
                                      //     : "28%",
                                      width:
                                        45 /
                                          (row?.appointment[mainIndex].length -
                                            1) +
                                        "%",
                                      position: "relative",
                                      marginTop:
                                        row.appointment[mainIndex][index]
                                          .hasMargin &&
                                        row.appointment[mainIndex][index]
                                          .margin,
                                    }}
                                    onClick={() =>
                                      props.setSelectedAppointmentId(
                                        row?.appointment[mainIndex][index].id
                                      )
                                    }
                                  >
                                    <Box style={styles.oneCard}>
                                      {/* <p> */}

                                      <h3 style={{ marginBottom: "auto" }}>
                                        {row?.appointment[mainIndex][index]
                                          .number + " "}

                                        {
                                          row?.appointment[mainIndex][index]
                                            .customerName
                                        }
                                      </h3>
                                      {row?.appointment[mainIndex][index]
                                        .dropOffTime != "" && "Drop Off :"}
                                      {
                                        row?.appointment[mainIndex][index]
                                          .dropOffTime
                                      }
                                      <br />
                                      {
                                        row?.appointment[mainIndex][index]
                                          .service
                                      }
                                    </Box>
                                  </Card>
                                </LightTooltip>
                              );
                            })}
                          </div>
                        </TableCell>
                      );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
      </Table>
      {/* </TableContainer> */}
    </div>
  );
};

export default CustomCalendar;
