import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

const CommonDialog = ({
  loader,
  open,
  onClose,
  onSave,
  dialogTitle,
  dialogSubtitle,
  buttonPadding = "16px 0 0 0",
  cancelButtonVariant = "outlined",
  cancelButtonText = "Cancel",
  cancelButtonWidth = "108px",
  saveButtonType = "error",
  saveButtonVariant = "contained",
  saveButtonText = "Delete",
  saveButtonWidth = "103px",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="commonDialog"
    >
      <Box
        sx={{
          position: "absolute",
          top: "0%",
          left: "0%",
          transform: "translate(-0%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          width: "100%",
          border: "none",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <AppBar
          color=""
          variant="outlined"
          style={{
            width: "inherit",
            borderRadius: "8px 8px 0px 0px !important",
            padding: "0px 16px",
          }}
        >
          <Toolbar variant="regular" sx={{ color: "black" }}>
            <Typography variant="h6" width={"98%"}>
              {dialogTitle}
            </Typography>
            <Stack direction={"row"} spacing={0}>
              <div
                onClick={onClose}
                style={{ cursor: "pointer", height: "32px" }}
              >
                <CloseIcon sx={{ fill: "black", fontSize: 32 }} />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <Container
        style={{
          marginTop: "64px",
          padding: "16px",
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "black" }}
          >
            {dialogSubtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: buttonPadding }}>
          <Button
            onClick={onClose}
            variant={cancelButtonVariant}
            disabled={loader}
            style={{ width: cancelButtonWidth }}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onSave}
            autoFocus
            color={saveButtonType}
            variant={saveButtonVariant}
            disabled={loader}
            style={{ width: saveButtonWidth }}
          >
            {loader ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              saveButtonText
            )}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default CommonDialog;
