import { FormControl } from "@mui/base";
import { Add, Info } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../App";
import CarfaxHistoryModel from "../../../components/Models/CarfaxHistoryModel";
import VehicleModel from "../../../components/Models/VehicleModel";
import {
  APEX_AUTO_APP,
  SHOP_CREATED,
  SHOP_IMPORTED,
} from "../../../config/dataService/constants";
import { configDetails } from "../../../redux/Account/action";
import {
  addNote,
  addVehicle,
  customerAppointments,
  customerDetail,
  customerVehicles,
  decodeVIN,
  deleteCustomer,
  deleteCustomerNote,
  deleteCustomerVehicle,
  deleteImportedVehicle,
  editCustomer,
  editCustomerNote,
  editCustomerVehicle,
  editImportedVehicle,
  getNotesListing,
  importedCustomerDetail,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../../redux/Customer/action";
import { appointmentDetails } from "../../../redux/appointment/action";
import { getItem, removeItem } from "../../../utility/localStorage";
import { checkValidVIN } from "../../../utility/utilityFunctions";
import AppointmentModel from "../../Appointment/AppointmentModel";
import ViewUpcomingModal from "../../Appointment/UpcomingAppointment/ViewUpcomingModal";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AppointmentRequestModal from "../../modal/AppointmentrequestModal";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import "../Customers.css";
import CustomerProfileLeftDataSection from "./CustomerProfileLeftDataSection";
import CustomerProfileQuickEdit from "./CustomerProfileQuickEdit";
import "./Profile.css";
import UpdateCustomerModal from "./UpdateCustomerModal";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const deleteStyle = {
  position: "absolute",
  top: "0%",
  left: "0%",
  width: 400,
  transform: "translate(-0%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpenSideBar } = useContext(Context);
  const [quickEdit, setQuickEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEditCustomer, setOpenEditCustomer] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openVehicleDelete, setOpenVehicleDelete] = React.useState(false);
  const [vinLoading, setVINLoading] = useState(false);

  const [openViewVehicle, setOpenViewVehicle] = React.useState(false);
  const [editVehicle, setEditVehicle] = useState({
    edit: false,
    index: "",
    id: "",
  });
  const [deleteVehicleId, setDeleteVehicleId] = useState("");
  const [editDeleteNoteId, setEditDeleteNoteId] = useState("");
  const [editNoteText, setEditNoteText] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successMessageType, setSuccessMessageType] = useState("success");

  const [selectedActionOfNote, setSelectedActionOfNote] = useState("");
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);
  const [appointmentModalLoader, setAppointmentModalLoader] = useState(false);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [completeAddress, setCompleteAddress] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [getAddress, setAddress] = useState();
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [makeLoader, setMakeLoader] = useState(false);
  const [viewVehicleData, setViewVehicleData] = useState(null);
  //Hide Edit Delete buttons in view vehicle model based on isConverted
  const [showEditDeleteInViewModel, setShowEditDeleteInViewModel] =
    useState(true);
  const [openCarfaxHistory, setOpenCarfaxHistory] = useState(false);
  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue: setValueinForm,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const [openAppointmentDetailModal, setOpenAppointmentDetailModal] =
    useState(false);

  const [openAppReqModal, setOpenAppReqModal] = useState(false);

  const [appointmentDetailData, setAppointmentDetailData] = useState(null);
  const [vehicles, setvehicles] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    setEditVehicle({ edit: false, index: "", id: "" });
    setImportedVehicleId(null);
    reset();
    setValueinForm("year", "");

    setValueinForm("vehicleMakeId", "");
    setValueinForm("vehicleModelId", "");
    setValueinForm("vehicleModelTrimId", "");

    setYearValue("");
    setMakeValue("");
    setModelValue("");
    setTrimValue("");
  };
  const handleCloseCustomer = () => setOpenEditCustomer(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleCloseDeleteVehicle = () => {
    setOpenVehicleDelete(false);
    setDeleteVehicleId("");
    setImportedVehicleId(null);
  };

  const handleCloseViewVehicle = () => {
    setOpenViewVehicle(false);
    // setImportedVehicleId(null);
  };
  const handleCloseCarfaxHistory = () => {
    setOpenCarfaxHistory(false);
  };
  const [appointmentPage, setAppointmentPage] = useState(1);
  const [appointmentLimit, setAppointmentLimit] = useState(10);

  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");

  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [customerNotePage, setCustomerNotePage] = useState(1);
  const [newNote, setNewNote] = useState("");
  const [noteError, setNoteError] = useState(false);
  const [yearPageLimit, setYearPageLimit] = useState({ page: 1, limit: 1000 });
  const [value, setValue] = React.useState(0);

  const [showUpcomingModal, setShowUpcomingModal] = useState(false);
  const [importedCustomerData, setImportedCustomerData] = useState({});
  const [country, setCountry] = useState();
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const [convertedCustomerId, setCustomerConvertedId] = useState(null);
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);
  const [importedVehicleId, setImportedVehicleId] = useState(null);
  const theme = useTheme();
  const [viewVehicleModalTabvalue, setViewVehicleModalTabValue] = useState(0);
  const handleChangeViewVehicleTabValue = (event, newValue) => {
    setViewVehicleModalTabValue(newValue);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const select_category = [
    { label: "Inglourious Basterds", year: 2009 },
    { label: "Snatch", year: 2000 },
    { label: "3 Idiots", year: 2009 },
    { label: "Monty Python and the Holy Grail", year: 1975 },
  ];
  let appointmentParams = {
    page: appointmentPage,
    limit: appointmentLimit,
    customerId: id,
  };
  let vehicleMakeParams = {
    page: makeValuePage,
    limit: 100,
    year: yearValue,
  };
  let vehicleModelParams = {
    page: modelValuePage,
    limit: 100,
    vehicleMakeId: makeValue,
  };
  let vehicleTrimParams = {
    page: trimValuePage,
    limit: 100,
    vehicleMakeParams,
    vehicleModelId: modelValue,
  };
  let customerNoteParams = {
    page: customerNotePage,
    limit: 100,
    customerId: id,
  };

  const getDetails = () => {
    fetchCustomerDetail();
    fetchCustomerVehicle();
    fetchCustomerAppointments(appointmentParams);
  };

  // const isConverted = getItem("converted");
  useEffect(() => {
    const converted = getItem("convertedCustomer");
    if (converted && converted.importedId == id) {
      navigate(`/customers/${converted.convertedId}/shop_created`, {
        replace: true,
      });
      removeItem("convertedCustomer");
      window.location.reload();
    }
  }, [getItem("convertedCustomer")]);

  useEffect(() => {
    if (convertedCustomerId) {
      getDetails();
    }
  }, [convertedCustomerId]);

  useEffect(() => {
    if (type != SHOP_IMPORTED) {
      getDetails();
      // fetchCustomerNotes()

      fetchCustomerNotes(customerNoteParams);
    }
    if (type == SHOP_IMPORTED) {
      fetchImportedCustomerDetail();
      setValue(0);
    }
    fetchConfig();

    // fetchVehicleYear(yearPageLimit.page, yearPageLimit.limit);
    // fetchVehicleMake(vehicleMakeParams);
  }, []);
  const fetchConfig = async () => {
    await dispatch(configDetails());
  };
  const config = useSelector((state) => state.account.configDetailData);
  const fetchImportedCustomerDetail = async () => {
    setShowLoader(true);

    const resp = await dispatch(importedCustomerDetail(id));
    setImportedCustomerData(resp);
    setvehicles(resp?.vehicle);
    setShowLoader(false);
  };

  const fetchCustomerDetail = async () => {
    setShowLoader(true);

    await dispatch(customerDetail(id));
    setShowLoader(false);
  };
  const data = useSelector((state) => state.customer.customerDetail);

  useEffect(() => {
    setAddress(data?.streetAddress);
  }, [data]);
  const fetchCustomerVehicle = async () => {
    setShowLoader(true);

    await dispatch(customerVehicles(id));
    setShowLoader(false);
  };
  const vehiclesData = useSelector((state) => state.customer.customerVehicle);

  useEffect(() => {
    if (type != SHOP_IMPORTED) setvehicles(vehiclesData);
  }, [vehiclesData]);

  const fetchCustomerAppointments = async (appointmentParams) => {
    setShowLoader(true);

    await dispatch(customerAppointments(appointmentParams));
    setShowLoader(false);
  };
  const appointments = useSelector(
    (state) => state.customer.customerAppointment
  );

  const fetchVehicleYear = async (yearPage, yearLimit) => {
    await dispatch(vehicleYearMaster({ page: yearPage, limit: yearLimit }));
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const makes = useSelector((state) => state.customer?.vehicleMake);
  useEffect(() => {
    setMake(makes);
  }, [makes]);
  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };
  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);
    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);
  const fetchCustomerNotes = async (customerNoteParams) => {
    await dispatch(getNotesListing(customerNoteParams));
  };

  const notes = useSelector((state) => state.customer.notesList);

  const handleAddNote = async () => {
    if (!newNote) {
      return setNoteError(true);
    }
    setNoteError(false);

    let payload = {
      customerId: id,
      notes: newNote,
    };
    const resp = await dispatch(addNote(payload));
    setNewNote("");
    if (resp) {
      fetchCustomerNotes(customerNoteParams);
      setOpenSuccessModal(true);
      setSuccessMessage("Note Added");
    }
  };
  const handleCloseAppointmentDetailModal = () => {
    setOpenAppointmentDetailModal(false);
    // fetchCustomerAppointments(appointmentParams);
  };
  const getAppointmentDetails = async (id) => {
    setAppointmentModalLoader(true);

    const detailView = await dispatch(appointmentDetails(id));

    if (detailView) {
      setAppointmentModalLoader(false);
      setAppointmentDetailData(detailView);

      if (detailView?.requestStatus === "pending") {
        setOpenAppReqModal(true);
      } else {
        if (
          dayjs(detailView?.dropOffDateTime).format("YYYY-DD-MM") <=
          dayjs().format("YYYY-DD-MM")
        ) {
          setOpenAppointmentDetailModal(true);
        } else {
          setShowUpcomingModal(true);
        }
      }
    }

    setAppointmentModalLoader(false);
  };
  const onSubmit = async (values) => {
    if (values?.vin && values?.vin !== "") {
      if (checkValidVIN(values?.vin)) {
        clearErrors("vin");
      } else {
        return setError("vin", { message: "Please enter valid VIN" });
      }
    }
    let payload = {
      // ...values,
      year: values?.year,
      vehicleMakeId: values?.vehicleMakeId,
      vehicleModelId: values?.vehicleModelId,
      // vehicleModelTrimId: values?.vehicleModelTrimId,
      vin: values?.vin,
      licenseNumber: values.licenseNumber,
      customerId: id,
    };

    if (!values?.vin) {
      delete payload["vin"];
    }

    if (values.vehicleModelTrimId) {
      payload.vehicleModelTrimId = values?.vehicleModelTrimId;
    }
    setShowLoader(true);
    if (editVehicle?.edit) {
      let resp = "";
      if (importedVehicleId) {
        resp = await dispatch(editImportedVehicle(importedVehicleId, payload));
      } else {
        resp = await dispatch(editCustomerVehicle(editVehicle.id, payload));
      }
      if (resp) {
        setEditVehicle({ edit: false, index: "", id: "" });
        handleClose();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        setImportedVehicleId(null);
        fetchCustomerVehicle();
        setOpenSuccessModal(true);
        setSuccessMessage("Vehicle Updated");
      }
    } else {
      const resp = await dispatch(addVehicle(payload));
      if (resp?.status) {
        handleClose();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        fetchCustomerVehicle();
        setOpenSuccessModal(true);
        setSuccessMessage("New Vehicle Added");
      }
    }
    setShowLoader(false);
    // if (resp) {

    // }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleClick(event) {
    event.preventDefault();
    // console.info("You clicked a breadcrumb.");
  }
  const onSubmitCustomer2 = async (resp) => {
    setShowLoader(false);
    handleCloseCustomer();
    fetchCustomerDetail();
    if (resp) {
      setOpenSuccessModal(true);
      setSuccessMessage("Customer Details Updated");
      setSuccessMessageType("success");
      if (quickEdit) {
        setQuickEdit(false);
      }
    }
  };

  const onSubmitCustomer = async (values) => {
    let payload = {
      // ...values,
      firstName: values.firstName,
      phoneNumber: values.phoneNumber,
      // countryCode: "+1",
    };
    if (values.lastName) {
      payload.lastName = values?.lastName;
    }
    // if (values.email) {
    //   payload.email = values?.email;
    // }

    // if (values?.company) {
    payload.company = values?.company;
    // }
    if (addressValues?.city) {
      payload.city = addressValues?.cityId;
    }

    if (addressValues?.country) {
      payload.country = "64e738882fec8f2c9b79c139";
    }

    if (values?.postalCode) {
      payload.postalCode = values?.postalCode;
    }
    if (addressValues?.province) {
      payload.province = addressValues?.provinceId;
    }
    if (values?.streetAddress) {
      payload.streetAddress = values?.streetAddress;
    }
    if (values?.contactPrefrence) {
      payload.contactPrefrence = values?.contactPrefrence;
    }
    if (values?.primaryPhoneNumberType) {
      payload.primaryPhoneNumberType = values?.primaryPhoneNumberType;
      payload.countryCode = values?.countryCode;
      payload.phoneNumberCountry = values?.phoneNumberCountry;
    }

    payload.secondaryPhoneNumberType = values?.secondaryPhoneNumberType;
    payload.secondaryPhoneNumber = values?.secondaryPhoneNumber;

    if (values?.secondaryPhoneNumber) {
      payload.secondaryPhoneNumberCountryCode =
        values?.secondaryPhoneNumberCountryCode;
      payload.secondaryPhoneNumberCountry = values?.secondaryPhoneNumberCountry;
    }

    if (primaryEmailIndex == 0) {
      payload.email = values?.email;
      payload.secondaryEmail = values?.secondaryEmail;
    } else {
      payload.secondaryEmail = values?.email;
      payload.email = values?.secondaryEmail;
    }

    if (
      values.membershipProgram &&
      values.membershipProgram !== "" &&
      values.membershipProgram !== "none"
    ) {
      payload.membershipProgram = values.membershipProgram;
    } else {
      payload.membershipProgram = "";
    }

    // if (primaryEmailIndex == 0) {
    //   if (values.email) {
    //     payload.email = values.email;
    //   } else {
    //     // return displayError("Please enter email");
    //     // payload.email = "";
    //   }
    //   if (values?.secondaryEmail) {
    //     payload.secondaryEmail = values?.secondaryEmail;
    //   }
    // } else {
    //   if (values?.secondaryEmail) {
    //     payload.email = values?.secondaryEmail;
    //   }
    //   if (values?.email) {
    //     payload.secondaryEmail = values?.email;
    //   }
    // }

    setShowLoader(true);
    const resp = await dispatch(editCustomer(id, payload));
    setShowLoader(false);
    handleCloseCustomer();
    fetchCustomerDetail();
    if (resp) {
      setOpenSuccessModal(true);
      setSuccessMessage("Customer Details Updated");
      setSuccessMessageType("success");
      if (quickEdit) {
        setQuickEdit(false);
      }
    }
  };

  const handleDeleteCustomer = async () => {
    setShowLoader(true);
    const resp = await dispatch(deleteCustomer(id));
    if (resp) {
      setTimeout(() => {
        handleCloseCustomer();
        handleCloseDelete();
        setShowLoader(false);
        navigate("/customers");
        setOpenSuccessModal(true);
        setSuccessMessageType("success");
        setSuccessMessage("Customer Deleted");
      }, 2500);
    }
  };

  const handleDeleteVehicle = async () => {
    setShowLoader(true);
    let resp = "";
    if (importedVehicleId) {
      resp = await dispatch(deleteImportedVehicle(importedVehicleId));
      setImportedVehicleId(null);
      if (type == SHOP_IMPORTED) fetchImportedCustomerDetail();
    } else {
      resp = await dispatch(deleteCustomerVehicle(deleteVehicleId));
      setDeleteVehicleId("");
    }

    handleCloseDeleteVehicle();
    handleClose();
    setDeleteVehicleId("");
    handleCloseViewVehicle();
    // handleCloseDelete();
    fetchCustomerVehicle();
    setShowLoader(false);
    if (resp) {
      setOpenSuccessModal(true);
      setSuccessMessage("Vehicle Deleted");
    }
  };
  const [anchorElAtions, setAnchorElActions] = React.useState(null);
  const openNoteActions = Boolean(anchorElAtions);

  const handleClickNoteActions = (event, data) => {
    setSelectedActionOfNote(data);
    setAnchorElActions(event.currentTarget);
  };
  const handleCloseNoteActions = () => {
    setAnchorElActions(null);
    setSelectedActionOfNote("");
  };

  const editNote = async () => {
    let payload = { notes: editNoteText };
    setShowLoader(true);
    await dispatch(editCustomerNote(editDeleteNoteId?._id, payload));
    fetchCustomerNotes(customerNoteParams);
    // setShowLoader(false)
    setEditDeleteNoteId("");
    setEditNoteText("");
    setShowLoader(false);
    setOpenSuccessModal(true);
    setSuccessMessage("Note Updated");
  };
  const deleteNote = async (id) => {
    setShowLoader(true);
    await dispatch(deleteCustomerNote(id));
    fetchCustomerNotes(customerNoteParams);
    // setShowLoader(false)
    setEditDeleteNoteId("");
    setEditNoteText("");
    setShowLoader(false);
    setOpenSuccessModal(true);
    setSuccessMessage("Note Deleted");
  };

  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");

      setVINLoading(true);

      const resp = await dispatch(decodeVIN(getValues()?.vin));
      // setModelSelectLoader(true);
      setMakeValue(resp?.data?.make?._id);
      setModelValue("");
      setTrimValue("");
      await dispatch(
        vehicleMakeMaster({
          page: 1,
          limit: 1000,
          year: resp?.data?.year,
        })
      );
      let para = {
        page: 1,
        limit: 1000,
        vehicleMakeId: resp?.data?.make?._id,
        year: resp?.data?.year,
      };
      let maekResult = await dispatch(vehicleModelMaster(para));
      if (maekResult) {
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          vehicleModelId: resp?.data?.model?._id,
          year: resp?.data?.year,
        };
        await dispatch(vehicleTrimMaster(para));
      }

      if (resp) {
        setValueinForm("year", resp?.data?.year + "");
        setValueinForm("vehicleMakeId", resp?.data?.make?._id);
        setValueinForm("vehicleModelId", resp?.data?.model?._id);
        setValueinForm("vehicleModelTrimId", resp?.data?.trim?._id);
        setYearValue(resp?.data?.year);
        setMakeValue(resp?.data?.make?._id);
        setModelValue(resp?.data?.model?._id);
        setTrimValue(resp?.data?.trim?._id);

        setVINLoading(false);
      }
    } else if (getValues()?.vin && getValues()?.vin.length != 17) {
      setVINLoading(false);
      // setVINError("Please Enter Valid VIN");
      setError("vin", {
        message: "Please enter valid VIN",
      });
    } else {
      setVINLoading(false);
      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");

      // message.error("Please enter VIN number");
    }
  };
  const handleCloseUpComing = () => {
    setShowUpcomingModal(false);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const customerAddress = () => {
    let add = " ";
    if (data?.streetAddress) {
      add += data?.streetAddress;
    }
    if (data?.city) {
      if (add.trim() != "") add += ", " + data?.city?.city;
      else add += data?.city?.city;
    }
    if (data?.province) {
      if (add.trim() != "") add += ", " + data?.province?.province;
      else add += data?.province?.province;
    }
    // if (data?.country) {
    //   add += data?.country?.country + ", ";
    // }
    if (data?.postalCode) {
      if (add.trim() != "") add += ", " + data?.postalCode;
      else add += data?.postalCode;
    }
    return add;
  };
  const importCustomerAddress = () => {
    let add = " ";
    if (importedCustomerData?.streetAddress) {
      if (add.trim() != "") add += ", " + importedCustomerData?.streetAddress;
      else {
        add += importedCustomerData?.streetAddress;
      }
    }
    if (importedCustomerData?.city) {
      if (add.trim() != "") add += ", " + importedCustomerData?.city?.city;
      else add += importedCustomerData?.city?.city;
    }
    if (importedCustomerData?.province) {
      if (add.trim() != "")
        add += ", " + importedCustomerData?.province?.province;
      else add += importedCustomerData?.province?.province;
    }
    // if (importedCustomerData?.country) {
    //   add += importedCustomerData?.country?.country + ", ";
    // }
    if (importedCustomerData?.postalCode) {
      if (add.trim() != "") add += ", " + importedCustomerData?.postalCode;
      else add += importedCustomerData?.postalCode;
    }
    return add;
  };

  if (showLoader)
    return (
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  return (
    <Box
      padding={"32px 24px"}
      sx={{ bgcolor: "white", minHeight: "calc(100vh - 64px)", height: "100%" }}
    >
      <Grid
        container
        spacing={0}
        sx={{ padding: "16px 0px 16px 0px" }}
        gap={"8px"}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/customers"
                style={{
                  color: "black",
                  // opacity: "0.6",
                  textDecoration: "none",
                }}
                onClick={() => {
                  removeItem("searchValue");
                }}
              >
                Customers
              </Link>
              <Link
                // to={`/customers/${id}`}
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography color="black">
                  {type == SHOP_IMPORTED
                    ? importedCustomerData?.firstName +
                      " " +
                      importedCustomerData?.lastName
                    : data?.firstName + " " + data?.lastName}
                </Typography>
              </Link>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent={"space-between"}
          spacing={1}
          rowGap={2}
        >
          <Grid item lg={isOpenSideBar ? 4 : 3} md={5} sm={12} xs={12} xl={3.2}>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Customer Profile
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={isOpenSideBar ? 8.4 : 8}
            xl={8}
            sx={{
              display: "flex",
              justifyContent: { lg: "end" },
              flexWrap: { xs: "wrap", md: "nowrap" }, // Allow wrapping on smaller screens
              gap: 2,
              pointerEvents: quickEdit ? "none" : "auto",
              opacity: quickEdit ? "0.5" : "",
            }}
            // columnGap={2}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
              onClick={() => {
                navigate(
                  `/appointment/new-appointment/${id}/${
                    type
                      ? type == SHOP_CREATED
                        ? SHOP_CREATED
                        : type == SHOP_IMPORTED
                        ? SHOP_IMPORTED
                        : APEX_AUTO_APP
                      : data.createdFrom == SHOP_CREATED
                      ? SHOP_CREATED
                      : APEX_AUTO_APP
                  }`
                );
              }}
              size="large"
            >
              new appointment
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
              onClick={() => {
                navigate(
                  `/estimates/new/${id}/${
                    type
                      ? type == SHOP_CREATED
                        ? SHOP_CREATED
                        : type == SHOP_IMPORTED
                        ? SHOP_IMPORTED
                        : APEX_AUTO_APP
                      : data.createdFrom == SHOP_CREATED
                      ? SHOP_CREATED
                      : APEX_AUTO_APP
                  }`
                );
              }}
              className="btn-text-size-manage"
              size="large"
            >
              new estimate
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
              onClick={() => {
                navigate(
                  `/invoice/new/${id}/${
                    type
                      ? type == SHOP_CREATED
                        ? SHOP_CREATED
                        : type == SHOP_IMPORTED
                        ? SHOP_IMPORTED
                        : APEX_AUTO_APP
                      : data.createdFrom == SHOP_CREATED
                      ? SHOP_CREATED
                      : APEX_AUTO_APP
                  }`
                );
              }}
              className="btn-text-size-manage"
              size="large"
            >
              new invoice
            </Button>
            {type != SHOP_IMPORTED && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={handleOpen}
                size="large"
                sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
              >
                ADD VEHICLE
              </Button>
            )}
            <Modal
              // open={open}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  width: "40vw",
                  // overflowY: "scroll",
                }}
              >
                <AppBar
                  color="secondary"
                  variant="elevation"
                  sx={{
                    height: "64px",
                    paddingRight: "0px !important",
                  }}
                >
                  <Toolbar variant="regular">
                    <Typography variant="h6" width={"98%"} color={"white"}>
                      {importedVehicleId
                        ? "Confirm Vehicle Information"
                        : editVehicle.edit
                        ? "Edit Vehicle "
                        : "Add Vehicle"}
                    </Typography>
                    <Stack direction={"row"} spacing={0}>
                      <div
                        onClick={handleClose}
                        style={{ cursor: "pointer", height: "32px" }}
                      >
                        <CloseIcon
                          style={{ color: "#0000008F", fontSize: 32 }}
                        />
                      </div>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <Container
                  gap={2}
                  disableGutters
                  className="viewVehicleModal"
                  style={{
                    marginTop: "64px",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        scrollbarColor: "blue",
                        scrollbarWidth: "thin",
                        overflowY: "auto",
                        marginBottom: "24px",
                        maxHeight: "80vh",
                      }}
                    >
                      <Grid item xs={12} md={12} xl={12}>
                        <Typography variant="body1" className="label-500">
                          VIN
                          {/* <span style={{ color: "REQUIRED_RED" }}>*</span> */}
                        </Typography>
                        <Controller
                          name="vin"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.vin}
                              helperText={errors.vin ? errors.vin.message : ""}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                if (e.target.value !== "") {
                                  const regex = checkValidVIN(e.target.value);
                                  if (regex) {
                                    clearErrors("vin");
                                  } else {
                                    setError("vin", {
                                      message: "Please enter valid VIN",
                                    });
                                  }
                                } else {
                                  clearErrors("vin");
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {" "}
                                    {vinLoading ? (
                                      <CircularProgress />
                                    ) : (
                                      <Button onClick={handleDecodeVIN}>
                                        Decode
                                      </Button>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                        {/* <Typography style={{ color: "red" }}>
                            {vinError}
                          </Typography> */}
                      </Grid>
                      <Divider
                        textAlign="center"
                        style={{
                          width: "100%",
                          paddingLeft: "24px",
                          marginTop: "16px",
                        }}
                      >
                        Or
                      </Divider>
                      <Grid item xs={12} sm={4}>
                        <FormControl>
                          <Typography
                            variant="body1"
                            className="label-500"
                            style={{ fontWeight: 400 }}
                          >
                            Year <span style={{ color: "#D32F2F" }}> *</span>
                          </Typography>
                          <Controller
                            defaultValue=""
                            name="year"
                            control={control}
                            rules={{
                              required: "Year is required",
                            }}
                            render={({ field }) => (
                              <Select
                                fullWidth
                                value={yearValue}
                                onChange={(event) => {
                                  field.onChange(event);

                                  setYearValue(event.target.value);
                                  setMakeValue(null);
                                  setModelValue(null);
                                  setTrimValue(null);
                                  setMake([]);
                                  setModel([]);
                                  setTrim([]);
                                  fetchVehicleMake({
                                    page: makeValuePage,
                                    limit: 500,
                                    year: event.target.value,
                                  });
                                }}
                                error={!!errors.year}
                                helperText={
                                  errors.year ? errors.year.message : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                                // {...field}
                              >
                                {Array.isArray(year) &&
                                  year?.map((y) => {
                                    return (
                                      <MenuItem value={y} key={y}>
                                        {y}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl>
                          <Typography
                            variant="body1"
                            className="label-500"
                            style={{ fontWeight: 400 }}
                          >
                            Make <span style={{ color: "#D32F2F" }}> *</span>
                          </Typography>
                          {editVehicle?.edit &&
                          makeLoader &&
                          makeValue != null ? (
                            <Skeleton
                              animation="wave"
                              variant="text"
                              sx={{ fontSize: "3.5rem" }}
                              className="skelton"
                            ></Skeleton>
                          ) : (
                            <Controller
                              name="vehicleMakeId"
                              defaultValue={""}
                              control={control}
                              rules={{
                                required: "Make is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  fullWidth
                                  value={makeValue ? makeValue : ""}
                                  disabled={yearValue !== "" ? false : true}
                                  onChange={(event) => {
                                    field.onChange(event);

                                    setMakeValue(event.target.value);
                                    setModelValue(null);
                                    setModel([]);
                                    setTrim([]);
                                    setTrimValue(null);

                                    fetchVehicleModel({
                                      page: modelValuePage,
                                      limit: 1000,
                                      vehicleMakeId: event.target.value,
                                      year: yearValue,
                                    });
                                  }}
                                  error={!!errors.vehicleMakeId}
                                  helperText={
                                    errors.vehicleMakeId
                                      ? errors.vehicleMakeId.message
                                      : ""
                                  }
                                  MenuProps={{
                                    style: { maxHeight: "400px" },
                                  }}
                                >
                                  {Array.isArray(make) &&
                                    make?.map((m) => {
                                      return (
                                        <MenuItem value={m?._id} key={m?._id}>
                                          {m?.name}
                                        </MenuItem>
                                      );
                                    })}
                                  {makeLoader && (
                                    <MenuItem disabled>
                                      <CircularProgress />
                                    </MenuItem>
                                  )}
                                </Select>
                              )}
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl>
                          <Typography
                            variant="body1"
                            className="label-500"
                            style={{ fontWeight: 400 }}
                          >
                            Model <span style={{ color: "#D32F2F" }}> *</span>
                          </Typography>
                          {editVehicle?.edit &&
                          modelLoader &&
                          modelValue != null ? (
                            <Skeleton
                              animation="wave"
                              variant="text"
                              sx={{ fontSize: "3.5rem" }}
                              className="skelton"
                            ></Skeleton>
                          ) : (
                            <Controller
                              name="vehicleModelId"
                              control={control}
                              rules={{
                                required: "Model is required",
                              }}
                              render={({ field, fieldState }) => (
                                <Select
                                  fullWidth
                                  value={modelValue}
                                  disabled={
                                    yearValue !== "" && makeValue !== ""
                                      ? false
                                      : true
                                  }
                                  onChange={(event) => {
                                    field.onChange(event);
                                    setModelValue(event.target.value);
                                    setTrimValue(null);
                                    setValueinForm("vehicleModelTrimId", null);
                                    setTrim([]);

                                    fetchVehicleTrim({
                                      page: trimValuePage,
                                      limit: 1000,
                                      vehicleMakeId: makeValue,
                                      vehicleModelId: event.target.value,
                                      year: yearValue,
                                    });
                                  }}
                                  error={!!errors.vehicleModelId}
                                  helperText={
                                    errors.vehicleModelId
                                      ? errors.vehicleModelId.message
                                      : ""
                                  }
                                  MenuProps={{
                                    style: { maxHeight: "400px" },
                                  }}
                                  // {...field}
                                >
                                  {Array.isArray(model) &&
                                    model?.map((m) => {
                                      return (
                                        <MenuItem value={m?._id} key={m?._id}>
                                          {m?.name}
                                        </MenuItem>
                                      );
                                    })}

                                  {modelLoader && (
                                    <MenuItem disabled>
                                      <CircularProgress />
                                    </MenuItem>
                                  )}
                                </Select>
                              )}
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <Typography
                            variant="body1"
                            className="label-500"
                            style={{ fontWeight: 400 }}
                          >
                            Trim
                            {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                          </Typography>
                          {editVehicle?.edit &&
                          trimLoader &&
                          trimValue != null ? (
                            <Skeleton
                              animation="wave"
                              variant="text"
                              sx={{ fontSize: "3.5rem" }}
                              className="skelton"
                            ></Skeleton>
                          ) : (
                            <Controller
                              name="vehicleModelTrimId"
                              control={control}
                              // rules={{
                              //   required: "Make is required",
                              // }}
                              render={({ field, fieldState }) => (
                                <Select
                                  fullWidth
                                  disabled={
                                    yearValue && makeValue && modelValue
                                      ? false
                                      : true
                                  }
                                  value={trimValue}
                                  onChange={(event) => {
                                    field.onChange(event);

                                    setTrimValue(event.target.value);
                                  }}
                                  error={!!errors.vehicleModelTrimId}
                                  helperText={
                                    errors.vehicleModelTrimId
                                      ? errors.vehicleModelTrimId.message
                                      : ""
                                  }
                                  MenuProps={{
                                    style: { maxHeight: "400px" },
                                  }}
                                  // {...field}
                                >
                                  {Array.isArray(trim) &&
                                    trim?.map((m) => {
                                      return (
                                        <MenuItem value={m?._id} key={m?._id}>
                                          <div>
                                            <div style={{ fontWeight: 500 }}>
                                              {m?.name}
                                            </div>
                                            <div>{m?.description}</div>
                                          </div>
                                        </MenuItem>
                                      );
                                    })}
                                  {trimLoader && (
                                    <MenuItem disabled>
                                      <CircularProgress />
                                    </MenuItem>
                                  )}
                                </Select>
                              )}
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Divider
                        textAlign="center"
                        style={{
                          width: "100%",
                          paddingLeft: "24px",
                          marginTop: "16px",
                        }}
                      >
                        And
                      </Divider>
                      <Grid item xs={12} md={12} xl={12}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Licence Plate Number{" "}
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>

                        <Controller
                          name="licenseNumber"
                          control={control}
                          rules={{
                            required: "Licence Plate Number is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.licenseNumber}
                              helperText={
                                errors.licenseNumber
                                  ? errors.licenseNumber.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                        {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                      </Grid>
                    </Grid>

                    <Grid item container justifyContent={"space-between"}>
                      <Grid item>
                        <Stack spacing={2} direction="row">
                          {editVehicle?.edit && (
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                handleClose();

                                setDeleteVehicleId(editVehicle.id);
                                setOpenVehicleDelete(true);
                              }}
                              disabled={vehicles?.length == 1}
                              size="large"
                            >
                              DELETE
                            </Button>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                            size="large"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            // onClick={handleClose}
                            size="large"
                            type="submit"
                          >
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </Box>
            </Modal>
            {/* </Stack> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        // padding={"24px"}
        // spacing={2}
        marginTop={"20px"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          container
          height={"fit-content"}
          xs={12}
          sm={12}
          md={12}
          lg={5.1}
          xl={3.5}
          // spacing={2}
          sx={{
            boxShadow:
              "0px 2px 16px 1px rgba(0, 0, 0, 0.12), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 4px 5px -2px rgba(0, 0, 0, 0.20)",
          }}
          borderRadius={"4px"}
          padding="24px"
          // paddingTop={"0px"}
          marginTop={"26px"}
        >
          {!quickEdit ? (
            <CustomerProfileLeftDataSection
              data={data}
              importedCustomerData={importedCustomerData}
              setOpenEditCustomer={setOpenEditCustomer}
              setOpenDelete={setOpenDelete}
              setQuickEdit={setQuickEdit}
            />
          ) : (
            <CustomerProfileQuickEdit
              data={data}
              setQuickEdit={setQuickEdit}
              onSubmitCustomer={onSubmitCustomer}
              setAddressValues={setAddressValue}
              addressValues={addressValues}
              type={type}
            />
          )}
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={12}
          sm={12}
          lg={6.9}
          xl={8.5}
          borderRadius={"4px"}
          sx={{
            pointerEvents: quickEdit ? "none" : "auto",
            opacity: quickEdit ? "0.5" : "",
            userSelect: quickEdit ? "none" : "",
          }}
        >
          {/* <Grid item container xs={12} sm={12} md={12} xl={12}> */}
          <Box sx={{ width: "100%", padding: "24px" }}>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="OVERVIEW"
                  // style={{ display: type == SHOP_IMPORTED && "none" }}
                  {...a11yProps(0)}
                />

                <Tab label="VEHICLES" {...a11yProps(1)} />

                <Tab
                  label="APPOINTMENTS"
                  style={{ display: type == SHOP_IMPORTED && "none" }}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>

            <CustomTabPanel
              value={value}
              index={0}
              // style={{ display: type == SHOP_IMPORTED && "none" }}
            >
              <Grid container>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent={"space-between"}
                  // spacing={2}
                  borderRadius={"4px"}
                >
                  {type == SHOP_IMPORTED && (
                    <Grid container xs={12} sm={12} md={12} xl={12} rowGap={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        marginTop={"24px"}
                      >
                        <Alert severity="warning">
                          <div
                            style={{
                              display: "flex",
                              fontSize: "16px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              Customer information needs to be confirmed.
                            </div>

                            <div
                              onClick={() => {
                                setOpenCustomerInfo(true);
                              }}
                              style={{
                                color: "#0A66C2",
                                textDecoration: "underline",
                                marginLeft: "2px",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                            >
                              Confirm Now
                            </div>
                          </div>
                        </Alert>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    style={{ display: type == SHOP_IMPORTED && "none" }}
                    container
                    justifyContent={"start"}
                    borderTop={"none"}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      rowGap={3}
                    >
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        {data?.lastAppointmentDate && (
                          <Stack spacing={2} sx={{ marginTop: "24px" }}>
                            <Alert
                              icon={<Info className="lastAppointmentIcon" />}
                              className="lastAppointment"
                              onClick={() => {
                                navigate(
                                  `/appointment/past-appointment/${data?.lastAppointmentId}`
                                );
                              }}
                            >
                              <AlertTitle>
                                Last Appointment:{" "}
                                {dayjs(data.lastAppointmentDate).format(
                                  "MMMM DD, YYYY"
                                )}
                              </AlertTitle>
                              <Typography variant="body2" fontWeight={400}>
                                Vehicle Serviced:{" "}
                                {data?.vehicle?.year && data?.vehicle?.year}{" "}
                                {data?.vehicle?.vehicleMake?.name &&
                                  data?.vehicle?.vehicleMake?.name}{" "}
                                {data?.vehicle?.vehicleModel?.name &&
                                  data?.vehicle?.vehicleModel?.name}{" "}
                                {data?.vehicle?.vehicleModelTrim?.name &&
                                  data?.vehicle?.vehicleModelTrim?.name}
                              </Typography>
                            </Alert>
                          </Stack>
                        )}
                      </Grid>

                      <Stack
                        spacing={2}
                        sx={{ width: "100%", padding: "16px" }}
                      >
                        <Grid item xs={12} sm={12} md={12} xl={12} padding={""}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, marginBottom: "16px" }}
                          >
                            Recent Notes
                          </Typography>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} xl={12}>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                            type="text"
                            value={newNote}
                            onChange={(e) => setNewNote(e.target.value)}
                          />
                        </Grid>
                        {noteError && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            Please enter note
                          </p>
                        )}
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                          justifyContent={"end"}
                        >
                          <Grid>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              onClick={handleAddNote}
                            >
                              Add Note
                            </Button>
                          </Grid>
                        </Grid>
                      </Stack>

                      <Grid item container>
                        {Array.isArray(notes) &&
                          notes?.map((data) => {
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                xl={12}
                                style={{
                                  backgroundColor: "#e6f7f7",
                                  borderRadius: "8px",
                                  padding: "16px",
                                  marginBottom: "24px",
                                }}
                                // paddingBottom={"16px"}
                                display={"block"}
                              >
                                {/* <Card padding={"16px"} style={{backgroundColor:"#e6f7f7"}}> */}
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  mb={2}
                                >
                                  <div>
                                    <Typography variant="body1">
                                      {dayjs(data?.createdAt).format(
                                        "MMMM DD, YYYY"
                                      ) +
                                        " at " +
                                        dayjs(data?.createdAt).format("h:mm A")}
                                    </Typography>
                                  </div>

                                  <div style={{ marginRight: "16px" }}>
                                    <Button
                                      variant="contained"
                                      onClick={(e) => {
                                        handleClickNoteActions(e, data);
                                      }}
                                      id={data?._id}
                                      aria-controls={
                                        openNoteActions
                                          ? "demo-positioned-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        openNoteActions ? "true" : undefined
                                      }
                                      endIcon={<ExpandMoreIcon />}
                                    >
                                      Actions
                                    </Button>
                                    <Menu
                                      style={{ width: "300px" }}
                                      id="demo-positioned-menu"
                                      aria-labelledby={data?._id}
                                      anchorEl={anchorElAtions}
                                      open={openNoteActions}
                                      onClose={handleCloseNoteActions}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          handleCloseNoteActions();
                                          setEditDeleteNoteId(
                                            selectedActionOfNote
                                          );
                                          setEditNoteText(
                                            selectedActionOfNote?.notes
                                          );
                                        }}
                                      >
                                        {" "}
                                        <EditIcon
                                          style={{ marginRight: "10px" }}
                                        />{" "}
                                        Edit Note
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          handleCloseNoteActions();
                                          // setEditDeleteNoteId(
                                          //   selectedActionOfNote
                                          // );
                                          deleteNote(selectedActionOfNote?._id);
                                        }}
                                      >
                                        {" "}
                                        <DeleteIcon
                                          style={{ marginRight: "10px" }}
                                        />{" "}
                                        Delete Note
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                </Stack>
                                {editDeleteNoteId?._id != data._id && (
                                  <Card
                                    style={{
                                      backgroundColor: "#fff",
                                      padding: "10px",
                                      marginRight: "10px",
                                      marginBottom: "8px",
                                      border: "1px solid #C4C4C4",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                      }}
                                    >
                                      {data?.notes}
                                    </Typography>
                                  </Card>
                                )}
                                {editDeleteNoteId &&
                                  editDeleteNoteId?._id == data?._id && (
                                    <>
                                      <TextField
                                        // value={data?.notes}
                                        style={{
                                          backgroundColor: "#fff",
                                          // padding: "10px",
                                          marginRight: "10px",
                                          marginBottom: "16px",
                                          // border: "1px solid #C4C4C4",
                                          width: "100%",
                                        }}
                                        value={editNoteText}
                                        onChange={(e) =>
                                          setEditNoteText(e.target.value)
                                        }
                                      />
                                      <Button
                                        variant="contained"
                                        style={{ marginRight: "16px" }}
                                        onClick={() => editNote()}
                                      >
                                        SAVE
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          setEditDeleteNoteId("");
                                          setSelectedActionOfNote("");
                                        }}
                                      >
                                        CANCEL
                                      </Button>
                                    </>
                                  )}
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>

            <div style={{ margin: "24px 0" }}>
              <CustomTabPanel value={value} index={1}>
                <Grid container rowGap={3}>
                  {Array.isArray(vehicles) &&
                    vehicles.map((item, index) => {
                      if (item) {
                        return (
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            xl={12}
                            justifyContent={"space-between"}
                            boxShadow={2}
                            padding={"16px"}
                            borderRadius={"4px"}
                            alignItems="center"
                            key={item._id}
                            gap={1}
                          >
                            {/* <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={8}
                              xl={7}
                            ></Grid> */}

                            <Grid
                              item
                              direction={"row"}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"start"}
                              alignItems={"start"}
                              xs={12}
                              gap={2}
                              spacing={2}
                            >
                              <Stack spacing={0.5}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: "medium" }}
                                >
                                  {item?.year ? item?.year : ""}
                                  {item?.vehicleYear ? item?.vehicleYear : ""}

                                  {item?.vehicleMake?.name
                                    ? " " + item?.vehicleMake?.name
                                    : ""}

                                  {item?.vehicleModel?.name
                                    ? " " + item?.vehicleModel?.name
                                    : ""}

                                  {item?.vehicleModelTrim?.name
                                    ? " " + item?.vehicleModelTrim?.name
                                    : ""}
                                  {item?.vehicleTrim?.name
                                    ? " " + item?.vehicleTrim?.name
                                    : ""}
                                </Typography>
                                {item?.vehicleModelTrim?.description && (
                                  <Typography
                                    variant="body1"
                                    display={"flex"}
                                    sx={{ fontWeight: 400 }}
                                    gap={1}
                                  >
                                    <Typography fontWeight={500}>
                                      Trim Desc:
                                    </Typography>
                                    {item?.vehicleModelTrim?.description}
                                  </Typography>
                                )}
                                {item?.vehicleTrim?.description && (
                                  <Typography
                                    variant="body1"
                                    display={"flex"}
                                    sx={{ fontWeight: 400 }}
                                    gap={1}
                                  >
                                    <Typography fontWeight={500}>
                                      Trim Desc:
                                    </Typography>
                                    {item?.vehicleTrim?.description}
                                  </Typography>
                                )}
                                {console.log("jsdgfjgsdhfsd", item)}
                                {(item?.provinceCode ||
                                  item?.licenseNumber ||
                                  item?.license) && (
                                  <Typography
                                    variant="body1"
                                    display="flex"
                                    sx={{ fontWeight: 400 }}
                                    gap={1}
                                  >
                                    <Typography fontWeight={500}>
                                      Licence Plate:
                                    </Typography>
                                    {item?.provinceCode &&
                                      item?.provinceCode + " "}
                                    {item?.licenseNumber && item?.licenseNumber}
                                    {item?.license && item?.license}
                                  </Typography>
                                )}

                                {item?.vin && (
                                  <Typography
                                    variant="body1"
                                    display={"flex"}
                                    sx={{ fontWeight: 400 }}
                                    gap={1}
                                  >
                                    <Typography fontWeight={500}>
                                      VIN:
                                    </Typography>{" "}
                                    {item?.vin}
                                  </Typography>
                                )}
                              </Stack>
                              {/* {type != SHOP_IMPORTED && ( */}
                              <Stack
                                spacing={2}
                                direction="row"
                                justifyContent={"flex-end"}
                                style={{ width: "100%" }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setOpenViewVehicle(true);
                                    // setEditVehicle({
                                    //   edit: true,
                                    //   index: index,
                                    //   id: item?._id,
                                    // });
                                    setViewVehicleData({ item, index });
                                    if (item?.isConverted == false) {
                                      setImportedVehicleId(item?._id);
                                    }
                                    // setShowEditDeleteInViewModel(
                                    //   item?.isConverted == false ? false : true
                                    // );
                                  }}
                                >
                                  View
                                </Button>
                                {/* {item?.isConverted !== false && (
                                    <> */}
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setEditVehicle({
                                      edit: true,
                                      index: index,
                                      id: item?._id,
                                      data: item,
                                    });
                                    if (item?.isConverted == false) {
                                      setImportedVehicleId(item?._id);
                                    }
                                    setOpen(true);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    setDeleteVehicleId(item?._id);
                                    setOpenVehicleDelete(true);
                                    if (item?.isConverted == false) {
                                      setImportedVehicleId(item?._id);
                                    }
                                  }}
                                  disabled={vehicles?.length == 1}
                                >
                                  DELETE
                                </Button>
                                {/* </>
                                  )} */}
                              </Stack>
                              {/* )} */}
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </CustomTabPanel>
            </div>

            <div style={{ margin: "24px 0" }}>
              <CustomTabPanel
                value={value}
                index={2}
                style={{ display: type == SHOP_IMPORTED && "none" }}
              >
                <Grid container rowGap={3}>
                  {Array.isArray(appointments?.data) &&
                    appointments?.data.map((item) => {
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          boxShadow={2}
                          padding={"16px"}
                          borderRadius={"4px"}
                          key={item?._id}
                        >
                          <Grid item xs={4} sm={5} md={6} xl={6}>
                            <Stack spacing={0.5}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "medium" }}
                              >
                                {item?.appointmentNumber +
                                  ": " +
                                  dayjs(item?.dropOffDateTime).format(
                                    "MMMM DD, YYYY"
                                  )}
                              </Typography>
                              {item?.serviceCount > 1 ? (
                                <Typography variant="body1">
                                  Total Services: {item?.serviceCount}
                                </Typography>
                              ) : (
                                <Typography>
                                  Booking for: {item?.services?.[0]?.name}
                                </Typography>
                              )}
                              <Typography variant="body1">
                                {item?.vehicle?.year && item?.vehicle?.year}{" "}
                                {item?.vehicle?.vehicleMake?.name &&
                                  item?.vehicle?.vehicleMake?.name}{" "}
                                {item?.vehicle?.vehicleModel?.name &&
                                  item?.vehicle?.vehicleModel?.name}{" "}
                                {item?.vehicle?.vehicleModelTrim?.name &&
                                  item?.vehicle?.vehicleModelTrim?.name}
                              </Typography>
                            </Stack>
                          </Grid>

                          <Grid item spacing={2} direction={"row"}>
                            <Stack spacing={2} direction="row">
                              <Button
                                variant="contained"
                                onClick={() => {
                                  item?.status == "customer_paid" ||
                                  item?.status == "mark_as_no_show"
                                    ? navigate(
                                        `/appointment/past-appointment/${item?._id}`
                                      )
                                    : getAppointmentDetails(item?._id);
                                }}
                              >
                                View
                              </Button>
                              {/* <Button variant="contained">Delete</Button> */}
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </CustomTabPanel>
            </div>
          </Box>
          {/* </Grid> */}
        </Grid>
      </Grid>
      <UpdateCustomerModal
        openEditCustomer={openEditCustomer}
        handleCloseCustomer={handleCloseCustomer}
        onSubmitCustomer={onSubmitCustomer2}
        convertedCustomerId={convertedCustomerId}
        addressValues={addressValues}
        setAddressValue={setAddressValue}
        primaryEmailIndex={primaryEmailIndex}
        setPrimaryEmailIndex={setPrimaryEmailIndex}
        setOpenDelete={setOpenDelete}
        type={type}
        setShowLoader={setShowLoader}
      />
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="deleteCustomer"
      >
        <Box
          sx={{
            ...deleteStyle,
            width: "100%",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
            padding: 0,
          }}
        >
          <AppBar
            color=""
            variant="outlined"
            style={{
              width: "inherit",
              borderRadius: "8px 8px 0px 0px !important",
              padding: "0",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"}>
                Delete Customer
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleCloseDelete}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon sx={{ fill: "black", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
        <Container
          style={{
            marginTop: "64px",
            padding: "0",
          }}
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "black" }}
            >
              Are you sure you want to delete this customer?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: "8px" }}>
            <Button
              onClick={handleCloseDelete}
              variant="outlined"
              sx={{ fontSize: "15px" }}
            >
              CANCEL
            </Button>
            <Button
              onClick={handleDeleteCustomer}
              autoFocus
              color="error"
              variant="contained"
              sx={{ fontSize: "15px" }}
            >
              DELETE
            </Button>
          </DialogActions>
        </Container>
        {/* <IconButton
          aria-label="close"
          onClick={handleCloseDelete}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
      </Dialog>
      <Dialog
        open={openVehicleDelete}
        onClose={handleCloseDeleteVehicle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="deleteCustomer"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "400", fontSize: "24px" }}
        >
          {"Delete Vehicle"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDeleteVehicle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#0000008F",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this vehicle?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: "8px" }}>
          <Button onClick={handleCloseDeleteVehicle} variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleDeleteVehicle}
            autoFocus
            color="error"
            variant="contained"
          >
            DELETE VEHICLE
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openViewVehicle}
        onClose={handleCloseViewVehicle}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "624px",
            // overflowY: "scroll",
          }}
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Vehicle
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleCloseViewVehicle();
                    setEditVehicle({ edit: false, index: "", id: "" });
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={0}
            disableGutters
            className="viewVehicleModal"
            style={{
              scrollbarColor: "blue",
              scrollbarWidth: "thin",
              overflowY: "auto",
              marginTop: "64px",
              maxHeight: "80vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >
              <Tabs
                value={viewVehicleModalTabvalue}
                onChange={handleChangeViewVehicleTabValue}
                aria-label="basic tabs example"
              >
                <Tab
                  label="OVERVIEW"
                  // style={{ display: type == SHOP_IMPORTED && "none" }}
                  {...a11yProps(0)}
                />

                <Tab label="ENGINE" {...a11yProps(1)} />
              </Tabs>
              {config?.carfaxSettings?.canFetchROHistory && (
                <Button
                  variant="outlined"
                  sx={{ height: "max-content", display: "" }}
                  onClick={() => {
                    if (
                      (vehicles && vehicles[viewVehicleData?.index]?.vin) ||
                      (vehicles &&
                        vehicles[viewVehicleData?.index]?.licenseNumber)
                    ) {
                      setOpenCarfaxHistory(true);
                      handleCloseViewVehicle();
                    } else {
                      setSuccessMessage(
                        "Please enter licence plate or VIN to retrieve the Carfax Report."
                      );
                      setOpenSuccessModal(true);
                      setSuccessMessageType("error");
                    }
                  }}
                >
                  VIEW CARFAX REPORT
                </Button>
              )}
            </div>
            <CustomTabPanel value={viewVehicleModalTabvalue} index={0}>
              <Grid container item spacing={2} color={"#000000"}>
                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    VIN
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles && vehicles[viewVehicleData?.index]?.vin}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Year
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles && vehicles[viewVehicleData?.index]?.year
                      ? vehicles && vehicles[viewVehicleData?.index]?.year
                      : vehicles &&
                        vehicles[viewVehicleData?.index]?.vehicleYear}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Make
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleMake?.name}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Model
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModel?.name}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Trim
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim?.name}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Trim Description
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim
                        ?.description}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Licence Plate Number
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.licenseNumber}
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={viewVehicleModalTabvalue} index={1}>
              <Grid container item spacing={2} color={"#000000"}>
                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Engine Type
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim
                        ?.engineType}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Engine Fuel Type
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim
                        ?.engineFuelType}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Engine Drive Type
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim
                        ?.engineDriveType}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Engine Transmission
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim
                        ?.engineTransmission}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    md={4}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    letterSpacing={"0.15px"}
                    fontWeight={500}
                  >
                    Body Type
                  </Grid>
                  <Grid item md={8} fontSize={"16px"} fontWeight={400}>
                    {vehicles &&
                      vehicles[viewVehicleData?.index]?.vehicleModelTrim
                        ?.bodyType}
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>
            {/* {showEditDeleteInViewModel && ( */}
            <Stack
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              marginTop="24px"
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  height: "42px",
                  lineHeight: "26px",
                  letterSpacing: "0.46px",
                  fontSize: "15px",
                }}
                onClick={() => {
                  setDeleteVehicleId(viewVehicleData?.item?._id);
                  setOpenVehicleDelete(true);
                }}
                disabled={vehicles?.length == 1}
              >
                DELETE
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: "42px",
                  lineHeight: "26px",
                  letterSpacing: "0.46px",
                  fontSize: "15px",
                }}
                onClick={() => {
                  setOpen(true);
                  handleCloseViewVehicle();
                  setEditVehicle({
                    edit: true,
                    index: viewVehicleData?.index,
                    id: viewVehicleData?.item?._id,
                    data: viewVehicleData?.item,
                  });
                  if (type == SHOP_IMPORTED)
                    setYearValue(vehicles[editVehicle.index]?.vehicleYear + "");
                  else {
                    setYearValue(vehicles[editVehicle.index]?.year + "");
                  }

                  // fetchVehicleMake({
                  //   page: makeValuePage,
                  //   limit: 500,
                  //   year:
                  //     type == SHOP_IMPORTED
                  //       ? vehicles[editVehicle.index]?.vehicleYear
                  //       : vehicles[editVehicle.index]?.year,
                  // });
                  // fetchVehicleModel({
                  //   page: modelValuePage,
                  //   limit: 1000,
                  //   vehicleMakeId:
                  //     vehicles[editVehicle.index]?.vehicleMake?._id,
                  //   year:
                  //     type == SHOP_IMPORTED
                  //       ? vehicles[editVehicle.index]?.vehicleYear
                  //       : vehicles[editVehicle.index]?.year,
                  // });
                  // fetchVehicleTrim({
                  //   page: trimValuePage,
                  //   limit: 1000,
                  //   vehicleMakeId:
                  //     vehicles[editVehicle.index]?.vehicleMake?._id,
                  //   vehicleModelId:
                  //     vehicles[editVehicle.index]?.vehicleModel?._id,
                  //   year:
                  //     type == SHOP_IMPORTED
                  //       ? vehicles[editVehicle.index]?.vehicleYear
                  //       : vehicles[editVehicle.index]?.year,
                  // });
                  // setValueinForm("vin", vehicles[editVehicle.index]?.vin);
                  // setValueinForm(
                  //   "year",
                  //   (type == SHOP_IMPORTED
                  //     ? vehicles[editVehicle.index]?.vehicleYear
                  //     : vehicles[editVehicle.index]?.year) + ""
                  // );

                  // setValueinForm(
                  //   "vehicleMakeId",
                  //   vehicles[editVehicle.index]?.vehicleMake?._id
                  // );
                  // setValueinForm(
                  //   "vehicleModelId",
                  //   vehicles[editVehicle.index]?.vehicleModel?._id
                  // );
                  // setValueinForm(
                  //   "vehicleModelTrimId",
                  //   vehicles[editVehicle.index]?.vehicleModelTrim?._id
                  // );

                  // setMakeValue(vehicles[editVehicle.index]?.vehicleMake?._id);
                  // setModelValue(vehicles[editVehicle.index]?.vehicleModel?._id);
                  // setTrimValue(
                  //   vehicles[editVehicle.index]?.vehicleModelTrim?._id
                  // );

                  // setValueinForm(
                  //   "licenseNumber",
                  //   vehicles[editVehicle.index]?.licenseNumber
                  // );
                }}
              >
                EDIT
              </Button>
            </Stack>
            {/* )} */}
          </Container>
        </Box>
      </Modal>
      <VehicleModel
        open={open}
        handleClose={handleClose}
        id={id}
        fetchCustomerVehicle={fetchCustomerVehicle}
        editVehicle={editVehicle}
        setEditVehicle={setEditVehicle}
        importedVehicleId={importedVehicleId}
        setImportedVehicleId={setImportedVehicleId}
        setDeleteVehicleId={setDeleteVehicleId}
        setOpenVehicleDelete={setOpenVehicleDelete}
      />
      <AppointmentModel
        open={openAppointmentDetailModal}
        handleClose={handleCloseAppointmentDetailModal}
        data={appointmentDetailData}
        status={appointmentDetailData?.status}
        setUpdateStatus={() => {}}
        setAppointmentDropOffODo={() => {}}
        setAppointmentCompletionOffODo={() => {}}
        setSelectId={() => {}}
        recallDetailsAPI={() => {
          getAppointmentDetails(appointmentDetailData?._id);
        }}
      />

      {appointmentDetailData && (
        <AppointmentRequestModal
          open={openAppReqModal}
          handleClose={() => setOpenAppReqModal(false)}
          appointmentData={appointmentDetailData}
          id={appointmentDetailData._id}
          handleCancelAppointment={() => setOpenAppReqModal(false)}
          apiCall={getAppointmentDetails}
        />
      )}

      {/* <AppointmentRescheduleRequestModal
      // open={rescheduledRequestOpen}
      // setOpen={setRescheduledRequestOpen}
      // appointmentData={appointment}
      // id={appointmentId}
      // apiCall={dashboardDetails}
      /> */}
      <ViewUpcomingModal
        open={showUpcomingModal}
        handleClose={handleCloseUpComing}
        upcomingData={appointmentDetailData}
        handleCancelModel={handleCloseUpComing}
        recallDetailsAPI={() => {}}
      />
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        alertType={successMessageType}
      />
      <ConfirmCustomersInfoModel
        open={openCustomerInfo}
        handleClose={() => setOpenCustomerInfo(false)}
        // handleClose ={}
        customerData={importedCustomerData}
        id={importedCustomerData?._id}
        // vehicalDetailFunction={}
        setAddCustomerInfoButton={setAddCustomerInfoButton}
        setCustomerData={setImportedCustomerData}
        convertedCustomerId={convertedCustomerId}
        setCustomerConvertedId={setCustomerConvertedId}
        madeFromCustomerProfile={true}
        setOpenDelete={setOpenDelete}
        // setCustomerTypeData={}
        // state={}
        // setState={}
        // setAddVehicleInfoButton={}
        // data={}
        // vehicleInfo={}
      />
      <CarfaxHistoryModel
        open={openCarfaxHistory}
        handleClose={handleCloseCarfaxHistory}
        vehicleData={viewVehicleData}
      />
      <Dialog open={appointmentModalLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Profile;
