import { useTheme } from "@emotion/react";
import { Add } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { estimateDetails } from "../../redux/Estimate/action";
import SelectVehicleModal from "../modal/SelectVehicleModal";
import "./Estimates.css";

import {
  ESTIMATE_INVOICE_DRAFT,
  ESTIMATE_INVOICE_SENT,
  ESTIMATE_REQUEST_COLOR,
} from "../../App";
import { requestEstimateDetails } from "../../redux/Dashboard/action";
import EstimateRequestModal from "../modal/EstimateRequestModal";

//Estimate Request Pink

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Estimates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [estimateData, setEstimateData] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [type, setType] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [yearData, setYearData] = React.useState("");
  const [makeData, setMakeData] = React.useState("");
  const [modelData, setModelData] = React.useState("");
  const [open2, setOpen2] = React.useState(false);
  const [estimateId, setEstimateId] = React.useState("");
  const [estimateDetail, setEstimateDetail] = React.useState("");

  const [sortName, setSortName] = React.useState("ASC");
  const [sortCategory, setSortCategory] = React.useState("ASC");
  const [sortStatus, setSortStatus] = React.useState("DSC");
  const [sortByName, setSortByName] = React.useState("createdAt");

  const [selectDate, setSelectDate] = React.useState(null);
  const [dateData, setDateData] = React.useState("");
  const theme = useTheme();
  const [status, setStatus] = useState("all");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const params = {
    page: page + 1,
    limit: rowsPerPage,
    status: status,
    sortType: sortStatus,
    sortBy: sortByName,
    text: search,
    type,
    year: yearData,
    makeId: makeData,
    modelId: modelData,
    date: dateData,
  };

  const handleClose2 = () => {
    setOpen2(false);
    params.status = "requests";
    estimateDetailsData(params);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    params.page = newPage + 1;

    estimateDetailsData(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    params.limit = parseInt(event.target.value, 10);
    estimateDetailsData(params);
    // estimateDetailsData(1, parseInt(event.target.value, 10), "all", sortStatus, sortByName, search, type, yearData, makeData, modelData, dateData);
  };

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const nameArray = window.location.pathname.split("/");
    const name = nameArray[nameArray.length - 1];
    setPage(0);
    params.page = 1;
    params.limit = 10;
    setRowsPerPage(10);
    if (name == "all") {
      setValue(0);
      setStatus("all");
    }
    if (name == "sents") {
      setValue(1);
      setStatus("sents");
    }
    if (name == "drafts") {
      setValue(2);
      setStatus("drafts");
    }
    if (name == "requests") {
      setValue(3);
      setStatus("requests");
    }
    estimateDetailsData(params);
  }, [window.location.pathname]);
  const estimate = useSelector((state) => state.estimate.estimateData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
    params.page = 1;
    params.limit = 10;
    if (newValue == 0) {
      setStatus("all");

      // estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, type, yearData, makeData, modelData, dateData);
      navigate("/estimates/all");
    }
    if (newValue == 1) {
      setStatus("sent");
      // estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "sent", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
      navigate("/estimates/sents");
    }
    if (newValue == 2) {
      setStatus("draft");
      // estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "draft", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
      navigate("/estimates/drafts");
    }
    if (newValue == 3) {
      setStatus("requests");
      params.status = "requests";
      // estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "requests", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
      navigate("/estimates/requests");
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (yearData || makeData || modelData) {
      params.status = "all";
      estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, type, yearData, makeData, modelData, dateData);
    }
    setOpen(false);
  };
  const handleCancel = () => {
    params.status = "all";
    estimateDetailsData(params);
    // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, type, "", "", "", dateData);
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const estimateDetailsData = async (params) => {
    setShowLoader(true);
    let data = await dispatch(estimateDetails(params));
    if (data) {
      setEstimateData(data);
      setShowLoader(false);
    }
  };

  const index = [0, 1, 2, 3];

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  const onChange = async (e, values, model, year, make) => {
    setSearch(e);
    params.text = e;
    params.year = year;
    params.makeId = make;
    params.modelId = model;

    // estimateDetailsData(params);
    estimateDetailsData(params);
    // estimateDetailsData(1, rowsPerPage, "all", "DSC", sortByName, e, type, yearData, makeData, modelData, dateData);
  };

  const debounceOnChange = React.useCallback(debounce(onChange, 800), []);

  const [state, setState] = React.useState({
    EST: false,
    ESTR: false,
  });

  const handleCheckChange = (event) => {
    if (event.target.name == "EST") {
      if (event.target.checked) {
        if (state["ESTR"]) {
          params.type = "";
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
        } else {
          params.type = event.target.name;
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, event.target.name, yearData, makeData, modelData, dateData);
        }
      } else {
        if (state["ESTR"]) {
          params.type = "ESTR";
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, "ESTR", yearData, makeData, modelData, dateData);
        } else {
          params.type = "";
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
        }
      }
    }
    if (event.target.name == "ESTR")
      if (event.target.checked) {
        if (state["EST"]) {
          params.type = "";
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
        } else {
          params.type = event.target.name;
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, event.target.name, yearData, makeData, modelData, dateData);
        }
      } else {
        if (state["EST"]) {
          params.type = "EST";
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, "EST", yearData, makeData, modelData, dateData);
        } else {
          params.type = "";
          estimateDetailsData(params);
          // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, "", yearData, makeData, modelData, dateData);
        }
      }

    handleClose1();
    setType(event.target.name);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSortByName = (status, sortBy) => {
    setSortStatus(status);
    params.sortBy = sortBy;
    params.sortType = status;
    if (value == 0) {
      params.status = "all";
      estimateDetailsData(params);
      // estimateDetailsData(page + 1, rowsPerPage, "all", status, sortBy, search, type, yearData, makeData, modelData, dateData);
    }
    if (value == 1) {
      params.status = "sent";
      estimateDetailsData(params);
      // estimateDetailsData(page + 1, rowsPerPage, "sent", status, sortBy, search, type, yearData, makeData, modelData, dateData);
    }
    if (value == 2) {
      params.status = "draft";
      estimateDetailsData(params);
      // estimateDetailsData(page + 1, rowsPerPage, "draft", status, sortBy, search, type, yearData, makeData, modelData, dateData);
    }
    if (value == 3) {
      params.status = "requests";
      estimateDetailsData(params);
      // estimateDetailsData(page + 1, rowsPerPage, "requests", status, sortBy, search, type, yearData, makeData, modelData, dateData);
    }
    if (sortBy == "name") {
      if (status == "DSC") {
        setSortName("ASC");
      } else {
        setSortName("DSC");
      }
      setSortCategory(sortCategory);
    } else {
      if (status == "DSC") {
        setSortCategory("ASC");
      } else {
        setSortCategory("DSC");
      }
      setSortName(sortName);
    }
  };

  const handleDateChange = (date) => {
    if (date != null) {
      const newDate = new Date(date);
      let utcDate = moment(newDate).format("YYYY-MM-DD");
      setDateData(utcDate);
      params.date = utcDate;
      estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, type, yearData, makeData, modelData, utcDate);
    } else {
      params.date = "";
      estimateDetailsData(params);
      // estimateDetailsData(1, rowsPerPage, "all", sortStatus, sortByName, search, type, yearData, makeData, modelData, "");
    }
    setDateData("");
  };

  useEffect(() => {
    if (estimateId) {
      estimateView();
    }
  }, [estimateId]);

  const estimateView = async () => {
    if (estimateId) {
      setShowLoader(true);
      const data = await dispatch(requestEstimateDetails(estimateId));
      if (data) {
        setShowLoader(false);
        setEstimateDetail(data);
        setEstimateId("");
      }
    }
  };

  return (
    <>
      <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
        <Grid container rowGap={2} paddingBottom={"24px"}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={9}>
              <Typography variant="h4" sx={{ fontWeight: 400 }}>
                Estimates{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Add />}
                size="large"
                onClick={() => {
                  navigate("/estimates/new");
                }}
              >
                NEW ESTIMATE
              </Button>
            </Grid>
          </Grid>
          <>
            <Grid
              container
              item
              // gap={2}
              margin={"12px 0"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item container alignItems={"center"}>
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="ALL"
                        {...a11yProps(0)}
                        style={{ fontWeight: 550 }}
                      />
                      <Tab
                        label="Sent"
                        {...a11yProps(1)}
                        style={{ fontWeight: 550 }}
                      />
                      <Tab
                        label="drafts"
                        {...a11yProps(2)}
                        style={{ fontWeight: 550 }}
                      />
                      <Tab
                        label="estimate requests"
                        {...a11yProps(3)}
                        style={{ fontWeight: 550 }}
                      />
                    </Tabs>
                  </Box>

                  {index.map((item) => (
                    <CustomTabPanel value={value} index={item} padding={"24px"}>
                      <Grid container item>
                        <Grid
                          container
                          marginBottom={"32px"}
                          alignItems={"center"}
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            sm={5}
                            md={3}
                            lg={3}
                            xl={2}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                // label="Select date"
                                name="proposeDate"
                                value={selectDate}
                                onChange={(data) => {
                                  if (data) {
                                    // const formattedDate = new Date(data);
                                    setSelectDate(data);
                                    handleDateChange(data);
                                  } else {
                                    setSelectDate(null);
                                    handleDateChange(data);
                                  }
                                }}
                                style={{ width: "100%" }}
                                slotProps={{ field: { clearable: true } }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            xl={2}
                            justifyContent={"center"}
                          >
                            <Button
                              variant="text"
                              color="primary"
                              style={{ color: "black", fontWeight: 550 }}
                              endIcon={<ExpandMoreIcon />}
                              onClick={handleOpen}
                            >
                              SELECT VEHICLE
                            </Button>
                            <SelectVehicleModal
                              handleClose={handleClose}
                              open={open}
                              cancel={handleCancel}
                              setYearData={setYearData}
                              setMakeData={setMakeData}
                              setModelData={setModelData}
                              yearData={yearData}
                              makeData={makeData}
                              modelData={modelData}
                            />
                          </Grid>
                          {value == 0 && (
                            <Grid
                              item
                              container
                              xs={6}
                              sm={3}
                              md={2}
                              lg={2}
                              xl={2}
                              justifyContent={"center"}
                            >
                              <Button
                                variant="text"
                                id="demo-positioned-button"
                                aria-controls={
                                  open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick1}
                                style={{ color: "black", fontWeight: 550 }}
                                endIcon={<ExpandMoreIcon />}
                              >
                                SELECT TYPE
                              </Button>
                              <Menu
                                style={{ width: "300px" }}
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem>
                                  {/* {" "}
                                                                    <CheckBoxOutlineBlank style={{ marginRight: "10px" }} /> Estimate (EST) */}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.EST}
                                        onChange={handleCheckChange}
                                        name="EST"
                                      />
                                    }
                                    label="Estimate (EST)"
                                  />
                                </MenuItem>
                                <MenuItem>
                                  {" "}
                                  {/* <CheckBoxOutlineBlank style={{ marginRight: "10px" }} /> Estimate Request (ESTR) */}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.ESTR}
                                        onChange={handleCheckChange}
                                        name="ESTR"
                                      />
                                    }
                                    label="Estimate Request (ESTR)"
                                  />
                                </MenuItem>
                              </Menu>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={5}
                            xl={6}
                            padding={"6px"}
                          >
                            <FormControl fullWidth>
                              <InputLabel htmlFor="outlined-adornment-search">
                                Search
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-search"
                                placeholder="Customer name, service, etc..."
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                }
                                label="Search"
                                onChange={(e) =>
                                  debounceOnChange(
                                    e.target.value,
                                    value,
                                    modelData,
                                    yearData,
                                    makeData
                                  )
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          padding={"20px"}
                          boxShadow={2}
                          borderRadius={"8px"}
                          backgroundColor="white"
                        >
                          <Grid item xl={12} md={12} sm={12} xs={12}>
                            <TableContainer sx={{ maxHeight: 600 }}>
                              <Table
                                stickyHeader
                                aria-label="caption table"
                                padding="16px"
                                style={{ boxShadow: 0 }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={
                                          sortCategory == "DSC" ? "desc" : "asc"
                                        }
                                        sx={{ fontWeight: 500 }}
                                        onClick={() => {
                                          setSortByName("createdAt");
                                          handleSortByName(
                                            sortCategory == "DSC"
                                              ? "DSC"
                                              : "ASC",
                                            "createdAt"
                                          );
                                        }}
                                      >
                                        Estimate Number
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        onClick={() => {
                                          setSortByName("name");
                                          handleSortByName(
                                            sortName == "DSC" ? "DSC" : "ASC",
                                            "name"
                                          );
                                        }}
                                        active={true}
                                        direction={
                                          sortName == "DSC" ? "desc" : "asc"
                                        }
                                        sx={{ fontWeight: 500 }}
                                      >
                                        Customer Name
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={
                                          sortCategory == "DSC" ? "desc" : "asc"
                                        }
                                        sx={{ fontWeight: 500 }}
                                        onClick={() => {
                                          setSortByName("createdAt");
                                          handleSortByName(
                                            sortCategory == "DSC"
                                              ? "DSC"
                                              : "ASC",
                                            "createdAt"
                                          );
                                        }}
                                      >
                                        Date
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      {" "}
                                      Status
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      {" "}
                                      Actions
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {estimateData &&
                                    estimateData?.map((row) => (
                                      <TableRow
                                        key={row.name}
                                        hover={true}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (row?.status == "request") {
                                            handleOpen2();
                                            setEstimateId(row?._id);
                                          } else {
                                            navigate(
                                              `/estimates/${row?.status}/${row?._id}`
                                            );
                                          }
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {row?.estimateNumber}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row?.customer?.firstName &&
                                            row?.customer?.firstName
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                              row?.customer?.firstName?.slice(
                                                1
                                              )}{" "}
                                          {row?.customer?.lastName &&
                                            row?.customer?.lastName
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                              row?.customer?.lastName?.slice(1)}
                                        </TableCell>
                                        <TableCell align="left">
                                          {" "}
                                          {row?.estimateDate &&
                                            moment(row?.estimateDate).format(
                                              "MMM D, YYYY"
                                            )}
                                        </TableCell>
                                        <TableCell align="left">
                                          <Chip
                                            label={
                                              row?.status == "decline"
                                                ? "Declined"
                                                : row?.status == "accept"
                                                ? "Accepted"
                                                : row?.status
                                                    ?.charAt(0)
                                                    .toUpperCase() +
                                                  row?.status?.slice(1)
                                            }
                                            style={{
                                              backgroundColor:
                                                row?.status == "sent"
                                                  ? ESTIMATE_INVOICE_SENT
                                                  : row?.status == "draft"
                                                  ? ESTIMATE_INVOICE_DRAFT
                                                  : ESTIMATE_REQUEST_COLOR,
                                              color: "black",
                                              fontWeight: "500",
                                            }}
                                          />
                                        </TableCell>

                                        <TableCell align="left">
                                          {row?.status && (
                                            <>
                                              <Button
                                                variant="text"
                                                color="primary"
                                              >
                                                View Estimate
                                              </Button>
                                            </>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <EstimateRequestModal
                              open={open2}
                              handleCancel={handleClose2}
                              estimateData={estimateDetail}
                              id={estimateId}
                              handleClose={handleClose2}
                            />
                            <Grid
                              container
                              item
                              alignItems={"center"}
                              justifyContent="end"
                            >
                              <Grid item>
                                {showLoader ? (
                                  ""
                                ) : (
                                  <>
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      colSpan={3}
                                      count={estimate?.totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      slotProps={{
                                        select: {
                                          "aria-label": "rows per page",
                                        },
                                        actions: {
                                          showFirstButton: true,
                                          showLastButton: true,
                                        },
                                      }}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CustomTabPanel>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Estimates;
