export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber) {
    phoneNumber = phoneNumber?.replace(/[-\s]/g, "");

    // Add dashes at appropriate positions
    phoneNumber = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return phoneNumber;
}
export const removeDashFromPhoneNumber = (phoneNumber) => {
  return phoneNumber?.replace(/[-\s]/g, "");
};
export function inputFormatPhoneNumber(phoneNumber) {
  let inputValue;
  if (phoneNumber) {
    inputValue = phoneNumber.replace(/-/g, "");
    if (inputValue.length > 3 && inputValue.length <= 6) {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
    } else if (inputValue.length > 6) {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6)}`;
    } else {
      inputValue = inputValue;
    }
    return inputValue;
  } else return "";
}
