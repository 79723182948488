import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";
import { paramsToQueryString } from "../../../utility/helpers/commonFunction";

export const getServiceCategory = (limit) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.account.shopServices.getServiceCategory}?limit=${limit}`
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.shopServices.getServiceCategory,
        getServiceCategory: response.data.data.list,
      });
      return response.data.data.list;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getServiceSubCategory = (limit, category) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.account.shopServices.getServiceSubCategory}?limit=${limit}&serviceCategory=${category}`
    );
    if (!response.data.error) {
      return response.data.data.list;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const createServiceSubcategory = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.shopServices.create,
        payload
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const createServiceCategory = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.shopServices.createCategory,
        payload
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const createServiceSubcategoryAccount = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.shopServices.accountCreate,
        payload
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const addShopService = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.shopServices.addShopService,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateShopService = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.shopServices.updateShopService}/${id}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const deleteServices = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.shopServices.deleteService}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const getAllShopServiceApi = (params) => async (dispatch) => {
  try {
    let queryString = paramsToQueryString(params);
    const response = await DataService.get(
      API.account.shopServices.shopServicesListing + queryString
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.shopServices.shopServicesListing,
        shopServicesListing: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const getShopServicesListing =
  (
    limit,
    page,
    searchValue,
    selectedIds,
    selectedPriceType,
    sortedType,
    sortBy,
    selectedVisibility
  ) =>
  async (dispatch) => {
    try {
      // Initialize query parameters
      const queryParams = [];

      // Add limit and page
      queryParams.push(`limit=${limit}`, `page=${page}`);

      // Add searchValue if it exists
      if (searchValue) {
        queryParams.push(`searchText=${searchValue}`);
      }

      // Add selectedIds if it has elements
      if (selectedIds.length > 0) {
        queryParams.push(
          `serviceCategoryId=${selectedIds.join("&serviceCategoryId=")}`
        );
      }

      // Add selectedPriceType if it has elements
      if (selectedPriceType.length > 0) {
        queryParams.push(`priceType=${selectedPriceType.join("&priceType=")}`);
      }

      // Handle selectedVisibility based on its length
      if (selectedVisibility.length === 1) {
        queryParams.push(
          `showShopServices=${selectedVisibility[0] === "yes" ? true : false}`
        );
      }

      // Add sortedType if it exists
      if (sortedType) {
        queryParams.push(`sortType=${sortedType}`);
      }

      // Add sortBy if it exists
      if (sortBy) {
        queryParams.push(`sortBy=${sortBy}`);
      }

      // Construct the final URL
      const url = `${
        API.account.shopServices.shopServicesListing
      }?${queryParams.join("&")}`;

      // Make the API call
      const response = await DataService.get(url);

      // Dispatch the response if there's no error
      if (!response.data.error) {
        dispatch({
          type: API.account.shopServices.shopServicesListing,
          shopServicesListing: response.data,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
export const updateShopServiceStatus = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.shopServices.updateShopServiceStatus}/${id}`,
        payload
      );

      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
