import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

// Personal details fetch
export const getSettingApi = ({ params }) => {
  console.log("🚀 ~ getSettingApi ~ params:", params);
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.settings.get);
      console.log(
        "🚀 ~ return ~ response:",
        response?.data?.data.defaultTaxInPercentage
      );
      if (!response.data.error) {
        dispatch({
          type: API.settings.get,
          settingData: response.data?.data,
          defaultTaxInPercentage:
            response?.data?.data?.defaultTaxInPercentage ?? null,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
