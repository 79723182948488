export const PHONE_TYPES = [
  {
    key: "mobile",
    value: "Mobile",
  },
  {
    key: "work",
    value: "Work",
  },
  {
    key: "home",
    value: "Home",
  },
  {
    key: "landline",
    value: "Landline",
  },
];

export const SHOP_SERVICE_PRICE_TYPES = {
  FIXED: "fixed",
  RANGE: "range",
  NO_PRICE: "no_price",
  STARTING_FROM: "starting_from",
};

export const CONTACT_PREFRENCES = [
  {
    key: "callOnPhone",
    value: "Phone Call",
  },
  {
    key: "inAppMessages",
    value: "In-App Message",
  },
  {
    key: "email",
    value: "Email",
  },
];
export const APPOINTMENT_CONTACT_PREFRENCES = [
  {
    key: "phone",
    value: "Phone Call",
  },
  {
    key: "message",
    value: "In-App Message",
  },
  {
    key: "email",
    value: "Email",
  },
];
export const APPOINTMENT_REQUEST_GENERATED_PLATFORM = {
  CUSTOMER_APP: "App Booking",
  ONLINE_WEB: "Online Booking",
};
export const USER_CREATED_FROM = {
  SHOP_CREATED: "shop_created",
  SHOP_IMPORTED: "shop_imported",
  SHOP_GUEST: "shop_guest",
  APEX_AUTO_APP: "apex_auto_app",
};
export const PAYMENT_TERMS = [
  {
    key: "none",
    value: "None",
  },
  {
    key: "30Days",
    value: "30 Days",
  },
  {
    key: "cashOnDelivery",
    value: "Cash on Delivery",
  },
  {
    key: "7Days",
    value: "7 Days",
  },
  {
    key: "14Days",
    value: "14 Days",
  },
  {
    key: "21Days",
    value: "21 Days",
  },
  {
    key: "20Days",
    value: "20 Days",
  },
  {
    key: "20DaysEndOfMonth",
    value: "20 Days (After end of month) ",
  },
  {
    key: "30DaysEndOfMonth",
    value: "30 Days (After end of month) ",
  },
  {
    key: "7DaysEndOfMonth",
    value: "7 Days (After end of month) ",
  },
];
export const PAYMENT_STATUS = [
  {
    key: "paidInFull",
    value: "Paid In Full",
  },
  {
    key: "partialPayment",
    value: "Partial Payment",
  },
  {
    key: "open",
    value: "Open",
  },
];

export const APPOINTMENT_REVIEW_PLATFORM = {
  APEX_AUTO: "apex_auto",
  GOOGLE: "google",
};
