import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { REQUIRED_RED } from "../../App";
import {
  FormatNumberForKm,
  preventPhoneInputKeys,
} from "../../components/FormatNumber/FormatNumber";
import {
  formatNumberWithCommas,
  handlePreventKeyDown,
  handlePreventKeyDownDecimal,
  parseNumberFromCommas,
} from "../../utility/helpers/commonFunction";
import { validateNumberPositive } from "../Common/validateNumberPositive";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 3,
};

const ServiceAddPartModal = ({
  open,

  setPartsOpen,
  setPartsData,
  partsData,
  setEditPartData,
  editPartData,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm();
  const modelRef = useRef();
  const [againScroll, setAgainScroll] = useState(false);
  const handleClosePartsModal = () => {
    setPartsOpen(false);
    setEditPartData({ edit: false, index: "" });
    reset();
  };

  const valueReset = () => {
    handleClosePartsModal();
  };
  const scrollModel = (errors) => {
    if (errors.hasOwnProperty("partsProvider")) {
      if (modelRef.current) {
        modelRef.current.scrollTo({
          top: modelRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (againScroll) {
      if (modelRef.current) {
        modelRef.current.scrollTo({
          top: modelRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [againScroll]);
  const onSubmit = async (value) => {
    clearErrors();
    value.cost = value.cost?.toString()?.replace(/,/g, "");
    value.totalAmount = value.totalAmount?.toString()?.replace(/,/g, "");

    if (editPartData?.edit) {
      let data = [...partsData];
      data[editPartData?.index] = value;
      setPartsData(data);
      setEditPartData({ edit: false, index: "" });
    } else {
      setPartsData([...partsData, value]);
    }

    reset();
    valueReset();
  };
  const editParts = (idx) => {
    let data = partsData[idx];

    const formattedPartsProvider = (data.partsProvider ?? "")
      .replace(/\s+/g, "_")
      .toLowerCase()
      .trim();

    setValue("partName", data?.partName);
    setValue("partNumber", data?.partNumber);
    setValue("description", data?.description);
    setValue("qty", data?.qty);
    setValue("cost", FormatNumberForKm(data?.cost));
    setValue("totalAmount", FormatNumberForKm(data?.totalAmount));
    setValue("partsProvider", formattedPartsProvider);
  };
  useEffect(() => {
    if (editPartData?.edit) {
      editParts(editPartData.index);
    }
  }, [editPartData]);

  const handleDeletePart = () => {
    const newdata = [...partsData];
    let newArray = [
      ...newdata.slice(0, editPartData.index),
      ...newdata.slice(editPartData.index + 1),
    ];
    setPartsData(newArray);
    handleClosePartsModal();
  };

  const validateNonNegative = (value) => {
    if (value < 0) {
      return "Number must not be negative";
    }
    return true;
  };
  function validateNumber(e) {
    const pattern = /^[0-9]$/;

    // return pattern.test(e.key);
    if (pattern.test(e.key)) {
      return pattern.test(e.key);
    } else return "This field accept only positive Number.";
  }

  const calculateTotalAmount = () => {
    const qtyValue = parseNumberFromCommas(getValues().qty);
    const costValue = parseNumberFromCommas(getValues().cost);

    if (qtyValue && costValue) {
      const totalAmount = parseFloat(qtyValue) * parseFloat(costValue);
      setValue("totalAmount", formatNumberWithCommas(totalAmount.toFixed(2)));
    } else {
      setValue("totalAmount", "");
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClosePartsModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{ ...style, width: "600px", border: "none" }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            variant="elevation"
            sx={{
              height: "64px",
              color: "black",
              bgcolor: "white",
              paddingRight: "0",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"}>
                {editPartData?.edit ? "Edit Part" : "Add Part"}
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    valueReset();
                  }}
                  style={{ cursor: "pointer", color: "black", height: "32px" }}
                >
                  <CloseIcon style={{ fill: "#000000DE", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            disableGutters
            style={{
              marginTop: "64px",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{
                maxHeight: "70vh",
                scrollbarColor: "blue",
                scrollbarWidth: "thin",
                overflowY: "auto",
                padding: "24px 24px 0 24px",
              }}
              ref={modelRef}
            >
              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  {/*<Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Part Name
                                    </Typography>*/}
                  <Typography variant="body1" className="label-500">
                    Part Name <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>

                  <Controller
                    name="partName"
                    control={control}
                    rules={{
                      required: " Part Name is required",
                    }}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.partName}
                          helperText={
                            errors.partName ? errors.partName.message : ""
                          }
                          {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  {/*} <Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Part Number
                                    </Typography>*/}
                  <Typography variant="body1" className="label-500">
                    Part Number
                  </Typography>
                  <Controller
                    name="partNumber"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.partNumber}
                          helperText={
                            errors.partNumber ? errors.partNumber.message : ""
                          }
                          {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  {/*} <Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Description
                                    </Typography>*/}
                  <Typography variant="body1" className="label-500">
                    Description
                  </Typography>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.description}
                          helperText={
                            errors.description ? errors.description.message : ""
                          }
                          {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  {/*} <Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Quantity
                                    </Typography>*/}
                  <Typography variant="body1" className="label-500">
                    Quantity <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>

                  <Controller
                    name="qty"
                    control={control}
                    rules={{
                      required: "Quantity is required",
                    }}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          // type="number"
                          value={formatNumberWithCommas(field.value)} // Ensure the TextField value is controlled by the field value
                          error={!!errors.qty}
                          onChange={(event) => {
                            const value = event.target.value;
                            const numericValue = parseNumberFromCommas(value);
                            setValue("qty", numericValue);
                            calculateTotalAmount();
                          }}
                          onKeyDown={(e) => {
                            preventPhoneInputKeys(e);
                          }}
                          helperText={errors.qty ? errors.qty.message : ""}
                          // {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  <Typography variant="body1" className="label-500">
                    Cost <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>

                  <Controller
                    name="cost"
                    control={control}
                    rules={{
                      required: " Cost is required",
                      validate: (value) => {
                        // Check if the value is a valid positive number and not just a "."
                        if (value === ".") {
                          return "Cost cannot be just a decimal point.";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.cost}
                          value={formatNumberWithCommas(field.value)} // Ensure the TextField value is controlled by the field value
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            const numericValue =
                              parseNumberFromCommas(inputValue);
                            setValue("cost", numericValue);
                            calculateTotalAmount();
                          }}
                          onKeyDown={handlePreventKeyDownDecimal}
                          helperText={errors.cost ? errors.cost.message : ""}
                          // {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  <Typography variant="body1" className="label-500">
                    Total Amount
                  </Typography>

                  <Controller
                    name="totalAmount"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          // value={qty * cost}
                          variant="outlined"
                          // type="number"
                          error={!!errors.totalAmount}
                          helperText={
                            errors.totalAmount ? errors.totalAmount.message : ""
                          }
                          {...field}
                          disabled
                          sx={{
                            backgroundColor: "#eaeaea",

                            border: 0,
                          }}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} xl={12}>
                <Stack spacing={0.5}>
                  <Typography variant="body1" className="label-500">
                    Parts Provider{" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <Controller
                    name="partsProvider"
                    control={control}
                    rules={{
                      required: "Parts Provider is required",
                    }}
                    render={({ field }) => (
                      <>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          {...field}
                          error={!!errors.partsProvider}
                          helperText={
                            errors.partsProvider
                              ? errors.partsProvider.message
                              : ""
                          }
                          style={{ paddingLeft: "10px" }}
                        >
                          <FormControlLabel
                            value="oem"
                            control={<Radio />}
                            label="OEM"
                          />
                          <FormControlLabel
                            value="after_market"
                            control={<Radio />}
                            label="After Market"
                          />
                          <FormControlLabel
                            value="used"
                            control={<Radio />}
                            label="Used"
                          />
                        </RadioGroup>
                        <FormHelperText error>
                          {errors.partsProvider && errors.partsProvider.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid item container justifyContent={"space-between"} p={3}>
              <div>
                <Stack direction="row">
                  {editPartData?.edit && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleDeletePart}
                    >
                      REMOVE PART
                    </Button>
                  )}
                </Stack>
              </div>

              <div>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      valueReset();
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    onClick={(e) => scrollModel(errors)}
                  >
                    {editPartData?.edit ? "SAVE PART" : "SAVE"}
                  </Button>
                </Stack>
              </div>
            </Grid>
          </Container>
        </Box>
      </form>
    </Modal>
  );
};

export default ServiceAddPartModal;
