import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import UserTag from "../../../components/UserTag";
import {
  APPOINTMENT_REQUEST_GENERATED_PLATFORM,
  USER_CREATED_FROM,
} from "../../../utility/Constant";
import { inputFormatPhoneNumber } from "../../Common/FormatPhoneNumber";
import ConvertCustomerModal from "./ConvertCustomerModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 3,
};

const GuestCustomerInfoModal = ({
  userData,
  openGuestModal,
  setOpenGuestModal,
  showBtn = false,
  showTag = false,
  fetchAppointmentDetails,
}) => {
  const [openConvertModal, setOpenConvertModal] = useState(false);
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);

  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
  });
  const handleCloseCustomer = (resp) => {
    setOpenConvertModal(false);
    if (resp?._id) {
      setOpenGuestModal(false);
      fetchAppointmentDetails();
    }
  };

  return (
    <div>
      <Modal
        open={openGuestModal}
        onClose={() => {
          setOpenGuestModal(false);
        }}
        // onClose={(event, reason) => {
        //   if (reason === "backdropClick") {
        //     // Ignore clicks outside the modal
        //     return;
        //   }
        //   setOpenGuestModal(false);
        // }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, border: "none", borderRadius: "8px 8px 0px 0px" }}
          className="modalBodyBoxContainer"
        >
          {" "}
          <AppBar
            variant="elevation"
            sx={{
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
              background: "#fff",
              borderBottom: "1px solid #C4C4C4",
              boxShadow: "none",
            }}
          >
            <Toolbar variant="regular">
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box
                  width={"80%"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  gap={1.5}
                >
                  <Typography variant="h6" gutterBottom={false} color={"#000"}>
                    Customer Details
                  </Typography>
                  {showTag && (
                    <UserTag
                      type={
                        userData?.customer?.createdFrom ===
                        USER_CREATED_FROM.SHOP_GUEST
                          ? 1
                          : userData?.createdPlatform ===
                            APPOINTMENT_REQUEST_GENERATED_PLATFORM.ONLINE_WEB
                          ? 2
                          : userData?.createdPlatform ===
                            APPOINTMENT_REQUEST_GENERATED_PLATFORM.CUSTOMER_APP
                          ? 3
                          : 3
                      }
                    />
                  )}
                </Box>

                <Stack
                  direction={"row"}
                  spacing={0}
                  onClick={() => {
                    setOpenGuestModal(false);
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon sx={{ fill: "black", fontSize: 32 }} />
                </Stack>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            disableGutters
            sx={{
              mt: "64px",
              padding: "24px",
            }}
          >
            <Typography
              gutterBottom={false}
              sx={{
                fontWeight: "500",
                letterSpacing: "0.15px",
                marginBottom: "8px",
              }}
            >
              Customer Information
            </Typography>
            <Stack
              sx={{
                padding: "16px",
                borderRadius: "4px",
                border: "1px solid #c4c4c4",
              }}
              gap={2}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom={false}>Name</Typography>
                <Typography gutterBottom={false}>
                  {userData?.customer?.firstName +
                    " " +
                    userData?.customer?.lastName}
                </Typography>
              </Stack>

              <Divider />

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom={false}>Email</Typography>
                <Typography gutterBottom={false}>
                  {userData?.customer?.email ?? "-"}
                </Typography>
              </Stack>

              <Divider />

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom={false}>Phone Number</Typography>
                <Typography gutterBottom={false}>
                  {" "}
                  {userData?.customer?.phoneNumber
                    ? inputFormatPhoneNumber(userData?.customer?.phoneNumber)
                    : "-"}
                </Typography>
              </Stack>
            </Stack>

            <Typography
              gutterBottom={false}
              sx={{
                fontWeight: "500",
                letterSpacing: "0.15px",
                marginBottom: "8px",
                marginTop: "24px",
              }}
            >
              Vehicle Information
            </Typography>
            <Stack
              sx={{
                padding: "16px",
                borderRadius: "4px",
                border: "1px solid #c4c4c4",
              }}
              gap={2}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom={false}>Vehicle</Typography>
                <Typography gutterBottom={false}>
                  {userData?.vehicle?.year ? userData?.vehicle?.year + " " : ""}
                  {userData?.vehicle?.vehicleMake?.name
                    ? userData?.vehicle?.vehicleMake?.name + " "
                    : ""}
                  {userData?.vehicle?.vehicleModel?.name
                    ? userData?.vehicle?.vehicleModel?.name + " "
                    : ""}
                </Typography>
              </Stack>

              <Divider />

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom={false}>Trim</Typography>
                <Typography gutterBottom={false}>
                  {userData?.vehicle?.vehicleModelTrim?.name ?? "-"}
                </Typography>
              </Stack>

              <Divider />

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom={false}>Licence Plate</Typography>
                <Typography gutterBottom={false}>
                  {userData?.vehicle?.licenseNumber ?? "-"}
                </Typography>
              </Stack>
            </Stack>
            {showBtn && (
              <Stack direction={"row"} justifyContent={"flex-end"} mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenConvertModal(true)}
                >
                  Convert to CUSTOMER
                </Button>
              </Stack>
            )}
          </Container>
        </Box>
      </Modal>

      {showBtn && (
        <ConvertCustomerModal
          showBtn={showBtn}
          userId={userData}
          openConvertModal={openConvertModal}
          handleCloseCustomer={handleCloseCustomer}
          addressValues={addressValues}
          setAddressValue={setAddressValue}
          primaryEmailIndex={primaryEmailIndex}
          setPrimaryEmailIndex={setPrimaryEmailIndex}
          setOpenDelete={() => {}}
        />
      )}
    </div>
  );
};

export default GuestCustomerInfoModal;
