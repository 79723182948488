import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select as MuiSelect,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { REQUIRED_RED } from "../../../App";
import PostalCode from "../../../components/Address/PostalCode";
import {
  formatPostalCode,
  preventPhoneInputKeys,
} from "../../../components/FormatNumber/FormatNumber";
import { SHOP_CREATED } from "../../../config/dataService/constants";
import { getCountryMasterListApi } from "../../../redux/Account/peronalIfno/action";
import { discountProgramApi } from "../../../redux/Account/Pricing/action";
import {
  editCustomer,
  getCityList,
  getProvinceList,
} from "../../../redux/Customer/action";
import { CONTACT_PREFRENCES, PHONE_TYPES } from "../../../utility/Constant";
import { handlePreventKeyDown } from "../../../utility/helpers/commonFunction";
import {
  customStyles,
  errorCustomStyles,
  phoneTypeCustomStyles,
} from "../../../utility/SelectDropdown";
import {
  inputFormatPhoneNumber,
  removeDashFromPhoneNumber,
} from "../../Common/FormatPhoneNumber";
import "../Customers.css";
import AddressSelect from "./AddressSelect";
import "./Profile.css";

function EditCustomerCustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, marginLeft: "" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  // p: 3,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function UpdateCustomerModal({
  openEditCustomer,
  handleCloseCustomer,
  onSubmitCustomer,
  convertedCustomerId,
  addressValues,
  setAddressValue,
  primaryEmailIndex,
  setPrimaryEmailIndex,
  setOpenDelete,
  type,
  setShowLoader,
}) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue: setValueinForm,
    setError,
    clearErrors,
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [editModalTabvalue, setEditModalTabValue] = useState(0);
  const [phoneTypes, setPhoneTypes] = useState(PHONE_TYPES);
  const [contactPrefrences, setContactPrefrences] =
    useState(CONTACT_PREFRENCES);
  const [showSecondaryNumber, setShowSecondaryNumber] = useState(false);
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState({
    primary: "mobile",
    secondary: "mobile",
  });
  const [getAddress, setAddress] = useState();
  const [country, setCountry] = useState();
  // const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);

  const handleChangeEditCustomerTabValue = (event, newValue) => {
    setEditModalTabValue(newValue);
  };
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [discountProgramLoader, setDiscountProgramLoader] = useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountrySecondary, setSelectedCountrySecondary] =
    useState(null);

  const countryMasterList = useSelector(
    (state) => state.account?.countryMasterList
  );
  const data = useSelector((state) => state.customer.customerDetail);

  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
    fetchDiscountProgram();
    fetchCountryMasterList();
    setValueinForm(
      "primaryPhoneNumberType",
      selectedPhoneTypes
        ? phoneTypes.find((option) => option.key == selectedPhoneTypes?.primary)
        : ""
    );
  }, []);

  const fetchCountryMasterList = async () => {
    await dispatch(getCountryMasterListApi());
  };

  const fetchDiscountProgram = async () => {
    setDiscountProgramLoader(true);
    let resp = await dispatch(discountProgramApi({ pagination: false }));
    if (resp.length > 0) {
      setDiscountList(resp);
    }
    setDiscountProgramLoader(false);
  };

  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      // let finalCity = [...resp];
      // finalCity = finalCity
      // .filter((obj) => obj.hasOwnProperty("city"))
      // .map((obj) => obj.city);

      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      // let finalProvince = [...resp];
      // finalProvince = finalProvince
      // .filter((obj) => obj.hasOwnProperty("province"))
      // .map((obj) => obj.province);

      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  useEffect(() => {
    reset();
    setShowSecondaryNumber(false);
    setShowSecondaryEmail(false);
    console.log("datadatadata", data);
    if (data.primaryPhoneNumberType) {
      setValueinForm(
        "primaryPhoneNumberType",
        data.primaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == data.primaryPhoneNumberType
            )
          : ""
      );
    } else {
      setValueinForm(
        "primaryPhoneNumberType",
        selectedPhoneTypes
          ? phoneTypes.find(
              (option) => option.key == selectedPhoneTypes?.primary
            )
          : ""
      );
    }
    if (data?.membershipProgram) {
      setValueinForm(
        "membershipProgram",
        data.membershipProgram?._id ?? "none"
      );
    }
    setValueinForm("firstName", data?.firstName);
    setValueinForm("lastName", data?.lastName);

    if (data?.phoneNumberCountry?._id) {
      const selected = countryMasterList.find(
        (item) => item._id === data?.phoneNumberCountry?._id
      );
      setSelectedCountry(selected);
    } else {
      const selected = countryMasterList.find((item) => item.default === true);
      setSelectedCountry(selected);
    }

    // setValueinForm("phoneNumberCountry", data?.phoneNumberCountry);
    // setValueinForm("countryCode", data?.countryCode);

    setValueinForm("phoneNumber", inputFormatPhoneNumber(data?.phoneNumber));
    setValueinForm("email", data?.email);
    setValueinForm("company", data?.company);
    setValueinForm("contactPrefrence", data?.contactPrefrence);

    setValueinForm("province", data?.province?.province);
    setValueinForm("city", data?.city?.city);
    setValueinForm("streetAddress", data?.streetAddress);
    setValueinForm("country", data?.country?.country);
    setValueinForm("postalCode", formatPostalCode(data?.postalCode));
    setAddress(data?.streetAddress);
    setAddressValue({
      ...addressValues,
      city: data?.city?.city,
      province: data?.province?.province,
      cityId: data?.city?._id,
      provinceId: data?.province?._id,
      streetAddress: data?.streetAddress,
      postal: formatPostalCode(data?.postalCode),
    });
    setCountry(data?.country?.country);

    if (data?.secondaryPhoneNumber) {
      setShowSecondaryNumber(true);
      setValueinForm(
        "secondaryPhoneNumberType",
        data.secondaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == data?.secondaryPhoneNumberType
            )
          : ""
      );
      setValueinForm(
        "secondaryPhoneNumber",
        inputFormatPhoneNumber(data?.secondaryPhoneNumber)
      );
    } else {
      setValueinForm(
        "secondaryPhoneNumberType",
        selectedPhoneTypes
          ? phoneTypes.find(
              (option) => option.key == selectedPhoneTypes?.primary
            )
          : ""
      );
    }

    if (data?.secondaryPhoneNumberCountry?._id) {
      const selected = countryMasterList.find(
        (item) => item._id === data?.secondaryPhoneNumberCountry?._id
      );
      setSelectedCountrySecondary(selected);
    } else {
      const selected = countryMasterList.find((item) => item.default === true);
      setSelectedCountrySecondary(selected);
    }
    if (data?.secondaryEmail) {
      setShowSecondaryEmail(true);
      setValueinForm("secondaryEmail", data?.secondaryEmail);
    }
  }, [data, openEditCustomer]);

  const handleUpdate = async (values) => {
    const { streetAddress, city, province, postalCode } = values;

    const isAnyFieldFilled = [streetAddress, city, province, postalCode].some(
      (field) => field && field.trim() !== ""
    );
    if (isAnyFieldFilled) {
      if (!streetAddress)
        setError("streetAddress", {
          type: "required",
          message: "Street address is required",
        });
      if (!city)
        setError("city", { type: "required", message: "City is required" });
      if (!province)
        setError("province", {
          type: "required",
          message: "Province is required",
        });
      if (!postalCode)
        setError("postalCode", {
          type: "required",
          message: "Postal code is required",
        });

      if (!streetAddress || !city || !province || !postalCode) {
        setEditModalTabValue(1);
        return;
      }
    } else {
      clearErrors();
    }
    // data.contactPrefrence = JSON.parse(data.contactPrefrence);

    let payload = {
      // ...values,
      firstName: values.firstName,

      phoneNumber: values.phoneNumber,
      // countryCode: "+1",
    };
    if (values.lastName) {
      payload.lastName = values?.lastName;
    }
    // if (values.email) {
    //   payload.email = values?.email;
    // }

    // if (values?.company) {
    payload.company = values?.company;
    // }
    if (addressValues?.city) {
      payload.city = addressValues?.cityId;
    }

    if (addressValues?.country) {
      payload.country = "64e738882fec8f2c9b79c139";
    }

    if (values?.postalCode) {
      payload.postalCode = values?.postalCode;
    }
    if (addressValues?.province) {
      payload.province = addressValues?.provinceId;
    }
    if (values?.streetAddress) {
      payload.streetAddress = values?.streetAddress;
    }
    if (values?.contactPrefrence) {
      payload.contactPrefrence = values?.contactPrefrence;
    }
    if (values?.primaryPhoneNumberType) {
      payload.primaryPhoneNumberType = values?.primaryPhoneNumberType?.key;
      payload.countryCode = selectedCountry?.dial_code;
      payload.phoneNumberCountry = selectedCountry?._id;
    }

    payload.secondaryPhoneNumberType = values?.secondaryPhoneNumberType?.key;
    payload.secondaryPhoneNumber = values?.secondaryPhoneNumber;

    if (values?.secondaryPhoneNumber && values?.secondaryPhoneNumber !== "") {
      payload.secondaryPhoneNumberCountryCode =
        selectedCountrySecondary?.dial_code;
      payload.secondaryPhoneNumberCountry = selectedCountrySecondary?._id;
    } else {
    }

    if (primaryEmailIndex == 0) {
      payload.email = values?.email;
      payload.secondaryEmail = values?.secondaryEmail;
    } else {
      payload.secondaryEmail = values?.email;
      payload.email = values?.secondaryEmail;
    }

    if (
      values.membershipProgram &&
      values.membershipProgram !== "" &&
      values.membershipProgram !== "none"
    ) {
      payload.membershipProgram = values.membershipProgram;
    } else {
      payload.membershipProgram = "";
    }

    console.log("🚀 ~ onSubmitCustomer ~ payload:", payload);
    setLoader(true);
    const resp = await dispatch(editCustomer(data?._id, payload));
    if (resp) {
      onSubmitCustomer(resp);
    }
    setLoader(false);
  };

  const handleCountryChange = (event) => {
    const selected = countryMasterList.find(
      (item) => item._id === event.target.value
    );
    setSelectedCountry(selected);
  };

  const handleCountryChangeSecondary = (event) => {
    const selected = countryMasterList.find(
      (item) => item._id === event.target.value
    );
    setSelectedCountrySecondary(selected);
  };

  return (
    <div>
      <Modal
        open={openEditCustomer}
        onClose={() => {
          if (!loader) {
            handleCloseCustomer();
          }
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              backgroundColor: "#002394",
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Edit Customer Details
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    if (!loader) {
                      handleCloseCustomer();
                    }
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              marginTop: "66px",
              // scrollbarColor: "blue",
              // scrollbarWidth: "thin",
              // overflowY: "auto",
              // maxHeight: "80vh",
              // position: "relative",
            }}
          >
            <form onSubmit={handleSubmit(handleUpdate)}>
              <Grid
                container
                // spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  maxHeight: "70vh",
                }}
                padding={"24px 24px 0 24px"}
              >
                <Tabs
                  value={editModalTabvalue}
                  onChange={handleChangeEditCustomerTabValue}
                  aria-label="basic tabs example"
                  sx={{ marginBottom: "24px" }}
                >
                  <Tab
                    label="BASIC DETAILS"
                    // style={{ display: type == SHOP_IMPORTED && "none" }}
                    {...a11yProps(0)}
                  />

                  <Tab label="ADDITIONAL DETAILS" {...a11yProps(1)} />
                </Tabs>

                <div
                  style={{ display: editModalTabvalue == 0 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item container xs={12} sm={12} md={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {" "}
                            Primary Phone Number{" "}
                            <span style={{ color: REQUIRED_RED }}>*</span>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <Grid container columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.primaryPhoneNumberType}
                                >
                                  <Controller
                                    name="primaryPhoneNumberType"
                                    control={control}
                                    rules={{
                                      required: "Phone Number Type is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        placeholder="" // defaultValue={}
                                        isDisabled={
                                          type == SHOP_CREATED ? false : true
                                        }
                                        className={
                                          type != SHOP_CREATED &&
                                          "formFieldsDisabled"
                                        }
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ==
                                                  selectedPhoneTypes?.primary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option.key}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            primary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.primaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={!!errors.primaryPhoneNumberType}
                                        helperText={
                                          errors.primaryPhoneNumberType
                                            ? errors.primaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.primaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.primaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6.5}
                                xl={6.5}
                              >
                                <Controller
                                  name="phoneNumber"
                                  control={control}
                                  rules={{
                                    required: "Phone Number is required",
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      InputProps={{
                                        startAdornment: (
                                          <MuiSelect
                                            disabled={
                                              type == SHOP_CREATED
                                                ? false
                                                : true
                                            }
                                            style={{
                                              width: "92px",
                                              minWidth: "92px",
                                              height: "56px",
                                              border: "none",
                                              marginRight: "16.5px",
                                            }}
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  width: "264px",
                                                  marginLeft: "86px",
                                                  boxShadow:
                                                    "box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                                                },
                                              },
                                              MenuListProps: {
                                                style: {},
                                              },
                                            }}
                                            sx={{
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderRight: "none",
                                                },
                                              "& .MuiOutlinedInput-root": {
                                                borderTopRightRadius: "0px",
                                                borderBottomRightRadius: "0px",
                                              },
                                              borderTopRightRadius: "0px",
                                              borderBottomRightRadius: "0px",
                                              background: "#F1F1F1",
                                            }}
                                            value={selectedCountry?._id ?? ""}
                                            onChange={handleCountryChange}
                                            renderValue={(selected) => {
                                              const country =
                                                countryMasterList.find(
                                                  (item) =>
                                                    item._id === selected
                                                ) || selectedCountry;
                                              if (!country) return "Select";
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      marginRight: "8px",
                                                    }}
                                                  >
                                                    <img
                                                      src={country?.flag_svg}
                                                      alt="flag"
                                                      style={{
                                                        width: "24px",
                                                        height: "12px",
                                                      }}
                                                    />
                                                  </span>
                                                  {country.dial_code}
                                                </div>
                                              );
                                            }}
                                            IconComponent={
                                              KeyboardArrowDownIcon
                                            }
                                          >
                                            {countryMasterList?.length > 0 &&
                                              countryMasterList.map(
                                                (country) => (
                                                  <MenuItem
                                                    key={country._id}
                                                    value={country._id}
                                                    style={{ height: "44px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        marginRight: "8px",
                                                      }}
                                                    >
                                                      <img
                                                        src={country?.flag_svg}
                                                        alt="flag"
                                                        style={{
                                                          width: "24px",
                                                          height: "12px",
                                                        }}
                                                      />
                                                    </span>
                                                    {country.country} (
                                                    {country.dial_code})
                                                  </MenuItem>
                                                )
                                              )}
                                          </MuiSelect>
                                        ),
                                      }}
                                      className={
                                        type != SHOP_CREATED &&
                                        "formFieldsDisabled"
                                      }
                                      id="outlined-basic"
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          paddingLeft: "0",
                                        },
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      // required
                                      disabled={
                                        type == SHOP_CREATED ? false : true
                                      }
                                      error={!!errors.phoneNumber}
                                      helperText={
                                        errors.phoneNumber
                                          ? errors.phoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        if (
                                          removeDashFromPhoneNumber(
                                            e.target.value
                                          ).length <= 10
                                        ) {
                                          const input = e.target;
                                          const rawValue = e.target.value;
                                          // Store previous cursor position
                                          const prevCursorPosition =
                                            input.selectionStart;
                                          const isDeleting =
                                            e.nativeEvent.inputType ===
                                            "deleteContentBackward";
                                          // Count hyphens before formatting
                                          const hyphenCountBefore = (
                                            rawValue.match(/-/g) || []
                                          ).length;
                                          // Format the input value
                                          const formattedValue =
                                            inputFormatPhoneNumber(rawValue);

                                          // Count hyphens after formatting
                                          const hyphenCountAfter = (
                                            formattedValue.match(/-/g) || []
                                          ).length;

                                          let newCursorPosition =
                                            prevCursorPosition;

                                          if (isDeleting) {
                                            // If a hyphen was removed, move cursor back by 1
                                            if (
                                              hyphenCountAfter <
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition--;
                                            }
                                          } else {
                                            // If a hyphen was added, move cursor forward by 1
                                            if (
                                              hyphenCountAfter >
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition++;
                                            }
                                          }
                                          // Ensure cursor position doesn't go out of bounds
                                          newCursorPosition = Math.max(
                                            0,
                                            Math.min(
                                              newCursorPosition,
                                              formattedValue.length
                                            )
                                          );
                                          // Update the state
                                          setValueinForm(
                                            "phoneNumber",
                                            formattedValue
                                          );

                                          // Restore the adjusted cursor position
                                          setTimeout(() => {
                                            input.setSelectionRange(
                                              newCursorPosition,
                                              newCursorPosition
                                            );
                                          }, 0);

                                          if (
                                            removeDashFromPhoneNumber(
                                              e.target.value
                                            ).length < 10
                                          ) {
                                            setError("phoneNumber", {
                                              message:
                                                "Phone Number should be 10 digits",
                                            });
                                          } else clearErrors("phoneNumber");
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        preventPhoneInputKeys(e);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Stack>
                        {type == SHOP_CREATED && !showSecondaryNumber && (
                          <div
                            onClick={() => setShowSecondaryNumber(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD PHONE NUMBER
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {showSecondaryNumber && (
                      <Grid item container xs={12} sm={12} md={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              {" "}
                              Secondary Phone Number{" "}
                              {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                            </Typography>

                            <Grid container item columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.secondaryPhoneNumberType}
                                >
                                  <Controller
                                    name="secondaryPhoneNumberType"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className={
                                          type != SHOP_CREATED &&
                                          "formFieldsDisabled"
                                        }
                                        placeholder="" // defaultValue={}
                                        isDisabled={
                                          type == SHOP_CREATED ? false : true
                                        }
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ===
                                                  selectedPhoneTypes?.secondary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            secondary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.secondaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={
                                          !!errors.secondaryPhoneNumberType
                                        }
                                        helperText={
                                          errors.secondaryPhoneNumberType
                                            ? errors.secondaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.secondaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.secondaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6.6}
                                xl={6.6}
                              >
                                <Controller
                                  name="secondaryPhoneNumber"
                                  control={control}
                                  rules={{
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      InputProps={{
                                        startAdornment: (
                                          <MuiSelect
                                            disabled={
                                              type == SHOP_CREATED
                                                ? false
                                                : true
                                            }
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  width: "264px",
                                                  marginLeft: "86px",
                                                  boxShadow:
                                                    "box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                                                },
                                              },
                                              MenuListProps: {
                                                style: {},
                                              },
                                            }}
                                            style={{
                                              width: "92px",
                                              minWidth: "92px",
                                              height: "56px",
                                              border: "none",
                                              marginRight: "16.5px",
                                            }}
                                            sx={{
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderRight: "none",
                                                },
                                              "& .MuiOutlinedInput-root": {
                                                borderTopRightRadius: "0px",
                                                borderBottomRightRadius: "0px",
                                              },
                                              borderTopRightRadius: "0px",
                                              borderBottomRightRadius: "0px",
                                              background: "#F1F1F1",
                                            }}
                                            value={
                                              selectedCountrySecondary?._id ??
                                              ""
                                            }
                                            onChange={
                                              handleCountryChangeSecondary
                                            }
                                            renderValue={(selected) => {
                                              const country =
                                                countryMasterList.find(
                                                  (item) =>
                                                    item._id === selected
                                                ) || selectedCountrySecondary;
                                              if (!country) return "Select";
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      marginRight: "8px",
                                                    }}
                                                  >
                                                    <img
                                                      src={country?.flag_svg}
                                                      alt="flag"
                                                      style={{
                                                        width: "24px",
                                                        height: "12px",
                                                      }}
                                                    />
                                                  </span>
                                                  {country.dial_code}
                                                </div>
                                              );
                                            }}
                                            IconComponent={
                                              KeyboardArrowDownIcon
                                            }
                                          >
                                            {countryMasterList?.length > 0 &&
                                              countryMasterList.map(
                                                (country) => (
                                                  <MenuItem
                                                    key={country._id}
                                                    value={country._id}
                                                    style={{ height: "44px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        marginRight: "8px",
                                                      }}
                                                    >
                                                      <img
                                                        src={country?.flag_svg}
                                                        alt="flag"
                                                        style={{
                                                          width: "24px",
                                                          height: "12px",
                                                        }}
                                                      />
                                                    </span>
                                                    {country.country} (
                                                    {country.dial_code})
                                                  </MenuItem>
                                                )
                                              )}
                                          </MuiSelect>
                                        ),
                                      }}
                                      className={
                                        type != SHOP_CREATED &&
                                        "formFieldsDisabled"
                                      }
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          paddingLeft: "0",
                                        },
                                      }}
                                      id="outlined-basic"
                                      variant="outlined"
                                      fullWidth
                                      disabled={
                                        type == SHOP_CREATED ? false : true
                                      }
                                      // required
                                      error={!!errors.secondaryPhoneNumber}
                                      helperText={
                                        errors.secondaryPhoneNumber
                                          ? errors.secondaryPhoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        if (
                                          removeDashFromPhoneNumber(
                                            e.target.value
                                          ).length <= 10
                                        ) {
                                          const input = e.target;
                                          const rawValue = e.target.value;
                                          // Store previous cursor position
                                          const prevCursorPosition =
                                            input.selectionStart;
                                          const isDeleting =
                                            e.nativeEvent.inputType ===
                                            "deleteContentBackward";
                                          // Count hyphens before formatting
                                          const hyphenCountBefore = (
                                            rawValue.match(/-/g) || []
                                          ).length;
                                          // Format the input value
                                          const formattedValue =
                                            inputFormatPhoneNumber(rawValue);

                                          // Count hyphens after formatting
                                          const hyphenCountAfter = (
                                            formattedValue.match(/-/g) || []
                                          ).length;

                                          let newCursorPosition =
                                            prevCursorPosition;

                                          if (isDeleting) {
                                            // If a hyphen was removed, move cursor back by 1
                                            if (
                                              hyphenCountAfter <
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition--;
                                            }
                                          } else {
                                            // If a hyphen was added, move cursor forward by 1
                                            if (
                                              hyphenCountAfter >
                                              hyphenCountBefore
                                            ) {
                                              newCursorPosition++;
                                            }
                                          }
                                          // Ensure cursor position doesn't go out of bounds
                                          newCursorPosition = Math.max(
                                            0,
                                            Math.min(
                                              newCursorPosition,
                                              formattedValue.length
                                            )
                                          );
                                          // Update the state
                                          setValueinForm(
                                            "secondaryPhoneNumber",
                                            formattedValue
                                          );

                                          // Restore the adjusted cursor position
                                          setTimeout(() => {
                                            input.setSelectionRange(
                                              newCursorPosition,
                                              newCursorPosition
                                            );
                                          }, 0);

                                          if (
                                            removeDashFromPhoneNumber(
                                              e.target.value
                                            ).length < 10
                                          ) {
                                            setError("secondaryPhoneNumber", {
                                              message:
                                                "Phone Number should be 10 digits",
                                            });
                                          } else
                                            clearErrors("secondaryPhoneNumber");
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        preventPhoneInputKeys(e);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2} sm={2} md={1} xl={1}>
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    color="error"
                                    size="medium"
                                    disabled={
                                      type == SHOP_CREATED ? false : true
                                    }
                                    onClick={() => {
                                      if (type == SHOP_CREATED)
                                        setShowSecondaryNumber(false);
                                      setValueinForm(
                                        "secondaryPhoneNumber",
                                        ""
                                      );
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          First Name{" "}
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: "First Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.firstName}
                              helperText={
                                errors.firstName ? errors.firstName.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Last Name
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        <Controller
                          name="lastName"
                          control={control}
                          // rules={{
                          //   required: "Last Name is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.lastName}
                              helperText={
                                errors.lastName ? errors.lastName.message : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    {/* <Grid item xs={12} md={12} xl={12}>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      Phone Number <span style={{ color: "#D32F2F" }}> *</span>
                    </Typography>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "Phone Number is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          id="outlined-basic"
                          variant="outlined"
                          error={!!errors.phoneNumber}
                          helperText={
                            errors.phoneNumber ? errors.phoneNumber.message : ""
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid> */}

                    <Grid item container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            marginBottom: "8px",
                          }}
                        >
                          {" "}
                          Email
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <Grid container item columnSpacing={2} rowGap={0}>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                              <Controller
                                name="email"
                                control={control}
                                rules={{
                                  // required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    className={
                                      type != SHOP_CREATED &&
                                      "formFieldsDisabled"
                                    }
                                    id="outlined-basic"
                                    /*label="Email"*/
                                    variant="outlined"
                                    fullWidth
                                    disabled={
                                      type == SHOP_CREATED ? false : true
                                    }
                                    // required
                                    error={!!errors.email}
                                    helperText={
                                      errors.email ? errors.email.message : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={2}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={0 == primaryEmailIndex}
                                      disabled={type != SHOP_CREATED}
                                      onChange={(e) => {
                                        showSecondaryEmail &&
                                          setPrimaryEmailIndex(
                                            e.target.checked ? 0 : 1
                                          );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                                {/* <CheckBox  >Primary</CheckBox> */}
                                {/* <IconButton
                        color="error"
                        size="medium"
                        onClick={() => setShowSecondaryNumber(false)}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {type == SHOP_CREATED && !showSecondaryEmail && (
                          <div
                            onClick={() => setShowSecondaryEmail(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD EMAIL
                          </div>
                        )}
                        {showSecondaryEmail && (
                          <Grid
                            container
                            item
                            columnSpacing={2}
                            rowGap={2}
                            marginTop={2}
                          >
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                              <Controller
                                name="secondaryEmail"
                                control={control}
                                rules={{
                                  // required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    className={
                                      type != SHOP_CREATED &&
                                      "formFieldsDisabled"
                                    }
                                    id="outlined-basic"
                                    /*label="Email"*/
                                    variant="outlined"
                                    fullWidth
                                    disabled={type != SHOP_CREATED}
                                    // required
                                    error={!!errors.secondaryEmail}
                                    helperText={
                                      errors.secondaryEmail
                                        ? errors.secondaryEmail.message
                                        : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={1 == primaryEmailIndex}
                                      disabled={type != SHOP_CREATED}
                                      onChange={(e) => {
                                        setPrimaryEmailIndex(
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                                {/* <CheckBox  >Primary</CheckBox> */}
                                <IconButton
                                  color="error"
                                  size="medium"
                                  disabled={type == SHOP_CREATED ? false : true}
                                  onClick={() => {
                                    if (type == SHOP_CREATED) {
                                      setShowSecondaryEmail(false);
                                      setPrimaryEmailIndex(0);
                                      setValueinForm("secondaryEmail", "");
                                    }
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{ display: editModalTabvalue == 1 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Company
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        <Controller
                          name="company"
                          control={control}
                          // rules={{
                          //   required: "Street Address is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              // defaultValue={addressValues?.streetAddress}
                              // required
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                              error={!!errors.company}
                              helperText={
                                errors.company ? errors.company.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Discount Program
                        </Typography>
                        <Controller
                          name="membershipProgram"
                          control={control}
                          // rules={{
                          //   required: "Last Name is required",
                          // }}
                          render={({ field }) => (
                            <MuiSelect
                              onChange={(value) => {
                                setValueinForm(
                                  "membershipProgram",
                                  value.target.value
                                );
                                // setValueinForm("firstName", value?.firstName);
                              }}
                              {...field}
                            >
                              <MenuItem value={"none"}>None</MenuItem>
                              {discountList.map((item, index) => (
                                <MenuItem key={index} value={item._id}>
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%" }}
                                  >
                                    <div> {item.programName}</div>
                                    <Chip
                                      sx={{
                                        background: "#EBEBEB",
                                        color: "#000",
                                      }}
                                      size="small"
                                      label={`${item.discount}%`}
                                    />
                                  </Stack>
                                </MenuItem>
                              ))}
                            </MuiSelect>
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth error={!!errors.contactPrefrence}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Contact Preference
                          </Typography>
                          <Controller
                            name="contactPrefrence"
                            control={control}
                            // rules={{
                            //   required: "Phone Number Type is required",
                            // }}
                            render={({ field: { onChange, value } }) => (
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              >
                                {contactPrefrences?.map((contact) => (
                                  <FormControlLabel
                                    value={contact.key}
                                    control={<Radio />}
                                    label={contact?.value}
                                  />
                                ))}
                              </RadioGroup>
                            )}
                          />
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Street Address
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="streetAddress"
                          control={control}
                          // rules={{
                          //   required: (addressValues.city !== undefined || addressValues.city !== '' ||
                          //     addressValues.province == undefined || addressValues.province == "" ||
                          //     addressValues.postal !== '') &&
                          //     (addressValues.streetAddress == '') ? "Street Address is required" : false,
                          // }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              error={!!errors.streetAddress}
                              helperText={
                                errors.streetAddress
                                  ? errors.streetAddress.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                setValueinForm("streetAddress", e.target.value);
                                setAddressValue({
                                  ...addressValues,
                                  streetAddress: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          City
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="city"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={cityList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"city"}
                              loader={cityListLoader}
                              setValue={setValueinForm}
                              error={!!errors.city}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.city && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.city?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Province
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="province"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={provinceList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"province"}
                              setCityList={setCityList}
                              fetchCityList={fetchCityList}
                              loader={provinceListLoader}
                              setValue={setValueinForm}
                              error={!!errors.province}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.province && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.province?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <PostalCode
                        name="postalCode"
                        control={control}
                        errors={errors}
                        setValueinForm={setValueinForm}
                        setAddressValue={setAddressValue}
                        addressValues={addressValues}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                // spacing={2}
                style={{ marginTop: "24px" }}
                padding={"0px 24px 24px 24px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDelete(true)}
                      size="large"
                    >
                      DELETE
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (!loader) {
                          handleCloseCustomer();
                        }
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loader}
                      onClick={() => {
                        if (Object.keys(errors)?.length != 0) {
                          setEditModalTabValue(0);
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={openSuccessModal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="I love snacks"
        key={"top" + "center"}
        onClose={() => setOpenSuccessModal(false)}
        autoHideDuration={2000}
      >
        <Alert severity={"error"}>{successMessage}</Alert>
      </Snackbar>
    </div>
  );
}

export default UpdateCustomerModal;
