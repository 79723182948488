import { useTheme } from "@emotion/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Context, REQUIRED_RED } from "../../../App";
import { preventPhoneInputKeys } from "../../../components/FormatNumber/FormatNumber";
import {
  getCountryMasterListApi,
  personalDetails,
  updatePersonalDetails,
} from "../../../redux/Account/peronalIfno/action";
import {
  inputFormatPhoneNumber,
  removeDashFromPhoneNumber,
} from "../../Common/FormatPhoneNumber";
import SuccessMessageModal from "../../Common/SuccessMessage";
const PersonalInfo = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { isOpenSideBar } = useContext(Context);
  const [loadingCount, setLoadingCount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const {
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const CustomIcon = styled("svg")({
    width: "80px",
    height: "80px",
    fill: "none",
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const DetailsData = useSelector(
    (state) => state?.account?.personalDetailsData
  );

  useEffect(() => {
    getPersonalDetails();
    fetchCountryMasterList();
  }, []);

  const fetchCountryMasterList = async () => {
    let resp = await dispatch(getCountryMasterListApi());
    console.log("🚀 ~ fetchCountryMasterList ~ resp:", resp);
    if (resp?.length > 0) {
    }
  };

  const countryMasterList = useSelector(
    (state) => state.account?.countryMasterList
  );
  useEffect(() => {
    setValue("email", DetailsData?.email);
    setValue("firstName", DetailsData?.firstName);
    setValue("lastName", DetailsData?.lastName);
    setValue("phoneNumber", inputFormatPhoneNumber(DetailsData?.phoneNumber));
    if (countryMasterList?.length > 0) {
      if (DetailsData?.phoneNumberCountry) {
        const selected = countryMasterList.find(
          (item) => item._id === DetailsData?.phoneNumberCountry?._id
        );
        setSelectedCountry(selected);
      } else {
        const selected = countryMasterList.find(
          (item) => item.default === true
        );
        setSelectedCountry(selected);
      }
    }
  }, [DetailsData, countryMasterList]);

  useEffect(() => {
    setLoadingCount((prevCount) =>
      showLoader ? prevCount + 1 : prevCount - 1
    );
  }, [showLoader]);

  const getPersonalDetails = async () => {
    setShowLoader(true);

    const data = await dispatch(personalDetails());
    if (data) {
      setShowLoader(false);
    }
  };

  const onSubmit = async (values) => {
    if (selectedCountry?._id) {
      values.phoneNumberCountry = selectedCountry._id;
      values.countryCode = selectedCountry?.dial_code;
    }
    setShowLoader(true);
    // delete values.shopEmail;
    // if (values.email == DetailsData?.email) delete values.email;
    values.phoneNumber = values.phoneNumber.toString()?.replace(/-/g, "");
    // let noDashedNumber = DetailsData?.phoneNumber
    //   ?.toString()
    //   ?.replace(/-/g, "");
    // if (values.phoneNumber == noDashedNumber) delete values.phoneNumber;

    let result = await dispatch(updatePersonalDetails(values));
    if (result) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      getPersonalDetails();
    }
    setShowLoader(false);
  };

  const cancelHandle = () => {
    setValue("email", DetailsData?.email);
    setValue("firstName", DetailsData?.firstName);
    setValue("lastName", DetailsData?.lastName);
    setValue("phoneNumber", inputFormatPhoneNumber(DetailsData?.phoneNumber));
  };

  const validateNoLeadingSpaces = (value) => {
    if (value && value.trim() !== value) {
      return "Leading spaces are not allowed";
    }
    return true;
  };

  const handleCountryChange = (event) => {
    const selected = countryMasterList.find(
      (item) => item._id === event.target.value
    );
    setSelectedCountry(selected);
  };

  return (
    <>
      <Box>
        {" "}
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
          <Grid
            container
            sx={{ boxShadow: 3, bgcolor: "white" }}
            justifyContent={"start"}
            // alignItems={"center"}
            marginTop={"32px"}
            padding={"24px"}
            borderRadius={"10px"}
            // marginTop={"20px"}
          >
            <Grid item xs={12} sm={12} md={isOpenSideBar ? 6 : 5} lg={4} xl={4}>
              <Typography variant="h5">Personal Information</Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={isOpenSideBar ? 6 : 7}
              lg={8}
              xl={8}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                style={{ paddingTop: "20px" }}
              >
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "First Name is required",
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message:
                        "Only letters are allowed in First Name. or space will be not allowed.",
                    },
                    validate: validateNoLeadingSpaces, // Custom validation
                  }}
                  render={({ field }) => (
                    <>
                      <Typography variant="body1" className="label-500">
                        First Name{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <TextField
                        // label="First Name"
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.firstName}
                        helperText={
                          errors.firstName ? errors.firstName.message : ""
                        }
                        {...field}
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                style={{ paddingTop: "20px" }}
              >
                <Typography variant="body1" className="label-500">
                  Last Name <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Last Name is required",
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message: "Only letters are allowed in Last Name",
                    },
                    validate: validateNoLeadingSpaces, // Custom validation
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Last Name"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName.message : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid container item xs={12} sm={12} md={12} xl={12} spacing={2}> */}
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Phone Number <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>

                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Phone Number is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      style={{
                        marginBottom: "",
                      }}
                      InputProps={{
                        startAdornment: (
                          <Select
                            style={{
                              width: "92px",
                              minWidth: "92px",
                              height: "56px",
                              border: "none",
                              marginRight: "16.5px",
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  width: "264px",
                                  marginLeft: "86px",
                                  boxShadow:
                                    "box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
                                },
                              },
                              MenuListProps: {
                                style: {},
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiOutlinedInput-root": {
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                              },
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                              background: "#F1F1F1",
                            }}
                            value={selectedCountry?._id ?? ""}
                            onChange={handleCountryChange}
                            renderValue={(selected) => {
                              const country =
                                countryMasterList.find(
                                  (item) => item._id === selected
                                ) || selectedCountry;
                              if (!country) return "Select";
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ marginRight: "8px" }}>
                                    <img
                                      src={country?.flag_svg}
                                      alt="flag"
                                      style={{
                                        width: "24px",
                                        height: "12px",
                                      }}
                                    />
                                  </span>
                                  {country.dial_code}
                                </div>
                              );
                            }}
                            IconComponent={KeyboardArrowDownIcon} // Use custom icon
                          >
                            {countryMasterList?.length > 0 &&
                              countryMasterList.map((country) => (
                                <MenuItem
                                  key={country._id}
                                  value={country._id}
                                  style={{ height: "44px" }}
                                >
                                  <span style={{ marginRight: "8px" }}>
                                    <img
                                      src={country?.flag_svg}
                                      alt="flag"
                                      style={{
                                        width: "24px",
                                        height: "12px",
                                      }}
                                    />
                                  </span>
                                  {country.country} ({country.dial_code})
                                </MenuItem>
                              ))}
                          </Select>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          paddingLeft: "0",
                        },
                      }}
                      onChange={(e) => {
                        if (
                          removeDashFromPhoneNumber(e.target.value).length <= 10
                        ) {
                          const input = e.target;
                          const rawValue = e.target.value;
                          // Store previous cursor position
                          const prevCursorPosition = input.selectionStart;
                          const isDeleting =
                            e.nativeEvent.inputType === "deleteContentBackward";
                          // Count hyphens before formatting
                          const hyphenCountBefore = (rawValue.match(/-/g) || [])
                            .length;
                          // Format the input value
                          const formattedValue =
                            inputFormatPhoneNumber(rawValue);

                          // Count hyphens after formatting
                          const hyphenCountAfter = (
                            formattedValue.match(/-/g) || []
                          ).length;

                          let newCursorPosition = prevCursorPosition;

                          if (isDeleting) {
                            // If a hyphen was removed, move cursor back by 1
                            if (hyphenCountAfter < hyphenCountBefore) {
                              newCursorPosition--;
                            }
                          } else {
                            // If a hyphen was added, move cursor forward by 1
                            if (hyphenCountAfter > hyphenCountBefore) {
                              newCursorPosition++;
                            }
                          }
                          // Ensure cursor position doesn't go out of bounds
                          newCursorPosition = Math.max(
                            0,
                            Math.min(newCursorPosition, formattedValue.length)
                          );
                          // Update the state
                          setValue("phoneNumber", formattedValue);

                          // Restore the adjusted cursor position
                          setTimeout(() => {
                            input.setSelectionRange(
                              newCursorPosition,
                              newCursorPosition
                            );
                          }, 0);

                          if (
                            removeDashFromPhoneNumber(e.target.value).length <
                            10
                          ) {
                            setError("phoneNumber", {
                              message: "Phone Number should be 10 digits",
                            });
                          } else clearErrors("phoneNumber");
                        }
                      }}
                      type="tel"
                      onKeyDown={preventPhoneInputKeys}
                      fullWidth
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                    />
                  )}
                />
              </Grid>
              {/* </Grid> */}

              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Email <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                    validate: validateNoLeadingSpaces,
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Email"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"end"}
              spacing={2}
              style={{ marginTop: "10px" }}
            >
              <Grid item spacing={2}>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn-text-size-manage"
                    onClick={cancelHandle}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn-text-size-manage"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>{" "}
        </form>
      </Box>
      {/* <EmailCustomer setShowLoader={setShowLoader} /> */}
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default PersonalInfo;
