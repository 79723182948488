import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { toast } from "sonner";

export const isUpcomingOrToday = (date) => {
  const givenDate = new Date(date).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  return givenDate >= today;
};
export const handlePreventKeyDown = (event) => {
  console.log("event", event);
  const validKeys = [
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Enter",
    "Home",
    "End",
  ];
  // Allow Copy (Ctrl+C or Cmd+C), Paste (Ctrl+V or Cmd+V), and Select All (Ctrl+A or Cmd+A)
  if (
    (event.ctrlKey || event.metaKey) &&
    ["c", "v", "a"].includes(event.key.toLowerCase())
  ) {
    return;
  }
  if (validKeys.includes(event.key)) {
    return;
  }

  if (/^[0-9]$/.test(event.key)) {
    if (event.target.value.length >= 12) {
      event.preventDefault();
    }
  } else {
    event.preventDefault();
  }
};

export const paramsToQueryString = (params) => {
  if (params) {
    let queryString = "?";
    Object.keys(params).map((value) => {
      return (queryString += value + "=" + params[value] + "&");
    });
    queryString = queryString.slice(0, -1);
    return queryString;
  }
  return "";
};
export const filterUndefined = (obj) =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== undefined && value !== null) // Remove undefined and null
      .map(([key, value]) => [
        key,
        value && typeof value === "object" && !Array.isArray(value)
          ? filterUndefined(value) // Recursively process nested objects
          : value,
      ])
  );

export const handlePreventKeyDownDecimal = (event) => {
  const validKeys = [
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Enter",
    "Home",
    "End",
  ];

  if (validKeys.includes(event.key)) {
    return;
  }

  const { value, selectionStart, selectionEnd } = event.target;

  if (event.key === "." && !value.includes(".")) {
    return;
  }

  if (/^[0-9]$/.test(event.key)) {
    const [integerPart, decimalPart] = value.split(".");
    if (
      decimalPart &&
      selectionStart > value.indexOf(".") &&
      decimalPart.length >= 2
    ) {
      event.preventDefault();
    } else if (
      integerPart.length >= 12 &&
      selectionStart <= value.indexOf(".")
    ) {
      event.preventDefault();
    }
  } else {
    event.preventDefault();
  }
};

export const formatNumberWithCommas = (number) => {
  if (!number) return "";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parseNumberFromCommas = (numberString) => {
  if (!numberString) return "";
  return numberString.replace(/,/g, "");
};
export const copyToClipboard = (text) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("URL copied successfully!"))
      .catch((err) => console.error("Failed to copy: ", err));
  } else {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toast.success("URL copied successfully!");
    console.log("Text copied to clipboard (fallback)");
  }
};
export const roundToNext15Minutes = (date) => {
  const remainder = 15 - (date.minute() % 15);
  return date.add(remainder, "minute").second(0);
};
