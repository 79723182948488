import { toast } from "sonner";
import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import { paramsToQueryString } from "../../utility/helpers/commonFunction";

// Personal details fetch
export const invoiceListing = (
  pages,
  limit,
  status,
  sortType,
  sortBy,
  year,
  makeId,
  modelId,
  date,
  search
) => {
  console.log("🚀 ~ file: action.js:17 ~ makeId:", makeId);
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${
          API.invoice.invoiceList
        }?page=${pages}&limit=${limit}&status=${status}&sortType=${sortType}&sortBy=${sortBy}&timezone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }${makeId ? `&vehicleMakeId=${makeId}` : ""}${
          modelId ? `&vehicleModelId=${modelId}` : ""
        }${year ? `&vehicleYear=${year}` : ""}${date ? `&date=${date}` : ""}${
          search ? `&searchText=${search}` : ""
        }`
      );
      if (!response.data.error) {
        dispatch({
          type: API.invoice.invoiceList,
          InvoiceData: response.data,
          loading: false,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const customerWithAppointment = (pages, limit, customerSearch) => {
  return async (dispatch) => {
    let queryParamter = `?page=${pages}&limit=${limit}`;
    if (customerSearch) {
      queryParamter += `&searchText=${customerSearch}`;
    }
    try {
      const response = await DataService.get(
        `${API.invoice.getCustomerList}` + queryParamter
      );
      if (!response.data.error) {
        console.log(
          "🚀 ~ file: action.js:54 ~ return ~ response.data:",
          response.data
        );
        dispatch({
          type: API.invoice.getCustomerList,
          getCustomerList: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const vehicleList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(`${API.invoice.vehicleList}`);
      if (!response.data.error) {
        dispatch({
          type: API.invoice.vehicleList,
          vehicleList: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const invoiceDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.invoice.invoiceDetails}/${id}`
      );
      if (!response.data.error) {
        dispatch({
          type: API.invoice.invoiceDetails,
          invoiceDetails: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const createNewInvoice = (params, payload) => async (dispatch) => {
  try {
    const queryString = paramsToQueryString(params);
    const response = await DataService.post(
      API.invoice.create + queryString,
      payload
    );
    if (response.data.status) {
      dispatch({
        type: API.invoice.create,
        shopInfoData: response.data.data,
        loading: false,
      });

      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const createNewInvoiceDraft = (params, payload) => async (dispatch) => {
  try {
    const response = await DataService.post(API.invoice.createDraft, payload);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const customersList = () => async (dispatch) => {
  try {
    const response = await DataService.get(`${API.invoice.customerList}`);
    if (response.data.status) {
      dispatch({
        type: API.invoice.customerList,
        customerList: response.data,
        loading: false,
      });

      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const AllCustomersList =
  (page, limit, customerSearch) => async (dispatch) => {
    let queryParamter = `?page=${page}&limit=${limit}`;
    if (customerSearch) {
      queryParamter += `&searchText=${customerSearch}`;
    }
    try {
      const response = await DataService.get(
        `${API.customer.allCustomerList}` + queryParamter
      );
      if (response.data.status) {
        dispatch({
          type: API.invoice.customerList,
          customerList: response.data,
          loading: false,
        });

        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
export const CustomersListBasedOnType =
  (customerSearch, customerType, pages, limit) => async (dispatch) => {
    let queryParamter;
    let queryString;
    if (customerType.length > 0) {
      queryString = customerType
        .map((value) => `createdFrom=${value}`)
        .join("&");
      queryParamter = `?${queryString}&page=${pages}&limit=${limit}`;
      // queryParamter = `?${queryString}&page=${pages}`;
    }

    if (customerSearch) {
      queryParamter += `&name=${encodeURIComponent(customerSearch)}`;
    }
    try {
      const response = await DataService.get(
        `${API.customer.customerListWithType}` + queryParamter
      );
      if (response.data.status) {
        // dispatch({
        //   type: API.customer.customerListWithType,
        //   customerList: response.data,
        //   loading: false,
        // });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const customerDetailsBasedOnType = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.customer.customerDetilsWithType}/${id}`
    );
    if (response.data.status) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteInvoice = (id) => async (dispatch) => {
  try {
    const response = await DataService.delete(
      API.invoice.deleteDraft + "/" + id
    );
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const sendInvoice = (invoiceId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        `${API.invoice.sentInvoiceDetails}/${invoiceId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const downloadReport = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.invoice.downloadReport,
      payload,
      {},
      "arraybuffer"
    );

    if (response) {
      return response;
    } else {
      toast.info("Error");
      return false;
    }
  } catch (error) {
    toast.info("Error");
    return false;
  }
};
