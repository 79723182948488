import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addShopService,
  getServiceCategory,
  getServiceSubCategory,
  // getShopServicesListing,
  updateShopService,
} from "../../redux/Account/ServiceOffered/action";

import CloseIcon from "@mui/icons-material/Close";
import { Scrollbar } from "react-scrollbars-custom";
import { toast } from "sonner";
import { FormatNumberForKmFloat } from "../../components/FormatNumber/FormatNumber.js";
import { serviceCategoryData } from "../../redux/Estimate/action.js";
import { CustomPaper } from "../../utility/SelectDropdown.js";
import { displayError } from "../../utility/utilityFunctions.js";
import Loader from "../Common/Loader";
import SuccessMessageModal from "../Common/SuccessMessage";
import { REQUIRED_RED } from "./../../App.js";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 3,
};

const AddNewServiceModal = ({
  open,
  handleClose,
  openFor,
  editData,
  page,
  limit,
  searchValue,
  deleteData,
  selectedIds,
  selectedPriceType,
  setAutoFillDataInModal,
  reFetchParentDetails = () => {},
  getShopServicesListing, //If reFetchParentDetails is not passed, it will be empty by default, it is used in the parent component to refetch details after service is added or updated
}) => {
  const currentPathName =
    window.location.pathname.split("/")[
      window.location.pathname.split("/")?.length - 1
    ];
  const modelRef = useRef();
  const dispatch = useDispatch();
  const allServicecategories = useSelector(
    (state) => state?.account?.getServiceCategory
  );
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [validationPriceRangeError, setValidationPriceRangeError] =
    React.useState("");

  const [priceType, setPriceType] = React.useState("no_price");
  const [subCategoryData, subSubCategoryData] = React.useState([]);
  const [time, setTime] = React.useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showMenuLoader, setShowMenuLoader] = React.useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [againScroll, setAgainScroll] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setFocus,
  } = useForm();

  const [fieldError, setFieldsError] = useState([
    {
      serviceCategory: false,
      message: "Please choose service category",
    },
    {
      serviceSubCategory: false,
      message: "Please choose service sub category",
    },
    {
      serviceTime: false,
      message: "Please choose service time",
    },
  ]);

  useEffect(() => {
    getServiceCategories();
    if (openFor == "add") {
      setTime("01:00");
    }
  }, []);

  const getServiceCategories = async () => {
    let res = await dispatch(getServiceCategory(1000));
  };

  useEffect(() => {
    setValueManage();
  }, [editData]);

  const setValueManage = () => {
    if (openFor == "edit" && editData?.serviceCategory?._id) {
      getServiceSubCategories(editData?.serviceCategory?._id);
      setCategory(editData?.serviceCategory?._id);
      setSubCategory(editData?.serviceSubCategory?._id);
      setPriceType(editData?.priceType);
      setSwitchValue(editData?.showShopServices);
      const formattedFixedPrice = editData?.fixedPrice?.toLocaleString() || "";
      const formattedPriceMin = editData?.priceMin?.toLocaleString() || "";
      const formattedPriceMax = editData?.priceMax?.toLocaleString() || "";
      setValue("fixedPrice", formattedFixedPrice);
      setValue("priceMin", formattedPriceMin);
      setValue("priceMax", formattedPriceMax);
      setTime(editData.serviceTimeInHrs);
    }
  };

  const handleChangeCategory = (value) => {
    // console.log("event", event);
    setCategory(value?._id);
    subSubCategoryData([]);
    getServiceSubCategories(value?._id ?? "");
  };

  const fetchThisShopsCategory = async () => {
    const resp = await dispatch(serviceCategoryData({ limit: 1500 }));
  };

  const getServiceSubCategories = async (Newcategory) => {
    setShowMenuLoader(true);
    let res = await dispatch(getServiceSubCategory(1000, Newcategory));
    if (res) {
      subSubCategoryData(res);
    }
    setShowMenuLoader(false);
  };

  const handlePriceTypeChange = (event) => {
    setPriceType(event.target.value);
    setAgainScroll(false);
  };
  const [loading, setLoading] = useState(false);

  const saveHandler = async (data) => {
    toast.dismiss();

    clearErrors();
    // setAgainScroll(false);
    setValidationPriceRangeError("");
    let errors = [...fieldError];
    if (!category) {
      errors[0].serviceCategory = true;
      setFieldsError([...errors]);
    }
    if (!subCategory) {
      errors[1].serviceSubCategory = true;
      setFieldsError([...errors]);
    }

    for (const obj of errors) {
      if (Object.values(obj).some((value) => value === true)) {
        return;
      }
    }

    if (!time) {
      return displayError("Time is required");
    }

    const timeString = time;
    const timeArray = timeString?.split(":");

    const hours = parseInt(timeArray[0], 10);
    const minutes = parseInt(timeArray[1], 10);

    const currentDate = dayjs();
    const specifiedTime = currentDate.set("hour", hours).set("minute", minutes);

    let durationInMinutes;

    if (currentDate.isAfter(specifiedTime)) {
      // If the specified time is in the past, calculate the duration from midnight to specified time
      const midnight = currentDate.startOf("day");
      durationInMinutes = specifiedTime.diff(midnight, "minute");
    } else {
      // If the specified time is in the future, calculate the duration from specified time to midnight
      const midnight = currentDate.endOf("day");
      durationInMinutes = midnight.diff(specifiedTime, "minute");
    }

    const payload = {
      serviceCategoryId: category,
      serviceSubCategoryId: subCategory,
      serviceTimeInHrs: time,
      priceType: priceType,
      serviceTimeInMinutes: durationInMinutes,
      showShopServices: switchValue,
    };

    if (priceType === "fixed") {
      payload.fixedPrice = data.fixedPrice?.includes(",")
        ? data.fixedPrice.replace(/,/g, "")
        : data.fixedPrice;
      if (data?.fixedPrice?.toString()?.replace(/,/g, "") == 0) {
        setAgainScroll(true);
        if (modelRef.current) {
          modelRef.current.scrollTo({
            top: modelRef.current.scrollHeight,
            behavior: "smooth",
          });
          setAgainScroll(false);
        }
        setError("fixedPrice", {
          message: "Fixed price should be greater than 0.",
        });
        return toast.error("Fixed price should be greater than 0.");
      }
    } else if (priceType === "range") {
      const { priceMin, priceMax } = data;
      let formatePriceMin = priceMin?.toString()?.replace(/,/g, "");
      let formatePriceMax = priceMax?.toString()?.replace(/,/g, "");
      if (parseInt(formatePriceMin, 10) >= parseInt(formatePriceMax, 10)) {
        setValidationPriceRangeError(
          "The maximum price must be greater than the minimum price. "
        );
        setAgainScroll(true);
        if (modelRef.current) {
          modelRef.current.scrollTo({
            top: modelRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
        return;
      }
      payload.priceMin = data?.priceMin?.includes(",")
        ? data.priceMin.replace(/,/g, "")
        : data.priceMin;
      payload.priceMax = data?.priceMax?.includes(",")
        ? data.priceMax.replace(/,/g, "")
        : data.priceMax;
    } else {
      console.log("no price");
    }

    let res;
    setLoading(true);

    if (openFor == "add") {
      res = await dispatch(addShopService(payload));
      if (currentPathName == "new-appointment") setAutoFillDataInModal(res);
    } else {
      res = await dispatch(updateShopService(payload, editData._id));
      if (getShopServicesListing) getShopServicesListing();
    }

    if (res) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      // reFetchParentDetails();

      // await dispatch(
      //   getShopServicesListing(
      //     limit,
      //     page ? page : 1,
      //     searchValue,
      //     selectedIds,
      //     selectedPriceType
      //   )
      // );

      handleClose();
      setCategory("");
      setSubCategory("");
      setPriceType("");
      setValue("fixedPrice", "");
      setValue("priceMin", "");
      setValue("priceMax", "");
      setTime("");
      setValidationPriceRangeError("");
      reFetchParentDetails();
    }
    setLoading(false);
  };
  useEffect(() => {
    if (againScroll) {
      modelRef.current.scrollTo({
        top: modelRef.current.scrollHeight,
        behavior: "smooth",
      });
      setAgainScroll(false);
    }
  }, [againScroll]);

  useEffect(() => {
    if (
      errors?.hasOwnProperty("fixedPrice") ||
      errors?.hasOwnProperty("priceMin") ||
      errors?.hasOwnProperty("priceMax")
    ) {
      setAgainScroll(true);
    }
  }, [errors]);
  const autoScroll = () => {
    if (
      errors?.hasOwnProperty("fixedPrice") ||
      errors?.hasOwnProperty("priceMin") ||
      errors?.hasOwnProperty("priceMax")
    ) {
      setAgainScroll(true);
    }
  };
  const SwitchToggle = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#34C759",
      "&:hover": {
        backgroundColor: "#34c75922",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#34C759",
    },
  }));
  const onInvalid = (errors) => {
    console.log("🚀 ~ onInvalid ~ errors:", errors);
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setFocus(firstErrorField);
      }
    }
  };
  return (
    <Box>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />{" "}
      <Modal
        open={open}
        // title="Add new Service"
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, border: "none", borderRadius: "8px 8px 0px 0px" }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            variant="elevation"
            color="secondary"
            sx={{
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                className="btn-text-style"
              >
                {openFor == "add" ? "Add New Service" : "Edit Service"}
              </Typography>

              <Stack
                direction={"row"}
                spacing={0}
                onClick={handleClose}
                style={{ cursor: "pointer", height: "32px" }}
              >
                {/* <div > */}
                <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                {/* </div> */}
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            disableGutters
            sx={{
              mt: "67px",
            }}
          >
            <form onSubmit={handleSubmit(saveHandler, onInvalid)}>
              <Scrollbar
                trackXVisible={true}
                trackYVisible={true}
                style={{
                  width: "100%",

                  height: "434px",
                  // minHeight: loading ? "20vh" : "100%",
                }}
                className="boxStyle"
              >
                <Grid
                  container
                  justifyContent={"start"}
                  spacing={3}
                  p={3}
                  pb={0}
                  style={{
                    maxHeight: "80vh",
                    // overflowY: "auto",
                    // marginBottom: "24px",
                    scrollbarWidth: "thin",
                  }}
                  ref={modelRef}
                >
                  <Grid item xs={12} md={12} xl={12} spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Service Category{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">category</InputLabel> */}
                        {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        onChange={handleChangeCategory}
                      >
                        {allServicecategories?.map((data, index) => {
                          return (
                            <MenuItem value={data._id}>{data.name}</MenuItem>
                          );
                        })}
                      </Select> */}
                        <Autocomplete
                          PaperComponent={CustomPaper}
                          disablePortal
                          id="combo-box-demo"
                          options={
                            allServicecategories ? allServicecategories : []
                          }
                          value={
                            category
                              ? allServicecategories.filter(
                                  (d) => d._id == category
                                )?.[0]
                              : null
                          }
                          getOptionLabel={(option) => option?.name}
                          onChange={(event, newValue) => {
                            handleChangeCategory(newValue);
                            setSubCategory(newValue?._id);
                            let error = [...fieldError];
                            if (newValue) {
                              error[0].serviceCategory = false;
                            } else {
                              error[0].serviceCategory = true;
                            }
                            setFieldsError(error);
                          }}
                          ListboxProps={{
                            style: { maxHeight: "300px" },
                            // onScroll: (event) => {
                            //   handleScroll(event);
                            // },
                          }}
                          // loading={showServiceCategoryLoader}
                          // loadingText={
                          //   <div
                          //     style={{
                          //       display: "flex",
                          //       justifyContent: "center",
                          //     }}
                          //   >
                          //     {" "}
                          //     <CircularProgress />
                          //   </div>
                          // }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={fieldError[0].serviceCategory} // Add error prop to highlight input when error occurs
                              helperText={
                                fieldError[0].serviceCategory
                                  ? fieldError[0].message
                                  : ""
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12} spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Service Name{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <Autocomplete
                          PaperComponent={CustomPaper}
                          disablePortal
                          id="combo-box-demo"
                          options={subCategoryData ?? []}
                          getOptionLabel={(option) => option?.name}
                          value={
                            subCategory
                              ? subCategoryData.filter(
                                  (d) => d?._id == subCategory
                                )?.[0]
                              : null
                          }
                          // disabled={serviceCategory ? false : true}
                          onChange={(event, newValue) => {
                            setSubCategory(newValue?._id);
                            let error = [...fieldError];
                            if (newValue) {
                              error[1].serviceSubCategory = false;
                            } else {
                              error[1].serviceSubCategory = true;
                            }
                            setFieldsError(error);
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "200px",
                            },
                          }}
                          loading={showMenuLoader}
                          loadingText={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <CircularProgress />
                            </div>
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={fieldError[1].serviceSubCategory} // Add error prop to highlight input when error occurs
                              helperText={
                                fieldError[1].serviceSubCategory
                                  ? fieldError[1].message
                                  : ""
                              }
                            />
                          )}
                          renderOption={(props, option) => (
                            <>
                              <Box component="li" {...props}>
                                <li>{option?.name}</li>
                              </Box>
                              {showMenuLoader &&
                                props["data-option-index"] + 1 ==
                                  subCategoryData?.length && (
                                  <Box component="li" {...props}>
                                    <li {...props}>
                                      <CircularProgress />
                                    </li>
                                  </Box>
                                )}
                            </>
                          )}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12} spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Service Time (Hrs){" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={time ? dayjs(`2022-04-17T${time}`) : null}
                            onChange={(newValue) => {
                              const updatedString =
                                dayjs(newValue).format("HH:mm");
                              setTime(updatedString);
                            }}
                            ampm={false}
                            timeSteps={{ minutes: 15 }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Stack>
                  </Grid>
                  {/* VISIBLE SHOP PROFILE SWITCH BUTTON CR POINT 652 */}

                  <Grid item xs={12} sm={4} md={4} xl={4} spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Visible on Apex Auto App{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        <SwitchToggle
                          checked={switchValue}
                          onChange={(e) => setSwitchValue(e.target.checked)}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} xl={8} spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Price Type{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={priceType}
                        onChange={handlePriceTypeChange}
                        name="radio-buttons-group"
                        required
                      >
                        <FormControlLabel
                          value="no_price"
                          control={<Radio />}
                          label="No Price"
                        />
                        <FormControlLabel
                          value="fixed"
                          control={<Radio />}
                          label="Fixed Price"
                        />
                        <FormControlLabel
                          value="range"
                          control={<Radio />}
                          label="Price Range"
                        />
                      </RadioGroup>
                    </Stack>
                  </Grid>

                  {priceType == "fixed" ? (
                    <Grid item xs={12} md={12} xl={12} spacing={2}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Fixed Price{" "}
                          <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>

                        <Controller
                          name="fixedPrice"
                          control={control}
                          rules={{
                            required: "Fixed Price is required",
                            // validate: (value) =>
                            //   validateNumberPositive(
                            //     value,
                            //     "Fixed price should be greater than 0."
                            //   ),
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              // label="Shop Name"
                              variant="outlined"
                              fullWidth
                              // type="number"
                              error={!!errors.fixedPrice}
                              helperText={
                                errors.fixedPrice
                                  ? errors.fixedPrice.message
                                  : ""
                              }
                              // value={field.value}
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                // console.log(
                                //   "event.target.value",
                                //   event.target.value
                                // );
                                const numericValue = inputValue.replace(
                                  /[^\d.]/g,
                                  ""
                                );
                                // console.log(
                                //   "event.target.value",
                                //   event.target.value
                                // );
                                setValue(
                                  "fixedPrice",
                                  FormatNumberForKmFloat(numericValue)
                                );
                              }}
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  ) : priceType == "range" ? (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={12}
                      sm={12}
                      lg={12}
                      spacing={2}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Price Range{" "}
                          <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          rowGap={2}
                          marginTop={"20px"}
                        >
                          <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                            <Controller
                              name="priceMin"
                              control={control}
                              rules={{
                                required: "Minimum Price is required",
                              }}
                              render={({ field }) => (
                                <>
                                  <TextField
                                    id="outlined-basic"
                                    label={openFor == "edit" ? "" : "Minimum"}
                                    variant="outlined"
                                    fullWidth
                                    // type="number"
                                    error={!!errors.priceMin}
                                    helperText={
                                      errors.priceMin
                                        ? errors.priceMin.message
                                        : ""
                                    }
                                    {...field}
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      // console.log(
                                      //   "event.target.value",
                                      //   event.target.value
                                      // );
                                      const numericValue = inputValue.replace(
                                        /[^\d.]/g,
                                        ""
                                      );
                                      // console.log(
                                      //   "event.target.value",
                                      //   event.target.value
                                      // );
                                      setValue(
                                        "priceMin",
                                        FormatNumberForKmFloat(numericValue)
                                      );
                                    }}
                                    InputLabelProps={{
                                      shrink: field.value?.length > 0,
                                    }}
                                  />
                                </>
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                            <Controller
                              name="priceMax"
                              control={control}
                              rules={{
                                required: "Maximum Price is required",
                              }}
                              render={({ field }) => (
                                <TextField
                                  id="outlined-basic"
                                  // label="Maximum"
                                  label={openFor == "edit" ? "" : "Maximum"}
                                  variant="outlined"
                                  fullWidth
                                  // type="number"
                                  error={!!errors.priceMax}
                                  helperText={
                                    errors.priceMax
                                      ? errors.priceMax.message
                                      : ""
                                  }
                                  {...field}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const numericValue = inputValue.replace(
                                      /[^\d.]/g,
                                      ""
                                    );
                                    setValue(
                                      "priceMax",
                                      FormatNumberForKmFloat(numericValue)
                                    );
                                  }}
                                  InputLabelProps={{
                                    shrink: field.value?.length > 0,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Stack>
                      </Stack>
                      {validationPriceRangeError != "" && (
                        <Typography variant="body2" color="error">
                          {validationPriceRangeError}
                        </Typography>
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>{" "}
              </Scrollbar>
              <Grid
                item
                container
                justifyContent={"space-between"}
                spacing={2}
                p={3}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    {openFor == "edit" && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          deleteData(editData._id);
                        }}
                      >
                        DELETE{" "}
                      </Button>
                    )}
                  </Stack>
                </Grid>
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClose();
                        setCategory("");
                        setSubCategory("");
                        setPriceType("");
                        reset();
                        // setValue("fixedPrice", "");
                        // setValue("priceMin", "");
                        // setValue("priceMax", "");
                        setTime("");
                        setValidationPriceRangeError("");
                      }}
                    >
                      Cancel{" "}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      // onClick={autoScroll}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Loader showLoader={loading} />
    </Box>
  );
};

export default AddNewServiceModal;
