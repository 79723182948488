import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ConvertCustomerModal from "./ConvertCustomerModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 3,
};

const SaveCustomerConfirmModal = ({
  open,
  setSaveConfirmModal,
  setActionState,
  userName,
  userData,
  handleClose,
}) => {
  const [openConvertModal, setOpenConvertModal] = useState(false);
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);

  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
  });
  const handleCloseCustomer = (resp) => {
    setOpenConvertModal(false);
    handleClose(resp);
  };
  return (
    <div>
      <Modal
        open={open}
        // onClose={() => {
        //   setSaveConfirmModal(false);
        // }}

        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            // Ignore clicks outside the modal
            return;
          }
          setActionState({
            isActionSaved: false,
            messageToSend: null,
            rescheduleAction: null,
            authRequest: null,
          });
          setSaveConfirmModal(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, border: "none", borderRadius: "8px 8px 0px 0px" }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            variant="elevation"
            color="secondary"
            sx={{
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                className="btn-text-style"
              >
                {` Save ${userName} to Customer List?`}
              </Typography>

              <Stack
                direction={"row"}
                spacing={0}
                onClick={() => {
                  setActionState({
                    isActionSaved: false,
                    messageToSend: null,
                    rescheduleAction: null,
                    authRequest: null,
                  });
                  setSaveConfirmModal(false);
                }}
                style={{ cursor: "pointer", height: "32px" }}
              >
                <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
              </Stack>
            </Toolbar>
          </AppBar>

          <Container
            disableGutters
            sx={{
              mt: "67px",
              padding: "24px",
            }}
          >
            <Typography>{`In order to proceed, you need to save ${userName} to your customer list as they signed up as a guest user from your online booking page.`}</Typography>

            <Stack
              mt={3}
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setActionState({
                    isActionSaved: false,
                    messageToSend: null,
                    rescheduleAction: null,
                    authRequest: null,
                  });
                  setSaveConfirmModal(false);
                }}
              >
                Cancel{" "}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // setSaveConfirmModal(false);
                  setOpenConvertModal(true);
                }}
              >
                Save
              </Button>
            </Stack>
          </Container>
        </Box>
      </Modal>

      <ConvertCustomerModal
        showBtn={true}
        userId={userData}
        openConvertModal={openConvertModal}
        handleCloseCustomer={handleCloseCustomer}
        addressValues={addressValues}
        setAddressValue={setAddressValue}
        primaryEmailIndex={primaryEmailIndex}
        setPrimaryEmailIndex={setPrimaryEmailIndex}
        setOpenDelete={() => {}}
      />
    </div>
  );
};

export default SaveCustomerConfirmModal;
