import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Modal,
  Stack,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addShopService,
  createServiceCategory,
  createServiceSubcategoryAccount,
  getServiceCategory,
  getServiceSubCategory,
  // getShopServicesListing,
  updateShopService,
} from "../../redux/Account/ServiceOffered/action";

import { Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Scrollbar } from "react-scrollbars-custom";
import { toast } from "sonner";
import { FormatNumberForKmFloat } from "../../components/FormatNumber/FormatNumber.js";
import { SHOP_SERVICE_PRICE_TYPES } from "../../utility/Constant.js";
import { CustomPaper } from "../../utility/SelectDropdown.js";
import { displayError } from "../../utility/utilityFunctions.js";
import Loader from "../Common/Loader";
import SuccessMessageModal from "../Common/SuccessMessage";
import { REQUIRED_RED } from "./../../App.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const ManageServiceModal = ({
  open,
  handleClose,
  openFor,
  editData,
  deleteData,
  setAutoFillDataInModal,
  reFetchParentDetails = () => {},
  getShopServicesListing,
}) => {
  const currentPathName = window.location.pathname.split("/").pop();
  const modelRef = useRef();
  const dispatch = useDispatch();

  const allServicecategories = useSelector(
    (state) => state?.account?.getServiceCategory
  );
  console.log("🚀 ~ allServicecategories:", allServicecategories);
  const [categoryId, setCategoryId] = useState("");
  const [catInputValue, setCatInputValue] = useState("");
  const [filteredCategoryOptions, setFilteredCategoryOptions] = useState([]);
  console.log("🚀 ~ filteredCategoryOptions:", filteredCategoryOptions);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [validationPriceRangeError, setValidationPriceRangeError] =
    useState("");
  const [pricingType, setPricingType] = useState("no_price");
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [time, setTime] = useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showMenuLoader, setShowMenuLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    reset,
    setFocus,
  } = useForm();

  const [fieldError, setFieldsError] = useState([
    { serviceCategory: false, message: "Please choose service category" },
    {
      serviceSubCategory: false,
      message: "Please choose service sub category",
    },
    { serviceTime: false, message: "Please choose service time" },
  ]);

  useEffect(() => {
    if (open) {
      if (openFor === "add") {
        setTime("01:00");
      }
      if (openFor === "edit" && editData?.serviceCategory?._id) {
        setValueManage();
      }
      getServiceCategories();
    }
  }, [open]);

  const getServiceCategories = async (string) => {
    setShowLoader(true);
    const resp = await dispatch(getServiceCategory(1000));
    if (resp?.length > 0) {
      if (string) {
        setFilteredCategoryOptions(
          resp.filter((service) =>
            service?.name.toLowerCase().includes(string?.toLowerCase())
          )
        );
      } else {
        setFilteredCategoryOptions(resp);
      }
    }
    setShowLoader(false);
  };

  const setValueManage = () => {
    getServiceSubCategories(editData?.serviceCategory?._id);
    setCategoryId(editData?.serviceCategory?._id);
    setSubCategoryId(editData?.serviceSubCategory?._id);
    setPricingType(editData?.priceType);
    setIsVisible(editData?.showShopServices);
    setValue("fixedPrice", editData?.fixedPrice?.toLocaleString() || "");
    setValue("priceMin", editData?.priceMin?.toLocaleString() || "");
    setValue("priceMax", editData?.priceMax?.toLocaleString() || "");
    setTime(editData.serviceTimeInHrs);
  };

  const getServiceSubCategories = async (categoryId, string) => {
    setShowMenuLoader(true);
    const res = await dispatch(getServiceSubCategory(1000, categoryId));
    if (res?.length > 0) {
      setSubCategoryData(res);
      if (string && string !== "") {
        setFilteredOptions(
          res.filter((service) =>
            service?.name.toLowerCase().includes(string?.toLowerCase())
          )
        );
      } else {
        setFilteredOptions(res);
      }
    }
    setShowMenuLoader(false);
  };

  const handleChangeCategory = (value) => {
    setCategoryId(value?._id);
    setSubCategoryData([]);
    setFilteredOptions([]);
    getServiceSubCategories(value?._id ?? "");
  };

  const handleChangePriceType = (_, newType) => {
    if (newType !== null) {
      setPricingType(newType);
      clearErrors(["priceMin", "priceMax", "fixedPrice"]);
      setValue("priceMin", "");
      setValue("priceMax", "");
      setValue("fixedPrice", "");
    }
  };

  const saveHandler = async (data) => {
    toast.dismiss();
    clearErrors();
    setValidationPriceRangeError("");
    let errors = [...fieldError];
    if (!categoryId) {
      errors[0].serviceCategory = true;
      setFieldsError([...errors]);
    }
    if (!subCategoryId) {
      errors[1].serviceSubCategory = true;
      setFieldsError([...errors]);
    }

    for (const obj of errors) {
      if (Object.values(obj).some((value) => value === true)) {
        return;
      }
    }

    if (!time) {
      return displayError("Time is required");
    }

    const timeString = time;
    const timeArray = timeString?.split(":");
    const hours = parseInt(timeArray[0], 10);
    const minutes = parseInt(timeArray[1], 10);
    const currentDate = dayjs();
    const specifiedTime = currentDate.set("hour", hours).set("minute", minutes);
    let durationInMinutes;

    if (currentDate.isAfter(specifiedTime)) {
      const midnight = currentDate.startOf("day");
      durationInMinutes = specifiedTime.diff(midnight, "minute");
    } else {
      const midnight = currentDate.endOf("day");
      durationInMinutes = midnight.diff(specifiedTime, "minute");
    }

    const payload = {
      serviceCategoryId: categoryId,
      serviceSubCategoryId: subCategoryId,
      serviceTimeInHrs: time,
      priceType: pricingType,
      serviceTimeInMinutes: durationInMinutes,
      showShopServices: isVisible,
    };

    if (pricingType === "fixed") {
      payload.fixedPrice = data.fixedPrice?.replace(/,/g, "") || 0;
      if (payload.fixedPrice == 0) {
        setError("fixedPrice", {
          message: "Fixed price should be greater than 0.",
        });
        return toast.error("Fixed price should be greater than 0.");
      }
    } else if (pricingType === "range") {
      const { priceMin, priceMax } = data;
      let formattedPriceMin = priceMin?.replace(/,/g, "") || 0;
      let formattedPriceMax = priceMax?.replace(/,/g, "") || 0;
      if (parseInt(formattedPriceMin, 10) >= parseInt(formattedPriceMax, 10)) {
        setValidationPriceRangeError(
          "The maximum price must be greater than the minimum price."
        );
        return;
      }
      payload.priceMin = formattedPriceMin;
      payload.priceMax = formattedPriceMax;
    } else if (pricingType === SHOP_SERVICE_PRICE_TYPES.STARTING_FROM) {
      payload.priceMin = data.priceMin?.replace(/,/g, "") || 0;
    }

    console.log("🚀 ~ saveHandler ~ payload:", payload);

    setLoading(true);
    let res;

    // if (payload?.serviceSubCategoryId === "new") {
    //   payload.name = inputValue;
    //   payload.serviceCategory = payload?.serviceCategoryId;
    //   delete payload.serviceSubCategoryId;
    //   delete payload.serviceCategoryId;
    //   res = await dispatch(createServiceSubcategory(payload));
    //   if (currentPathName === "new-appointment") setAutoFillDataInModal(res);
    // }
    if (openFor === "add") {
      res = await dispatch(addShopService(payload));
      if (currentPathName === "new-appointment") setAutoFillDataInModal(res);
    } else {
      res = await dispatch(updateShopService(payload, editData._id));
      if (getShopServicesListing) getShopServicesListing();
    }

    if (res) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      handleClose();
      resetForm();
      reFetchParentDetails();
    }
    setLoading(false);
  };

  const resetForm = () => {
    setCategoryId("");
    setSubCategoryId("");
    setPricingType("no_price");
    reset();
    setTime("");
    setValidationPriceRangeError("");
  };

  const handleCloseWrapper = () => {
    handleClose();
    resetForm();
    setFieldsError([
      { serviceCategory: false, message: "Please choose service category" },
      {
        serviceSubCategory: false,
        message: "Please choose service sub category",
      },
      { serviceTime: false, message: "Please choose service time" },
    ]);
  };

  //Custom sub category  create
  const handleSaveService = async (serviceName) => {
    setShowMenuLoader(true); // Start saving process
    const resp = await dispatch(
      createServiceSubcategoryAccount({
        name: serviceName,
        serviceCategory: categoryId,
      })
    );
    if (resp) {
      await getServiceSubCategories(categoryId, serviceName);

      let error = [...fieldError];
      error[1].serviceSubCategory = false;
      setFieldsError(error);

      // Ensure the new service is selected
      setSubCategoryId(resp?._id || null);
      setInputValue(serviceName || "");
    }

    setShowMenuLoader(false); // End saving process
    const autocompleteInput = document.getElementById("combo-box-demo");
    if (autocompleteInput) {
      autocompleteInput.blur();
    }
  };

  //Custom category create
  const handleSaveCategory = async (categoryName) => {
    setShowLoader(true); // Start saving process
    const resp = await dispatch(
      createServiceCategory({
        name: categoryName,
      })
    );
    if (resp) {
      await getServiceCategories(categoryName);

      let error = [...fieldError];
      error[0].serviceCategory = false;
      setFieldsError(error);

      // Ensure the new service is selected
      setCategoryId(resp?._id || null);

      setCatInputValue(categoryName || "");
      handleChangeCategory(resp);
    }

    setShowLoader(false); // End saving process
    const autocompleteInput = document.getElementById("combo-box-category");
    if (autocompleteInput) {
      autocompleteInput.blur();
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    borderRadius: "100px",
    padding: "8px",
    background: "#F3F3F3",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    width: "max-content",

    "& .MuiToggleButton-root": {
      border: "none",
      color: "#FFF",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 400,
      padding: "8px 16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      borderRadius: "50px",
      transition: "none", // Disable hover effects
      textTransform: "capitalize",
      height: "40px",

      "&.Mui-selected": {
        backgroundColor: "#007AFF",
        border: "1px solid #0066E1",
        color: "#FFF",
        boxShadow: "0px 2px 2px 0px rgba(0, 122, 255, 0.25)",
      },

      "&:not(.Mui-selected)": {
        backgroundColor: "#F3F3F3",
        border: "1px solid #F3F3F3",
        color: "#000",
      },

      "&:not(.Mui-selected):hover": {
        backgroundColor: "#F3F3F3",
      },

      "&.Mui-selected:hover": {
        backgroundColor: "#007AFF",
      },
    },
  }));

  return (
    <Box>
      <Modal open={open} onClose={handleCloseWrapper}>
        <Box
          sx={{ ...style, border: "none", borderRadius: "8px 8px 0px 0px" }}
          className="modal900"
        >
          <AppBar
            variant="elevation"
            color="secondary"
            sx={{
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                className="btn-text-style"
              >
                {openFor === "add" ? "Add New Service" : "Edit Service"}
              </Typography>
              <Stack
                direction={"row"}
                spacing={0}
                onClick={handleCloseWrapper}
                style={{ cursor: "pointer", height: "32px" }}
              >
                <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
              </Stack>
            </Toolbar>
          </AppBar>
          <Container disableGutters sx={{ mt: "67px" }}>
            <form onSubmit={handleSubmit(saveHandler)}>
              <Scrollbar
                trackXVisible={true}
                trackYVisible={true}
                style={{ width: "100%", height: "471px" }}
                className="boxStyle"
              >
                <Grid
                  container
                  justifyContent={"start"}
                  spacing={3}
                  p={3}
                  pb={0}
                  style={{ maxHeight: "80vh", scrollbarWidth: "thin" }}
                  ref={modelRef}
                >
                  {/* Service Category field */}
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Service Category{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <Autocomplete
                          PaperComponent={CustomPaper}
                          disablePortal
                          id="combo-box-category"
                          options={filteredCategoryOptions || []}
                          value={
                            categoryId
                              ? allServicecategories.find(
                                  (d) => d._id === categoryId
                                )
                              : null
                          }
                          inputValue={catInputValue}
                          onInputChange={(event, newValue, reason) => {
                            if (reason === "reset" && !categoryId) {
                              setCatInputValue("");
                              setFilteredCategoryOptions(allServicecategories);
                            } else {
                              setCatInputValue(newValue.trimStart());
                              setFilteredCategoryOptions(
                                allServicecategories.filter((service) =>
                                  service?.name
                                    .toLowerCase()
                                    .includes(
                                      newValue.trimStart().toLowerCase()
                                    )
                                )
                              );
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
                              if (
                                filteredCategoryOptions.length === 0 &&
                                catInputValue &&
                                !subCategoryData.find(
                                  (d) =>
                                    d?._id === categoryId &&
                                    d?.name === catInputValue
                                )
                              ) {
                                handleSaveCategory(catInputValue);
                              } else if (
                                filteredCategoryOptions.length === 1 &&
                                filteredCategoryOptions[0]?._id &&
                                filteredCategoryOptions[0]?._id !== "new" &&
                                filteredCategoryOptions[0]?.name
                              ) {
                                handleChangeCategory(
                                  filteredCategoryOptions[0]
                                );
                                setCatInputValue(
                                  filteredCategoryOptions[0]?.name || ""
                                );
                                let error = [...fieldError];
                                error[0].serviceCategory =
                                  !filteredCategoryOptions[0];
                                error[0].serviceSubCategory = false;
                                setFieldsError(error);
                                setFilteredCategoryOptions(
                                  allServicecategories.filter((service) =>
                                    service?.name
                                      .toLowerCase()
                                      .includes(
                                        filteredCategoryOptions[0]?.name.toLowerCase()
                                      )
                                  )
                                );
                                const autocompleteInput =
                                  document.getElementById("combo-box-category");
                                if (autocompleteInput) {
                                  autocompleteInput.blur();
                                }
                              }
                            }
                          }}
                          filterOptions={(options, { inputValue }) => {
                            let filtered = options.filter((service) =>
                              service?.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            );

                            if (
                              inputValue &&
                              !filtered.some(
                                (s) =>
                                  s?.name.toLowerCase() ===
                                  inputValue.toLowerCase()
                              )
                            ) {
                              filtered = [
                                {
                                  name: inputValue,
                                  new: true,
                                },
                                ...filtered,
                              ];
                            }
                            return filtered;
                          }}
                          getOptionLabel={(option) => option?.name}
                          onChange={(event, newValue) => {
                            handleChangeCategory(newValue);
                            setCatInputValue(newValue?.name || "");
                            let error = [...fieldError];
                            error[0].serviceCategory = !newValue;
                            error[0].serviceSubCategory = false;
                            setFieldsError(error);
                            setFilteredCategoryOptions(
                              allServicecategories.filter((service) =>
                                service?.name
                                  .toLowerCase()
                                  .includes(newValue?.name.toLowerCase())
                              )
                            );
                          }}
                          onOpen={() => {
                            setFilteredCategoryOptions(
                              allServicecategories.filter((service) =>
                                service?.name
                                  .toLowerCase()
                                  .includes(catInputValue.toLowerCase())
                              )
                            );
                          }}
                          ListboxProps={{ style: { maxHeight: "300px" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={fieldError[0].serviceCategory}
                              helperText={
                                fieldError[0].serviceCategory
                                  ? fieldError[0].message
                                  : ""
                              }
                            />
                          )}
                          renderOption={(props, option, index) => {
                            if (showLoader && option?.new) {
                              return (
                                <Box
                                  key={`option-${index}`}
                                  sx={{
                                    height: "44px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <CircularProgress size={30} />
                                </Box>
                              );
                            } else if (!showLoader && option?.new) {
                              return (
                                <Box
                                  key={`option-${index}`}
                                  padding={0}
                                  component="li"
                                  {...props}
                                  onClick={(e) => e.stopPropagation()} // Prevent selection
                                  sx={{
                                    height: "44px",
                                    width: "100%",
                                    cursor: "default",
                                  }}
                                  style={{
                                    paddingRight: "0",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>{option?.name}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      padding: "6px 16px",
                                      background: "rgba(33, 150, 243, 0.15)",
                                      cursor: "pointer",
                                      pointerEvents: "all",
                                    }}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      handleSaveCategory(option?.name);
                                    }}
                                  >
                                    <Save
                                      sx={{
                                        color: "#006CDD",
                                        width: "22px",
                                        height: "22px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        color: "#006CDD",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "22px",
                                        marginLeft: "6px",
                                      }}
                                    >
                                      Save
                                    </span>
                                  </div>
                                </Box>
                              );
                            } else if (!showLoader) {
                              return (
                                <Box
                                  key={`option-${index}`}
                                  component="li"
                                  {...props}
                                  sx={{
                                    height: "44px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {option?.name}
                                </Box>
                              );
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  {/* Service Name field */}
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Service Name{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <Autocomplete
                          PaperComponent={CustomPaper}
                          disablePortal
                          id="combo-box-demo"
                          options={filteredOptions || []}
                          getOptionLabel={(option) => option?.name}
                          value={
                            subCategoryId
                              ? subCategoryData.find(
                                  (d) => d?._id === subCategoryId
                                )
                              : null
                          }
                          inputValue={inputValue}
                          onInputChange={(event, newValue, reason) => {
                            if (reason === "reset" && !subCategoryId) {
                              setInputValue("");
                              setFilteredOptions(subCategoryData);
                            } else {
                              setInputValue(newValue);
                              setFilteredOptions(
                                subCategoryData.filter((service) =>
                                  service?.name
                                    .toLowerCase()
                                    .includes(newValue.toLowerCase())
                                )
                              );
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
                              if (
                                filteredOptions.length === 0 &&
                                inputValue &&
                                !subCategoryData.find(
                                  (d) =>
                                    d?._id === subCategoryId &&
                                    d?.name === inputValue
                                )
                              ) {
                                handleSaveService(inputValue);
                              } else if (
                                filteredOptions.length === 1 &&
                                filteredOptions[0]?._id &&
                                filteredOptions[0]?._id !== "new" &&
                                filteredOptions[0]?.name
                              ) {
                                setSubCategoryId(filteredOptions[0]?._id);
                                setInputValue(filteredOptions[0]?.name);

                                let error = [...fieldError];
                                error[1].serviceSubCategory =
                                  !filteredOptions[0];
                                setFieldsError(error);

                                setFilteredOptions(
                                  subCategoryData.filter((service) =>
                                    service?.name
                                      .toLowerCase()
                                      .includes(
                                        filteredOptions[0]?.name.toLowerCase()
                                      )
                                  )
                                );
                                const autocompleteInput =
                                  document.getElementById("combo-box-demo");
                                if (autocompleteInput) {
                                  autocompleteInput.blur();
                                }
                              }
                            }
                          }}
                          filterOptions={(options, { inputValue }) => {
                            let filtered = options.filter((service) =>
                              service?.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            );

                            if (
                              inputValue &&
                              !filtered.some(
                                (s) =>
                                  s?.name.toLowerCase() ===
                                  inputValue.toLowerCase()
                              )
                            ) {
                              filtered = [
                                {
                                  name: inputValue,
                                  new: true,
                                },
                                ...filtered,
                              ];
                            }
                            return filtered;
                          }}
                          onChange={(event, newValue) => {
                            setSubCategoryId(newValue?._id);
                            setInputValue(newValue?.name || "");
                            if (newValue?._id !== "new") {
                              setFilteredOptions(
                                subCategoryData.filter((service) =>
                                  service?.name
                                    .toLowerCase()
                                    .includes(newValue?.name.toLowerCase())
                                )
                              );
                            }
                            let error = [...fieldError];
                            error[1].serviceSubCategory = !newValue;
                            setFieldsError(error);
                          }}
                          onOpen={() => {
                            setFilteredOptions(
                              subCategoryData.filter((service) =>
                                service?.name
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                              )
                            );
                          }}
                          ListboxProps={{
                            style: { maxHeight: "300px" },
                          }}
                          loading={showMenuLoader}
                          loadingText={<CircularProgress />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={fieldError[1].serviceSubCategory}
                              helperText={
                                fieldError[1].serviceSubCategory
                                  ? fieldError[1].message
                                  : ""
                              }
                            />
                          )}
                          renderOption={(props, option, index) => {
                            if (showMenuLoader && option?.new) {
                              return (
                                <Box
                                  key={`option-${index}`}
                                  sx={{
                                    height: "44px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <CircularProgress size={30} />
                                </Box>
                              );
                            } else if (!showMenuLoader && option?.new) {
                              return (
                                <Box
                                  key={`option-${index}`}
                                  padding={0}
                                  component="li"
                                  {...props}
                                  onClick={(e) => e.stopPropagation()} // Prevent selection
                                  sx={{
                                    height: "44px",
                                    width: "100%",
                                    cursor: "default",
                                  }}
                                  style={{
                                    paddingRight: "0",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>{option?.name}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      padding: "6px 16px",
                                      background: "rgba(33, 150, 243, 0.15)",
                                      cursor: "pointer",
                                      pointerEvents: "all",
                                    }}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      handleSaveService(option?.name);
                                    }}
                                  >
                                    <Save
                                      sx={{
                                        color: "#006CDD",
                                        width: "22px",
                                        height: "22px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        color: "#006CDD",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "22px",
                                        marginLeft: "6px",
                                      }}
                                    >
                                      Save
                                    </span>
                                  </div>
                                </Box>
                              );
                            } else if (!showMenuLoader) {
                              return (
                                <Box
                                  key={`option-${index}`}
                                  component="li"
                                  {...props}
                                  sx={{
                                    height: "44px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {option?.name}
                                </Box>
                              );
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  {/* Service Time field */}
                  <Grid item xs={12} sm={4} md={4}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Service Time (Hrs){" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={time ? dayjs(`2022-04-17T${time}`) : null}
                            onChange={(newValue) =>
                              setTime(dayjs(newValue).format("HH:mm"))
                            }
                            ampm={false}
                            timeSteps={{ minutes: 15 }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Stack>
                  </Grid>
                  {/* Visible on Apex Auto field */}
                  <Grid item xs={6} sm={8} md={8}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Visible on Apex Auto
                      </Typography>
                      <StyledToggleButtonGroup
                        exclusive
                        size="small"
                        value={isVisible}
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== isVisible) {
                            setIsVisible(newValue);
                          }
                        }}
                      >
                        <ToggleButton value={true} aria-label="left aligned">
                          Enabled
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="left aligned">
                          Disabled
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </Stack>
                  </Grid>
                  {/* Price Type field */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    style={{ opacity: !isVisible ? "0.4" : "" }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Price Type{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <StyledToggleButtonGroup
                        disabled={!isVisible}
                        exclusive
                        value={pricingType}
                        onChange={handleChangePriceType}
                      >
                        <ToggleButton
                          value={SHOP_SERVICE_PRICE_TYPES.NO_PRICE}
                          aria-label="left aligned"
                        >
                          No Price
                        </ToggleButton>
                        <ToggleButton
                          value={SHOP_SERVICE_PRICE_TYPES.STARTING_FROM}
                          aria-label="left aligned"
                        >
                          Starting From
                        </ToggleButton>
                        <ToggleButton
                          value={SHOP_SERVICE_PRICE_TYPES.FIXED}
                          aria-label="left aligned"
                        >
                          Fixed Price
                        </ToggleButton>
                        <ToggleButton
                          value={SHOP_SERVICE_PRICE_TYPES.RANGE}
                          aria-label="left aligned"
                        >
                          Price Range
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </Stack>
                  </Grid>
                  {/* Price field */}
                  {pricingType === SHOP_SERVICE_PRICE_TYPES.FIXED ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      style={{ opacity: !isVisible ? "0.4" : "" }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Fixed Price{" "}
                          <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>
                        <Controller
                          name="fixedPrice"
                          control={control}
                          rules={{ required: "Fixed Price is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={!isVisible}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              error={!!errors.fixedPrice}
                              helperText={
                                errors.fixedPrice
                                  ? errors.fixedPrice.message
                                  : ""
                              }
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                const numericValue = inputValue.replace(
                                  /[^\d.]/g,
                                  ""
                                );
                                setValue(
                                  "fixedPrice",
                                  FormatNumberForKmFloat(numericValue)
                                );
                              }}
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  ) : pricingType === SHOP_SERVICE_PRICE_TYPES.RANGE ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      style={{ opacity: !isVisible ? "0.4" : "" }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Price Range{" "}
                          <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          rowGap={2}
                          marginTop={"20px"}
                        >
                          <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                            <Controller
                              name="priceMin"
                              control={control}
                              rules={{ required: "Price is required" }}
                              render={({ field }) => (
                                <TextField
                                  id="outlined-basic"
                                  disabled={!isVisible}
                                  label={openFor === "edit" ? "" : "Minimum"}
                                  variant="outlined"
                                  fullWidth
                                  error={!!errors.priceMin}
                                  helperText={
                                    errors.priceMin
                                      ? errors.priceMin.message
                                      : ""
                                  }
                                  {...field}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const numericValue = inputValue.replace(
                                      /[^\d.]/g,
                                      ""
                                    );
                                    setValue(
                                      "priceMin",
                                      FormatNumberForKmFloat(numericValue)
                                    );
                                  }}
                                  InputLabelProps={{
                                    shrink: field.value?.length > 0,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                            <Controller
                              name="priceMax"
                              control={control}
                              rules={{ required: "Price is required" }}
                              render={({ field }) => (
                                <TextField
                                  disabled={!isVisible}
                                  id="outlined-basic"
                                  label={openFor === "edit" ? "" : "Maximum"}
                                  variant="outlined"
                                  fullWidth
                                  error={!!errors.priceMax}
                                  helperText={
                                    errors.priceMax
                                      ? errors.priceMax.message
                                      : ""
                                  }
                                  {...field}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const numericValue = inputValue.replace(
                                      /[^\d.]/g,
                                      ""
                                    );
                                    setValue(
                                      "priceMax",
                                      FormatNumberForKmFloat(numericValue)
                                    );
                                  }}
                                  InputLabelProps={{
                                    shrink: field.value?.length > 0,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Stack>
                        {validationPriceRangeError && (
                          <Typography variant="body2" color="error">
                            {validationPriceRangeError}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  ) : pricingType === SHOP_SERVICE_PRICE_TYPES.STARTING_FROM ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      style={{ opacity: !isVisible ? "0.4" : "" }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Starting From{" "}
                          <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>
                        <Controller
                          name="priceMin"
                          control={control}
                          rules={{
                            required: "Starting From Price is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={!isVisible}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              error={!!errors.priceMin}
                              helperText={
                                errors.priceMin ? errors.priceMin.message : ""
                              }
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                const numericValue = inputValue.replace(
                                  /[^\d.]/g,
                                  ""
                                );
                                setValue(
                                  "priceMin",
                                  FormatNumberForKmFloat(numericValue)
                                );
                              }}
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  ) : null}
                </Grid>
              </Scrollbar>
              <Grid
                item
                container
                justifyContent={"flex-end"}
                spacing={2}
                p={3}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCloseWrapper}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Loader showLoader={loading} />
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </Box>
  );
};

export default ManageServiceModal;
