import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advanced format plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form"; // Step 1
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../App";
import {
  fetchShopClosingTime,
  reschuleAppointment,
} from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import "../Estimates/Estimates.css";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const textAreaStyle = {
  width: "100%",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
  fontWeight: 400,
};

const ReseduleAppointmentModal = ({
  open,
  handleClose,
  id,
  handleClose2,
  date,
  recallDetailsAPI,
  fromCaledner,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({});

  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef(null);
  const localTimezone = dayjs.tz.guess();
  const [showLoader, setShowLoader] = React.useState(false);
  console.log("🚀 ~ showLoader:", showLoader);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const validateDateTime = (proposeDateTime, estimatePickUpDateAndTime) => {
    if (proposeDateTime && estimatePickUpDateAndTime) {
      const proposeNewDate = new Date(
        proposeDateTime.$y,
        proposeDateTime.$M,
        proposeDateTime.$D,
        proposeDateTime.$H,
        proposeDateTime.$m,
        proposeDateTime.$s,
        proposeDateTime.$ms
      );
      const isoProposeDate = proposeNewDate.toISOString();

      const newDate = new Date(
        estimatePickUpDateAndTime.$y,
        estimatePickUpDateAndTime.$M,
        estimatePickUpDateAndTime.$D,
        estimatePickUpDateAndTime.$H,
        estimatePickUpDateAndTime.$m,
        estimatePickUpDateAndTime.$s,
        estimatePickUpDateAndTime.$ms
      );
      const isoPickUpDate = newDate.toISOString();

      const date1String = isoProposeDate;
      const date2String = isoPickUpDate;

      const date1 = new Date(date1String);
      const date2 = new Date(date2String);

      if (date1.getTime() === date2.getTime() || date1 > date2) {
        setError("pickUpDateAndTime", {
          type: "custom",
          message: "Pick up time must be after drop off.",
        });
        return true;
      } else {
        clearErrors("pickUpDateAndTime");
        return false;
      }
    }
  };

  const onSubmit = async (formData) => {
    const { proposeDateAndTime, pickUpDateAndTime, noteForCustomer } = formData;

    if (!proposeDateAndTime || !pickUpDateAndTime) {
      toast.error("Propose and Pick up Date and Time both are required");
      return;
    }
    if (validateDateTime(proposeDateAndTime, pickUpDateAndTime)) {
      return;
    }
    const dropOff = new Date(proposeDateAndTime);
    const pickUp = new Date(pickUpDateAndTime);

    if (dropOff >= pickUp) {
      toast.error("Pick up time must be after drop off.");
      return;
    }
    const payload = {
      dropOffDateTime: dropOff.toISOString(),
      pickupDateTime: pickUp.toISOString(),
      noteForCustomer,
      appointmentId: id,
    };
    setShowLoader(true);
    const reschedule = await dispatch(reschuleAppointment(payload));
    if (reschedule) {
      setShowLoader(false);
      reset();
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment rescheduled successfully");
      setTimeout(() => {
        handleClose();
        // if (!fromCaledner) {
        handleClose2();
        // }
      }, 1000);
      if (
        window.location.pathname.split("/").includes("workOrderAuthorization")
      ) {
        recallDetailsAPI();
      }
      // if (fromCaledner) {
      // recallDetailsAPI();
      // }
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getShopClosingTime(dayjs().format("YYYY-MM-DDTHH:mm"));
  }, [open]);

  const getShopClosingTime = async (time) => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
    let resp = await dispatch(
      fetchShopClosingTime({
        dropOffDateTime: dayjs(time).utc(),
        cancelToken: cancelTokenSourceRef.current.token,
      })
    );

    // Convert API response to local time
    const shopClosingTimeLocal = dayjs(resp.shopClosingTime).tz(localTimezone);

    setValue("pickUpDateAndTime", shopClosingTimeLocal);
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={9} md={9} xl={9}>
            <Modal
              open={open}
              onClose={() => {
                handleClose();
                reset();
              }}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                {" "}
                <Box
                  sx={{
                    ...style,
                    height: "fit-content",
                    width: "848px",
                    border: "none",
                  }}
                >
                  <AppBar
                    variant="elevation"
                    sx={{
                      height: "64px",
                      paddingRight: "0px !important",
                      boxShadow: "none",
                    }}
                  >
                    <Toolbar variant="regular">
                      <Typography variant="h6" fontWeight={500} width={"98%"}>
                        Reschedule Appointment
                      </Typography>
                      <Stack direction={"row"} spacing={0}>
                        <div
                          onClick={() => {
                            handleClose();
                            reset();
                          }}
                          style={{ cursor: "pointer", height: "32px" }}
                        >
                          <CloseIcon sx={{ fontSize: 32 }} />
                        </div>
                      </Stack>
                    </Toolbar>
                  </AppBar>
                  <Container
                    gap={4}
                    disableGutters
                    style={{ marginTop: "66px" }}
                  >
                    <Grid container padding={"10px 0px"}>
                      <Grid container item xs={12} md={12} xl={12} spacing={3}>
                        <Grid item>
                          <Stack spacing={2}>
                            <Stack>
                              <Typography
                                variant="body1"
                                fontWeight={500}
                                fontSize={"16px"}
                              >
                                Current Appointment Drop Off
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography
                                variant="body1"
                                fontWeight={500}
                                fontSize={"16px"}
                              >
                                {/* {date} */}

                                {date &&
                                  moment(date).format(
                                    "ddd. MMM D, YYYY h:mm A"
                                  )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                          <Typography
                            variant="body1"
                            padding={"8px 0px"}
                            fontWeight={500}
                            fontSize={16}
                          >
                            New Drop Off Date and Time{" "}
                            <span style={{ color: REQUIRED_RED }}> *</span>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <Controller
                                name="proposeDateAndTime"
                                control={control}
                                rules={{
                                  required:
                                    "Drop-off date and time is required",
                                }}
                                render={({ field }) => (
                                  <DateTimePicker
                                    name="proposeDateAndTime"
                                    value={field.value}
                                    onChange={async (value) => {
                                      field.onChange(value);
                                      await getShopClosingTime(value);
                                      validateDateTime(
                                        value,
                                        getValues().pickUpDateAndTime
                                      );
                                    }}
                                    minDate={dayjs().startOf("day")}
                                    timeSteps={{ minutes: 30 }}
                                  />
                                )}
                              />
                              {errors.proposeDateAndTime && (
                                <Typography
                                  color="red"
                                  fontSize="14px"
                                  style={{ marginTop: "4px" }}
                                >
                                  {errors.proposeDateAndTime.message}
                                </Typography>
                              )}
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                          <Typography
                            variant="body1"
                            padding={"8px 0px"}
                            fontWeight={500}
                            fontSize={16}
                          >
                            New Pick Up Date and Time{" "}
                            <span style={{ color: REQUIRED_RED }}> *</span>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <Controller
                                name="pickUpDateAndTime"
                                control={control}
                                rules={{
                                  required: "Pick-up date and time is required",
                                }}
                                render={({ field }) => (
                                  <DateTimePicker
                                    {...field}
                                    minDate={dayjs().startOf("day")}
                                    name="pickUpDateAndTime"
                                    value={field.value}
                                    onChange={(value) => {
                                      field.onChange(value);
                                      validateDateTime(
                                        getValues().proposeDateAndTime,
                                        value
                                      );
                                    }}
                                    timeSteps={{ minutes: 30 }}
                                  />
                                )}
                              />
                              {errors.pickUpDateAndTime && (
                                <Typography
                                  color="red"
                                  fontSize="14px"
                                  style={{ marginTop: "4px" }}
                                >
                                  {errors.pickUpDateAndTime.message}
                                </Typography>
                              )}
                            </Stack>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={12} xl={12}>
                          <Stack spacing={2}>
                            <Controller
                              name="noteForCustomer"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <textarea
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    style={textAreaStyle}
                                    placeholder="Type Optional Message"
                                  />
                                </>
                              )}
                            />
                          </Stack>
                        </Grid>

                        <Grid
                          item
                          container
                          justifyContent={"end"}
                          spacing={2}
                          style={{ marginTop: "0px" }}
                        >
                          <Grid item>
                            <Stack spacing={2} direction="row">
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleClose();
                                  reset();
                                }}
                              >
                                CANCEL
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                              >
                                RESCHEDULE
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </form>
            </Modal>
          </Grid>
        </Grid>
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
        <Dialog fullScreen={fullScreen} open={showLoader}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default ReseduleAppointmentModal;
