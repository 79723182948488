import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  TableContainer,
  TablePagination,
  TableSortLabel,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { green } from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatNumber from "../../../components/FormatNumber/FormatNumber";
import {
  deleteServices,
  getShopServicesListing,
  updateShopServiceStatus,
} from "../../../redux/Account/ServiceOffered/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AddNewServiceModal from "../../modal/AddNewServiceModal";
import ManageServiceModal from "../../modal/ManageServiceModal";
import { SHOP_SERVICE_PRICE_TYPES } from "../../../utility/Constant";
import { formatCurrency } from "../../../utility/helpers/displayHelper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const SwitchToggle = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[500],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));
export default function ServiceTable({
  selectedCategory,
  selectedPriceType,
  selectedVisibility,
  selectedIds,
  searchValue,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const Servicecategories = useSelector(
    (state) => state?.account?.shopServicesListing
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    field: "category",
    order: "ASC",
  });
  const [totalCount, setTotalCount] = useState(0);
  const [switchValues, setSwitchValues] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openDeleteConformModal, setOpenDeleteConformModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    fetchServices();
  }, [selectedPriceType, page, rowsPerPage, sortConfig, selectedVisibility]);

  useEffect(() => {
    setPage(0);
    fetchServices();
  }, [searchValue]);

  useEffect(() => {
    if (selectedIds.length === 1) {
      setPage(0);
      setSortConfig({
        field: "name",
        order: "ASC",
      });
    } else {
      fetchServices();
      setPage(0);
    }
  }, [selectedIds]);

  const fetchServices = async () => {
    setLoader(true);
    const res = await dispatch(
      getShopServicesListing(
        rowsPerPage,
        page + 1,
        searchValue,
        selectedIds,
        selectedPriceType,
        sortConfig.order,
        sortConfig.field,
        selectedVisibility
      )
      // showShopServices
    );
    if (res) {
      setTotalCount(res.totalCount);
      setSwitchValues(res.data);
    }
    setLoader(false);
  };

  const handleSort = (field) => {
    setSortConfig((prev) => ({
      field,
      order: prev.field === field && prev.order === "ASC" ? "DSC" : "ASC",
    }));
  };

  const handleDelete = async () => {
    setLoader(true);
    const res = await dispatch(deleteServices(deleteId));
    if (res) {
      fetchServices();
      setOpenSuccessModal(true);
      setSuccessMessage("Service deleted successfully");
    }
    setLoader(false);
    setOpenDeleteConformModal(false);
  };

  const handleToggleVisibility = async (value, id, index) => {
    setLoader(true);
    const res = await dispatch(
      updateShopServiceStatus({ showShopServices: value }, id)
    );
    if (res) {
      setSwitchValues((prev) => {
        const updated = [...prev];
        updated[index].showShopServices = value;
        return updated;
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    setSwitchValues(Servicecategories?.data);
  }, [Servicecategories]);

  return (
    <>
      <Dialog fullScreen={fullScreen} open={loader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Grid container item borderRadius={"10px"}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <TableContainer sx={{ maxHeight: 600, maxWidth: "100%" }}>
            <Table padding="16px">
              <TableHead>
                <TableRow style={{ fontWeight: 500 }}>
                  <TableCell style={{ width: 360 }}>
                    <TableSortLabel
                      active={sortConfig.field === "name"}
                      direction={sortConfig.order === "ASC" ? "asc" : "desc"}
                      onClick={() => handleSort("name")}
                    >
                      Name
                    </TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={sortConfig.field === "category"}
                      direction={sortConfig.order === "ASC" ? "asc" : "desc"}
                      onClick={() => {
                        if (selectedIds.length === 1) {
                        } else {
                          handleSort("category");
                        }
                      }}
                    >
                      {" "}
                      Category
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Service Time (Hrs)</TableCell>
                  {/* VISIBLE SHOP PROFILE SWITCH BUTTON CR POINT 652 */}

                  <TableCell>Visible on Apex Auto App</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Servicecategories?.data?.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: 360 }}
                    >
                      {row.serviceSubCategory.name}
                    </TableCell>
                    <TableCell align="left">
                      {row.serviceCategory.name}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row?.priceMin} - {row?.priceMax} */}
                      {row?.priceType ===
                      SHOP_SERVICE_PRICE_TYPES.STARTING_FROM ? (
                        `Starting from: ${formatCurrency(row?.priceMin)}`
                      ) : row.priceType === SHOP_SERVICE_PRICE_TYPES.RANGE ? (
                        `Range ${formatCurrency(
                          row?.priceMin
                        )} - ${formatCurrency(row?.priceMax)}`
                      ) : row.priceType === SHOP_SERVICE_PRICE_TYPES.FIXED ? (
                        <FormatNumber
                          inputValue={(row?.fixedPrice).toString()}
                        />
                      ) : (
                        "No Price"
                      )}
                    </TableCell>
                    <TableCell align="left">{row.serviceTimeInHrs}</TableCell>
                    {/* VISIBLE SHOP PROFILE SWITCH BUTTON CR POINT 652 */}

                    <TableCell align="left">
                      <SwitchToggle
                        checked={row.showShopServices}
                        onChange={(e) =>
                          handleToggleVisibility(
                            e.target.checked,
                            row._id,
                            index
                          )
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      <Box>
                        <Stack direction="row" spacing={1}>
                          <div>
                            <Button
                              variant="contained"
                              style={{ lineHeight: "24px" }}
                              onClick={() => {
                                setOpen(true);
                                setEditData(row);
                              }}
                            >
                              {" "}
                              Edit
                            </Button>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              size="medium"
                              color="error"
                              onClick={() => {
                                setDeleteId(row._id);
                                setOpenDeleteConformModal(true);
                              }}
                            >
                              {" "}
                              Delete
                            </Button>
                            <SuccessMessageModal
                              message={successMessage}
                              open={openSuccessModal}
                              setOpenSuccessModal={setOpenSuccessModal}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        </Grid>
      </Grid>
      {loader ? (
        ""
      ) : (
        <TablePagination
          rowsPerPageOptions={[
            5, 10, 25,
            // { label: "All", value: 1000 },
          ]}
          colSpan={3}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          // page={page}
          page={page}
          slotProps={{
            select: {
              "aria-label": "rows per page",
            },
            actions: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
          onPageChange={(event, newPage) => setPage(newPage)}
          className="pagination-right"
        />
      )}
      <Dialog
        open={openDeleteConformModal}
        onClose={() => setOpenDeleteConformModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this service?{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);
            }}
          >
            No
          </Button>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              handleDelete();
              setOpenDeleteConformModal(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ManageServiceModal
        open={open}
        handleClose={() => setOpen(false)}
        openFor="edit"
        editData={editData}
        page={page}
        limit={10}
        searchValue={searchValue}
        getShopServicesListing={fetchServices}
        deleteData={handleDelete}
        selectedIds={selectedIds}
        selectedPriceType={selectedPriceType}
      />
    </>
  );
}
