import { useTheme } from "@emotion/react";
import {
  alpha,
  Grid,
  styled,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  Modifier,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import googleImg from "../../../../src/assets/googleImage.png";
import googleImgSmall from "../../../../src/assets/googleSmall.png";
import { Context, REQUIRED_RED } from "../../../App";
import { updateShopConfigApi } from "../../../redux/Account/action";
import {
  emailThankuDetails,
  sendTestThankuEmail,
  updateEmailThankuDetails,
} from "../../../redux/Account/peronalIfno/action";
import { APPOINTMENT_REVIEW_PLATFORM } from "../../../utility/Constant";
import SuccessMessageModal from "../../Common/SuccessMessage";
import "./EmailCustomer.css";

const FilterIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
  >
    <mask
      id="mask0_7986_90880"
      maskUnits="userSpaceOnUse"
      x="0"
      y="-1"
      width="23"
      height="22"
    >
      <path
        d="M22.7601 -0.00012207H0V20.0001H22.7601V-0.00012207Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_7986_90880)">
      <path
        d="M3.98962 18.7954C4.15355 18.5241 4.23121 18.2238 4.23698 17.9177C4.2571 16.8927 4.49295 15.9053 5.00493 15.0506L9.67027 7.32424C10.2542 6.34833 11.1803 5.62651 12.2791 5.18767C12.6472 5.04039 12.9809 4.8152 13.2541 4.50916C13.8582 3.83353 14.025 2.92978 13.7834 2.10116L12.3683 3.28491C12.0289 3.5679 11.5082 3.5852 11.0912 3.32826C10.7949 3.14636 10.536 2.91248 10.329 2.64108L10.2053 2.48225C9.94928 2.14736 9.96656 1.71138 10.2456 1.44864L11.6894 0.0973513C10.6827 -0.168245 9.58977 0.0973511 8.89082 0.874061C8.36157 1.46594 8.16598 2.23396 8.28968 2.97312C8.46799 4.05008 8.25514 5.11258 7.72304 6.00475L3.0692 13.864C2.56872 14.7042 1.86115 15.4173 0.966618 15.8735C0.655991 16.0323 0.39425 16.272 0.218793 16.5924C-0.2673 17.4673 0.086484 18.6482 1.01839 19.2747C2.06824 19.9763 3.4201 19.7483 3.98962 18.7954ZM1.24275 18.6655C1.20247 18.5702 1.12193 18.4894 1.02127 18.449C0.854461 18.3797 0.75091 18.2122 0.773919 18.0505C0.7883 17.9524 0.756661 17.8513 0.684754 17.7676C0.569703 17.6318 0.563949 17.4471 0.670372 17.3258C0.733652 17.2536 0.759538 17.1526 0.739402 17.0544C0.704889 16.8898 0.796931 16.7339 0.958003 16.6877C1.05582 16.6588 1.13059 16.5895 1.16509 16.5C1.22263 16.3499 1.38656 16.2691 1.55914 16.3066C1.66268 16.3297 1.76911 16.3095 1.84965 16.2518C1.98197 16.1565 2.17755 16.168 2.3156 16.2835C2.39902 16.3528 2.50833 16.3874 2.61188 16.3788C2.78446 16.3643 2.957 16.4683 3.02316 16.6271C3.06343 16.7224 3.14397 16.8032 3.24467 16.8436C3.41149 16.9129 3.51503 17.0804 3.49203 17.2421C3.47764 17.3403 3.50926 17.4413 3.58119 17.525C3.69623 17.6607 3.702 17.8455 3.59557 17.9668C3.5323 18.039 3.50641 18.14 3.52653 18.2382C3.56103 18.4028 3.46899 18.5587 3.30794 18.6049C3.21013 18.6338 3.13536 18.703 3.10086 18.7925C3.04332 18.9427 2.87935 19.0235 2.70392 18.986C2.60038 18.9629 2.49395 18.9831 2.41341 19.0409C2.28109 19.1361 2.08551 19.1246 1.94747 19.0091C1.86404 18.9398 1.75473 18.9051 1.65118 18.9138C1.48149 18.9282 1.3089 18.8272 1.24275 18.6655Z"
        fill={color}
      />
      <path
        d="M10.8011 5.13124C10.3496 5.18323 9.85772 5.05329 9.46655 4.779C9.07538 4.50471 8.79064 4.08894 8.68994 3.65295C9.10704 4.42677 9.92099 5.03019 10.8011 5.13124Z"
        fill={color}
      />
      <path
        d="M11.1384 0.249023C10.8536 0.454016 10.5861 0.673455 10.3244 0.898649C10.2582 0.953539 10.1921 1.01129 10.1288 1.06903C10.0626 1.12964 10.0022 1.17873 9.93321 1.24803C9.86705 1.31443 9.8124 1.39238 9.77216 1.47612C9.72901 1.55986 9.69735 1.64936 9.68012 1.74176C9.64558 1.92945 9.66574 2.12864 9.73474 2.31633C9.76928 2.41163 9.82105 2.49823 9.87571 2.58483C9.93609 2.67147 9.99648 2.73787 10.0569 2.81296C10.3043 3.1132 10.6178 3.36729 10.9687 3.53478C10.6609 3.30089 10.3963 3.0295 10.1863 2.72921C10.1346 2.65127 10.0742 2.57328 10.0281 2.50398C9.98498 2.42893 9.94471 2.35388 9.92171 2.27303C9.86994 2.11709 9.85555 1.94965 9.88721 1.79661C9.91309 1.64071 9.98498 1.49922 10.0885 1.38083C10.1374 1.32308 10.2064 1.25958 10.264 1.20183C10.3244 1.14119 10.3848 1.08058 10.4452 1.01704C10.681 0.768748 10.9169 0.517558 11.1384 0.249023Z"
        fill={color}
      />
      <path
        d="M13.6192 2.59033C13.6336 3.1505 13.4553 3.67598 13.1418 4.09462C12.9807 4.30251 12.7908 4.4873 12.5694 4.63454C12.4601 4.70674 12.3422 4.77313 12.2185 4.82798C12.0977 4.87998 11.9596 4.93193 11.833 4.98968C11.3182 5.22356 10.8407 5.52671 10.4323 5.89919C10.021 6.26877 9.68446 6.70761 9.44287 7.18979C9.75638 6.74805 10.1303 6.35537 10.5474 6.01758C10.9673 5.67975 11.4333 5.39391 11.9366 5.16871C12.0632 5.11097 12.1868 5.06187 12.322 5.00123C12.4543 4.94058 12.578 4.86843 12.6931 4.78758C12.9232 4.62299 13.1245 4.4209 13.2769 4.18991C13.4323 3.95892 13.5416 3.69908 13.602 3.42765C13.6566 3.15336 13.6681 2.87042 13.6192 2.59033Z"
        fill={color}
      />
      <path
        d="M0.445312 16.7237C0.643774 16.4552 0.902646 16.256 1.21039 16.1261C1.36859 16.0568 1.51529 15.9673 1.66198 15.8807C1.8058 15.7883 1.94384 15.6901 2.07615 15.5833C2.3379 15.3696 2.57375 15.1271 2.77799 14.8644C2.98219 14.6016 3.15766 14.3157 3.28709 14.0097C3.08 14.2695 2.87003 14.5208 2.64568 14.7575C2.53063 14.873 2.42132 14.9943 2.29762 15.1011C2.1797 15.2137 2.05604 15.3205 1.9295 15.4245C1.67637 15.6324 1.40597 15.82 1.11835 15.9875C0.819219 16.1521 0.56324 16.4119 0.445312 16.7237Z"
        fill={color}
      />
      <path
        d="M4.38867 19.9925C4.7108 18.7018 5.23717 17.5787 5.87285 16.4988C7.13267 14.3651 8.71461 12.4711 10.2793 10.5626C10.9869 9.69927 11.6398 8.79266 12.3157 7.90339C12.3819 7.81675 12.4452 7.73015 12.5516 7.66665C11.9217 9.21709 11.2918 10.7676 10.6417 12.3614C12.2927 12.3614 13.8775 12.3614 15.5199 12.3614C15.146 11.4461 14.7865 10.5626 14.4269 9.68197C14.0444 8.74071 13.6561 7.79945 13.2879 6.85243C13.2333 6.71094 13.2218 6.50885 13.2793 6.37311C13.587 5.60509 13.984 4.86887 14.2256 4.08351C14.4672 3.30104 14.5563 2.47238 14.7232 1.62066C14.9677 1.50806 15.0281 1.74191 15.1115 1.94115C15.8363 3.65042 16.564 5.3597 17.2917 7.06898C19.0549 11.2238 20.8181 15.3786 22.5812 19.5334C22.6359 19.6633 22.6819 19.7961 22.7567 19.9925C22.1411 19.9925 21.5832 19.9925 21.0223 19.9925C20.355 19.9925 19.6905 19.9751 19.0233 20.0011C18.7184 20.0127 18.5717 19.9232 18.4566 19.6286C18.0166 18.4911 17.5391 17.3708 17.0933 16.2361C16.9926 15.982 16.866 15.8867 16.587 15.8896C14.24 15.9012 11.8958 15.9012 9.54872 15.8896C9.28987 15.8896 9.17194 15.9762 9.07701 16.2159C8.62546 17.365 8.14798 18.5026 7.69642 19.6517C7.59572 19.9029 7.47206 20.0069 7.19305 20.0011C6.28413 19.9809 5.37236 19.9925 4.38867 19.9925Z"
        fill={color}
      />
    </g>
  </svg>
);
const SwitchToggle = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[500],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));

const EmailCustomer = ({
  setShowLoader,
  selectedButton,
  setSelectedButton,
}) => {
  const dispatch = useDispatch();
  // const [showLoader, setShowLoader] = useState(false);
  const { isOpenSideBar } = useContext(Context);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [thankuSwitch, setThankuSwitch] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const {
    handleSubmit,

    setValue,
    control,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("1280"));
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  console.log("🚀 ~ isSmUp:", isSmUp);

  useEffect(() => {
    fetchThankYouEmailDetails();
  }, []);

  const fetchThankYouEmailDetails = async () => {
    setShowLoader(true);

    const data = await dispatch(emailThankuDetails());

    if (data) {
      setShowLoader(false);
    }
  };

  const DetailsData = useSelector(
    (state) => state?.account?.thankyouDetailData
  );
  function cleanHtmlContent(html) {
    // Remove unnecessary <br> tags and newlines between <p> tags
    const cleanedHtml = html
      .replace(/<pre>/g, "<p>") // Remove opening <pre> tag
      .replace(/<\/pre>/g, "</p>") // Remove closing </pre> tag
      .replace(/&#10;/g, "</p><p>") // Replace newline characters with closing and opening <p> tags

      .replace(/<p>\s*<\/p>/g, "") // Remove empty <p> tags
      .replace(/\n\s*<p>/g, "<p>") // Remove newline before <p> tags
      .replace(/<code>/g, '<code style="font-family:Roboto">')
      .replace(/<p>/g, '<p style="margin:0">');

    return cleanedHtml;
  }

  useEffect(() => {
    setValue("shopEmail", DetailsData?.replyToEmail);
    setThankuSwitch(DetailsData?.isShopSendThankYouEmail);

    // Convert HTML to ContentState
    const blocksFromHTML = convertFromHTML(
      DetailsData?.shopThankYouEmailSignature
        ? cleanHtmlContent(DetailsData?.shopThankYouEmailSignature)
        : ""
    );

    // Safeguard to check if the conversion returned valid blocks
    const contentBlocks = blocksFromHTML.contentBlocks;
    let contentState =
      contentBlocks.length > 0
        ? ContentState.createFromBlockArray(
            contentBlocks,
            blocksFromHTML.entityMap
          )
        : ContentState.createFromText(""); // Fallback to an empty content state

    // Apply the Roboto style to every character
    const selectionState = contentState.getSelectionAfter();
    const blockMap = contentState.getBlockMap();

    blockMap.forEach((block) => {
      const blockKey = block.getKey();
      const length = block.getLength();

      for (let i = 0; i < length; i++) {
        const updatedSelection = selectionState.merge({
          anchorKey: blockKey,
          anchorOffset: i,
          focusKey: blockKey,
          focusOffset: i + 1,
        });

        contentState = Modifier.applyInlineStyle(
          contentState,
          updatedSelection,
          "ROBOTO"
        );
      }
    });
    setEditorState(EditorState.createWithContent(contentState));

    // }, 800);
  }, [DetailsData]);

  const onSubmitEmailDetails = async (values) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.trim() === "") {
      return setError(true);
    } else {
      setError(false);
    }

    let htmlContent = stateToHTML(editorState.getCurrentContent());
    htmlContent = cleanHtmlContent(htmlContent);
    console.log("htmlContent", htmlContent, values, thankuSwitch);
    let payload = {
      isShopSendThankYouEmail: thankuSwitch,
      replyToEmail: values?.shopEmail,
      shopThankYouEmailSignature: htmlContent,
    };
    setShowLoader(true);
    const response = await dispatch(
      updateShopConfigApi({ appointmentReviewPlatform: selectedButton })
    );
    if (
      response?.config?.appointmentReviewPlatform &&
      response?.config?.appointmentReviewPlatform !== ""
    ) {
      setSelectedButton(response.config.appointmentReviewPlatform);
    } else {
      setSelectedButton(APPOINTMENT_REVIEW_PLATFORM.GOOGLE);
    }
    let resp = "";
    if (isTesting) {
      delete payload.isShopSendThankYouEmail;
      payload.shopThankYouEmailSignature = `${htmlContent}`;
      resp = await dispatch(sendTestThankuEmail(payload));
    } else {
      if (thankuSwitch == false) {
        delete payload.replyToEmail;
        delete payload.shopThankYouEmailSignature;
      }
      resp = await dispatch(updateEmailThankuDetails(payload));
    }
    if (resp && !isTesting) {
      fetchThankYouEmailDetails();
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
    }

    if (resp && isTesting) {
      setOpenSuccessModal(true);
      setSuccessMessage("Email Sent");
    }

    setShowLoader(false);
  };

  const cancelHandle = () => {
    setValue("shopEmail", DetailsData?.replyToEmail);
    setThankuSwitch(DetailsData?.isShopSendThankYouEmail);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(DetailsData?.shopThankYouEmailSignature ?? "")
        )
      )
    );
  };

  const validateNoLeadingSpaces = (value) => {
    if (value && value.trim() !== value) {
      return "Leading spaces are not allowed";
    }
    return true;
  };
  const StyledToggleButtonGroupLaptop = styled(ToggleButtonGroup)(
    ({ theme }) => ({
      borderRadius: "30px",
      padding: "6px",
      background: "#E9E9EA",
      display: "flex",
      alignItems: "center",
      gap: "6px",
      width: "max-content",

      "& .MuiToggleButton-root": {
        border: "none",
        color: "#FFF",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.15px",
        fontFeatureSettings: "'liga' off, 'clig' off",
        borderRadius: "40px",
        padding: "0 12px",
        transition: "none", // Disable hover effects
        textTransform: "capitalize",
        height: "40px",
        display: "flex",
        alignItems: "center",
        gap: "12px",

        "&.Mui-selected": {
          backgroundColor: "#006CDD",
          color: "#FFF",
        },

        "&:not(.Mui-selected)": {
          backgroundColor: "#E9E9EA",
          color: "#000",
        },

        // Custom hover effects for unselected buttons
        "&:not(.Mui-selected):hover": {
          backgroundColor: "#E9E9EA", // Change this color for unselected hover
        },

        // Custom hover effects for selected buttons
        "&.Mui-selected:hover": {
          backgroundColor: "#006CDD", // Change this color for selected hover
        },
      },
    })
  );

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    borderRadius: "50px",
    padding: "6px",
    background: "#E9E9EA",
    display: "flex",
    flexDirection: "coloumn",
    alignItems: "center",
    gap: "6px",
    width: "max-content",

    "& .MuiToggleButton-root": {
      border: "none",
      color: "#FFF",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.15px",
      fontFeatureSettings: "'liga' off, 'clig' off",
      borderRadius: "40px",
      transition: "none", // Disable hover effects
      textTransform: "capitalize",
      height: "100%",
      display: "flex",
      padding: "10.5px 12px",
      flexDirection: "column",
      alignItems: "center",
      gap: "4px",
      width: "160px",

      "&.Mui-selected": {
        backgroundColor: "#006CDD",
        color: "#FFF",
      },

      "&:not(.Mui-selected)": {
        backgroundColor: "#E9E9EA",
        color: "#000",
      },

      // Custom hover effects for unselected buttons
      "&:not(.Mui-selected):hover": {
        backgroundColor: "#E9E9EA", // Change this color for unselected hover
      },

      // Custom hover effects for selected buttons
      "&.Mui-selected:hover": {
        backgroundColor: "#006CDD", // Change this color for selected hover
      },
    },
  }));

  const ThankyouToggle = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      defaultChecked={thankuSwitch}
      onChange={(e) => setThankuSwitch(e.target.checked)}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#92BC94",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(120, 120, 128, 0.16)"
          : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const handleEditorChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
    // const rawContent = convertToRaw(newEditorState.getCurrentContent());
    // const text = rawContent.blocks.map((block) => block.text).join("");
    // console.log("text", text);

    setEditorState(newEditorState);
  };
  const blockStyleFn = (block) => {
    if (block.getType() === "header-one") {
      return "header-one";
    } else if (block.getType() === "header-two") {
      return "header-two";
    } else if (block.getType() === "ROBOTO_BLOCK") {
      return "roboto-block";
    } else {
      return null;
    }
  };

  // Handle pasted text to apply the Roboto style
  const handlePastedText = (text, html, editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Replace pasted content with plain text and apply Roboto style
    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      text,
      editorState.getCurrentInlineStyle().add("ROBOTO")
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    setEditorState(newEditorState);
    return "handled";
  };

  return (
    <>
      {" "}
      <form
        onSubmit={handleSubmit(onSubmitEmailDetails)}
        style={{ marginTop: "20px" }}
      >
        <Grid
          container
          sx={{ boxShadow: 3, bgcolor: "white" }}
          justifyContent={"space-between"}
          borderTop={"none"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
        >
          <Grid
            item
            xs={12}
            sm={isOpenSideBar ? 12 : 12}
            md={isOpenSideBar ? 12 : 6}
            lg={3.8}
            xl={4}
            mb={{ xs: "24px", sm: "24px" }}
          >
            <div style={{ display: "flex" }}>
              <Typography variant="h5">Email & Review Settings</Typography>
            </div>
            <Stack mt={"12px"} gap={3} style={{ maxWidth: "352px" }}>
              <div>
                <Typography
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Filter Reviews through Apex Auto
                </Typography>
                <Typography
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Collect reviews privately on Apex Auto first. Positive reviews
                  (4+ stars) are sent to Google, while negative reviews are
                  emailed to you for follow-up, protecting your online
                  reputation.
                </Typography>
              </div>

              <div>
                <Typography
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Direct Google Reviews
                </Typography>
                <Typography
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  {`Send customers straight to Google for reviews. This option
                    focuses on boosting Google reviews doesn’t allow for
                    filtering or addressing negative feedback privately.`}
                </Typography>
              </div>

              <Typography
                style={{
                  margin: "0",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                {`Automatically send personalized thank-you emails to customers after their visit. Enhance the customer experience with a simple gesture while encouraging feedback and building loyalty.`}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={isOpenSideBar ? 12 : 12}
            md={isOpenSideBar ? 12 : 6}
            lg={7.7}
            xl={8}
            // mt={!isMobile ? "24px" : ""}
            style={{ height: "max-content" }}
          >
            <Grid container item xs={12} gap={3}>
              <Grid item xs={12}>
                {isSmUp ? (
                  <StyledToggleButtonGroupLaptop
                    size="small"
                    value={selectedButton}
                    exclusive
                    onChange={(event, newValue) => {
                      // Only update the selectedButton if the new value is different
                      if (newValue && newValue !== selectedButton) {
                        console.log("🚀 ~ value:", newValue);
                        setSelectedButton(newValue);
                      }
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={APPOINTMENT_REVIEW_PLATFORM.APEX_AUTO}
                      aria-label="left aligned"
                    >
                      <FilterIcon
                        color={
                          selectedButton !==
                          APPOINTMENT_REVIEW_PLATFORM.APEX_AUTO
                            ? "#006CDD"
                            : "#fff"
                        }
                      />{" "}
                      <p style={{ margin: 0 }}>
                        {!isSmUp
                          ? "Filter Review"
                          : "Filter reviews through Apex Auto"}
                      </p>
                    </ToggleButton>
                    <ToggleButton
                      value={APPOINTMENT_REVIEW_PLATFORM.GOOGLE}
                      aria-label="centered"
                      style={{
                        background:
                          selectedButton === APPOINTMENT_REVIEW_PLATFORM.GOOGLE
                            ? "linear-gradient(81deg, #1A237E 36.53%, #4280EF 63.47%)"
                            : "",
                      }}
                    >
                      {!isSmUp ? (
                        <img src={googleImgSmall} alt="google-img" />
                      ) : (
                        <img src={googleImg} alt="google-img" />
                      )}
                      <p style={{ margin: 0 }}>
                        {!isSmUp
                          ? "Review on Google"
                          : "Review directly on Google"}
                      </p>
                    </ToggleButton>
                  </StyledToggleButtonGroupLaptop>
                ) : (
                  <StyledToggleButtonGroup
                    size="small"
                    value={selectedButton}
                    exclusive
                    onChange={(event, newValue) => {
                      // Only update the selectedButton if the new value is different
                      if (newValue && newValue !== selectedButton) {
                        console.log("🚀 ~ value:", newValue);
                        setSelectedButton(newValue);
                      }
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={APPOINTMENT_REVIEW_PLATFORM.APEX_AUTO}
                      aria-label="left aligned"
                    >
                      <FilterIcon
                        color={
                          selectedButton !==
                          APPOINTMENT_REVIEW_PLATFORM.APEX_AUTO
                            ? "#006CDD"
                            : "#fff"
                        }
                      />{" "}
                      <p style={{ margin: 0 }}>
                        {!isSmUp
                          ? "Filter Reviews"
                          : "Filter reviews through Apex Auto"}
                      </p>
                    </ToggleButton>
                    <ToggleButton
                      value={APPOINTMENT_REVIEW_PLATFORM.GOOGLE}
                      aria-label="centered"
                      style={{
                        background:
                          selectedButton === APPOINTMENT_REVIEW_PLATFORM.GOOGLE
                            ? "linear-gradient(245deg, #1A237E 34.49%, #4280EF 70.32%)"
                            : "",
                      }}
                    >
                      {!isSmUp ? (
                        <img
                          src={googleImgSmall}
                          alt="google-img-small"
                          style={{ height: "20px" }}
                        />
                      ) : (
                        <img src={googleImg} alt="google-img" />
                      )}
                      <p style={{ margin: 0 }}>
                        {!isSmUp
                          ? "Review on Google"
                          : "Review directly on Google"}
                      </p>
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Stack gap={"12px"}>
                  <Typography fontWeight={500}>Thank You Email</Typography>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <SwitchToggle
                      defaultChecked={thankuSwitch}
                      checked={thankuSwitch}
                      onChange={(e) => setThankuSwitch(e.target.checked)}
                    />
                    <Button
                      type="submit"
                      className="btn-text-size-manage"
                      onClick={() => {
                        setIsTesting(true);
                      }}
                      style={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        borderRadius: "4px",
                        background: " rgba(0, 0, 0, 0.10)",
                        boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                        color: thankuSwitch == false ? "grey" : "#000",
                        cursor:
                          thankuSwitch == false ? "not-allowed" : "pointer",
                      }}
                      disabled={thankuSwitch == false}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.4904 6.51082L5.84904 11.4432L10.3936 14.0736L14.4009 10.0652C14.6041 9.8621 14.8798 9.74804 15.1672 9.74814C15.4545 9.74824 15.7301 9.8625 15.9332 10.0658C16.1364 10.2691 16.2504 10.5447 16.2503 10.8321C16.2502 11.1195 16.136 11.395 15.9327 11.5982L11.9244 15.6065L14.5569 20.15L19.4904 6.51082ZM19.8403 4.07982C21.1349 3.61074 22.3894 4.86524 21.9203 6.15982L16.1981 21.9819C15.728 23.2797 13.9556 23.4379 13.2634 22.243L9.77829 16.2218L3.75712 12.7367C2.56221 12.0445 2.72037 10.2722 4.01821 9.80199L19.8403 4.07982Z"
                          fill={thankuSwitch == false ? "grey" : "#000"}
                        />
                      </svg>{" "}
                      Test Email
                    </Button>
                  </div>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  Reply to Email <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="shopEmail"
                  control={control}
                  rules={{
                    required: "Shop Email for Thank You is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                    validate: validateNoLeadingSpaces,
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Email"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      error={!!errors.shopEmail}
                      helperText={
                        errors.shopEmail ? errors.shopEmail.message : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                      disabled={thankuSwitch == false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Typography variant="body1" className="label-500">
                  Email Signature (From){" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>

                <div
                  style={{
                    border: error
                      ? "1px solid rgb(211,47,47)"
                      : "1px solid #ACACAC",
                    minHeight: "60px",
                    maxHeight: "80px",

                    padding: "12px",
                    borderRadius: "4px",
                    overflow: "auto",
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onChange={handleEditorChange}
                    blockStyleFn={blockStyleFn}
                    readOnly={thankuSwitch == false}
                    handlePastedText={handlePastedText}
                    customStyleMap={{
                      ROBOTO: {
                        fontFamily: "Roboto, sans-serif",
                      },
                    }}
                  />
                </div>

                {error && (
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "rgb(211,47,47)",
                    }}
                  >
                    Email Signature is required
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"end"}
            // spacing={2}
            style={{ marginTop: "10px" }}
          >
            <Grid item spacing={2}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn-text-size-manage"
                  onClick={cancelHandle}
                >
                  Cancel Changes
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className="btn-text-size-manage"
                  onClick={() => {
                    setIsTesting(false);
                  }}
                >
                  Save Changes
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>{" "}
      </form>{" "}
      {/* <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog> */}
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default EmailCustomer;
