import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import "./Appointment.css";

import { Add, Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Badge, Divider, Modal, Stack, Toolbar } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import CommonDialog from "../../../common/CommonDialog";
import { FormatNumberForKm } from "../../../components/FormatNumber/FormatNumber";
import {
  addServiceToAppointmentDetailApi,
  deleteServiceFromAppointmentDetailApi,
} from "../../../redux/appointment/action";
import {
  SHOP_SERVICE_PRICE_TYPES,
  USER_CREATED_FROM,
} from "../../../utility/Constant";
import { formatCurrency } from "../../../utility/helpers/displayHelper";
import Skeletons from "../../Common/Skeletons";
import GuestCustomerInfoModal from "../../Customers/GuestCustomers/GuestCustomerInfoModal";
import SaveCustomerConfirmModal from "../../Customers/GuestCustomers/SaveCustomerConfirmModal";
import CancelAppointment from "../../modal/CancelAppointment";
import ReseduleAppointmentModal from "../../modal/ReseduleAppointmentModal";
import ShortServiceModal from "../../Sidebar/ShortServiceModal";
import AddAssigneeModal from "../TodayAppointment/AddAssigneeModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  // pt: 2,
  // px: 4,
  // pb: 3,
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewUpcomingModal = ({
  fromCaledner = false,
  open,
  handleClose,
  handleOpen,
  id,
  upcomingData,
  fetchAppointmentDetails,
  handleCancelModel,
  recallDetailsAPI,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const navigate = useNavigate();
  const [showAssignee, setShowAssignee] = useState(false);
  const [isAssignChanged, setIsAssigneeChanged] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [saveConfirmModal, setSaveConfirmModal] = useState(false);

  const [actionState, setActionState] = useState({
    isActionSaved: false, // Indicates if an action is saved
    messageToSend: null, // Holds a string message to send
    rescheduleAction: null, // Holds a function to handle rescheduling
    authRequest: null, // Holds a string for authentication requests
  });

  //new appointment hooks
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [isServiceLoader, setIsServiceLoader] = useState(false);
  const [appointmentServices, setAppointmentServices] = useState(
    upcomingData?.services || []
  );
  console.log("🚀 ~ appointmentServices:", appointmentServices);
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePreviewPdf = () => {
    const pdfUrl = upcomingData?.estimate?.pdfUrl;
    window.open(pdfUrl, "_blank");
  };
  const handleCloseAssigneeModal = () => {
    setShowAssignee(false);
  };
  useEffect(() => {
    if (isAssignChanged) {
      if (recallDetailsAPI) {
        recallDetailsAPI();
      }
      setIsAssigneeChanged(false);
    }
  }, [isAssignChanged]);

  useEffect(() => {
    if (!open) {
      setValue(0);
      setAppointmentServices([]);
    } else {
      setAppointmentServices(upcomingData?.services || []);
    }
  }, [open, upcomingData]);

  useEffect(() => {
    if (open) {
      recallDetailsAPI();
    }
  }, [open]);

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          handleCancelModel();
          setValue(0);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, width: "896px" }}
          className="appointmentModalBodyBoxContainer"
        >
          <AppBar
            variant="outlined"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              background: "white",
              color: "black",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" gutterBottom={false} width={"98%"}>
                Appointment Details
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleCancelModel();
                    setValue(0);
                  }}
                  style={{
                    cursor: "pointer",
                    height: "32px",
                  }}
                >
                  <CloseIcon sx={{ fill: "rgb(0 0 0 / 56%)", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Grid
            container
            marginTop={"64px"}
            fontSize={"15px"}
            fontWeight={500}
            className="modalBody_container"
            minHeight={"500px"}
          >
            <Grid
              item
              md={4.13}
              sm={12}
              xs={12}
              padding={"24px"}
              style={{ background: "#F5F5F7" }}
              borderRight={"1px solid rgba(0, 0, 0, 0.23)"}
              className="appointmentModal_leftContainer"
              gap={2}
            >
              <Stack gap={"16px"}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  sx={{
                    height: "42px",
                    lineHeight: "26px",
                    letterSpacing: "0.46px",
                    fontSize: "15px",
                  }}
                  onClick={handleOpen2}
                >
                  cancel appointment
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: "42px",
                    lineHeight: "26px",
                    letterSpacing: "0.46px",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    if (
                      upcomingData?.customer?.createdFrom ===
                      USER_CREATED_FROM.SHOP_GUEST
                    ) {
                      setSaveConfirmModal(true);
                      setActionState({
                        isActionSaved: true,
                        messageToSend: null,
                        rescheduleAction: true,
                        authRequest: null,
                      });
                    } else {
                      handleOpen1();
                    }
                  }}
                >
                  reschedule
                </Button>

                {upcomingData?.hasEstimate && (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: "42px",
                      lineHeight: "26px",
                      letterSpacing: "0.46px",
                      fontSize: "15px",
                    }}
                    onClick={handlePreviewPdf}
                  >
                    VIEW ESTIMATE
                  </Button>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: "42px",
                    lineHeight: "26px",
                    letterSpacing: "0.46px",
                    fontSize: "15px",
                  }}
                  // onClick={() =>
                  //   Object.keys(upcomingData)?.length > 0 &&
                  //   navigate(`/messenger/${upcomingData?.customer?._id}`)
                  // }
                  onClick={() => {
                    if (
                      upcomingData?.customer?.createdFrom ===
                      USER_CREATED_FROM.SHOP_GUEST
                    ) {
                      setSaveConfirmModal(true);

                      setActionState({
                        isActionSaved: true,
                        messageToSend: `/messenger/${upcomingData?.customer?._id}`,
                        rescheduleAction: null,
                        authRequest: null,
                      });
                    } else {
                      Object.keys(upcomingData)?.length > 0 &&
                        navigate(`/messenger/${upcomingData?.customer?._id}`);
                    }
                  }}
                >
                  SEND MESSAGE
                </Button>
              </Stack>
              {!location.pathname
                .split("/")
                .includes("workOrderAuthorization") && (
                <Badge
                  invisible={
                    upcomingData?.workOrderAuthorization?.showUnreadBadge ==
                    true
                      ? false
                      : true
                  }
                  badgeContent={""}
                  color="error"
                >
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    // onClick={() =>
                    // Object.keys(upcomingData)?.length > 0 &&
                    // navigate(
                    //   `/appointment/workOrderAuthorization/${upcomingData?._id}`
                    // )
                    // }

                    onClick={() => {
                      if (
                        upcomingData?.customer?.createdFrom ===
                        USER_CREATED_FROM.SHOP_GUEST
                      ) {
                        setSaveConfirmModal(true);
                        setActionState({
                          isActionSaved: true,
                          messageToSend: null,
                          rescheduleAction: null,
                          authRequest: `/appointment/workOrderAuthorization/${upcomingData?._id}`,
                        });
                      } else {
                        Object.keys(upcomingData)?.length > 0 &&
                          navigate(
                            `/appointment/workOrderAuthorization/${upcomingData?._id}`
                          );
                      }
                    }}
                  >
                    {upcomingData?.workOrderAuthorization?._id
                      ? "VIEW AUTHORIZATION"
                      : "REQUEST AUTHORIZATION"}
                  </Button>
                </Badge>
              )}
            </Grid>
            {/* <Divider orientation="vertical" flexItem /> */}
            <Grid
              item
              md={7.8}
              sm={12}
              xs={12}
              padding={"24px"}
              className="appointmentModal_rightContainer"
            >
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      // style={{ fontSize: "14px" }}
                      label="OVERVIEW"
                      {...a11yProps(0)}
                    />
                    <Tab
                      // style={{ fontSize: "14px" }}
                      label="VEHICLE"
                      {...a11yProps(1)}
                    />
                    <Tab
                      // style={{ fontSize: "14px" }}
                      label="SERVICES"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {/* <Grid container item spacing={2}> */}
                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Customer
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        {upcomingData &&
                        upcomingData?.customer?.createdFrom ===
                          USER_CREATED_FROM.SHOP_GUEST ? (
                          <span
                            onClick={() => setOpenGuestModal(true)}
                            style={{
                              color: "#2196F3",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            {upcomingData?.customer?.firstName}{" "}
                            {upcomingData?.customer?.lastName}
                          </span>
                        ) : (
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            <Link
                              to={`/customers/${upcomingData?.customer?._id}/${
                                upcomingData?.customer?.createdFrom
                                  ? upcomingData?.customer?.createdFrom
                                  : "apex_auto_app"
                              }`}
                              style={{
                                color: "#2196F3",
                                textDecoration: "none",
                              }}
                            >
                              {upcomingData &&
                                upcomingData?.customer?.firstName +
                                  " " +
                                  upcomingData?.customer?.lastName}
                            </Link>
                          </Typography>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Vehicle
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        <Typography fontSize={"16px"} lineHeight={"24px"}>
                          {upcomingData?.vehicle?.year +
                            " " +
                            upcomingData?.vehicle?.vehicleMake?.name +
                            " " +
                            upcomingData?.vehicle?.vehicleModel?.name}
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Drop Off
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        <Typography fontSize={"16px"} lineHeight={"24px"}>
                          {upcomingData?.proposedropOffDateTime
                            ? moment(
                                upcomingData?.proposedropOffDateTime
                              ).format("MMM D, YYYY hh:mm A")
                            : upcomingData?.dropOffDateTime
                            ? moment(upcomingData?.dropOffDateTime).format(
                                "MMM D, YYYY hh:mm A"
                              )
                            : "-"}
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Estimated Pick Up
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        <Typography fontSize={"16px"} lineHeight={"24px"}>
                          {upcomingData?.proposePickupDateTime
                            ? moment(
                                upcomingData?.proposePickupDateTime
                              ).format("MMM D, YYYY hh:mm A")
                            : upcomingData?.pickupDateTime
                            ? moment(upcomingData?.pickupDateTime).format(
                                "MMM D, YYYY hh:mm A"
                              )
                            : moment(upcomingData?.shopClosingTime).format(
                                "MMM D, YYYY hh:mm A"
                              )}
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Customer Wants To
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        <Typography fontSize={"16px"} lineHeight={"24px"}>
                          {upcomingData?.waitingOnSite &&
                          upcomingData?.waitingOnSite == "dropping_off_car"
                            ? "Dropping Off Car"
                            : " Wait on Site "}
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Contact Preferences
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        <Typography fontSize={"16px"} lineHeight={"24px"}>
                          {upcomingData?.contactPreferences &&
                          upcomingData?.contactPreferences == "phone"
                            ? "Phone Call"
                            : upcomingData?.contactPreferences == "email"
                            ? "Email"
                            : "In-App Message"}
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Note from Customer
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        {upcomingData?.noteForMechanic ? (
                          <Typography
                            fontSize={"16px"}
                            border={"1px solid gray"}
                            padding={"10px 10px"}
                            borderRadius={"8px"}
                            lineHeight={"24px"}
                          >
                            {upcomingData?.noteForMechanic}
                          </Typography>
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Internal Shop Note
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        {upcomingData?.noteForCustomer ? (
                          <Typography
                            fontSize={"16px"}
                            border={"1px solid gray"}
                            padding={"10px 10px"}
                            borderRadius={"8px"}
                            lineHeight={"24px"}
                          >
                            {upcomingData?.noteForCustomer}
                          </Typography>
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="grid-detail-container">
                    <div className="left-side-grid-detail">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Assigned To
                      </Typography>
                    </div>
                    {upcomingData && Object.keys(upcomingData)?.length == 0 ? (
                      <Skeletons></Skeletons>
                    ) : (
                      <div className="right-side-grid-detail">
                        {upcomingData?.assignee &&
                        Array.isArray(upcomingData?.assignee) ? (
                          <Typography
                            fontSize={"16px"}
                            lineHeight={"24px"}
                            onClick={() => setShowAssignee(true)}
                            style={{
                              color: "#2196F3",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            {/* <Link
                            
                            style={{ color: "#2196F3", textDecoration: "none" }}
                          > */}
                            {upcomingData?.assignee[0]?.name}
                            {/* </Link> */}
                          </Typography>
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </div>
                    )}
                  </div>

                  {/* </Grid> */}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {/* <Grid container item spacing={2}> */}

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Year
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      <Typography fontSize={"16px"} lineHeight={"24px"}>
                        {upcomingData?.vehicle?.year
                          ? upcomingData?.vehicle?.year
                          : " - "}
                      </Typography>
                    </div>
                  </div>

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Make
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      <Typography fontSize={"16px"} lineHeight={"24px"}>
                        {upcomingData?.vehicle?.vehicleMake?.name
                          ? upcomingData?.vehicle?.vehicleMake?.name
                          : " - "}
                      </Typography>
                    </div>
                  </div>

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Model
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      <Typography fontSize={"16px"} lineHeight={"24px"}>
                        {upcomingData?.vehicle?.vehicleModel?.name
                          ? upcomingData?.vehicle?.vehicleModel?.name
                          : " - "}
                      </Typography>
                    </div>
                  </div>

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Trim
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      <Typography fontSize={"16px"} lineHeight={"24px"}>
                        {upcomingData?.vehicle?.vehicleModelTrim?.description
                          ? upcomingData?.vehicle?.vehicleModelTrim?.name
                          : " - "}
                      </Typography>
                    </div>
                  </div>

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Licence Plate Number
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      <Typography fontSize={"16px"} lineHeight={"24px"}>
                        {upcomingData?.vehicle?.licenseNumber
                          ? upcomingData?.vehicle?.licenseNumber
                          : " - "}
                      </Typography>
                    </div>
                  </div>

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        VIN
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      <Typography fontSize={"16px"} lineHeight={"24px"}>
                        {upcomingData?.vehicle?.vin
                          ? upcomingData?.vehicle?.vin
                          : " - "}
                      </Typography>
                    </div>
                  </div>

                  <div className="grid-detail-container">
                    <div className="vehicle-left-side-grid">
                      <Typography
                        fontSize={"14px"}
                        color={"#00000099"}
                        lineHeight={"143%"}
                        letterSpacing={"0.17px"}
                      >
                        Odometer
                      </Typography>
                    </div>
                    <div className="right-side-grid-detail">
                      {upcomingData?.vehicle?.odo &&
                      upcomingData?.vehicle?.odo !== "" ? (
                        <>
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {FormatNumberForKm(upcomingData?.vehicle?.odo)} km
                          </Typography>
                          Last Updated:{" "}
                          {moment(upcomingData?.vehicle?.odoUpdatedAt).format(
                            "MMM D, YYYY"
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      margin: "24px 0",
                      textAlign: "end",
                      textDecoration: "underline",
                      textUnderlineOffset: "2px",
                      textTransform: "capitalize",
                      color: "#006CDD",
                    }}
                  >
                    <p
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        cursor: "pointer",
                        margin: "0",
                        letterSpacing: "0.15px",
                        lineHeight: "175%",
                        fontWeight: 500,
                      }}
                    >
                      {showMore ? " Show Less" : "Show More"}
                    </p>
                  </div>
                  {showMore && (
                    <div>
                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Trim Description
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {upcomingData?.vehicle?.vehicleModelTrim
                              ? upcomingData?.vehicle?.vehicleModelTrim
                                  ?.description
                              : " - "}
                          </Typography>
                        </div>
                      </div>
                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {upcomingData?.vehicle?.vehicleModelTrim
                              ? upcomingData?.vehicle?.vehicleModelTrim
                                  ?.engineType
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Fuel Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {upcomingData?.vehicle?.vehicleModelTrim
                              ? upcomingData?.vehicle?.vehicleModelTrim
                                  ?.engineFuelType
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Drive Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {upcomingData?.vehicle?.vehicleModelTrim
                              ? upcomingData?.vehicle?.vehicleModelTrim
                                  ?.engineDriveType
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Engine Transmission
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {upcomingData?.vehicle?.vehicleModelTrim
                              ? upcomingData?.vehicle?.vehicleModelTrim
                                  ?.engineTransmission
                              : " - "}
                          </Typography>
                        </div>
                      </div>

                      <div className="grid-detail-container">
                        <div className="vehicle-left-side-grid">
                          <Typography
                            fontSize={"14px"}
                            color={"#00000099"}
                            lineHeight={"143%"}
                            letterSpacing={"0.17px"}
                          >
                            Body Type
                          </Typography>
                        </div>
                        <div className="right-side-grid-detail">
                          <Typography fontSize={"16px"} lineHeight={"24px"}>
                            {upcomingData?.vehicle?.vehicleModelTrim
                              ? upcomingData?.vehicle?.vehicleModelTrim
                                  ?.bodyType
                              : " - "}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* </Grid> */}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Grid container rowGap={2}>
                    <Grid
                      container
                      // justifyContent={"space-between"}
                      item
                      rowGap={3}
                      // boxShadow={2}
                      // padding={"16px"}
                      style={{ backgroundColor: "white", borderRadius: "4px" }}
                    >
                      <Grid item xs={6} sm={4} md={4}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#00000099",
                          }}
                          variant="title"
                        >
                          {" "}
                          Total Services
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "black",
                          }}
                          variant="title"
                        >
                          {appointmentServices?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    {appointmentServices?.map((item, index) => {
                      const hasQuestionAnswer =
                        Object.keys(item?.questionAnswer || {}).length > 0;
                      const hasValidPricing = item?.pricingType !== "no_price";

                      return (
                        <Grid
                          bgcolor={"#fff"}
                          border={"1px solid #C4C4C4"}
                          borderRadius={"4px"}
                          color={"black"}
                          container
                          item
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          padding={"15px"}
                          key={index}
                        >
                          <Grid container justifyContent={"space-between"}>
                            <Grid
                              item
                              md={6}
                              // style={{ height: !hasValidPricing ? "21px" : "" }}
                            >
                              <Typography
                                style={{ fontSize: "14px", fontWeight: 500 }}
                                variant="body1"
                              >
                                {item?.serviceName}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              style={{ height: !hasValidPricing ? "21px" : "" }}
                            >
                              <Typography
                                style={{ fontSize: "14px", fontWeight: 400 }}
                                variant="body1"
                              >
                                {item?.pricingType === "no_price" &&
                                !hasQuestionAnswer ? (
                                  <Delete
                                    style={{
                                      color: "#D32F2F",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (appointmentServices?.length > 1) {
                                        setSelectedServiceId(item?._id);
                                        setIsDeleteDialogOpen(true);
                                      } else {
                                        toast.error(
                                          "At least one service is required."
                                        );
                                      }
                                    }}
                                  />
                                ) : item?.pricingType ===
                                  SHOP_SERVICE_PRICE_TYPES.NO_PRICE ? (
                                  "No Price"
                                ) : item?.pricingType ===
                                  SHOP_SERVICE_PRICE_TYPES.STARTING_FROM ? (
                                  `Starting from: ${formatCurrency(
                                    item.priceMin
                                  )}`
                                ) : item?.pricingType ===
                                  SHOP_SERVICE_PRICE_TYPES.FIXED ? (
                                  `${formatCurrency(item.fixedPrice)}`
                                ) : item?.pricingType ===
                                  SHOP_SERVICE_PRICE_TYPES.RANGE ? (
                                  `Range ${formatCurrency(
                                    item.priceMin
                                  )} - ${formatCurrency(item.priceMax)}`
                                ) : (
                                  "-"
                                )}
                              </Typography>
                            </Grid>
                            {item?.description && (
                              <Grid item xs={12}>
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#646466",
                                    lineHeight: "20px",
                                    letterSpacing: "0.17px",
                                    marginTop: "4px",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                  variant="body1"
                                >
                                  {item?.description}
                                </Typography>
                              </Grid>
                            )}

                            {(hasQuestionAnswer || hasValidPricing) && (
                              <Divider
                                textAlign="center"
                                style={{
                                  color: "#0000001F",
                                  width: "100%",
                                  margin: "4px 0",
                                }}
                              />
                            )}

                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"flex-end"}
                            >
                              {hasQuestionAnswer ? (
                                <>
                                  <Grid item xs={10}>
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                      }}
                                      variant="body1"
                                    >
                                      {item?.questionAnswer?.question}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        marginTop: "4px",
                                      }}
                                      variant="body1"
                                    >
                                      {item?.questionAnswer?.answer}
                                    </Typography>
                                  </Grid>
                                  <Delete
                                    style={{
                                      color: "#D32F2F",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (appointmentServices?.length > 1) {
                                        setSelectedServiceId(item?._id);
                                        setIsDeleteDialogOpen(true);
                                      } else {
                                        toast.error(
                                          "At least one service is required."
                                        );
                                      }
                                    }}
                                  />
                                </>
                              ) : hasValidPricing ? (
                                <Grid
                                  xs={12}
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                >
                                  <Delete
                                    style={{
                                      color: "#D32F2F",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (appointmentServices?.length > 1) {
                                        setSelectedServiceId(item?._id);
                                        setIsDeleteDialogOpen(true);
                                      } else {
                                        toast.error(
                                          "At least one service is required."
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}

                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        startIcon={<Add />}
                        variant="contained"
                        color="primary"
                        onClick={() => setIsAddServiceModalOpen(true)}
                      >
                        ADD SERVICE
                      </Button>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ShortServiceModal
        loader={isServiceLoader}
        open={isAddServiceModalOpen}
        onClose={() => {
          setIsAddServiceModalOpen(false);
        }}
        onSave={async (service, obj) => {
          setIsServiceLoader(true);
          const resp = await dispatch(
            addServiceToAppointmentDetailApi(upcomingData?._id, { service })
          );
          if (resp) {
            // let cloneobj = { ...obj };
            // cloneobj.serviceName = obj.serviceSubCategory.name;
            // cloneobj.serviceId = obj.serviceSubCategory._id;
            // cloneobj.pricingType = obj.priceType;

            // setAppointmentServices((prev) => {
            //   const isAlreadyAdded = prev.some(
            //     (item) => item._id === cloneobj._id
            //   );
            //   return isAlreadyAdded ? prev : [...prev, cloneobj];
            // });
            setIsAddServiceModalOpen(false);
            recallDetailsAPI();
            setIsServiceLoader(false);
          }
        }}
      />
      <CommonDialog
        loader={isServiceLoader}
        open={isDeleteDialogOpen}
        onClose={() => {
          if (!isServiceLoader) {
            setIsDeleteDialogOpen(false);
          }
        }}
        onSave={async () => {
          setIsServiceLoader(true);
          const resp = await dispatch(
            deleteServiceFromAppointmentDetailApi(
              upcomingData?._id,
              selectedServiceId
            )
          );
          if (resp) {
            // recallDetailsAPI();
            setAppointmentServices((prev) =>
              prev.filter((item) => item._id !== selectedServiceId)
            );
            setSelectedServiceId(null);
            setIsDeleteDialogOpen(false);
          }
          setIsServiceLoader(false);
        }}
        dialogTitle={"Delete service? "}
        dialogSubtitle={
          "Are you sure you want to delete this service from the appointment?"
        }
      />
      <ReseduleAppointmentModal
        open={open1}
        handleClose={handleClose1}
        id={upcomingData?._id}
        handleClose2={handleClose}
        date={
          upcomingData?.proposedropOffDateTime
            ? upcomingData?.proposedropOffDateTime
            : upcomingData?.dropOffDateTime
        }
        recallDetailsAPI={() => {
          console.log("recalllllll");
          recallDetailsAPI();
        }}
        fromCaledner={fromCaledner}
      />
      <GuestCustomerInfoModal
        userData={upcomingData}
        openGuestModal={openGuestModal}
        setOpenGuestModal={setOpenGuestModal}
        showBtn
        showTag
        fetchAppointmentDetails={fetchAppointmentDetails}
      />

      <CancelAppointment
        open={open2}
        handleClose={handleClose2}
        id={upcomingData?._id}
        data={upcomingData}
        handleClose2={handleClose}
      />
      <AddAssigneeModal
        open={showAssignee}
        handleClose={handleCloseAssigneeModal}
        data={upcomingData}
        setIsAssigneeChanged={setIsAssigneeChanged}
      />
      <SaveCustomerConfirmModal
        userData={upcomingData}
        open={saveConfirmModal}
        setSaveConfirmModal={setSaveConfirmModal}
        userName={
          upcomingData?.customer?.firstName +
          " " +
          upcomingData?.customer?.lastName
        }
        setActionState={setActionState}
        handleClose={(resp) => {
          setSaveConfirmModal(false);
          if (resp !== "close") {
            if (actionState.isActionSaved) {
              if (actionState.authRequest) {
                navigate(actionState.authRequest);
              }
              if (actionState.messageToSend) {
                navigate(actionState.messageToSend);
              }
              if (actionState.rescheduleAction) {
                handleOpen1();
              }
            }
            // if (fromCaledner) {
            //   // recallDetailsAPI();
            // } else {
            if (!fromCaledner) {
              fetchAppointmentDetails();
            }
            // }
          } else {
            setActionState({
              isActionSaved: false,
              messageToSend: null,
              rescheduleAction: null,
              authRequest: null,
            });
          }
        }}
      />
    </Box>
  );
};

export default ViewUpcomingModal;
