import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 3,
  px: 3,
  pb: 3,
};

const DescriptionModal = ({
  open,
  saveIndex,
  serviceList,
  setServiceList,
  onClose,
  onSave,
  updateIndex,
  setUpdateIndex,
  discountProgram,
  handleSubmitProgram,
}) => {
  const [storeDescrption, setStoreDescription] = useState(""); // State to handle program name error
  useEffect(() => {
    setStoreDescription(serviceList[saveIndex]?.description ?? "");
  }, [saveIndex]);

  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: "720px", height: "fit-content" }}>
        <AppBar
          variant="elevation"
          color="secondary"
          sx={{
            height: "64px",
            paddingRight: "0px !important",
          }}
        >
          <Toolbar variant="regular">
            <Typography
              variant="h6"
              gutterBottom={false}
              width={"98%"}
              color={"white"}
              fontSize={"20px"}
              lineHeight={"32px"}
            >
              Add Description
            </Typography>
            <Stack direction={"row"} spacing={0}>
              <div
                style={{ cursor: "pointer", height: "32px" }}
                onClick={() => handleClose()}
              >
                <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>

        <Container
          gap={4}
          disableGutters
          style={{
            height: "fit-content",
            marginTop: "66px",
            gap: "24px",
          }}
        >
          <Typography variant="body1" className="label-500">
            Description
          </Typography>
          <TextField
            multiline
            fullWidth
            style={{ marginTop: "3px" }}
            variant="outlined"
            value={storeDescrption}
            onChange={(event) => setStoreDescription(event.target.value)}
          />

          <Grid
            container
            justifyContent={"end"}
            spacing={2}
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item spacing={2}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClose()}
                >
                  Cancel{" "}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setServiceList((prevList) =>
                      prevList.map((service, i) =>
                        i === saveIndex
                          ? {
                              ...service,
                              description: storeDescrption,
                            }
                          : service
                      )
                    );
                    handleClose();
                  }}
                >
                  SAVE{" "}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Modal>
  );
};

export default DescriptionModal;
