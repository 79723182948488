import { Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Modal,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { REQUIRED_RED } from "../../App";
import {
  createServiceSubcategory,
  getAllShopServiceApi,
} from "../../redux/Account/ServiceOffered/action";
import { CustomPaper } from "../../utility/SelectDropdown";

const ShortServiceModal = ({ loader, open, onClose, onSave }) => {
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subCategoryObject, setSubCategoryObject] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showMenuLoader, setShowMenuLoader] = useState(false);
  const [savingService, setSavingService] = useState(false); // Loader for new service creation

  const cachedServices = JSON.parse(localStorage.getItem("allServices"));

  useEffect(() => {
    if (open) {
      if (cachedServices?.length > 0) {
        setServices(cachedServices);
      }
      setSubCategoryId(null);
      setSubCategoryObject(null);
      fetchAllService();
    }
  }, [open]);

  const fetchAllService = async (string, resp) => {
    const response = await dispatch(
      getAllShopServiceApi({
        pagination: false,
        limit: 2000,
        sortBy: "name",
        sortType: "ASC",
      })
    );
    const serviceData = response?.data || [];
    localStorage.setItem("allServices", JSON.stringify(serviceData));

    setServices(serviceData);
    if (string && string !== "") {
      setFilteredOptions(
        serviceData.filter((service) =>
          service?.serviceSubCategory?.name
            .toLowerCase()
            .includes(string?.toLowerCase())
        )
      );
      setSubCategoryObject(
        serviceData.find((service) =>
          service?.serviceSubCategory?.name
            .toLowerCase()
            .includes(string?.toLowerCase())
        )
      );
    } else {
      // setSubCategoryObject(serviceData);
      setFilteredOptions(serviceData);
    }
  };

  const handleSaveService = async (serviceName) => {
    setShowMenuLoader(true); // Start saving process
    let payload = { name: serviceName };
    const resp = await dispatch(createServiceSubcategory(payload));

    if (resp) {
      await fetchAllService(resp?.name, resp); // Fetch all services again to include the new one

      // Ensure the new service is selected
      setSubCategoryId(resp?._id || null);
      setInputValue(resp?.name || "");
    }

    setShowMenuLoader(false); // End saving process
    const autocompleteInput = document.getElementById("combo-box-demo");
    if (autocompleteInput) {
      autocompleteInput.blur();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!showMenuLoader || loader) {
          onClose();
        }
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px 8px 0px 0px",
        }}
        className="modalBodyBoxContainer"
      >
        <AppBar
          color="secondary"
          variant="elevation"
          sx={{
            height: "64px",
            borderRadius: "8px 8px 0px 0px !important",
            paddingRight: "0px !important",
          }}
        >
          <Toolbar variant="regular">
            <Typography variant="h6" width={"98%"} color={"white"}>
              Add Service
            </Typography>
            <Stack direction={"row"}>
              <div
                onClick={() => {
                  if (!showMenuLoader || loader) {
                    onClose();
                  }
                }}
                style={{ cursor: "pointer", height: "32px" }}
              >
                <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>

        <Container disableGutters sx={{ mt: "67px" }}>
          <form>
            <Grid container justifyContent={"start"} spacing={3} p={3} pb={0}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    Service Name <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <FormControl fullWidth>
                    <Autocomplete
                      PaperComponent={CustomPaper}
                      disablePortal
                      id="combo-box-demo"
                      options={filteredOptions}
                      loading={showMenuLoader} // Show loader while saving new service
                      loadingText={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                          }}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      }
                      getOptionLabel={(option) =>
                        option?.serviceSubCategory?.name || ""
                      }
                      value={
                        services.find(
                          (s) => s?.serviceSubCategory?._id === subCategoryId
                        ) || null
                      }
                      inputValue={inputValue}
                      onInputChange={(event, newValue, reason) => {
                        if (reason === "reset" && !subCategoryId) {
                          setInputValue("");
                          setFilteredOptions(services);
                        } else {
                          setInputValue(newValue);
                          setFilteredOptions(
                            services.filter((service) =>
                              service?.serviceSubCategory?.name
                                .toLowerCase()
                                .includes(newValue.toLowerCase())
                            )
                          );
                        }
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault(); // Prevent form submission

                          if (subCategoryId && subCategoryObject) {
                            onSave(subCategoryId, subCategoryObject);
                          } else if (
                            filteredOptions.length === 0 &&
                            inputValue &&
                            !services.find(
                              (d) =>
                                d?._id === subCategoryId &&
                                d?.serviceSubCategory?.name === inputValue
                            )
                          ) {
                            handleSaveService(inputValue);
                          } else if (
                            filteredOptions.length === 1 &&
                            filteredOptions[0]?.serviceSubCategory._id &&
                            filteredOptions[0]?.serviceSubCategory._id !==
                              "new" &&
                            filteredOptions[0]?.serviceSubCategory?.name
                          ) {
                            setSubCategoryId(
                              filteredOptions[0]?.serviceSubCategory._id || null
                            );
                            setInputValue(
                              filteredOptions[0]?.serviceSubCategory?.name
                            );

                            setFilteredOptions(
                              services.filter((service) =>
                                service?.serviceSubCategory?.name
                                  .toLowerCase()
                                  .includes(
                                    filteredOptions[0]?.serviceSubCategory?.name.toLowerCase()
                                  )
                              )
                            );
                            const autocompleteInput =
                              document.getElementById("combo-box-demo");
                            if (autocompleteInput) {
                              autocompleteInput.blur();
                            }
                          }
                        }
                      }}
                      filterOptions={(options, { inputValue }) => {
                        let filtered = options.filter((service) =>
                          service?.serviceSubCategory?.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        );

                        if (
                          inputValue &&
                          !filtered.some(
                            (s) =>
                              s?.serviceSubCategory?.name.toLowerCase() ===
                              inputValue.toLowerCase()
                          )
                        ) {
                          filtered = [
                            {
                              serviceSubCategory: {
                                name: inputValue,
                                new: true,
                              },
                            },
                            ...filtered,
                          ];
                        }
                        return filtered;
                      }}
                      onChange={(event, newValue) => {
                        setSubCategoryId(
                          newValue?.serviceSubCategory._id || null
                        );
                        setSubCategoryObject(newValue || null);
                        setInputValue(newValue?.serviceSubCategory?.name || "");
                        setFilteredOptions(
                          services.filter((service) =>
                            service?.serviceSubCategory?.name
                              .toLowerCase()
                              .includes(
                                newValue?.serviceSubCategory?.name.toLowerCase()
                              )
                          )
                        );
                      }}
                      onOpen={() => {
                        setFilteredOptions(
                          services.filter((service) =>
                            service?.serviceSubCategory?.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        );
                      }}
                      ListboxProps={{
                        style: { maxHeight: "300px" },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                      renderOption={(props, option, index) => {
                        if (showMenuLoader && option?.serviceSubCategory?.new) {
                          return (
                            <Box
                              key={`option-${index}`}
                              sx={{
                                height: "44px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <CircularProgress size={30} />
                            </Box>
                          );
                        } else if (
                          !showMenuLoader &&
                          option?.serviceSubCategory?.new
                        ) {
                          return (
                            <Box
                              key={`option-${index}`}
                              padding={0}
                              component="li"
                              {...props}
                              onClick={(e) => e.stopPropagation()} // Prevent selection
                              sx={{
                                height: "44px",
                                width: "100%",
                                cursor: "default",
                              }}
                              style={{
                                paddingRight: "0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>{option?.serviceSubCategory?.name}</div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "6px 16px",
                                  background: "rgba(33, 150, 243, 0.15)",
                                  cursor: "pointer",
                                  pointerEvents: "all",
                                }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  handleSaveService(
                                    option.serviceSubCategory.name,
                                    index
                                  );
                                }}
                              >
                                <Save
                                  sx={{
                                    color: "#006CDD",
                                    width: "22px",
                                    height: "22px",
                                  }}
                                />
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    color: "#006CDD",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "22px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  Save
                                </span>
                              </div>
                            </Box>
                          );
                        } else if (!showMenuLoader) {
                          return (
                            <Box
                              key={`option-${index}`}
                              component="li"
                              {...props}
                              sx={{
                                height: "44px",
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {option?.serviceSubCategory?.name}
                            </Box>
                          );
                        }
                      }}
                    />
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container justifyContent={"flex-end"} spacing={2} p={3}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (!showMenuLoader || loader) {
                      onClose();
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={loader}
                  color="primary"
                  style={{ width: "79.75px" }}
                  onClick={() => {
                    if (!showMenuLoader || loader) {
                      onSave(subCategoryId, subCategoryObject);
                    }
                  }}
                >
                  {loader ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </Modal>
  );
};

export default ShortServiceModal;
