import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { REQUIRED_RED } from "../../../App";
import { customerCreate } from "../../../redux/Customer/action";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";
import SuccessMessageModal from "../../Common/SuccessMessage";
import VehicleModelImport from "../../modal/VehicleModalmport";
import GoogleAutoComplete from "../NewCustomers/GoogleAutoComplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pt: 2,
  px: 4,
  pb: 3,
};

const NewImportedCustomers = () => {
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [completeAddress, setCompleteAddress] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editVehicle, setEditVehicle] = useState({
    edit: false,
    editIndex: "",
  });
  const [notes, setNotes] = useState([]);
  const [noteError, setNoteError] = useState(false);
  const [newNote, setNewNote] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedActionOfNote, setSelectedActionOfNote] = useState("");
  const [editDeleteNoteId, setEditDeleteNoteId] = useState("");
  const [editNoteText, setEditNoteText] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditVehicle({ edit: false, editIndex: "" });
  };
  const handleClickNoteActions = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedActionOfNote(data);
  };
  const handleCloseNoteActions = () => {
    setAnchorEl(null);
    setSelectedActionOfNote("");
  };
  const handleDeleteVehicle = (index) => {
    const data = vehicles[index];
    const filteredData = vehicles?.filter((d) => d != data);
    // console.log("filteredData", filteredData);
    setVehicles(filteredData);
    handleClose();
  };
  const handleAddNote = async () => {
    if (!newNote) {
      return setNoteError(true);
    }
    setNoteError(false);

    setNotes([...notes, { note: newNote, createdAt: new Date() }]);
    setNewNote("");
  };

  const deleteNote = (selectedActionOfNote) => {
    const index = notes.indexOf(selectedActionOfNote);
    notes.splice(index, 1);
  };

  const handleEditNote = () => {
    // console.log("notes", notes, editDeleteNoteId);
    const allNotes = [...notes];
    let findIndex = allNotes.indexOf(editDeleteNoteId);
    // console.log("findIndex", findIndex);
    let data = { ...allNotes[findIndex], note: editNoteText.trim() };
    allNotes[findIndex] = data;
    // allNotes[0].note="sdsds"
    setNotes(allNotes);
    setSelectedActionOfNote("");
    setEditDeleteNoteId("");
    setEditNoteText("");
  };

  // console.log("completeAddress", completeAddress);
  useEffect(() => {
    if (Object.keys(completeAddress).length > 0) {
      const country = completeAddress.address_components.filter(
        (country) => country.types[0] == "country"
      );
      const postalCode = completeAddress.address_components.filter(
        (postal) => postal.types[0] == "postal_code"
      );
      const provinceName = completeAddress.address_components.filter(
        (data) => data.types[0] == "administrative_area_level_1"
      );
      const cityName = completeAddress.address_components.filter(
        (data) => data.types[0] == "locality"
      );
      const route = completeAddress.address_components.filter(
        (data) => data.types[0] == "route"
      );

      const streetNumber = completeAddress.address_components.filter(
        (data) => data.types[0] == "street_number"
      );

      let completeStreetAddress = "";
      if (streetNumber.length != 0) {
        completeStreetAddress =
          completeAddress + streetNumber?.[0]?.long_name + " ";
      }
      if (route.length != 0) {
        completeStreetAddress =
          completeStreetAddress + " " + route[0]?.long_name + " ";
      }
      // console.log("completeStreetAddress", completeStreetAddress);
      setValue("city", cityName?.[0]?.long_name);
      setValue("province", provinceName?.[0]?.long_name);
      setValue("postalCode", postalCode?.[0]?.long_name);
      setValue("country", country?.[0]?.long_name);
      setValue("streetAddress", completeStreetAddress);
    }
  }, [completeAddress]);
  // console.log("complete address", completeAddress);

  const onSubmit = async (values) => {
    let payload = {
      ...values,
      addressDetailObject: {
        address_components: completeAddress?.address_components,
        formatted_address: completeAddress?.formatted_address,
        geometry: {
          location: {
            lat: completeAddress.geometry?.location?.lat(),
            lng: completeAddress.geometry?.location?.lng(),
          },
          viewport: {
            south: completeAddress.geometry?.viewport?.getSouthWest().lat(),
            west: completeAddress.geometry?.viewport?.getSouthWest().lng(),
            north: completeAddress.geometry?.viewport?.getNorthEast().lat(),
            east: completeAddress.geometry?.viewport?.getNorthEast().lng(),
          },
        },
        // name:completeAddress?.formatted_address
        place_id: placeId,
      },
      addressLatLong: {
        latitude: completeAddress.geometry?.location?.lat(),
        longitude: completeAddress.geometry?.location?.lng(),
      },
      notes: [],
      vehicle: [],
      countryCode: "+1",
    };

    notes?.map((notee) => {
      payload.notes.push({ notes: notee?.note });
    });
    vehicles?.map((vehicle) => {
      let data = {
        vin: vehicle?.vin,
        year: vehicle?.year + "",
        vehicleMakeId: vehicle?.vehicleMakeId?.id,
        vehicleModelId: vehicle?.vehicleModelId?.id,
        vehicleModelTrimId: vehicle?.vehicleModelTrimId?.id,
        licenseNumber: vehicle?.licenseNumber,
      };
      payload?.vehicle.push(data);
    });
    console.log("payload", payload);
    setShowLoader(true);
    const resp = await dispatch(customerCreate(payload));
    if (resp) {
      navigate("/customers");
      setOpenSuccessModal(true);
      setSuccessMessage("Customer Created");
    }
    setShowLoader(false);
  };
  return (
    <>
      <Box padding={"32px 24px"}>
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12} paddingBottom={"8px"}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/customers"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Customers
                </Link>
                <Link
                  to="/customers/new-customer"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Edit Customer
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} fontWeight={"bold"}>
            <Typography sx={{ fontSize: "34px", fontWeight: 500 }}>
              Edit Customer
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            // spacing={2}
            // sx={{ boxShadow: 2 }}
            justifyContent={"start"}
            // border={"1px solid gray"}
            // borderBottom={"2px solid gray"}
            boxShadow={3}
            borderTop={"none"}
            padding={"24px"}
            borderRadius={"10px"}
            marginTop={"32px"}
          >
            <Grid item xs={12} sm={12} md={3} xl={3} paddingTop={"24px"}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 400, fontSize: "24px" }}
              >
                Basic Details
              </Typography>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                xl={12}
                marginTop={"370px"}
              >
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Current Address
                  </Typography>
                  <Box
                    height={"150px"}
                    width={"100%"}
                    sx={{ backgroundColor: "#f3f5fd" }}
                  >
                    <Typography
                      padding={"10px"}
                      variant="body1"
                      className="label-500"
                      sx={{ padding: "10px" }}
                    >
                      {completeAddress?.formatted_address}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={9}
              xl={9}
              spacing={0}
              // columnGap={1}

              rowGap={2}
              padding={"24px"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} sm={12} md={5.7} lg={5.7} xl={5.7}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  First Name <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "First name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="First Name"*/
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.firstName}
                      helperText={
                        errors.firstName ? errors.firstName.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5.7} lg={5.7} xl={5.7}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Last Name <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Last name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="Last Name"*/

                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} xl={4}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Phone Number <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: "Phone Number is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        /*label="Phone Number"*/
                        //  onChange={(e)=>console.log("ee",e)}
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.phoneNumber}
                        helperText={
                          errors.phoneNumber ? errors.phoneNumber.message : ""
                        }
                        {...field}
                        onChange={(e) => {
                          setValue(
                            "phoneNumber",
                            formatPhoneNumber(e.target.value)
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Email <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="Email"*/
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Company <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: "Company is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="Email"*/
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={errors.company ? errors.company.message : ""}
                      {...field}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Country <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: "Country is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                     
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.email}
                        helperText={errors.country ? errors.country.message : ""}
                        {...field}
                      />
                    )}
                  />
                </Grid> */}
              {/* <Divider
                  style={{
                    width: "100%",
                    paddingBottom: "16px",
                    marginBottom: "16px",
                  }}
                /> */}

              {/* <Grid item xs={12} sm={6} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Country
                  </Typography>
                  <Controller
                    name="country"
                    control={control}
              
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                   
                        // disabled
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.country}
                        helperText={errors.country ? errors.country.message : ""}
                        {...field}
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                      />
                    )}
                  />
                </Grid> */}
              <Grid item xs={12} sm={6} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Street Address <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="streetAddress"
                  control={control}
                  rules={{
                    required: "Street Address is required",
                  }}
                  render={({ field }) => (
                    <GoogleAutoComplete
                      setCompleteAddress={setCompleteAddress}
                      setPlaceId={setPlaceId}
                      required
                      error={!!errors.streetAddress}
                      helperText={
                        errors.streetAddress ? errors.streetAddress.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} spacing={2}>
                <Grid item xs={12} sm={6} md={5} xl={5}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    City
                  </Typography>
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: "City is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        /*label="City"*/
                        variant="outlined"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                        error={!!errors.city}
                        helperText={errors.city ? errors.city.message : ""}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} xl={3}>
                  {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={select_category}
                  fullWidth
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Province" />
                  )}
                /> */}
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Province
                  </Typography>
                  <Controller
                    name="province"
                    control={control}
                    rules={{
                      required: "Province is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        /*label="Province"*/
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.province}
                        helperText={
                          errors.province ? errors.province.message : ""
                        }
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Postal Code
                  </Typography>
                  <Controller
                    name="postalCode"
                    control={control}
                    rules={{
                      required: "Postal Code is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        /*label="Postal Code"*/
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.postalCode}
                        helperText={
                          errors.postalCode ? errors.postalCode.message : ""
                        }
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* <Grid container justifyContent={"end"} spacing={2}>
                  <Grid item spacing={2}>
                    <Stack spacing={2} direction="row">
                      <Button variant="outlined" color="primary" size="large">
                        Cancel Changes
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </Stack>
                  </Grid>
                </Grid> */}
            </Grid>
          </Grid>
        </form>

        <Grid
          container
          // spacing={2}
          sx={{ boxShadow: 3 }}
          justifyContent={"start"}
          // border={"1px solid gray"}
          // borderBottom={"2px solid gray"}
          borderTop={"none"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
        >
          <Grid item xs={6} sm={6} md={3} xl={3}>
            <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
              Vehicle
            </Typography>
            <Grid item container xs={12} sm={12} md={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Available vehicle
                </Typography>
                <Box
                  height={"60px"}
                  width={"100%"}
                  sx={{
                    backgroundColor: "#f3f5fd",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body1"
                    className="label-500"
                    paddingTop={"15px"}
                  >
                    {" "}
                    2023 Acura ILX Hybrid
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6} sm={6} md={9} xl={9} rowGap={3}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              justifyContent={"end"}
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  size="large"
                >
                  ADD NEW VEHICLE
                </Button>
                <VehicleModelImport
                  open={open}
                  handleClose={handleClose}
                  setVehicles={setVehicles}
                  vehicles={vehicles}
                  editVehicle={editVehicle}
                  setEditVehicle={setEditVehicle}
                />
              </Grid>
            </Grid>

            {Array.isArray(vehicles) &&
              vehicles?.map((vehicle, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    // spacing={2}
                    rowGap={2}
                  >
                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <Stack spacing={1.5}>
                        <Typography variant="h6">
                          {vehicle?.year} {vehicle?.vehicleMakeId?.name}{" "}
                          {vehicle?.vehicleModelId?.name}
                        </Typography>
                        {vehicle?.vin && (
                          <Typography variant="subtitle1" fontWeight={500}>
                            VIN: {vehicle?.vin}
                          </Typography>
                        )}
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Licence Plate Number: {vehicle?.licenseNumber}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item spacing={2} direction={"row"}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="contained"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            handleOpen();
                            setEditVehicle({ edit: true, editIndex: index });
                          }}
                          size="large"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => {
                            handleDeleteVehicle(index);
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                  </Grid>
                );
              })}
            {/* <Divider style={{ width: "100%" }} /> */}
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"end"}
          marginTop={"32px"}
          paddingY={"7.5px"}
          paddingRight={"24px"}
          boxShadow={3}
          borderRadius={"10px"}
          // borderBottom={"2px solid gray"}
        >
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default NewImportedCustomers;
